import _ from 'lodash'
import { reactive, Ref, ref } from 'vue'

import { AnnotatedSegment } from '@/util/AnnotatedSegment'
import { RecorderService } from '@/util/RecorderService'
import { RecorderServiceOptions } from '@/util/Recording'
import { regularStyle, userSpeakingStyle } from '@/util/TextStyles'

// const colorParse = require('color-parse');

const SHORT_WORD_EXTRA_SENSITIVITY_LENGTH = 5
const SHORT_WORD_SENSITIVITY_MULTIPLIER = 0.5

function useFollower(recorderService: Ref<RecorderService>) {
  const currentStyle: Ref<string> = ref('')
  const currentSentenceIndex: Ref<number> = ref(-1)
  const currentSegmentIndex: Ref<number> = ref(-1)
  const currentElement: Ref<HTMLElement | null> = ref(null)
  // const currentBackgroundColor: Ref<string> = ref('')
  const baseLevel: Ref<number | null> = ref(null)
  const triggerLevel: Ref<number | null> = ref(null)
  const betweenVoiceStartAndStop: Ref<boolean> = ref(false)
  // const silenceStartedAt: Ref<Date | null> = ref(null)

  function followerSegmentStyle(style: string, _: AnnotatedSegment, sentenceIndex: number, segmentIndex: number): string {
    if (sentenceIndex === currentSentenceIndex.value && segmentIndex === currentSegmentIndex.value) {
      return currentStyle.value
    }
    return style
  }

  function setCurrentSegment(sentenceIndex: number, segmentIndex: number, annotatedSegment: AnnotatedSegment | null = null): void {
    currentSentenceIndex.value = sentenceIndex
    currentSegmentIndex.value = segmentIndex

    // make the sound EMA more sensitive for short words
    if (annotatedSegment && annotatedSegment.nChars() <= SHORT_WORD_EXTRA_SENSITIVITY_LENGTH) {
      if (recorderService.value) {
        recorderService.value.setSoundEwmaHalfLifeMultiplier(SHORT_WORD_SENSITIVITY_MULTIPLIER)
      }
    }
  }

  const followerOptions = reactive<RecorderServiceOptions>({
    audioFilters: false,
    onBaseLevel: (bl: number, __: number) => {
      // this.$log.info('followerMixin.onBaseLevel');
      baseLevel.value = bl
    },

    onError: (_: Error) => {
      // this.$log.info('followerMixin.onError');
    },

    onRecordingReady: async (_: Event) => {
      // this.$log.info('followerMixin.onRecordingReady');
    },

    onSoundUpdate: (_: number) => {
      // if (!this.betweenVoiceStartAndStop) {
      //   return;
      // }
      //
      // // calculate the alpha for the background
      // // this.$log.info(`onSoundUpdate, n = ${n}`);
      // const triggerLevel = this.triggerLevel ? this.triggerLevel : 0;
      // // this.$log.info(`onSoundUpdate, triggerLevel = ${triggerLevel}`);
      // const range = 1.0 - this.triggerLevel > 0 ? 1.0 - this.triggerLevel : 0;
      // // this.$log.info(`onSoundUpdate, range = ${range}`);
      // const min = 0.4; // don't make opacity less than this
      // const perc = (n - triggerLevel) / range;
      // // this.$log.info(`onSoundUpdate, perc = ${perc}`);
      // const unit = 1.0 - min;
      // // this.$log.info(`onSoundUpdate, unit = ${unit}`);
      // const alpha = manualSegmentation ? 1.0 : min + unit * perc;
      // // this.$log.info(`onSoundUpdate, alpha = ${alpha}`);
      // // set the new alpha
      // const rgb = colorParse(this.currentBackgroundColor);
      // const rgba = `rgba(${rgb.values[0]}, ${rgb.values[1]}, ${rgb.values[2]}, ${alpha})`;
      // // this.$log.info(`onSoundUpdate, rgba = ${rgba}`);
      // this.currentBackgroundColor = rgba;
      // this.currentElement.style.backgroundColor = this.currentBackgroundColor;
    },

    onStart: () => {
      // this.$log.info('followerMixin.onStart');
    },

    onStartRecording: () => {
      // this.$log.info('followerMixin.onStartRecording');
      setCurrentSegment(currentSentenceIndex.value, currentSegmentIndex.value, null)
    },

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onStartSilence: () => {},

    onStopRecording: () => {
      // this.$log.info('followerMixin.onStopRecording');
      currentStyle.value = regularStyle
    },

    onTriggerLevel: (tl: number) => {
      // this.$log.info('followerMixin.onTriggerLevel');
      triggerLevel.value = tl
    },

    onVoiceStart: (elementId: string) => {
      // this.$log.info(`onVoiceStart, elementId = ${elementId}`);
      betweenVoiceStartAndStop.value = true
      currentElement.value = document.getElementById(elementId)
      currentStyle.value = userSpeakingStyle
    },

    onVoiceStop: (manualSegmentation = false) => {
      return new Promise<void>((resolve) => {
        betweenVoiceStartAndStop.value = false
        currentStyle.value = regularStyle
        if (manualSegmentation) {
          return
        }
        currentElement.value = null
        resolve()
      })
    },

    voiceActivityDetection: true,
  } as RecorderServiceOptions)

  return {
    baseLevel,
    betweenVoiceStartAndStop,
    currentElement,
    currentSegmentIndex,
    currentSentenceIndex,
    currentStyle,
    followerOptions,
    followerSegmentStyle,
    setCurrentSegment,
    triggerLevel,
  }
}

export { useFollower }
