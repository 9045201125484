import { HaftarahType, ParashahType } from 'types/types'

export interface CreateHaftarahParameters {
  comment: string
  id: number
  parashah: ParashahType
  section: string
  username: string
  variant: string
  year: number
}

export function formatHaftarahForTeacher(haftarah: HaftarahType): string {
  let h = `${haftarah.parashah.heTitle} / ${haftarah.parashah.transliteratedTitle} @ ${haftarah.year}`
  if (haftarah.variant === 'A') {
    h += ' (Askhenazi)'
  } else if (haftarah.variant === 'S') {
    h += ' (Sephardic)'
  }

  if (haftarah.comment) {
    h += ` / ${haftarah.comment}`
  }

  h += `: ${haftarah.section}`

  return h
}

export function formatHaftarahForStudent(haftarah: HaftarahType): string {
  return `${haftarah.parashah.transliteratedTitle} / [${haftarah.parashah.heTitle}]`
}

export function formatHaftarahSimple(haftarah: HaftarahType): string {
  return `${formatHaftarahForStudent(haftarah)} @ ${haftarah.year}`
}
