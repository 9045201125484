import { createDirectStore } from 'direct-vuex'

// import createMultiTabState from 'vuex-multi-tab-state'
import admin from './admin'
import audio from './audio'
import dialog from './dialog'
import snackbar from './snackbar'
import staff from './staff'
import student from './student'
import style from './style'
import teacher from './teacher'
import user from './user'

const modules = {
  admin,
  audio,
  dialog,
  snackbar,
  staff,
  student,
  style,
  teacher,
  user,
}

const skipClean = {
  dialog: true,
  snackbar: true,
}

const { moduleActionContext, moduleGetterContext, rootActionContext, rootGetterContext, store } = createDirectStore({
  modules,
  // this seems to lead to multiple tabs updating store.audio and causing endless loops for both tabs;
  // we used to use this to cause tabs to log in/out as other tabs logged in and out
  // the primary problem seems to be that the scope isn't limited to user.user and I don't know how to fix it, but
  // plugins: [createMultiTabState({ statesPaths: ['user.user'] })],
})

export const reset = () => {
  Object.keys(modules).forEach((moduleName) => {
    // @ts-expect-error: error
    if (!skipClean[moduleName]) {
      // @ts-expect-error: error
      store.dispatch[moduleName].reset()
    }
  })
}

// Export the direct-store instead of the classic Vuex store.
export default store

// The following exports will be used to enable types in the
// implementation of actions and getters.
export { moduleActionContext, moduleGetterContext, rootActionContext, rootGetterContext }

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store
declare module 'vuex' {
  interface Store<S> {
    direct: AppStore
  }
}
