import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import _ from 'lodash'

import { useApollo } from '@/util/apolloClient'
import { CreateTeacherParameters } from '@/util/Teacher'
const { apolloClient } = useApollo()

import CreateTeacher from '../mutations/CreateTeacher.graphql'

export interface AdminState {
  error: string
  updating: boolean
}

const resetState = (): AdminState => {
  return {
    error: '',
    updating: false,
  }
}

const admin = defineModule({
  actions: {
    createTeacher(context, payload: CreateTeacherParameters): Promise<boolean> {
      // eslint-disable-next-line no-use-before-define
      const { commit } = adminActionContext(context)

      commit.setUpdating(true)
      return new Promise<boolean>((resolve) => {
        const p = apolloClient.mutate({
          mutation: CreateTeacher,
          variables: {
            userData: {
              email: payload.email.toLowerCase(),
              firstName: payload.firstName,
              lastName: payload.lastName,
              username: payload.username.toLowerCase(),
            },
          },
        })

        p.then((response) => {
          const { errors, ok } = response.data.createTeacher
          if (ok) {
            // do nothing; we don't store locally
          }
          commit.setError(errors)
          resolve(ok)
        }).catch((error) => {
          commit.setError(error.message)
          resolve(false)
        })
        commit.setUpdating(false)
      })
    },

    reset(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = adminActionContext(context)
      commit.reset()
    },

    resetError(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = adminActionContext(context)
      commit.setError('')
    },
  },
  getters: {
    error(...args): string {
      // eslint-disable-next-line no-use-before-define
      const { state } = adminGetterContext(args)
      return state.error
    },

    updating(...args): boolean {
      // eslint-disable-next-line no-use-before-define
      const { state } = adminGetterContext(args)
      return state.updating
    },
  },
  mutations: {
    reset(state: AdminState) {
      Object.assign(state, { ...resetState() })
    },

    setError(state: AdminState, error: string) {
      state.error = error
    },

    setUpdating(state: AdminState, updating: boolean) {
      state.updating = updating
    },
  },

  namespaced: true as const,

  state: resetState(),
})

export default admin
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const adminGetterContext = (args: [any, any, any, any]) => localGetterContext(args, admin)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const adminActionContext = (context: any) => localActionContext(context, admin)
