import _ from 'lodash'
import { AliyahType, ParashahType } from 'types/types'

import { formatParashahForStudent } from './Parashah'

export interface CreateAliyahParameters {
  id: number
  year: number
  parashah: ParashahType
  rank: number
  section: string
  variant: string
  comment: string
  username: string
}

enum AliyahRank {
  Rishon = 'רִאשׁוֹן',
  Sheni = 'שֵׁנִי',
  Shlishi = 'שְׁלִישִׁי',
  Revii = 'רְבִיעִי',
  Chamishi = 'חֲמִישִׁי',
  Shishi = 'שִׁישִׁי',
  Shevii = 'שְׁבִיעִי',
  Maftir = 'מַפְטִיר',
}
export const ALIYAH_RANK_MAP: Record<number, AliyahRank> = {
  1: AliyahRank.Rishon,
  2: AliyahRank.Sheni,
  3: AliyahRank.Shlishi,
  4: AliyahRank.Revii,
  5: AliyahRank.Chamishi,
  6: AliyahRank.Shishi,
  7: AliyahRank.Shevii,
  8: AliyahRank.Maftir,
}

export function rankName(rank: number): string {
  return ALIYAH_RANK_MAP[rank]
}

export function formatRank(rank: number): string {
  return `${rank} - ${ALIYAH_RANK_MAP[rank]}`
}

export function formatAliyahSimple(aliyah: AliyahType): string {
  // return `${formatRank(aliyah.rank)} - ${aliyah.section}`;
  return `${formatRank(aliyah.rank)}`
}

export function formatAliyahForStudent(aliyah: AliyahType): string {
  return ` ${formatRank(aliyah.rank)} / ${formatParashahForStudent(aliyah.parashah)}`
}

export function formatAliyahForTeacher(aliyah: AliyahType): string {
  let s = `${formatRank(aliyah.rank)} (${aliyah.year} / ${aliyah.variant}`
  if (aliyah.comment) {
    s += ` / ${aliyah.comment}`
  }
  s += `)`
  return s
}

export function formatAliyahForTeacherWithSection(aliyah: AliyahType): string {
  return `${formatAliyahForTeacher(aliyah)}: ${aliyah.section}`
}
