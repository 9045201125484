import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'

export interface MessageType {
  visible?: boolean
  state: string
  message: string
}

export interface SnackbarState {
  messages: MessageType[]
}

const resetState = (): SnackbarState => {
  return {
    messages: [],
  }
}

const snackbar = defineModule({
  actions: {
    add(context, payload: MessageType) {
      const { commit } = snackbarActionContext(context)
      commit.add(payload)
    },

    delete(context, index: number) {
      const { commit } = snackbarActionContext(context)
      commit.delete(index)
    },
  },

  getters: {
    messages(...args): MessageType[] {
      // eslint-disable-next-line no-use-before-define
      const { state } = snackbarGetterContext(args)
      return state.messages
    },
  },

  mutations: {
    add(state: SnackbarState, message: MessageType) {
      state.messages.push({ ...message, visible: true })
    },

    delete(state: SnackbarState, index: number) {
      state.messages.splice(index, 1)
    },
  },

  namespaced: true as const,

  state: resetState(),
})

export default snackbar
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const snackbarGetterContext = (args: [any, any, any, any]) => localGetterContext(args, snackbar)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const snackbarActionContext = (context: any) => localActionContext(context, snackbar)
