import _ from 'lodash'
import { AnnotationType } from 'types/types'

import { AnnotatedCharacter } from './AnnotatedCharacter'
import { AnnotatedSegment } from './AnnotatedSegment'

class AnnotatedWord {
  private xsegment: AnnotatedSegment

  private text: string

  private annotations: Array<AnnotationType | null>

  private xindex: number

  private xoffsetInSentence: number

  private characters: Array<AnnotatedCharacter>

  constructor(segment: AnnotatedSegment, index: number, offsetInSentence: number, text: string, annotations: Array<AnnotationType | null>) {
    this.xsegment = segment
    this.xindex = index
    this.xoffsetInSentence = offsetInSentence
    this.text = text
    this.annotations = [...annotations]

    this.characters = new Array<AnnotatedCharacter>()
    this.generate()
  }

  segment(): AnnotatedSegment {
    return this.xsegment
  }

  offsetInSentence(): number {
    return this.xoffsetInSentence
  }

  word(): string {
    return this.text
  }

  nChars(): number {
    return this.text.length
  }

  index(): number {
    return this.xindex
  }

  generate(): void {
    this.characters.splice(0, this.characters.length)

    _.forEach(this.text, (character, index) => {
      const sentenceIndex = this.xoffsetInSentence + index
      const annotation = this.annotations[index]
      const annotatedCharacter = new AnnotatedCharacter(this, sentenceIndex, character, annotation)
      this.characters.push(annotatedCharacter)
    })
  }

  forEach(callback: (character: AnnotatedCharacter | null, index?: number) => void): void {
    _.forEach(this.characters, (annotatedCharacter, index) => {
      callback(annotatedCharacter, index)
    })
  }
}

export { AnnotatedWord }
