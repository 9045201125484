<script setup lang="ts">
import _ from 'lodash'
import { UserGrapheneType } from 'types/types'
import { computed } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRouter } from 'vue-router'
import { useTheme } from 'vuetify'

import { useUtil } from '@/composables/util'
import store from '@/store'

const router = useRouter()
const logger = useLogger()
const { getCsrfToken, handleLogoutClick, isStaff, resetStoreExceptUser } = useUtil(logger)

const studentFullName = computed(() => {
  return `${store.getters.user.user?.firstName} ${store.getters.user.user?.lastName}`
})

const studentInitial = computed(() => {
  return store.getters.user.user?.username.charAt(0).toUpperCase()
})

// see startImpersonate in views/ImpersonateView
//
// stopping impersonation involves calling the backend, then clearing
// store.getters.user.impersonator and moving that user back to .user
//
const doStopImpersonate = async () => {
  const response = await fetch('/backend/impersonate/stop/', {
    credentials: 'include',
    headers: {
      'x-csrftoken': getCsrfToken(),
    },
    method: 'POST',
  })
  logger.debug('response = ')
  logger.debug(response)

  if (response.ok) {
    logger.debug('calling stopImpersonate')
    const user = _.cloneDeep(store.getters.user.impersonator)
    logger.debug('calling resetStore')
    // we cannot call resetStore() because that will clear store.getters.user.user and
    // that will trigger the store.getters.user.user watcher in App.vue.
    // instead we'll do a sanitize() below
    resetStoreExceptUser()

    // clean the user store as much as possible, except user/impersonator related fields
    store.dispatch.user.sanitize()
    store.dispatch.user.clearImpersonator()
    store.dispatch.user.setUser(user as UserGrapheneType)
    router.push(store.getters.user.impersonatorUrl)
  }
}

const vueTheme = useTheme()
function switchTheme() {
  const theme = vueTheme.global.current.value.dark ? 'light' : 'dark'
  store.dispatch.user.updateTheme({
    theme,
  })
  vueTheme.global.name.value = theme
}
</script>

<template>
  <v-menu bottom left offset-y>
    <template #activator="{ props }">
      <v-btn
        v-if="!store.getters.user.impersonator"
        v-bind="props"
        elevation="4"
        icon
        size="x-small"
        :style="{
          color: $vuetify.theme.themes.light.colors.background,
          backgroundColor: $vuetify.theme.themes.light.colors.primary,
        }"
        >{{ studentInitial }}
      </v-btn>
      <v-btn v-else v-bind="props" color="error darken-2">Impersonating As {{ store.getters.user.user?.firstName }}</v-btn>
    </template>

    <v-list>
      <v-list-item to="/profile">
        <v-list-item-title class="title">{{ studentFullName }}</v-list-item-title>
        <v-list-item-subtitle>{{ store.getters.user.user?.email }}</v-list-item-subtitle>
        <template #append>
          <v-icon>mdi-pencil</v-icon>
        </template>
      </v-list-item>

      <v-divider />

      <v-list-item v-if="!store.getters.user.impersonator && isStaff()" to="/impersonate">
        <v-list-item-title>Impersonate</v-list-item-title>
        <template #append>
          <v-icon>mdi-incognito</v-icon>
        </template>
      </v-list-item>

      <v-list-item v-if="store.getters.user.impersonator" @click="doStopImpersonate">
        <v-list-item-title>Stop impersonation</v-list-item-title>
        <template #append>
          <v-icon color="error darken-2">mdi-incognito</v-icon>
        </template>
      </v-list-item>

      <v-list-item v-if="false">
        <v-list-item-title>Dark Theme</v-list-item-title>
        <template #append>
          <v-switch v-model="vueTheme.current.value" @change="switchTheme"></v-switch>
        </template>
      </v-list-item>

      <v-divider />

      <v-list-item @click="handleLogoutClick(router)">
        <v-list-item-title>Log Out</v-list-item-title>
        <template #append>
          <v-icon>mdi-logout</v-icon>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
