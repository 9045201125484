<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import _ from 'lodash'
import { useField, useForm } from 'vee-validate'
import { onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRoute, useRouter } from 'vue-router'
import { z as zod } from 'zod'

import { useZodValidation } from '@/composables/zodValidation'
import store from '@/store'

interface LoginState {
  username: string
  password: string
  showPassword: boolean
  loginState: boolean
}

interface Props {
  loginName?: string
}

const logger = useLogger()

const props = withDefaults(defineProps<Props>(), {
  loginName: '',
})

const state = reactive<LoginState>({
  loginState: true,
  password: '',
  showPassword: false,
  username: '',
})

const { nonEmptyString } = useZodValidation()

const { errors, handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(
    zod.object({
      password: nonEmptyString,
      username: nonEmptyString,
    }),
  ),
})

const { value: username } = useField('username')
const { value: password } = useField('password')

const route = useRoute()
const router = useRouter()

onMounted(() => {
  if (props.loginName) {
    state.username = props.loginName
  }
  state.username ||= ''
  state.password ||= ''

  // bridge between vee-validate and the component's state
  watch(
    () => state.username,
    () => {
      username.value = state.username
    },
  )

  watch(
    () => state.password,
    () => {
      password.value = state.password
    },
  )
})

const onSubmit = handleSubmit((values) => {
  logger.debug('onSubmit, values = ')
  logger.debug(values)

  const r = store.dispatch.user.login({
    password: values.password,
    username: values.username
  })
  r.then((result: boolean) => {
    if (result) {
      if (route.query.redirect) {
        logger.debug(`LoginView redirect to ${route.query.redirect}`)
        router.push(route.query.redirect as string)
        return
      } else {
        router.push('/')
      }
    } else {
      state.loginState = false
      store.dispatch.snackbar.add({
        message: `Login failed for ${state.username}`,
        state: 'error',
      })
    }
  }).catch((err) => {
    state.loginState = false
    store.dispatch.snackbar.add({
      message: `Login failed for ${state.username}, error = ${err}`,
      state: 'error',
    })
  })
})
</script>

<template>
  <v-card>
    <v-card-title>Login</v-card-title>
    <v-card-text>
      <v-form id="form" @submit.prevent="onSubmit">
        <v-row>
          <v-col lg="3" md="5" sm="7" xl="2" xs="9">
            <v-text-field
              v-model="username"
              class="input-group--focused"
              :error-messages="errors.username"
              label="Username"
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="3" md="5" sm="7" xl="2" xs="11">
            <v-text-field
              v-model="password"
              :append-icon="state.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :error-messages="errors.password"
              label="Password"
              :type="state.showPassword ? 'text' : 'password'"
              variant="underlined"
              @click:append="state.showPassword = !state.showPassword"
            />
            <v-row class="mt-3 mx-0 px-0" justify="space-between">
              <v-col class="mx-0 px-0" md="4">
                <v-btn block color="primary" :disabled="!meta.valid" type="submit">Sign in</v-btn>
              </v-col>
              <v-col class="text-right mx-0 px-0" md="6">
                <v-btn
                  variant="plain"
                  @click="
                    () => {
                      $router.push({ name: 'forgot-password' })
                    }
                  "
                  >I forgot my password</v-btn
                 />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
