<script setup lang="ts">
import isIos from 'is-ios'
import _ from 'lodash'
import { AliyahType, BarMitzvahType, HaftarahType, ParashahType, SentenceGroupType, SentenceType } from 'types/types'
import { nextTick, onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import AnnotatedText from '@/components/AnnotatedText.vue'
import Popover from '@/components/PopOver.vue'
import StudentToSentencesSelect from '@/components/StudentToSentencesSelect.vue'
import { useFont } from '@/composables/font'
import {} from '@/composables/font'
import ClearSentenceAnnotations from '@/mutations/ClearSentenceAnnotations.graphql'
import SetAnnotationOnCharacter from '@/mutations/SetAnnotationOnCharacter.graphql'
import store from '@/store'
import { AnnotatedCharacter, CharacterStyleValue } from '@/util/AnnotatedCharacter'
import { AnnotatedSegment } from '@/util/AnnotatedSegment'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { AnnotatedWord } from '@/util/AnnotatedWord'
import { useApollo } from '@/util/apolloClient'
const { apolloClient } = useApollo()

interface ColorData {
  aliyah: AliyahType | null
  annotatedSentences: Array<AnnotatedSentence> | null
  barMitzvah: BarMitzvahType | null
  haftarah: HaftarahType | null
  parashah: ParashahType | null
  sentenceGroup: SentenceGroupType | null
  popoverAnnotatedChar: AnnotatedCharacter | null
  foregroundColorPick: string
  backgroundColorPick: string
  popoverTarget: string | null
  refreshAnnotatedText: number
  sentences: Array<SentenceType>
  isIos: boolean
}

const state = reactive<ColorData>({
  aliyah: null,
  // managed by StudentToSentenceSelector
  annotatedSentences: null,

  backgroundColorPick: '',

  barMitzvah: null,

  // used to trigger a refresh in AnnotatedText
  // to manage the popover annotation chooser
  foregroundColorPick: '',

  haftarah: null,

  isIos,

  parashah: null,

  popoverAnnotatedChar: null,

  popoverTarget: null,

  // managed by AnnotatedText
  refreshAnnotatedText: 0,

  sentenceGroup: null,

  sentences: [],
})

const logger = useLogger()
const { fontSize, lineHeight } = useFont()

onMounted(async () => {
  await store.dispatch.user.fetchUser()

  watch(
    () => state.sentences,
    () => {
      resetPopover()
    },
  )

  watch(
    () => state.foregroundColorPick,
    (newVal) => {
      if (state.popoverAnnotatedChar !== null && state.popoverAnnotatedChar.overrideStyle.color !== newVal) {
        logger.debug('watch state.foregroundColorPick, newVal =')
        logger.debug(newVal)
        updateOverrideStyle(state.popoverAnnotatedChar as AnnotatedCharacter, 'color', newVal)
      }
    },
  )

  watch(
    () => state.backgroundColorPick,
    (newVal) => {
      if (state.popoverAnnotatedChar !== null && state.popoverAnnotatedChar.overrideStyle.backgroundColor !== newVal) {
        logger.debug('watch state.backgroundColorPick, newVal =')
        logger.debug(newVal)
        updateOverrideStyle(state.popoverAnnotatedChar as AnnotatedCharacter, 'backgroundColor', newVal)
      }
    },
  )

  if (state.isIos) {
    store.dispatch.dialog.show({ message: 'This feature is not supported on iOS devices.', title: 'Not support on iOS' })
  }
})

function clearAll() {
  store.dispatch.dialog.show({
    isRejectable: true,
    message: 'This removes all colors from the entire text.',
    onAccept: () => {
      logger.debug('clearAll onAccept')
      if (!state.annotatedSentences) {
        logger.debug('unexpected state.annotatedSentences in onAccept clearAll')
        return
      }

      let nClears = 0
      logger.debug('clearAll, forEach, annotatedSentences =')
      logger.debug(state.annotatedSentences)
      _.forEach(state.annotatedSentences, (annotatedSentence) => {
        logger.debug('clearAll, forEach, annotatedSentence =')
        logger.debug(annotatedSentence)
        apolloClient
          .mutate({
            mutation: ClearSentenceAnnotations,
            variables: {
              barMitzvah: state.barMitzvah?.student.username,
              sentence: annotatedSentence.sentence().id,
            },
          })
          .then(() => {
            // when all have returned, refresh
            nClears += 1
            if (nClears >= state.annotatedSentences!.length) {
              state.refreshAnnotatedText += 1
            }
          })
      })
    },
    title: 'Are you sure?',
  })
}

function handleCharClick(
  __: AnnotatedSentence,
  ___: AnnotatedSegment,
  ____: AnnotatedWord,
  annotatedChar: AnnotatedCharacter,
  sentenceIndex: number,
  segmentIndex: number,
  wordIndex: number,
  charIndex: number,
) {
  if (state.popoverAnnotatedChar !== annotatedChar) {
    resetPopover()
  }

  nextTick(() => {
    const eltId = `text:${sentenceIndex}:${segmentIndex}:${wordIndex}:${charIndex}:char-id`
    // const htmlElt = document.getElementById(eltId);
    // const computedStyle = window.getComputedStyle(htmlElt);
    const { overrideStyle } = annotatedChar
    if (!overrideStyle) {
      logger.debug('unexpected null overrideStyle on handleCharClick')
      return
    }
    logger.debug(overrideStyle)
    state.foregroundColorPick = overrideStyle.color ?? ''
    state.backgroundColorPick = overrideStyle.backgroundColor ?? ''
    // state.$log.info(`foregroundColorPick: ${state.foregroundColorPick}`);
    // state.$log.info(`backgroundColorPick: ${state.backgroundColorPick}`);

    // needs to come last to ensure the popover doesn't open with errors
    state.popoverAnnotatedChar = annotatedChar
    state.popoverTarget = eltId
  })
}

function resetAnnotations(annotatedChar: AnnotatedCharacter, doSave = true) {
  logger.debug('resetAnnotations')
  _.forEach(Object.keys(annotatedChar.overrideStyle), (key) => {
    logger.debug(`resetAnnotations, delete key ${key}`)
    delete annotatedChar.overrideStyle[key as keyof CharacterStyleValue]
    // state.$delete(annotatedChar.overrideStyle, key)
  })
  if (doSave) {
    save(annotatedChar)
  }
  logger.debug(`resetAnnotations, setting to ''`)
  state.foregroundColorPick = ''
  state.backgroundColorPick = ''
}

function resetPopover() {
  state.popoverTarget = null
  state.popoverAnnotatedChar = null
  state.foregroundColorPick = ''
  state.backgroundColorPick = ''
}

function save(annotatedChar: AnnotatedCharacter) {
  apolloClient
    .mutate({
      mutation: SetAnnotationOnCharacter,
      variables: {
        barMitzvah: state.barMitzvah?.student.username,
        index: annotatedChar.index(),
        sentence: annotatedChar.word().segment().sentence().sentence().id,
        style:
          Object.keys(annotatedChar.overrideStyle).every(
            (key) => annotatedChar.overrideStyle[key as keyof CharacterStyleValue] === undefined,
          ) ||
          Object.keys(annotatedChar.overrideStyle).length === 0 ||
          _.isEmpty(annotatedChar.overrideStyle)
            ? ''
            : JSON.stringify(annotatedChar.overrideStyle),
      },
    })
    .finally(() => {
      state.refreshAnnotatedText += 1
    })
}

function updateOverrideStyle(annotatedChar: AnnotatedCharacter, attribute: keyof CharacterStyleValue, color: any) {
  const { overrideStyle } = annotatedChar
  logger.debug('updateOverrideStyle, attribute =')
  logger.debug(attribute)
  logger.debug('updateOverrideStyle, color =')
  logger.debug(color)
  overrideStyle[attribute] = color.hex
  save(annotatedChar)
}
</script>

<template>
  <v-container fluid>
    <div>
      <popover :attach-id="state.popoverTarget" :visible="!!state.popoverTarget">
        <v-card v-if="state.popoverAnnotatedChar">
          <v-card-title>
            <v-row>
              <v-col md="6"> Colorize {{ state.popoverAnnotatedChar.character() }} </v-col>
              <v-col md="4">
                <v-btn class="ml-3 mr-2" size="small" @click="resetAnnotations(state.popoverAnnotatedChar as AnnotatedCharacter)">
                  Reset
                </v-btn>
                <v-btn fab icon size="x-small" @click="state.popoverTarget = null">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <div class="mt-2 pt-2">
              Foreground
              <compact-picker v-model="state.foregroundColorPick" />
            </div>
            <div class="mt-2 pt-2">
              Background
              <compact-picker v-model="state.backgroundColorPick" />
            </div>
          </v-card-text>
        </v-card>
      </popover>

      <v-row v-if="state.isIos">
        <v-col class="text-h3 text-red"> This feature is not supported on iOS devices. </v-col>
      </v-row>

      <student-to-sentences-select
        v-model:aliyah="state.aliyah"
        v-model:bar-mitzvah="state.barMitzvah"
        v-model:haftarah="state.haftarah"
        v-model:parashah="state.parashah"
        v-model:sentence-group="state.sentenceGroup"
        v-model:sentences="state.sentences"
        :aliyah-disabled="false"
        :aliyah-reset-on-options-change="false"
        :bar-mitzvah-clearable="true"
        :disable-haftarah-when-student-has-no-haftarah="true"
        :disable-parashah-when-student-has-no-parashah="true"
        :disable-sentence-group-when-student-has-no-sentence-group="true"
        :force-student-select="true"
        :haftarah-clearable="true"
        :haftarah-disabled="false"
        :haftarah-disabled-on-no-bar-mitzvah="true"
        :haftarah-reset-on-options-change="true"
        :hide-haftarah-when-disabled="true"
        :hide-parashah-when-disabled="true"
        :hide-sentence-group-when-disabled="true"
        :no-sentence-select="true"
        :parashah-disabled="false"
        :parashah-disabled-on-no-bar-mitzvah="true"
        :parashah-reset-on-options-change="true"
        :prevent-auto-single-aliyah-select="false"
        :prevent-auto-single-bar-mitzvah-select="false"
        :prevent-auto-single-haftarah-select="true"
        :prevent-auto-single-parashah-select="true"
        :prevent-auto-single-sentence-group-select="true"
        :sentence-group-disabled-on-no-bar-mitzvah="true"
      />
      <v-row v-if="state.sentences && state.sentences.length" class="mt-3 pt-3">
        <v-col md="12">
          <v-card>
            <v-card-text>
              <v-row justify="center">
                <v-col class="text-center" md="11">
                  <annotated-text
                    v-model:annotated-sentences="state.annotatedSentences as Array<AnnotatedSentence>"
                    :bar-mitzvah="state.barMitzvah"
                    :clickable-char="() => true"
                    :default-font="store.getters.user.user?.properties?.font"
                    :font-size="fontSize"
                    :line-height="lineHeight"
                    name="text"
                    :refresh="state.refreshAnnotatedText"
                    :sentences="state.sentences"
                    :show-sentence-numbers="true"
                    @click-char="handleCharClick"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <template v-if="!!state.sentences && state.sentences.length">
        <v-row class="mt-3 pt-3" justify="center">
          <v-col md="2">
            <v-btn block color="warning" @click="clearAll"> Clear all </v-btn>
          </v-col>
        </v-row>
      </template>
    </div>
  </v-container>
</template>

<style>
.v-application .vc-compact ul {
  padding-left: 0px !important;
}
</style>
