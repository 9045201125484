<script setup lang="ts">
import _ from 'lodash'
import { SentenceType } from 'types/types'
import { computed, reactive, watch, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import AnnotatedText from '@/components/AnnotatedText.vue'
import VerseSelect from '@/components/VerseSelect.vue'
import { PHRASERS, useVersePhraser } from '@/composables/versePhraser'
import { AnnotatedSegment } from '@/util/AnnotatedSegment'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { SegmentationType } from '@/util/Segmentation'
import { SEGMENT_STYLES } from '@/util/TextStyles'

const logger = useLogger()
const { phrasers, sentenceToSegmentation } = useVersePhraser(logger)

interface ParseState {
  maxLevel: number
  section: string
  text: string
  translation: string
  segmentation: SegmentationType
  method: PHRASERS
}

const state = reactive<ParseState>({
  maxLevel: 2,
  method: PHRASERS.BMT,
  section: '',
  segmentation: [],
  text: '',
  translation: '',
})

const sentence = computed<SentenceType>(() => {
  return {
    text: state.text,
  } as SentenceType
})

const segmentStyle = (__: string, ___: AnnotatedSentence, ____: AnnotatedSegment, _____: number, segmentIndex: number): string => {
  return SEGMENT_STYLES[segmentIndex % 2]
}

watch(
  () => state.section,
  () => {
    fetch(`https://www.sefaria.org/api/texts/${state.section}?context=0&lang=he&pad=0`)
      .then((response) => response.json())
      .then((data) => {
        state.text = data.he.replace(/<[^>]*>?/gm, '')
        state.translation = data.text.replace(/<[^>]*>?/gm, '')
      })
  },
)

watchEffect(() => {
  state.segmentation = sentenceToSegmentation({ text: state.text } as SentenceType, state.method, state.maxLevel)
})
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col md="8">
        <v-text-field v-model="state.text" clearable dir="rtl" label="verse" variant="underlined" />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2"> OR </v-col>
    </v-row>
    <v-row>
      <v-col>
        <verse-select v-model:section="state.section" :selected-section="state.section" />
      </v-col>
    </v-row>
    <v-row v-if="state.text">
      <v-col md="1">
        <v-select v-model="state.method" :items="phrasers" label="Method" type="string" variant="underlined" />
      </v-col>
      <v-col md="1">
        <v-select v-model="state.maxLevel" :items="[1, 2, 3, 4]" label="Max Level" type="number" variant="underlined" />
      </v-col>
    </v-row>
    <v-row v-if="state.text">
      <v-col md="8">
        <annotated-text name="verse" :segment-style="segmentStyle" :segmentations="[state.segmentation]" :sentences="[sentence]" />
      </v-col>
    </v-row>
  </v-container>
</template>
