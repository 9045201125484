import _ from 'lodash'
import { SentenceType } from 'types/types'
import { computed, Ref } from 'vue'

const useResponsiveness = (sentences: Ref<Array<SentenceType>>) => {
  const sentencesHaveSameResponsiveness = computed<boolean>(() => {
    return _.every(sentences.value, (sentence: SentenceType) => sentence.isResponsive === sentences.value[0].isResponsive)
  })

  return {
    sentencesHaveSameResponsiveness,
  }
}

export { useResponsiveness }
