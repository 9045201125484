<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import _ from 'lodash'
import { useField, useForm } from 'vee-validate'
import { onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRouter } from 'vue-router'
import PasswordMeter from 'vue-simple-password-meter'
import { z as zod } from 'zod'

import { useZodValidation } from '@/composables/zodValidation'
import store from '@/store'

const TOKEN_EXPIRED_ERROR = 'Sorry, the link has expired. Please ask your teacher to reset your password.'

interface Props {
  secretToken?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  secretToken: null,
})

interface SetPasswordState {
  setOrChange: string
  username: string | null
  password: string
  passwordAgain: string
  setPasswordState: boolean
  invalidFeedback: string | null
  minPasswordScore: number
  passwordScore: number
  warning: string | null
  suggestions: string[]
  strength: string
}

const state = reactive<SetPasswordState>({
  invalidFeedback: null,
  minPasswordScore: 3,
  password: '',
  passwordAgain: '',
  passwordScore: 0,
  setOrChange: 'Set',
  setPasswordState: true,
  strength: '',
  suggestions: [],
  username: null,
  warning: null,
})

const logger = useLogger()
const router = useRouter()

const { nonEmptyString } = useZodValidation()

const { errors, handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(
    zod
      .object({
        password: nonEmptyString,
        passwordAgain: nonEmptyString,
      })
      .refine((data) => data.password === data.passwordAgain, {
        message: 'Passwords must match',
        path: ['passwordAgain'], // Specify the path of the error
      }),
  ),
})

const { value: password } = useField('password')
const { value: passwordAgain } = useField('passwordAgain')

onMounted(async () => {
  // the passed token only works when user is not logged in
  if (!store.getters.user.userIsLoggedIn) {
    await store.dispatch.user.fetchConfirmationToken({
      token: props.secretToken || '',
    })

    if (store.getters.user.confirmationToken?.user.username.length) {
      state.username = store.getters.user.confirmationToken.user.username
    }
  } else if (store.getters.user.user) {
    state.username = store.getters.user.user.username
    state.setOrChange = 'Change'
  } else {
    logger.error('unexpected store.getters.user.user')
  }

  watch(
    () => state.password,
    () => {
      password.value = state.password
    },
  )

  watch(
    () => state.passwordAgain,
    () => {
      passwordAgain.value = state.passwordAgain
    },
  )
})

const updateScore = (payload) => {
  state.strength = payload.strength
}

const onSubmit = handleSubmit((values) => {
  if (_.isEmpty(state.username) || _.isEmpty(state.password) || _.isEmpty(state.passwordAgain)) {
    return
  }

  const args: { newPassword: string; secretToken: string } = {
    newPassword: state.password,
    secretToken: '',
  }

  if (!store.getters.user.userIsLoggedIn) {
    if (!props.secretToken) {
      logger.error('unexpected null props.secretToken')
      return
    }

    args.secretToken = props.secretToken
  }

  const r = store.dispatch.user.setUserPassword(args)
  r.then(() => {
    if (!store.getters.user.userIsLoggedIn) {
      // Password saved
      router.push({
        name: 'login',
        params: {
          loginName: state.username,
          msgBody: `Please log in with your new password`,
          msgTitle: `Password saved`,
        },
      })
      store.dispatch.snackbar.add({
        message: 'Please login with your new password',
        state: 'success',
      })
    } else {
      router.push({
        name: 'profile',
        params: {
          msgBody: `Password changed`,
        },
      })
      store.dispatch.snackbar.add({
        message: 'Your password has been changed',
        state: 'success',
      })
    }
  }).catch((e) => {
    // oops, link expired?
  })
})
</script>

<template>
  <v-alert v-if="!state.username" color="error" show>{{ TOKEN_EXPIRED_ERROR }}</v-alert>
  <v-form v-else id="form" @submit.prevent="onSubmit">
    <v-row>
      <v-col lg="3" md="5" sm="7" xl="2" xs="11">
        <v-text-field v-model="state.username" disabled label="Username" type="text" variant="underlined"></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col md="2">
        <v-text-field
          id="password"
          v-model="state.password"
          autofocus
          :error-messages="errors.password"
          label="Password"
          placeholder="Enter password"
          type="password"
          variant="underlined"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        <v-text-field
          id="passwordAgain"
          v-model="state.passwordAgain"
          :error-messages="errors.passwordAgain"
          label="Password again"
          placeholder="Enter password again"
          type="password"
          variant="underlined"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <password-meter :password="state.password" @score="updateScore" />
        <span v-if="state.password" class="text-h6">This password is {{ state.strength }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        <v-btn block color="primary" :disabled="!meta.valid" type="submit">{{ state.setOrChange }} password</v-btn>
      </v-col>
      <v-col md="2">
        <v-btn
          v-if="store.getters.user.userIsLoggedIn"
          block
          color="warning"
          @click="
            () => {
              $router.push({ name: 'profile' })
            }
          "
          >Cancel</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<style>
div.message-spacer {
  min-height: 80px;
}

div.Password__strength-meter {
  margin-bottom: 0px !important;
}
</style>
