<script lang="ts" setup>
// {{{ imports
import { BarElement, CategoryScale, Chart as ChartJS, ChartData, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import _ from 'lodash'
import * as mime from 'mime-types'
import moment from 'moment'
import {
  AliyahType,
  BarMitzvahType,
  HaftarahMelodyType,
  HaftarahType,
  ParashahType,
  PronunciationType,
  SegmentedSentenceType,
  SegmentType,
  SentenceGroupMelodyType,
  SentenceGroupType,
  SentenceRecordingType,
  SentenceType,
  TagType,
  TorahMelodyType,
} from 'types/types'
import { computed, isRef, nextTick, onMounted, reactive, Ref, ref, toRefs, watch, watchEffect } from 'vue'
import { Line } from 'vue-chartjs'
import { useLogger } from 'vue-logger-plugin'
import { onBeforeRouteLeave } from 'vue-router'

import AnnotatedText from '@/components/AnnotatedText.vue'
import Popover from '@/components/PopOver.vue'
import SamplingOverlay from '@/components/SamplingOverlay.vue'
import StudentToSentencesSelect from '@/components/StudentToSentencesSelect.vue'
import { AudioPlayerParams, useAudioPlayer } from '@/composables/audioPlayer'
import { useFollower } from '@/composables/follower'
import { useFont } from '@/composables/font'
import { useResponsiveness } from '@/composables/responsive'
import { useSegmentation } from '@/composables/segmentation'
import { useSentences } from '@/composables/sentences'
import { StyledRecordings } from '@/composables/styledRecording'
import { useUtil } from '@/composables/util'
import { useVersePhraser } from '@/composables/versePhraser'
import UploadRecording from '@/mutations/UploadRecording.graphql'
import store from '@/store'
import { AnnotatedSegment } from '@/util/AnnotatedSegment'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { AnnotatedWord } from '@/util/AnnotatedWord'
import { useApollo } from '@/util/apolloClient'
import { DEFAULT_PITCH_SHIFT, PitchShiftType, SentenceRecordingMetadataWithPitchedAudios } from '@/util/AudioUtil'
import { PhrasingType } from '@/util/Phrasing'
import { RecorderService } from '@/util/RecorderService'
import { RecorderServiceOptions, RecordingType } from '@/util/Recording'
import { SegmentationType } from '@/util/Segmentation'
import { PHRASES, SENTENCE, WORDS } from '@/util/Tags'
import {
  regularStyle,
  SEGMENT_STYLES,
  segmentBoundaryStyle as defaultSegmentBoundaryStyle,
  waitingStyle,
  wordBoundaryStyle as defaultWordBoundaryStyle,
} from '@/util/TextStyles'
import { defaultTransformation, transformationByName, TransformationType, VOWELS_CANTILLATIONS } from '@/util/Transformations'
import {
  inReadMode as inReadModeFunc,
  inSingMode as inSingModeFunc,
  READ,
  RecordingVoiceStyles,
  SING,
  VoiceStyleSet,
} from '@/util/VoiceStyles'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
// }}}
// {{{ consts
const { apolloClient } = useApollo()

const logger = useLogger()
const { isDevelopment } = useUtil(logger)
const INTERWORD_PAUSE = 350 // milliseconds
const INTERSEGMENT_PAUSE = 1700 // milliseconds
const SING_NOW_POPOVER_SHOW = 1500 // ms

const RECOLOR_FREQUENCY = 5 // once every ...
const SAMPLE_SOUND_FREQUENCY = 10 // once every ...
const SAMPLE_SOUND_LENGTH = 25 // store maximum ... samples
const SAMPLE_TRIGGER_LENGTH = 50 // store maximum ... samples

const RECORDING_REFETCH_INTERVAL = 3000 // ms

// a sentence of this many words is still considered a phrase, even if it's
// unsplit
const MAX_WORDS_STILL_PHRASED = 4

type PhrasingOptionType = { title: string; value: PhrasingType }
const PHRASING_OPTIONS: Array<PhrasingOptionType> = [
  { title: 'Sentence', value: SENTENCE },
  { title: 'Phrases', value: PHRASES },
  { title: 'Words', value: WORDS },
]

// const START_RECORDING_KEY = 'r';
// const STOP_RECORDING_KEY = 's';
const PHRASE_KEY = 'p'
const CLOSE_KEY = 'c'
const NEXT_KEY = 'n'
const PREV_KEY = 'v'

const state = reactive<RecordingsData>({
  RecordingVoiceStyles,
  // selection of read/sing, and pronunciation/melodic styles
  aliyah: null,
  // managed by annotated-text
  annotatedSegments: new Array<Array<AnnotatedSegment>>(),
  // managed by annotated-text
  annotatedSentences: [],
  // whether or not to automatically jump to the next sentence after recording
  autoAdvance: false,
  // let the back-end figure out segmentation
  autoSegmentDetect: false,
  // to manage visual cues to the user that the background noise is being managed
  backgroundSampling: false,
  backgroundSamplingPercent: 0,
  // managed by StudentToSentencesSelector
  barMitzvah: null,
  cachedSentenceRecordings: {},
  // gets set if the user created a segmentation by clicking on a word boundary
  customSegmentation: null,
  // whether or not the user is going to press keys to indicate where phrase splits need to occur
  disableAutomaticVoiceStartStop: false,
  // for graph
  displayGraph: false,

  downloadingSentenceRecordingId: null,

  // only set when the user clicked on an existing recording (e.g., to
  // re-record). will be null if no such recording exists.
  existingSentenceRecordingMetadataWithPitchedAudios: null,

  // variables to manage the focus window after a user clicks on a sentence;
  // see similar notes in Practice.vue
  // the Sentence object in focus
  focusSentence: null,
  // the index in sentences[]
  focusSentenceIndex: null,

  focusTextRefresh: 0,

  haftarah: null,

  // tracks user clicks as ms since startRecordingAt
  manualSegmentSplitOffsets: new Array<number>(),

  nSoundUpdates: 0,

  nTriggerValues: 0,

  openedPanels: new Array<string>(),

  optionalFilename: '',

  parashah: null,
  pauseCounter: 0,

  // the recording a user uploaded via the upload box and on optional name
  pendingUploadRecording: null,

  phrasing: SENTENCE,

  //
  pitchShift: DEFAULT_PITCH_SHIFT,
  playingSentenceRecordingId: null,

  popoverSegment: null,

  popoverSegmentIndex: null,

  popoverSentence: null,

  popoverSentenceRecordingMetadataWithPitchedAudios: null,

  popoverShowStartNow: false,

  popoverTarget: null,

  // the utility that records the user
  recorderService: null,
  recorderServiceOptions: null,

  // each non-existing recording has an associated refetch interval timer
  // associated with it to fetch pending information from the back; this
  // table is indexed by sentence_recording.id and stores the interval function
  refetchIntervals: {},

  // set to the segment index when replacing a single recording
  replacementRecordingSegmentIndex: null,

  // the phrasing as clicked by the user
  segmentation: [],

  sentenceGroup: null,

  sentences: [],

  // the timestamp when VAD believes the loudness started dropping
  silenceStartedAt: null,

  soundValues: [],

  // time most recording started
  startRecordingAt: null,

  // hints to annotate-text how to render Hebrew
  transformation: defaultTransformation(),

  triggerValues: [],

  // not null, or file will be called 'null'
  // true during an upload; to give user visual feedback
  uploadWaiting: false,

  voiceStyleSet: {
    haftarahMelody: null,
    pronunciation: null,
    sentenceGroupMelody: null,
    tags: null,
    torahMelody: null,
    voice: RecordingVoiceStyles[READ],
  } as VoiceStyleSet,
})

const focusSentenceSpan = ref<HTMLInputElement | null>(null)

const refsState = toRefs(state)
const { baseLevel, currentSegmentIndex, currentSentenceIndex, currentStyle, followerOptions, followerSegmentStyle, setCurrentSegment } =
  useFollower(refsState.recorderService as Ref<RecorderService>)

state.recorderServiceOptions = {
  ...followerOptions,
  logger: logger,
  ...{
    onBaseLevel: (baseLevel: number, perc: number) => {
      if (followerOptions.onBaseLevel) {
        state.backgroundSamplingPercent = perc
        followerOptions.onBaseLevel(baseLevel, perc)
      }
    },

    onError: (error: Error) => {
      followerOptions.onError?.(error)
      logger.error(error.message)
      // state.alertMessage = error.message;
      // state.showAlert = true;
    },

    onRecordingReady: (e: CustomEvent): Promise<void> => {
      return uploadRecording(e.detail.recording)
    },

    onSoundUpdate: (n: number) => {
      state.nSoundUpdates += 1
      if (!(state.nSoundUpdates % RECOLOR_FREQUENCY) && followerOptions.onSoundUpdate) {
        followerOptions.onSoundUpdate(n)
      }

      if (!(state.nSoundUpdates % SAMPLE_SOUND_FREQUENCY)) {
        addSoundLevel(state.soundValues, n, SAMPLE_SOUND_LENGTH)
        if (state.displayGraph) {
          if (!_.isEmpty(state.triggerValues)) {
            addSoundLevel(state.triggerValues, state.triggerValues[state.triggerValues.length - 1].y, SAMPLE_TRIGGER_LENGTH)
          }
        }
      }
    },

    onStartRecording: () => {
      logger.debug('onStartRecording, followerOptions = ')
      logger.debug(followerOptions)
      if (followerOptions.onStartRecording) {
        followerOptions.onStartRecording()
      } else {
        logger.debug('unexpected null followerOptions.onStopRecording()')
      }
      if (!state.startRecordingAt) {
        state.startRecordingAt = new Date()
      }
      state.backgroundSampling = false
      state.backgroundSamplingPercent = 0
      if (state.recorderService) {
        state.recorderService.setSlowSilenceDetection(isSegmented.value ?? false)
      } else {
        logger.debug('unexpected null state.recorderService()')
      }

      const startSegment = state.replacementRecordingSegmentIndex === null ? 0 : state.replacementRecordingSegmentIndex
      state.popoverTarget = `focusText:0:${startSegment}:segment`
      state.popoverShowStartNow = true
      setTimeout(() => {
        state.popoverTarget = null
        state.popoverShowStartNow = false
      }, SING_NOW_POPOVER_SHOW)
      setCurrentSegmentColorWrapper(0, startSegment, state.annotatedSegments[0][startSegment] as AnnotatedSegment)
    },

    onStartSilence: () => {
      state.silenceStartedAt = new Date()
    },

    onTriggerLevel: (triggerLevel: number) => {
      if (followerOptions.onTriggerLevel) {
        followerOptions.onTriggerLevel(triggerLevel)
      }

      if (state.displayGraph) {
        state.nTriggerValues += 1
        if (!(state.nTriggerValues % SAMPLE_SOUND_FREQUENCY)) {
          addSoundLevel(state.triggerValues, triggerLevel, SAMPLE_TRIGGER_LENGTH)
        }
      }
    },

    onVoiceStart: () => {
      if (followerOptions.onVoiceStart) {
        followerOptions.onVoiceStart(`focusText:${currentSentenceIndex}:${currentSegmentIndex.value}:segment`)
      }
    },
    onVoiceStop: (): Promise<void> => {
      return new Promise((resolve) => {
        if (followerOptions.onVoiceStop) {
          followerOptions.onVoiceStop()
        }

        // on manual segmentation, don't do anything special
        if (state.disableAutomaticVoiceStartStop) {
          resolve()
          return
        }

        // we're in auto-follower mode

        // last word was said, turn off recording
        if (state.replacementRecordingSegmentIndex !== null || currentSegmentIndex.value + 1 >= state.annotatedSegments[0].length) {
          toggleRecord()
          resolve()
          return
        }

        // Duplicated with Practice.vue

        // not the last word.
        // wait the right time before jumping to next word
        let msSinceSilenceStarted = 0
        if (state.silenceStartedAt) {
          msSinceSilenceStarted = new Date().getTime() - state.silenceStartedAt.getTime()
        }
        state.silenceStartedAt = null // reset for next word
        setTimeout(
          () => {
            setCurrentSegmentColorWrapper(
              0,
              currentSegmentIndex.value + 1,
              state.annotatedSegments[0][currentSegmentIndex.value + 1] as AnnotatedSegment,
            )
          },
          isSegmented.value
            ? Math.max(0, INTERSEGMENT_PAUSE - msSinceSilenceStarted)
            : Math.max(0, INTERWORD_PAUSE - msSinceSilenceStarted),
        )

        resolve()
      })
    },
  },
}

state.recorderService = new RecorderService({
  ...state.recorderServiceOptions,
})

// monitor font
const { MAX_FONT_SIZE, MIN_FONT_SIZE, fontSize, lineHeight } = useFont()

// before passing state to composable, do toRefs to maintain reactivity
const { sentencesHaveSameResponsiveness } = useResponsiveness(refsState.sentences)
const { watchSentences } = useSentences(refsState.sentences, logger)
watchSentences()
const { sentenceToSegmentation } = useVersePhraser(logger)

const { RESET_TO_SENTENCE, RESET_TO_WORDS, resetSegments, segmentations } = useSegmentation(refsState.segmentation, ref([]), logger)
const { isPlayable, loaded, pause, paused, play, playing, setLoadPlay } = useAudioPlayer(logger)

const chartOptions = computed<ChartData>(() => ({
  animation: {
    duration: 0,
  },
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Voice Detection',
    },
  },
  responsive: true,
  responsiveAnimationDuration: 0,
  scales: {
    x: {
      ticks: {
        stepSize: 0.5,
      },
      type: 'linear',
    },
    y: {
      ticks: {
        max: 1,
        min: 0,
      },
    },
  },
}))

// }}}
// {{{ types, interfaces
type RecordingsCache = {
  [id: string]: Array<SentenceRecordingMetadataWithPitchedAudios>
}

type RefetchIntervals = {
  [key: string]: NodeJS.Timeout
}

interface RecordingsData {
  RecordingVoiceStyles: typeof RecordingVoiceStyles
  aliyah: AliyahType | null
  annotatedSegments: Array<Array<AnnotatedSegment>>
  annotatedSentences: Array<AnnotatedSentence>
  autoAdvance: boolean
  autoSegmentDetect: boolean
  backgroundSampling: boolean
  backgroundSamplingPercent: number
  barMitzvah: BarMitzvahType | null
  cachedSentenceRecordings: RecordingsCache
  customSegmentation: boolean | null
  displayGraph: boolean
  focusSentence: SentenceType | null
  focusSentenceIndex: number | null
  focusTextRefresh: number
  existingSentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios | null
  haftarah: HaftarahType | null
  manualSegmentSplitOffsets: Array<number>
  disableAutomaticVoiceStartStop: boolean
  nSoundUpdates: number
  nTriggerValues: number
  openedPanels: Array<string>
  optionalFilename: string
  parashah: ParashahType | null
  pauseCounter: number
  pendingUploadRecording: File | null
  pitchShift: PitchShiftType
  playingSentenceRecordingId: string | null
  downloadingSentenceRecordingId: string | null
  phrasing: PhrasingType | null
  popoverTarget: string | null
  popoverSegment: AnnotatedSegment | null
  popoverSegmentIndex: number | null
  popoverSentence: AnnotatedSentence | null
  popoverSentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios | null
  popoverShowStartNow: boolean
  recorderService: RecorderService | null
  recorderServiceOptions: RecorderServiceOptions | null
  replacementRecordingSegmentIndex: number | null
  refetchIntervals: RefetchIntervals
  segmentation: Array<number>
  sentenceGroup: SentenceGroupType | null
  sentences: Array<SentenceType>
  silenceStartedAt: Date | null
  soundValues: Array<object>
  startRecordingAt: Date | null
  transformation: TransformationType
  triggerValues: Array<object>
  uploadWaiting: boolean
  voiceStyleSet: VoiceStyleSet
}
// }}}

// {{{ onBeforeRouteLeave
onBeforeRouteLeave((__, ___, next): void => {
  if (isRecording.value) {
    toggleRecord()
  }
  _.forEach(_.toPairs(state.refetchIntervals), (__, func) => {
    clearInterval(func)
  })
  state.refetchIntervals = {}
  next()
})
// }}}

onMounted((): void => {
  // {{{ init
  logger.debug('onMounted')
  logger.debug('RecordingsView, state.recorderServiceOptions = ')
  logger.debug(state.recorderServiceOptions)
  logger.debug('RecordingsView, followerOptions = ')
  logger.debug(followerOptions)
  logger.debug('onMounted, state =')
  logger.debug(state)
  store.dispatch.audio.setLogger(logger)
  store.dispatch.user.fetchUser()
  store.dispatch.student.fetchTropes()
  // }}}
  // {{{ keypress
  window.addEventListener('keypress', (e) => {
    // if (e.key === START_RECORDING_KEY) {
    //   if (!isRecording()) {
    //     toggleRecord();
    //   }
    // }

    if (e.key === CLOSE_KEY && !!state.focusSentence) {
      closeRecordOverlay()
    }

    if (e.key === NEXT_KEY && !!state.focusSentence) {
      proceedToSentence(1)
    }

    if (e.key === PREV_KEY && !!state.focusSentence) {
      proceedToSentence(-1)
    }

    if (!isRecording.value) {
      return
    }

    // if (e.key === STOP_RECORDING_KEY) {
    //   toggleRecord();
    // }

    if (e.key === PHRASE_KEY) {
      state.manualSegmentSplitOffsets.push(new Date().getTime() - state.startRecordingAt!.getTime())

      // partly copied from onVoiceStop()
      setCurrentSegmentColorWrapper(
        0,
        currentSegmentIndex.value + 1,
        state.annotatedSegments[0][currentSegmentIndex.value + 1] as AnnotatedSegment,
      )
      if (currentSegmentIndex.value >= state.annotatedSegments[0].length) {
        toggleRecord()
      }
    }
  })
  // }}}
  // {{{ watch state.voiceStyleSet.voice
  watch(
    () => state.voiceStyleSet.voice,
    () => {
      if (state.focusSentenceIndex !== null) {
        setVoiceStyleMelody()
      }
    },
    { deep: true },
  )
  // }}}
  // {{{ watch state.phrasing,
  watch(
    () => state.phrasing,
    () => {
      if (phrasingDropdownDisabled.value) {
        return
      }

      if (state.phrasing === SENTENCE) {
        resetSegments(RESET_TO_SENTENCE, annotatedSentence)
      } else if (state.phrasing === WORDS) {
        resetSegments(RESET_TO_WORDS, annotatedSentence)
      } else if (state.phrasing === PHRASES) {
        if (!annotatedSentence.value) {
          logger.debug('unexpected null annotatedSentence.value in watch state.phrasing')
          return
        }
        state.segmentation = sentenceToSegmentation(annotatedSentence.value.sentence())
      }
      state.customSegmentation = false
    },
  )
  // }}}
  // {{{ watch store.getters.audio.recordings
  watch(
    () => store.getters.audio.recordings,
    () => {
      state.cachedSentenceRecordings = {}
    },
    { deep: true },
  )
  // }}}
  // {{{ watch state.sentences
  watch(
    () => state.sentences,
    (newSentences: Array<SentenceType>, oldSentences: Array<SentenceType>): void => {
      if (playing()) {
        pauseWrapper()
      }

      logger.debug('state.sentences watch fired')
      logger.debug('newSentences =')
      logger.debug(newSentences)

      // any sentences that are going out of scope can be released from memory
      // NB: duplicate of Practice.watch.sentences
      const sentencesOutOfScope = _.difference(newSentences, oldSentences)
      _.forEach(sentencesOutOfScope, (sentence: SentenceType) => {
        store.dispatch.audio.releaseSentence({ sentence })
      })
      foldExansionPanels()
    },
  )
  // }}}
  // {{{ watchEffect [pronunciation]
  watchEffect(() => {
    if (pronunciationChoices.value.length === 1) {
      nextTick(() => {
        state.voiceStyleSet.pronunciation = pronunciationChoices.value[0] as PronunciationType
      })
    }
  })
  // }}}
  // {{{ watch torahMelodyChoices
  watch(
    () => torahMelodyChoices,
    () => {
      setVoiceStyleMelody()
    },
    { immediate: true },
  )
  // }}}
  // {{{ watch haftarahMelodyChoices
  watch(
    () => haftarahMelodyChoices,
    () => {
      setVoiceStyleMelody()
    },
    { immediate: true },
  )
  // }}}
  // {{{ watch sentenceGroupMelodyChoices
  watch(
    () => sentenceGroupMelodyChoices,
    () => {
      setVoiceStyleMelody()
    },
    { immediate: true },
  )
  // }}}
  // {{{ watch store.getters.users.user
  if (store.getters.user.user) {
    watch(
      () => store.getters.user.user,
      () => {
        // assume this will be a reading; will be overriden if a existingSentenceRecordingMetadataWithPitchedAudios exists
        // for this sentence below
        if (!state.voiceStyleSet.voice) {
          state.voiceStyleSet.voice = RecordingVoiceStyles[READ]
        }
      },
      { immediate: true },
    )
  }
  // }}}
  // {{{ watch state.disableAutomaticVoiceStartStop
  watch(
    () => state.disableAutomaticVoiceStartStop,
    () => {
      // turn displayGraph off when manual segmentation is turned on
      if (state.disableAutomaticVoiceStartStop) {
        state.displayGraph = false
      }
    },
  )
  // }}}
  // {{{ watch annotatedSentence
  watch(
    () => annotatedSentence,
    () => {
      setAutoTag()
    },
  )
  // }}}
  // {{{ watch state.segmentation
  watch(
    () => state.segmentation,
    () => {
      setAutoTag()
    },
    { deep: true },
  )
  // }}}
})

// computed functions
// {{{ computed annotatedSentence
// convenience function that safely returns
// state.annotatedSentences[state.focusSentenceIndex]
const annotatedSentence = computed<AnnotatedSentence | null>((): AnnotatedSentence | null => {
  if (state.focusSentenceIndex === null) {
    return null
  }
  if (_.isEmpty(state.annotatedSentences)) {
    return null
  }
  return state.annotatedSentences[state.focusSentenceIndex] as AnnotatedSentence
})
// }}}
// {{{ computed chartData
// returns data for the line-chart
const chartData = computed(() => {
  return {
    datasets: [
      {
        borderColor: 'blue',
        data: state.soundValues,
        label: 'Voice level',
      },
      {
        borderColor: 'red',
        data: state.triggerValues,
        label: 'Trigger threshold',
      },
    ],
  }
})
// }}}
// {{{ computed focusSentences
// wraps state.focusSentence into an array
const focusSentences = computed(() => {
  return _.compact([state.focusSentence])
})
// }}}
// {{{ computed haftarahMelodyChoices
// returns available options available in the haftarah melodies dropdown
const haftarahMelodyChoices = computed<Array<HaftarahMelodyType>>(() => {
  if (!state.haftarah || _.isEmpty(state.barMitzvah?.haftarahMelody)) {
    return []
  }

  if (!_.isEmpty(state.barMitzvah)) {
    return [state.barMitzvah.haftarahMelody]
  }
  return _.map(store.getters.user.user?.haftarahMelodies, (h) => h.melody)
})
// }}}
// {{{ computed inReadMode
// whether or not current voiceStyleSet criteria suggest read mode
const inReadMode = computed<boolean>(() => {
  return inReadModeFunc(state.voiceStyleSet)
})
// }}}
// {{{ computed isMelodicStyleDisabled
// returns true if in read mode or no voice selected
const isMelodicStyleDisabled = computed<boolean>(() => {
  return !state.voiceStyleSet.voice || inReadMode.value
})
// }}}
// {{{ computed isReadyForRecording
// returns true if/when voiceStyleSet criteria are internally consistent and
// set to allow recording to start
const isReadyForRecording = computed<boolean>(() => {
  if (_.isEmpty(state.voiceStyleSet.pronunciation)) {
    logger.debug('isReadyForRecording !pronunciation')
    return false
  }

  if (_.isEmpty(state.voiceStyleSet.voice)) {
    logger.debug('isReadyForRecording !voice')
    return false
  }

  if (_.isEmpty(state.voiceStyleSet.tags)) {
    logger.debug('isReadyForRecording !tags')
    return false
  }

  // enough criteria for recording in read mode
  if (inReadMode.value) {
    return true
  }

  // singing, either torah melody or haftarah melody or sentenceGroup melody
  // needs to be set
  const r =
    !_.isEmpty(state.voiceStyleSet.torahMelody) ||
    !_.isEmpty(state.voiceStyleSet.haftarahMelody) ||
    !_.isEmpty(state.voiceStyleSet.sentenceGroupMelody)
  logger.debug(`isReadyForRecording r = ${r}`)
  return r
})
// }}}
// {{{ computed isRecording
const isRecording = computed<boolean>(() => {
  return state.recorderService?.isRecording() ?? false
})
// }}}
// {{{ computed isSegmented
// returns true if a segmentation is set that isn't just the
// 1-word-per-segment type; otherwise false
const isSegmented = computed<boolean>(() => {
  return !_.isEmpty(state.segmentation) && !hasWordSegmentation(state.segmentation, annotatedSentence)
})
// }}}
// {{{ computed nonHiddenUserTags
const nonHiddenUserTags = computed<Array<TagType>>(() => {
  if (_.isEmpty(store.getters.user.user)) {
    return []
  }
  return _.sortBy(
    _.filter(store.getters.user.user.tags, (tag: TagType) => {
      return !tag.hidden
    }),
    ['name'],
  )
})
// }}}
// {{{ computed pronunciationChoices
// returns available options are available in pronunciations dropdown
const pronunciationChoices = computed(() => {
  if (!_.isEmpty(state.barMitzvah)) {
    return [state.barMitzvah.pronunciation]
  }

  if (!store.getters.user.user) {
    return []
  }

  const choices = _.map(store.getters.user.user.pronunciations, (p) => p.pronunciation)
  return choices
})
// }}}
// {{{ computed recordingFields
// which fields for the per-sentence recordings overview
const recordingFields = computed(() => {
  const fields = [
    {
      key: 'select',
      title: '',
    },
    {
      key: 'action',
      title: '',
    },
    {
      key: 'date',
      title: 'Date',
    },
  ]

  if (isDevelopment()) {
    fields.push({
      key: 'id',
      title: 'ID',
    })
    fields.push({
      key: 'dbfs',
      title: 'DBFS',
    })
  }

  if (pronunciationChoices.value.length > 1) {
    fields.push({
      key: 'pronunciation',
      title: 'Pronunciation',
    })
  }

  if (isDevelopment() || nonHiddenUserTags.value.length) {
    fields.push({
      key: 'tags',
      title: 'Style',
    })
  }

  fields.push({
    key: 'voice',
    title: 'Voice',
  })

  if (torahMelodyChoices.value.length > 1 || haftarahMelodyChoices.value.length > 1 || sentenceGroupMelodyChoices.value.length > 1) {
    fields.push({
      key: 'melody',
      title: 'Melodic Style',
    })
  }

  fields.push({
    key: 'warning',
    title: 'Status',
  })
  return fields
})
// }}}
// {{{ computed segmentableSentence
const segmentableSentence = computed<boolean>(() => {
  return annotatedSentence.value ? annotatedSentence.value.nWords() > MAX_WORDS_STILL_PHRASED : false
})
// }}}
// {{{ computed segmentedSentence
// returns state.existingSentenceRecordingMetadataWithPitchedAudios.sentenceRecording.negmentedSentence
const segmentedSentence = computed<SegmentedSentenceType | null>(() => {
  const esra = state.existingSentenceRecordingMetadataWithPitchedAudios
  if (!esra) {
    return null
  }

  const sr = esra.sentenceRecording
  if (!sr) {
    return null
  }

  const ss = sr.segmentedSentence
  if (!ss) {
    return null
  }

  return ss
})
// }}}
// {{{ computed sentenceGroupMelodyChoices
// returns available options available in the sentenceGroup melodies dropdown
const sentenceGroupMelodyChoices = computed<Array<SentenceGroupMelodyType>>((): Array<SentenceGroupMelodyType> => {
  if (_.isEmpty(state.sentenceGroup)) {
    return []
  }

  if (!_.isEmpty(state.barMitzvah)) {
    return [state.barMitzvah.sentenceGroupMelody] as Array<SentenceGroupMelodyType>
  }
  return _.map(store.getters.user.user?.sentenceGroupMelodies, (h) => h.melody)
})
// }}}
// {{{ computed sentenceRecordingMetadatas
//
// returns a map that maps a sentenceId to an Array of all its recordings
//
//   initiates a periodic fetch for all sentences that seem to be still
//   processing on the backend
//
const sentenceRecordingMetadatas = computed<StyledRecordings>(() => {
  logger.debug('recompute sentenceRecordingMetadatas')
  const sentenceToMetadatas = {} as StyledRecordings
  _.forEach(
    Object.entries(store.getters.audio.recordings),
    ([sentenceId, value]: [string, Record<string, SentenceRecordingMetadataWithPitchedAudios>]) => {
      logger.debug(`sentenceRecordingMetadatas, sentenceId = ${sentenceId}, value =`)
      logger.debug(value)
      sentenceToMetadatas[sentenceId] = new Array<SentenceRecordingMetadataWithPitchedAudios>()
      _.forEach(
        Object.entries(value),
        ([sentenceRecordingId, sentenceRecordingMetadataWithPitchedAudios]: [string, SentenceRecordingMetadataWithPitchedAudios]) => {
          if (isProcessing(sentenceRecordingMetadataWithPitchedAudios)) {
            if (!state.refetchIntervals[sentenceRecordingId]) {
              const interval = setInterval(() => {
                try {
                  store.dispatch.audio.fetchRecording({
                    id: parseInt(sentenceRecordingId, 10),
                  })
                } catch (error) {
                  // on error, stop trying
                  clearAndDeleteRefetch(sentenceRecordingId)
                }
              }, RECORDING_REFETCH_INTERVAL)
              logger.debug(`setInterval for sentenceRecordingId ${sentenceRecordingId}, interval = ${interval}`)
              state.refetchIntervals[sentenceRecordingId] = interval
            }
          } else if (state.refetchIntervals[sentenceRecordingId]) {
            clearAndDeleteRefetch(sentenceRecordingId)
          }
          sentenceToMetadatas[sentenceId].push(sentenceRecordingMetadataWithPitchedAudios)
        },
      )
    },
  )

  /*
    // sort read recordings first; force the song recordings at the end
    const r = _.sortBy(recordings, (srmd: SentenceRecordingMetadataWithPitchedAudios) => {
      let x = 0
      if (srmd.sentenceRecording) {
        x = isSung(srmd) ? 2 ** 16 + srmd.sentenceRecording.modified : srmd.sentenceRecording.modified
      }
      return x
    })
  */

  logger.debug('sentenceRecordingMetadatas, returning =')
  logger.debug(sentenceToMetadatas)
  return sentenceToMetadatas
})
// }}}
// {{{ computed torahMelodyChoices
// returns available options available in the torah melodies dropdown
const torahMelodyChoices = computed<Array<TorahMelodyType>>(() => {
  if (!state.aliyah || _.isEmpty(state.barMitzvah) || _.isEmpty(state.barMitzvah.torahMelody)) {
    return []
  }

  if (!_.isEmpty(state.barMitzvah.torahMelody)) {
    return [state.barMitzvah.torahMelody]
  }

  const tm = _.map(store.getters.user.user?.torahMelodies, (m) => m.melody)
  logger.debug('torahMelodyChoices =')
  logger.debug(tm)
  return tm
})
// }}}
// {{{ computed voices
// returns a hash that maps sentenceRecording.id to READ/SING.
const voices = computed(() => {
  const voices: Record<string, string> = {}
  for (const recordingMap of Object.values(store.getters.audio.recordings)) {
    for (const srmd of Object.values(recordingMap) as SentenceRecordingMetadataWithPitchedAudios[]) {
      const { sentenceRecording } = srmd
      if (!sentenceRecording?.haftarahMelody && !sentenceRecording?.torahMelody && !sentenceRecording?.sentenceGroupMelody) {
        voices[sentenceRecording!.id] = RecordingVoiceStyles[READ]
      } else {
        voices[sentenceRecording!.id] = RecordingVoiceStyles[SING]
      }
    }
  }
  return voices
})
// }}}
// {{{ computed phrasingDropdownDisabled
const phrasingDropdownDisabled = computed<boolean>(() => {
  return isRecording.value || state.autoSegmentDetect || !!state.existingSentenceRecordingMetadataWithPitchedAudios
})
// }}}

// utility functions about existing recordings
// {{{ hasEqualRecording
// returns true if there is another recording besides sentenceRecording that
// has the exact same criteria
function hasEqualRecording(sentenceRecording: SentenceRecordingType): boolean {
  const recordingMap = store.getters.audio.recordings[sentenceRecording.sentence.id]
  return !!_.find(Object.values(recordingMap), (srmd: SentenceRecordingMetadataWithPitchedAudios) => {
    // this is self
    if (srmd.sentenceRecording?.id === sentenceRecording.id) {
      return false
    }
    if (srmd.sentenceRecording?.pronunciation?.id !== sentenceRecording.pronunciation?.id) {
      return false
    }

    if (
      (!srmd.sentenceRecording?.torahMelody && sentenceRecording.torahMelody) ||
      (srmd.sentenceRecording?.torahMelody && !sentenceRecording.torahMelody) ||
      (sentenceRecording.torahMelody &&
        sentenceRecording.torahMelody &&
        srmd.sentenceRecording?.torahMelody?.id !== sentenceRecording.torahMelody.id)
    ) {
      return false
    }

    if (
      (!srmd.sentenceRecording?.haftarahMelody && sentenceRecording.haftarahMelody) ||
      (srmd.sentenceRecording?.haftarahMelody && !sentenceRecording.haftarahMelody) ||
      (sentenceRecording.haftarahMelody &&
        sentenceRecording.haftarahMelody &&
        srmd.sentenceRecording?.haftarahMelody?.id !== sentenceRecording.haftarahMelody.id)
    ) {
      return false
    }

    if (
      (!srmd.sentenceRecording?.sentenceGroupMelody && sentenceRecording.sentenceGroupMelody) ||
      (srmd.sentenceRecording?.sentenceGroupMelody && !sentenceRecording.sentenceGroupMelody) ||
      (sentenceRecording.sentenceGroupMelody &&
        sentenceRecording.sentenceGroupMelody &&
        srmd.sentenceRecording?.sentenceGroupMelody?.id !== sentenceRecording.sentenceGroupMelody.id)
    ) {
      return false
    }

    if (!_.isEqual(srmd.sentenceRecording?.tags, sentenceRecording.tags)) {
      return false
    }
    return true
  })
}
// }}}
// {{{ hasFullSentenceSegmentation
function hasFullSentenceSegmentation(segmentation: SegmentationType, annotatedSentence: Ref<AnnotatedSentence | null>): boolean {
  return !_.isEmpty(annotatedSentence.value) && segmentation?.length === 1 && segmentation[0] === annotatedSentence.value.nWords()
}
// }}}
// {{{ hasSegmentRecording
// returns true if the given segment has an associated recording
function hasSegmentRecording(
  annotatedSentence: AnnotatedSentence | null,
  __: AnnotatedSegment | null,
  ___: number,
  segmentIndex: number,
): boolean {
  if (isRecording.value || !state.existingSentenceRecordingMetadataWithPitchedAudios) {
    return false
  }

  if (!annotatedSentence) {
    logger.debug('unexpected null annotatedSentence.value in hasSegmentRecording')
    return false
  }

  const playable = isPlayable({
    pitchShift: state.pitchShift,
    segmentIndex,
    sentenceRecordingMetadataWithPitchedAudios: state.existingSentenceRecordingMetadataWithPitchedAudios, // help disambiguate in case of multiple matches
  })

  return playable
}
// }}}
// {{{ hasStandardSegmentation
// returns TRUE if
// - segmentation is set AND
//   - reading mode && segmentation is set to 1-word-per-segment
//     OR
//   - singing mode && segmentation is set to full-sentence-is-one-segment
const hasStandardSegmentation = (
  segmentationParam: SegmentationType = state.segmentation,
  annotatedSentenceParam: Ref<AnnotatedSentence | null> = annotatedSentence,
): boolean => {
  if (_.isEmpty(state.segmentation)) {
    return false
  }

  return (
    (inReadMode.value && hasWordSegmentation(segmentationParam, annotatedSentenceParam)) ||
    (!inReadMode.value && hasFullSentenceSegmentation(state.segmentation, annotatedSentenceParam))
  )
}
// }}}
// {{{ hasWordSegmentation
const hasWordSegmentation = (segmentation: SegmentationType, annotatedSentence: Ref<AnnotatedSentence | null>): boolean => {
  if (_.isEmpty(annotatedSentence?.value)) {
    return false
  }
  return segmentation.length === annotatedSentence.value.nWords()
}
// }}}
// {{{ hasSegmentRecordingWordWrapper
// returns true if the given segment has an associated recording
function hasSegmentRecordingWordWrapper(
  annotatedSentence: AnnotatedSentence | null,
  annotatedSegment: AnnotatedSegment | null,
  __: AnnotatedWord | null,
  sentenceIndex: number,
  segmentIndex: number,
  ___: number,
): boolean {
  return hasSegmentRecording(annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)
}
// }}}
// {{{ hiddenTagName
function hiddenTagName(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): string | null {
  const t = _.find(sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.tags, (tag: TagType) => {
    return tag.hidden
  }) as TagType
  return t ? t.name : null
}
// }}}
// {{{ hiddenTagNameIncludes
function hiddenTagNameIncludes(
  sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios,
  name: string,
): boolean {
  const t = _.find(sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.tags, (tag: TagType) => {
    return tag.hidden && tag.name === name
  }) as TagType
  return !_.isEmpty(t)
}
// }}}
// {{{ isProcessing
// returns true if this recording is still being processed on the backend
function isProcessing(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios) {
  return (
    !!sentenceRecordingMetadataWithPitchedAudios?.sentenceRecording?.uploadedAt &&
    !sentenceRecordingMetadataWithPitchedAudios?.sentenceRecording?.processingEndedAt
  )
}
// }}}
// {{{ isRead
// returns true if this sentenceRecordingAudio is read (i.e, has an
// associated melodic style)
function isRead(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): boolean {
  return (
    !sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.torahMelody &&
    !sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.haftarahMelody &&
    !sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.sentenceGroupMelody
  )
}
// }}}
// {{{ isSegmentedRecordingMetadata
// returns true if this recording is associated with a segmentedSentence
function isSegmentedRecordingMetadata(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): boolean {
  return !!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.segmentedSentence
}
// }}}
// {{{ isSung
// returns true if this sentenceRecordingAudio is sung (i.e., has an
// associated melodic style)
function isSung(sentenceRecordingHTMLAudioWrapper: SentenceRecordingMetadataWithPitchedAudios): boolean {
  return !isRead(sentenceRecordingHTMLAudioWrapper)
}
// }}}
// {{{ nSentenceSegments
// returns number of segments if isSegmentedRecordingMetadata
// otherwise returns -1
function nSentenceSegments(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): number {
  if (!isSegmentedRecordingMetadata(sentenceRecordingMetadataWithPitchedAudios)) {
    return -1
  }
  return sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.segmentedSentence?.segments.length || -1
}
// }}}
// {{{ nAudioSegments
// returns the number of audio segments associated with this recording
function nAudioSegments(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): number {
  return sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.segments.length || -1
}
// }}}

// {{{ warnManualSegmentation
// when turning this option on, give a warning
function warnManualSegmentation(): void {
  if (!state.disableAutomaticVoiceStartStop) {
    return
  }
  store.dispatch.dialog.show({
    isRejectable: false,
    message:
      "With automatic voice start/stop recognition disabled, you must press the 'p' key after each phrase. You only need this if you are experiencing recording problems that lead to incorrect sentence phrasing.",
    title: 'Warning',
  })
}
// }}}

// modify existing recordings
// {{{ setVoiceStyleMelody
function setVoiceStyleMelody(): void {
  // torah melody
  if (inReadMode.value || _.isEmpty(torahMelodyChoices.value)) {
    state.voiceStyleSet.torahMelody = null
  }

  if (!inReadMode.value && torahMelodyChoices.value.length === 1) {
    nextTick(() => {
      state.voiceStyleSet.torahMelody = torahMelodyChoices.value[0] as TorahMelodyType
      logger.debug('setVoiceStyleMelody state.voiceStyleSet.torahMelody =')
      logger.debug(state.voiceStyleSet.torahMelody)
    })
  }

  // haftarah melody
  if (inReadMode.value || _.isEmpty(haftarahMelodyChoices.value)) {
    state.voiceStyleSet.haftarahMelody = null
  }

  if (!inReadMode.value && haftarahMelodyChoices.value.length === 1) {
    nextTick(() => {
      state.voiceStyleSet.haftarahMelody = haftarahMelodyChoices.value[0] as HaftarahMelodyType
      logger.debug('setVoiceStyleMelody state.voiceStyleSet.haftarahMelody =')
      logger.debug(state.voiceStyleSet.haftarahMelody)
    })
  }

  // sentence group melody
  if (inReadMode.value || _.isEmpty(sentenceGroupMelodyChoices.value)) {
    state.voiceStyleSet.sentenceGroupMelody = null
  }

  if (!inReadMode.value && sentenceGroupMelodyChoices.value.length === 1) {
    nextTick(() => {
      state.voiceStyleSet.sentenceGroupMelody = sentenceGroupMelodyChoices.value[0] as SentenceGroupMelodyType
    })
  }
}
// }}}
// {{{ setCurrentSegmentColorWrapper
function setCurrentSegmentColorWrapper(
  sentenceIndex: number,
  segmentIndex: number,
  annotatedSegment: AnnotatedSegment | null = null,
): void {
  setCurrentSegment(sentenceIndex, segmentIndex, annotatedSegment)
  currentStyle.value = waitingStyle
}
// }}}
// {{{ setAutoTag
function setAutoTag(): void {
  logger.debug('setAutoTag, state.segmentation =')
  logger.debug(state.segmentation)

  if (!state.segmentation || !annotatedSentence.value) {
    logger.debug('setAutoTag early return')
    return
  }

  // strip all hidden tags
  state.voiceStyleSet.tags = _.filter(state.voiceStyleSet.tags, (tag: TagType) => {
    return !tag.hidden
  })

  const hiddenTagsToAdd = []
  const nWords = annotatedSentence.value.nWords()
  if (state.segmentation.length === nWords) {
    state.phrasing = WORDS
    hiddenTagsToAdd.push(WORDS)
  }

  if (state.segmentation.length === 1) {
    logger.debug('setAutoTag SENTENCE')
    state.phrasing = SENTENCE
    hiddenTagsToAdd.push(SENTENCE)
  }

  // if no tag has been applied, or if this is a short sentence, then it's
  // (also) a phrased sentence
  if (_.isEmpty(hiddenTagsToAdd) || nWords <= MAX_WORDS_STILL_PHRASED) {
    hiddenTagsToAdd.push(PHRASES)
    logger.debug('setAutoTag PHRASES')
  }

  _.forEach(hiddenTagsToAdd, (hiddenTagToAdd: string) => {
    if (!store.getters.user.user) {
      logger.error('unexpected null store.getters.user.user')
      return
    }

    if (!state.voiceStyleSet.tags) {
      logger.error('unexpected null state.voiceStyleSet.tags')
      return
    }

    state.voiceStyleSet.tags.push(
      _.find(store.getters.user.user.tags, (tag: TagType) => {
        return tag.name === hiddenTagToAdd && tag.hidden
      }) as TagType,
    )
  })
}
// }}}
// {{{ setVoice
// updates read/sing on an existing recording
function setVoice(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): void {
  // if there is no sentenceRecording, then there is nothing to do
  // (this can happen when there is no recording yet)
  if (!sentenceRecordingMetadataWithPitchedAudios) {
    return
  }

  const { sentenceRecording } = sentenceRecordingMetadataWithPitchedAudios
  if (!sentenceRecording) {
    return
  }

  if (voices.value[sentenceRecording.id] === RecordingVoiceStyles[READ]) {
    sentenceRecording.torahMelody = null
    sentenceRecording.haftarahMelody = null
    sentenceRecording.sentenceGroupMelody = null
  } else if (state.aliyah) {
    ;[sentenceRecording.torahMelody] = torahMelodyChoices.value
  } else if (state.haftarah) {
    ;[sentenceRecording.haftarahMelody] = haftarahMelodyChoices.value
  } else if (!_.isEmpty(state.sentenceGroup)) {
    ;[sentenceRecording.sentenceGroupMelody] = sentenceGroupMelodyChoices.value
  }
  updateSentenceRecordingMetadataWithPitchedAudios(sentenceRecordingMetadataWithPitchedAudios)
}
// }}}
// {{{ updateSentenceRecordingMetadataWithPitchedAudios
// stores the state of sentenceRecordingAudio on the backend
function updateSentenceRecordingMetadataWithPitchedAudios(
  sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios,
): void {
  const srmd = { ...sentenceRecordingMetadataWithPitchedAudios }
  if (_.isEmpty(srmd.sentenceRecording)) {
    return
  }

  if (
    (!srmd.sentenceRecording.torahMelody || !srmd.sentenceRecording.torahMelody.id) &&
    (!srmd.sentenceRecording.haftarahMelody || !srmd.sentenceRecording.haftarahMelody.id) &&
    (!srmd.sentenceRecording.sentenceGroupMelody || !srmd.sentenceRecording.sentenceGroupMelody.id)
  ) {
    srmd.sentenceRecording.torahMelody = null
    srmd.sentenceRecording.haftarahMelody = null
    srmd.sentenceRecording.sentenceGroupMelody = null
  }
  store.dispatch.audio.modifySentenceRecording({
    sentenceRecordingMetadataWithPitchedAudios: srmd,
  })
  store.dispatch.snackbar.add({ message: 'Recording updated.', state: 'primary' })
}
// }}}

// {{{ foldExansionPanels
function foldExansionPanels() {
  state.openedPanels.splice(0, state.openedPanels.length)
}
// }}}
// {{{ unfoldExpansionPanels
function unfoldExpansionPanels() {
  state.openedPanels = Array.from(Array(state.annotatedSentences.length).keys()).map((num) => num.toString())
}
// }}}

// {{{ addSoundLevel
// utility function for collecting graphing data, bounded to a maximum amount
function addSoundLevel(a: Array<object>, n: number, length: number): void {
  if (!state.startRecordingAt) {
    state.startRecordingAt = new Date()
  }

  a.push({
    x: (new Date().getTime() - state.startRecordingAt.getTime()) / 1000,
    y: n,
  })
  while (a.length > length) {
    a.splice(0, 1)
  }
}
// }}}

// {{{ clearAndDeleteRefetch
// stops the periodic refetch for the given sentenceRecording
function clearAndDeleteRefetch(sentenceRecordingId: string) {
  logger.debug(`clearAndDeleteRefetch, sentenceRecordingId ${sentenceRecordingId}`)
  if (!state.refetchIntervals[sentenceRecordingId]) {
    logger.debug(`clearAndDeleteRefetch, sentenceRecordingId ${sentenceRecordingId} not found`)
    return
  }

  logger.debug(`clearAndDeleteRefetch, clearInterval ${sentenceRecordingId}`)
  clearInterval(state.refetchIntervals[sentenceRecordingId])
  delete state.refetchIntervals[sentenceRecordingId]
}
// }}}
// {{{ segmentStyle
// used by focus window to style segments; in singing or autoSegmentDetect mode, all
// segments appear normal. in read mode, the style is managed followerSegmentStyle
// (to make segments appear yellow as they are boing spoken by the user)
function segmentStyle(
  style: string,
  __: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  sentenceIndex: number,
  segmentIndex: number,
): string {
  if (state.autoSegmentDetect) {
    return style
  }

  if (!isRecording.value || segmentIndex !== currentSegmentIndex.value) {
    return SEGMENT_STYLES[segmentIndex % 2]
  }

  // isRecording && segmentIndex === state.currentSegmentIndex
  return followerSegmentStyle(style, annotatedSegment, sentenceIndex, segmentIndex)
}
// }}}
// {{{ segmentBoundaryStyle
// styles the boundary between two segments
function segmentBoundaryStyle(style: string, __: AnnotatedSentence, ___: AnnotatedSegment, ____: number, _____: number): string {
  if (
    isRecording.value ||
    state.phrasing !== PHRASES ||
    state.autoSegmentDetect ||
    state.existingSentenceRecordingMetadataWithPitchedAudios
  ) {
    return style
  }
  return defaultSegmentBoundaryStyle
}
// }}}
// {{{ wordBoundaryStyle
// styles the boundary between two words
function wordBoundaryStyle(
  _: string,
  __: AnnotatedSentence,
  ___: AnnotatedSegment,
  ____: AnnotatedWord,
  _____: number,
  ______: number,
  _______: number,
): string {
  if (
    isRecording.value ||
    state.phrasing !== PHRASES ||
    state.autoSegmentDetect ||
    state.existingSentenceRecordingMetadataWithPitchedAudios
  ) {
    return regularStyle
  }
  return defaultWordBoundaryStyle
}
// }}}

// {{{ canProceedToSentence
// returns true if there is a sentence after this one
function canProceedToSentence(targetSentenceIndex: number) {
  if (isRecording.value) {
    return false
  }

  if (targetSentenceIndex < 0 || targetSentenceIndex >= state.sentences.length) {
    return false
  }
  return true
}
// }}}
// {{{ proceedToSentence
// handler when Previous/Next buttons clicked; essentially simulates a
// click from the all text view.
function proceedToSentence(increment = 1) {
  const fsi = state.focusSentenceIndex
  if (fsi === null) {
    logger.debug('fsi null')
    return
  }

  if (!canProceedToSentence(fsi + increment)) {
    return
  }
  const wasCustomSegmented = state.customSegmentation

  resetFocusedSentence()
  state.focusSentenceIndex = fsi + increment

  if (wasCustomSegmented) {
    resetSegments(RESET_TO_SENTENCE, annotatedSentence)
  }
  handleSentenceClick(state.annotatedSentences[state.focusSentenceIndex] as AnnotatedSentence, state.focusSentenceIndex)
}
// }}}

// {{{ confirmDeleteRecording
// deletes a recording after confirming
function confirmDeleteRecording(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): void {
  store.dispatch.dialog.show({
    message: 'Are you sure you want to delete this recording?',
    onAccept: () => {
      if (playing()) {
        pauseWrapper()
      }

      if (!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
        logger.debug('unexpected null sentenceRecordingMetadataWithPitchedAudios.sentenceRecording in confirmDeleteRecording')
        return
      }

      clearAndDeleteRefetch(sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.id)
      store.dispatch.audio.deleteRecording({
        sentenceRecording: sentenceRecordingMetadataWithPitchedAudios.sentenceRecording as SentenceRecordingType,
      })
    },
    title: 'Confirm',
  })
}
// }}}
// {{{ handleSegmentBoundaryClick
// handles a click on an inter-segment boundary
function handleSegmentBoundaryClick(_: AnnotatedSentence, __: AnnotatedSegment, ___: number, segmentIndex: number): void {
  if (state.phrasing !== PHRASES) {
    return
  }

  // if on an existing recording, don't allow resegmentation
  if (state.existingSentenceRecordingMetadataWithPitchedAudios) {
    return
  }

  if (!segmentableSentence.value) {
    store.dispatch.dialog.show({
      message: `Cannot phrase a sentence shorter than (or equal to) ${MAX_WORDS_STILL_PHRASED} words.`,
      title: 'Error',
    })
    return
  }

  state.segmentation[segmentIndex] += state.segmentation[segmentIndex + 1]
  state.segmentation.splice(segmentIndex + 1, 1)
  state.customSegmentation = true
}
// }}}
// {{{ handleWordBoundaryClick
// handles a click on an inter-word boundary
function handleWordBoundaryClick(
  annotatedSentence: AnnotatedSentence | null,
  annotatedSegment: AnnotatedSegment | null,
  annotatedWord: AnnotatedWord | null,
  sentenceIndex: number,
  segmentIndex: number,
  wordIndex: number,
): void {
  // if on an existing recording, the user means to play the audio
  if (state.existingSentenceRecordingMetadataWithPitchedAudios) {
    handleWordClick(annotatedSentence, annotatedSegment, annotatedWord, sentenceIndex, segmentIndex, wordIndex)
    return
  }

  if (state.phrasing !== PHRASES) {
    return
  }

  if (!segmentableSentence.value) {
    store.dispatch.dialog.show({ message: `Cannot phrase a sentence shorter/equal to ${MAX_WORDS_STILL_PHRASED} words`, title: 'Error' })
    return
  }

  // split segment
  state.segmentation[segmentIndex] = state.segmentation[segmentIndex] - (wordIndex + 1)
  state.segmentation.splice(segmentIndex, 0, wordIndex + 1)
  state.customSegmentation = true
}
// }}}
// {{{ handleWordClick
// plays the recording associated with the segment that this word is a part of
function handleWordClick(
  annotatedSentence: AnnotatedSentence | null,
  annotatedSegment: AnnotatedSegment | null,
  annotatedWord: AnnotatedWord | null,
  sentenceIndex: number,
  segmentIndex: number,
  wordIndex: number,
): void {
  if (!hasSegmentRecordingWordWrapper(annotatedSentence, annotatedSegment, annotatedWord, sentenceIndex, segmentIndex, wordIndex)) {
    return
  }

  // don't do anything while recording or if no audio exists for this phrase
  if (isRecording.value || !state.existingSentenceRecordingMetadataWithPitchedAudios) {
    return
  }

  // close popover if another segment was clicked
  if (state.popoverSegment !== annotatedSegment) {
    resetPopover()
  }

  nextTick(() => {
    state.popoverSentence = annotatedSentence
    state.popoverSentenceRecordingMetadataWithPitchedAudios = state.existingSentenceRecordingMetadataWithPitchedAudios
    state.popoverSegmentIndex = segmentIndex
    state.popoverSegment = annotatedSegment
    state.popoverTarget = `focusText:${sentenceIndex}:${segmentIndex}:segment`
  })

  if (!annotatedSentence) {
    logger.debug('unexpected null annotatedSentence.value')
    return
  }

  // similar to code in Practice.vue
  if (playing()) {
    pauseWrapper()
  }

  setLoadPlay({
    pitchShift: state.pitchShift,
    segmentIndex,
    sentence: annotatedSentence.sentence(),
    sentenceRecordingMetadataWithPitchedAudios: state.existingSentenceRecordingMetadataWithPitchedAudios,
  } as AudioPlayerParams)
}
// }}}
// {{{ handleEditRecording
// opens the focus window for a given recording
function handleEditRecording(
  sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios,
  annotatedSentence: AnnotatedSentence,
  sentenceIndex: number,
  cache = true,
  scroll = true,
) {
  logger.debug('handleEditRecording, sentenceRecordingMetadataWithPitchedAudios =')
  logger.debug(sentenceRecordingMetadataWithPitchedAudios)

  if (isProcessing(sentenceRecordingMetadataWithPitchedAudios)) {
    logger.debug('handleEditRecording returns because audio is processing')
    return
  }

  // stop playing audio from the overview if it is playing
  if (playing()) {
    pauseWrapper()
  }
  state.pauseCounter += 1 // this will stop the segment sequence player

  // fetches existing audio, then preps the UI widgets in the focus screen
  // to edit the existing sentence
  sentenceRecordingMetadataWithPitchedAudios.downloading = true
  state.existingSentenceRecordingMetadataWithPitchedAudios = sentenceRecordingMetadataWithPitchedAudios
  store.dispatch.audio
    .fetchAudio({
      cache,
      pitchShift: DEFAULT_PITCH_SHIFT,
      sentenceRecording: sentenceRecordingMetadataWithPitchedAudios.sentenceRecording as SentenceRecordingType,
    })
    .then(() => {
      sentenceRecordingMetadataWithPitchedAudios.downloading = false
      state.focusTextRefresh += 1
      logger.info(`handleEditRecording bumped focusTextRefresh to ${state.focusTextRefresh}`)
    })

  // set voiceStyleSet.[melody] based on the text selection, will be
  // overriden below by specifics of the recording
  setVoiceStyleMelody()

  handleSentenceClick(annotatedSentence, sentenceIndex, scroll)

  const sr = sentenceRecordingMetadataWithPitchedAudios.sentenceRecording
  if (sr) {
    state.voiceStyleSet.voice = RecordingVoiceStyles[sr.torahMelody || sr.haftarahMelody || sr.sentenceGroupMelody ? SING : READ]
    state.voiceStyleSet.torahMelody = sr.torahMelody as TorahMelodyType
    state.voiceStyleSet.haftarahMelody = sr.haftarahMelody as HaftarahMelodyType
    state.voiceStyleSet.sentenceGroupMelody = sr.sentenceGroupMelody as SentenceGroupMelodyType
    state.voiceStyleSet.tags = sr.tags
  }
}
// }}}
// {{{ handleSentenceClick
// opens the focus window for a given sentence
function handleSentenceClick(annotatedSentence: AnnotatedSentence | Ref<AnnotatedSentence>, sentenceIndex: number, scroll = true) {
  const refAnnotatedSentence: Ref<AnnotatedSentence> = isRef(annotatedSentence)
    ? (annotatedSentence as Ref<AnnotatedSentence>)
    : (ref(annotatedSentence) as Ref<AnnotatedSentence>)

  logger.debug('handleSentenceClick')
  // don't show misleading information while fetchAudio() is running
  state.annotatedSegments.splice(0, state.annotatedSegments.length)
  state.focusSentence = null

  // clear graph stats
  state.nTriggerValues = 0
  state.nSoundUpdates = 0
  state.triggerValues.splice(0, state.triggerValues.length)
  state.soundValues.splice(0, state.soundValues.length)

  // reset manual timings
  state.manualSegmentSplitOffsets.splice(0, state.manualSegmentSplitOffsets.length)

  // reset the segmentation, but only if we don't clobber a previously set
  // non-standard segmentation
  let phrasing: PhrasingType | null = null
  if (!state.customSegmentation || hasStandardSegmentation()) {
    if (inReadMode.value) {
      resetSegments(RESET_TO_WORDS, refAnnotatedSentence)
      phrasing = WORDS
    } else {
      resetSegments(RESET_TO_SENTENCE, refAnnotatedSentence)
      phrasing = SENTENCE
    }
  }

  // if this is an existing recording with a segmentation, override
  // state.segmentation with the SegmentedSentence's segmentation
  if (segmentedSentence.value) {
    state.segmentation.splice(0, state.segmentation.length)
    _.forEach(_.sortBy(segmentedSentence.value.segments, ['index']), (segment: SegmentType) => {
      state.segmentation.push(segment.words)
    })
    if (hasWordSegmentation(state.segmentation, refAnnotatedSentence)) {
      phrasing = WORDS
    } else if (hasFullSentenceSegmentation(state.segmentation, refAnnotatedSentence)) {
      phrasing = SENTENCE
    } else {
      phrasing = PHRASES
    }
  } else if (
    // there is no segmentedSentence for this recording; that will happen
    // when this is a whole sentence / singing recording
    state.existingSentenceRecordingMetadataWithPitchedAudios &&
    !isSegmentedRecordingMetadata(state.existingSentenceRecordingMetadataWithPitchedAudios) &&
    isSung(state.existingSentenceRecordingMetadataWithPitchedAudios)
  ) {
    resetSegments(RESET_TO_SENTENCE, refAnnotatedSentence)
    phrasing = SENTENCE
  }

  // always show all cantillations when recording
  state.transformation = transformationByName(VOWELS_CANTILLATIONS)

  nextTick(() => {
    if (!refAnnotatedSentence.value) {
      logger.debug('handleSentenceClick, refAnnotatedSentence.value === null')
      return
    }

    // set the overlay
    state.focusSentenceIndex = sentenceIndex
    state.focusSentence = refAnnotatedSentence.value.sentence()
    logger.debug(`state.focusSentenceIndex is now ${state.focusSentenceIndex}`)

    if (phrasing) {
      nextTick(() => {
        state.phrasing = phrasing
      })
    }

    if (scroll) {
      nextTick(() => {
        if (_.isEmpty(focusSentenceSpan.value)) {
          return
        }
        focusSentenceSpan.value.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      })
    }
  })
}
// }}}

// {{{ resetPopover
function resetPopover() {
  state.popoverTarget = null
  state.popoverSegment = null
}
// }}}
// {{{ resetFocusedSentence
// clears state when closing the focus window
function resetFocusedSentence() {
  logger.debug('resetFocusedSentence')
  state.focusSentenceIndex = null
  state.focusSentence = null
  state.existingSentenceRecordingMetadataWithPitchedAudios = null
  state.segmentation = []
  state.uploadWaiting = false
  state.pendingUploadRecording = null
  state.customSegmentation = false
  state.popoverTarget = null
  state.popoverSegment = null
  state.popoverSegmentIndex = null
  state.popoverSentence = null
  state.popoverSentenceRecordingMetadataWithPitchedAudios = null

  if (playing()) {
    pauseWrapper()
  }
}
// }}}
// {{{ closeRecordOverlay
// closes the focus window and transitions back to the all text view.
function closeRecordOverlay() {
  if (_.isEmpty(state.focusSentence)) {
    return
  }

  if (isRecording.value) {
    toggleRecord()
  }

  const panelHeaderId = `panel-title-${state.focusSentence.id}`
  const panelHeaderElt = document.getElementById(panelHeaderId)
  nextTick(() => {
    nextTick(() => {
      if (panelHeaderElt) {
        panelHeaderElt.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }
    })
  })
  resetFocusedSentence()
}
// }}}

// {{{ upload
// uploads the file to the backend
function upload(file: File): void {
  if (!annotatedSentence.value) {
    logger.debug('upload: unexpected null annotatedSentence')
    return
  }

  if (state.focusSentenceIndex === null) {
    logger.debug('upload: unexpected state.focusSentenceIndex')
    return
  }

  const sentenceRecording = state.existingSentenceRecordingMetadataWithPitchedAudios
    ? state.existingSentenceRecordingMetadataWithPitchedAudios.sentenceRecording
    : null
  state.uploadWaiting = true
  apolloClient
    .mutate({
      mutation: UploadRecording,
      variables: {
        aliyah: state.aliyah ? state.aliyah.id : null,
        autoSegment: state.autoSegmentDetect,
        file,
        haftarah: state.haftarah ? state.haftarah.reading.id : null,
        haftarahMelodyStyle:
          state.voiceStyleSet.voice === RecordingVoiceStyles[SING] && state.voiceStyleSet.haftarahMelody
            ? state.voiceStyleSet.haftarahMelody.id
            : null,
        manualSegments: state.manualSegmentSplitOffsets,
        nwords: annotatedSentence.value.nWords(),
        optionalFilename: state.optionalFilename,
        pronunciation: state.voiceStyleSet.pronunciation?.id,
        recordingVoiceStyle: state.voiceStyleSet.voice,
        replacesSegment: state.replacementRecordingSegmentIndex === null ? -1 : state.replacementRecordingSegmentIndex,
        segmentedSentence: segmentedSentence.value ? segmentedSentence.value.id : null,
        segments: hasStandardSegmentation() ? [] : state.segmentation,
        sentence: state.focusSentence ? state.focusSentence.id : null,
        sentenceGroup: !_.isEmpty(state.sentenceGroup) ? state.sentenceGroup.reading.id : null,
        sentenceGroupMelodyStyle:
          state.voiceStyleSet.voice === RecordingVoiceStyles[SING] && state.voiceStyleSet.sentenceGroupMelody
            ? state.voiceStyleSet.sentenceGroupMelody.id
            : null,
        sentenceRecording: sentenceRecording ? sentenceRecording.id : null,
        tags: state.voiceStyleSet.tags ? _.map(state.voiceStyleSet.tags, (tag: TagType) => tag.id) : [],
        torahMelodyStyle:
          state.voiceStyleSet.voice === RecordingVoiceStyles[SING] && state.voiceStyleSet.torahMelody
            ? state.voiceStyleSet.torahMelody.id
            : null,
      },
    })
    .then((result) => {
      if (result.data.uploadRecording.errors) {
        store.dispatch.dialog.show({ message: `Upload failed. Error: ${result.data.uploadRecording.errors}`, title: 'Error' })
      } else {
        // fetch the newly added existingSentenceRecordingMetadataWithPitchedAudios asynchronously;
        store.dispatch.audio.releaseRecording({ sentenceRecording: sentenceRecording as SentenceRecordingType })
        store.dispatch.audio.fetchRecording({ id: result.data.uploadRecording.id }).then(() => {
          if (state.replacementRecordingSegmentIndex === null) {
            return
          }
          const p1 = state.existingSentenceRecordingMetadataWithPitchedAudios
          const p2 = annotatedSentence.value
          const p3 = state.focusSentenceIndex
          resetFocusedSentence()
          // notice we handleEditRecording with cache = false so we refetch the just-replaced recording
          handleEditRecording(p1 as SentenceRecordingMetadataWithPitchedAudios, p2 as AnnotatedSentence, p3 as number, false, false)
        })
      }
    })
    .catch((error) => {
      store.dispatch.dialog.show({ message: `Upload failed. Error: ${error}`, title: 'Error' })
    })
    .finally(() => {
      if (state.replacementRecordingSegmentIndex !== null) {
        // handled above with fetchRecording and refocus
        return
      }

      if (state.focusSentenceIndex === null) {
        logger.debug('unexpected null in finally() state.focusSentenceIndex()')
        return
      }

      if (state.autoAdvance && canProceedToSentence(state.focusSentenceIndex + 1)) {
        proceedToSentence(1)
      } else {
        closeRecordOverlay()
      }
    })
}
// }}}
// {{{ uploadFile
// prepares the file that was dropped in/uploaded to the browser (as apposed
// to the in-browser recording)
function uploadFile(): void {
  if (state.pendingUploadRecording) {
    upload(state.pendingUploadRecording)
  } else {
    logger.debug('unexpected null state.pendingUploadRecording in uploadFile()')
  }
}
// }}}
// {{{ uploadRecording
// callback of the recorderService; we use it to create a new
// assistantRecording and upload it.
function uploadRecording(recording: RecordingType): Promise<void> {
  return new Promise((resolve) => {
    // this can happen if the recording stopped because the recording overlay was abruptly closed
    if (!state.focusSentence || state.focusSentenceIndex === null) {
      logger.debug('null focusSentence or focusSentenceIndex in uploadRecording; calling resolve()')
      resolve()
      return
    }

    const file = new Blob([recording.blob], { type: recording.mimeType })
    const extension = mime.extension(file.type)
    const parashah = state.haftarah ? state.haftarah.parashah : state.parashah

    let fileName = ''
    if (state.aliyah) {
      fileName += `Par-${parashah!.transliteratedTitle}-Ali-${state.aliyah.rank}`
    } else if (state.haftarah) {
      fileName += `Haf-${parashah!.transliteratedTitle}`
    } else if (state.sentenceGroup) {
      fileName += `Txt-${state.sentenceGroup.reading.description}`
    }
    fileName += `-Sen-${state.focusSentenceIndex + 1}`
    fileName += `-Pron-${state.voiceStyleSet.pronunciation?.description}`
    if (state.voiceStyleSet.torahMelody) {
      fileName += `-TMel-${state.voiceStyleSet.torahMelody?.description}`
    } else if (state.voiceStyleSet.haftarahMelody) {
      fileName += `-HM${state.voiceStyleSet.haftarahMelody.description}`
    } else if (state.voiceStyleSet.sentenceGroupMelody) {
      fileName += `-SGM${state.voiceStyleSet.sentenceGroupMelody.description}`
    }
    fileName += `-${moment().format('YYYY-MM-DD-hh-mm-ss')}`
    fileName += `.${extension}`
    ;(file as any).name = fileName
    upload(file)
    resolve()
  })
}
// }}}

// play audio
// {{{ fetchRecordingForDownload
// downloads a file by faking a click on a non-existing <a href> element
function fetchRecordingForDownload(sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios): void {
  if (!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
    logger.error('unexpected null sentenceRecordingMetadataWithPitchedAudios.sentenceRecording')
    return
  }
  sentenceRecordingMetadataWithPitchedAudios.downloading = true
  state.downloadingSentenceRecordingId = sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.id
  store.dispatch.audio
    .fetchAudio({
      cache: true,
      pitchShift: DEFAULT_PITCH_SHIFT,
      sentenceRecording: sentenceRecordingMetadataWithPitchedAudios.sentenceRecording as SentenceRecordingType,
    })
    .then(() => {
      const link = document.createElement('a')
      if (!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
        logger.debug('unexpected null sentenceRecordingMetadataWithPitchedAudios.sentenceRecording in fetchRecordingForDownload')
        return
      }
      if (!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.file) {
        logger.debug('unexpected null sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.file in fetchRecordingForDownload')
        return
      }
      if (!sentenceRecordingMetadataWithPitchedAudios.audio[state.pitchShift].sentenceHTMLAudioWrapper.audio) {
        logger.debug(
          'unexpected null sentenceRecordingMetadataWithPitchedAudios.audio[state.pitchShift].sentenceHTMLAudioWrapper.audio in fetchRecordingForDownload',
        )
        return
      }
      link.href = sentenceRecordingMetadataWithPitchedAudios.audio[state.pitchShift].sentenceHTMLAudioWrapper.audio!.src
      link.setAttribute('download', sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.file)
      link.click()
    })
    .finally(() => {
      state.downloadingSentenceRecordingId = null
      sentenceRecordingMetadataWithPitchedAudios.downloading = false
    })
}
// }}}
// {{{ pauseWrapper
const pauseWrapper = () => {
  state.pauseCounter += 1
  pause()
}
// }}}
// {{{ playSentenceOrSequencedSegments
const playSentenceOrSequencedSegments = async (sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios) => {
  if (!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
    logger.error('unexpected null sentenceRecordingMetadataWithPitchedAudios.sentenceRecording')
    return
  }
  const sentenceRecording: SentenceRecordingType = sentenceRecordingMetadataWithPitchedAudios.sentenceRecording

  state.playingSentenceRecordingId = sentenceRecording.id
  store.dispatch.audio
    .fetchAudio({
      cache: true,
      pitchShift: state.pitchShift,
      sentenceRecording: sentenceRecording,
    })
    .then(async () => {
      // play segments until we can't if this is a sentence, then this will run only once
      for (let segmentIndex = 0; ; segmentIndex += 1) {
        try {
          const prePauseCounter = state.pauseCounter
          await setLoadPlay({
            pitchShift: state.pitchShift,
            segmentIndex: segmentIndex,
            sentenceRecordingMetadataWithPitchedAudios: sentenceRecordingMetadataWithPitchedAudios,
          })

          // we've been asked to stop playing
          if (state.pauseCounter > prePauseCounter) {
            break
          }
        } catch (error) {
          break
        }
      }
      state.playingSentenceRecordingId = null
    })
}
// }}}

// {{{ stopRecording
function stopRecording(): void {
  if (!state.recorderService) {
    logger.debug('stopRecording, unexpected null state.recorderService')
    return
  }
  state.popoverShowStartNow = false
  state.popoverTarget = null
  state.backgroundSampling = false
  state.backgroundSamplingPercent = 0
  state.startRecordingAt = null
  state.recorderService.stopRecording()
  setCurrentSegment(-1, -1)
}
// }}}
// {{{ toggleRecord
// turns on/off recording
const toggleRecord = (): void => {
  state.popoverTarget = null
  if (isRecording.value) {
    stopRecording()
    return
  }

  if (playing()) {
    pause()
  }

  // need to do background sampling if baseLevel hasn't been set yet
  if (!baseLevel.value) {
    baseLevel.value = 0
    state.backgroundSampling = true
    state.backgroundSamplingPercent = 0
  }

  if (!state.recorderService) {
    logger.debug('stopRecording, unexpected null state.recorderService')
    return
  }

  if (!state.recorderService.options) {
    logger.error('unexpected null state.recorderService.options')
    return
  }

  try {
    state.recorderService.options.voiceActivityDetection = true
    state.recorderService.startRecording(baseLevel.value) // argument determines whether or not to re-sample
  } catch (error) {
    stopRecording()
    store.dispatch.dialog.show({ message: error as unknown as string, title: 'Error' })
  }
}
// }}}
</script>

<template>
  <!-- {{{ template -->
  <v-container fluid>
    <sampling-overlay
      v-if="state.backgroundSampling"
      :on-click="
        () => {
          baseLevel = 0
          toggleRecord()
        }
      "
      :percent="state.backgroundSamplingPercent"
      :visible="state.backgroundSampling"
    />

    <popover :attach-id="state.popoverTarget" :visible="!!state.popoverTarget">
      <v-card>
        <v-card-text class="pa-1">
          <template v-if="state.popoverShowStartNow">
            <span class="mx-4 my-5 text-h5">{{ inSingModeFunc(state.voiceStyleSet) ? 'Sing' : 'Read' }} now!</span>
          </template>
          <template v-else>
            <v-icon
              v-if="!loaded() || paused()"
              size="xxx-large"
              @click="
                () => {
                  if (!state.popoverSentence) {
                    logger.error('unexpected null state.popoverSentence')
                    return
                  }
                  if (state.popoverSegmentIndex === null) {
                    logger.error('unexpected null state.popoverSegmentIndex')
                    return
                  }
                  if (!state.popoverSentenceRecordingMetadataWithPitchedAudios) {
                    logger.error('unexpected null state.popoverSentenceRecordingMetadataWithPitchedAudios')
                    return
                  }
                  if (!loaded()) {
                    setLoadPlay({
                      segmentIndex: state.popoverSegmentIndex,
                      pitchShift: state.pitchShift,
                      sentenceRecordingMetadataWithPitchedAudios: state.popoverSentenceRecordingMetadataWithPitchedAudios,
                    })
                  } else {
                    play()
                  }
                }
              "
              >mdi-play mdi-rotate-180
            </v-icon>

            <v-icon
              v-else
              size="xxx-large"
              @click="
                () => {
                  if (!state.popoverSentence) {
                    logger.error('unexpected null state.popoverSentence')
                    return
                  }
                  if (playing()) {
                    pauseWrapper()
                  }
                }
              "
              >mdi-pause
            </v-icon>

            <v-icon
              size="xxx-large"
              @click="
                () => {
                  if (playing()) {
                    pauseWrapper()
                  }
                  if (!state.popoverSentence) {
                    logger.error('unexpected null state.popoverSentence')
                    return
                  }
                  if (state.popoverSegmentIndex === null) {
                    logger.error('unexpected null state.popoverSegmentIndex')
                    return
                  }
                  if (!state.popoverSentenceRecordingMetadataWithPitchedAudios) {
                    logger.error('unexpected null state.popoverSentenceRecordingMetadataWithPitchedAudios')
                    return
                  }
                  setLoadPlay({
                    segmentIndex: state.popoverSegmentIndex,
                    pitchShift: state.pitchShift,
                    sentenceRecordingMetadataWithPitchedAudios: state.popoverSentenceRecordingMetadataWithPitchedAudios,
                  })
                }
              "
              >mdi-skip-forward
            </v-icon>

            <v-icon
              color="red"
              size="xxx-large"
              @click="
                () => {
                  if (playing()) {
                    pauseWrapper()
                  }
                  state.replacementRecordingSegmentIndex = state.popoverSegmentIndex
                  toggleRecord()
                }
              "
              >mdi-record
            </v-icon>

            <v-icon
              size="xxx-large"
              @click="
                () => {
                  if (playing()) {
                    pauseWrapper()
                  }
                  state.popoverTarget = null
                }
              "
              >mdi-close
            </v-icon>
          </template>
        </v-card-text>
      </v-card>
    </popover>

    <v-card v-show="!!state.focusSentence">
      <v-card-text>
        <v-row justify="center">
          <v-col md="2"></v-col>
          <v-col align="right" md="2">
            <v-btn
              id="prev_link"
              :disabled="state.focusSentenceIndex !== null && !canProceedToSentence(state.focusSentenceIndex - 1)"
              small
              @click="
                () => {
                  proceedToSentence(-1)
                }
              "
            >
              <v-icon color="primary" left small>mdi-skip-backward</v-icon>
              Prev
            </v-btn>
            <!--
              <v-tooltip target="prev_link" delay="500">shortcut: 'v' </v-tooltip>
              -->
          </v-col>

          <v-col align="center" md="2" offset-md="1">
            <v-slider v-if="false" v-model="fontSize" label="Size" :max="MAX_FONT_SIZE" :min="MIN_FONT_SIZE" :step="10" thumb-label />
          </v-col>

          <v-col md="2" offset-md="1">
            <v-btn
              id="next_link"
              :disabled="state.focusSentenceIndex !== null && !canProceedToSentence(state.focusSentenceIndex + 1)"
              small
              @click="
                () => {
                  proceedToSentence(1)
                }
              "
            >
              Next
              <v-icon color="primary" right small>mdi-skip-forward</v-icon>
            </v-btn>
            <v-tooltip bottom open-delay="500" text="Automatically advance to the next sentence.">
              <template #activator="{ props }">
                <span v-bind="props">
                  <v-switch
                    v-model="state.autoAdvance"
                    color="primary"
                    :disabled="state.focusSentenceIndex !== null && !canProceedToSentence(state.focusSentenceIndex + 1)"
                    label="Auto-next"
                  />
                </span>
              </template>
            </v-tooltip>
            <!--
                    <v-tooltip target="next_link" delay="500"> shortcut: 'n' </v-tooltip>
                    -->
          </v-col>

          <v-col align="right" md="1" offset-md="1">
            <v-btn
              color="red-lighten-3"
              fab
              icon
              size="x-small"
              @click="
                () => {
                  closeRecordOverlay()
                }
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- text to record -->
        <v-row class="mt-2 pt-2">
          <v-col align="center" md="12">
            <span ref="focusSentenceSpan">
              <annotated-text
                v-if="store.getters.user.userIsLoggedIn"
                v-model:annotated-segments="state.annotatedSegments as Array<Array<AnnotatedSegment>>"
                :bar-mitzvah="state.barMitzvah"
                :clickable-segment="hasSegmentRecording"
                :clickable-segment-boundary="
                  () => {
                    return (
                      !isRecording &&
                      state.phrasing === PHRASES &&
                      !state.existingSentenceRecordingMetadataWithPitchedAudios &&
                      segmentableSentence
                    )
                  }
                "
                :clickable-word="hasSegmentRecordingWordWrapper"
                :clickable-word-boundary="
                  () => {
                    return (
                      !isRecording &&
                      state.phrasing === PHRASES &&
                      !state.existingSentenceRecordingMetadataWithPitchedAudios &&
                      segmentableSentence
                    )
                  }
                "
                :default-font="store.getters.user.user?.properties?.font"
                :empty-on-missing-style="false"
                :font-size="fontSize"
                :line-height="lineHeight"
                name="focusText"
                :refresh="state.focusTextRefresh"
                :segment-boundary-style="segmentBoundaryStyle"
                :segment-style="segmentStyle"
                :segmentations="segmentations"
                :sentences="focusSentences"
                :show-end-of-sentence-character="true"
                :show-sentence-numbers="false"
                :start-index="state.focusSentenceIndex as number"
                :transformation="state.transformation"
                :voice-style-set="state.voiceStyleSet"
                :word-boundary-style="wordBoundaryStyle"
                @click-segment-boundary="handleSegmentBoundaryClick"
                @click-word="handleWordClick"
                @click-word-boundary="handleWordBoundaryClick"
              />
            </span>
          </v-col>
        </v-row>

        <v-container v-if="state.displayGraph && isRecording">
          <v-row class="mt-3 pt-3" justify="center">
            <v-col align="center" md="12">
              <Line :data="chartData" :options="chartOptions" />
            </v-col>
          </v-row>
        </v-container>

        <!-- style set of recording -->
        <v-row class="mt-2 pt-2" justify="center">
          <!-- pronunciation style -->
          <v-col v-if="pronunciationChoices && pronunciationChoices.length > 1" md="3">
            <v-select
              v-model="state.voiceStyleSet.pronunciation"
              :clearable="false"
              :disabled="isRecording"
              item-title="description"
              :items="pronunciationChoices"
              label="Pronunciation"
              return-object
              variant="underlined"
            />
          </v-col>

          <!-- voice style -->
          <v-col md="2">
            <v-radio-group
              v-model="state.voiceStyleSet.voice"
              @update:model-value="
                setVoice(state.existingSentenceRecordingMetadataWithPitchedAudios as SentenceRecordingMetadataWithPitchedAudios)
              "
            >
              <v-radio
                v-for="recordingVoiceStyle in RecordingVoiceStyles"
                :key="recordingVoiceStyle"
                :label="recordingVoiceStyle"
                :value="recordingVoiceStyle"
              />
            </v-radio-group>
          </v-col>

          <v-col md="2">
            <v-radio-group v-model="state.phrasing" :disabled="phrasingDropdownDisabled">
              <v-radio
                v-for="phrasingOption in PHRASING_OPTIONS"
                :key="phrasingOption.title"
                :label="phrasingOption.title"
                :value="phrasingOption.value"
              />
            </v-radio-group>
          </v-col>

          <!-- torah melodic style -->
          <v-col v-if="!_.isEmpty(state.aliyah) && !isMelodicStyleDisabled && torahMelodyChoices && torahMelodyChoices.length > 1" md="4">
            <v-select
              v-model="state.voiceStyleSet.torahMelody"
              :clearable="false"
              :disabled="isRecording || !state.voiceStyleSet.voice || inReadMode"
              item-title="description"
              :items="torahMelodyChoices"
              label="Melody"
              return-object
              variant="underlined"
            />
          </v-col>

          <!-- haftarah melodic style -->
          <v-col
            v-else-if="!_.isEmpty(state.haftarah) && !isMelodicStyleDisabled && haftarahMelodyChoices && haftarahMelodyChoices.length > 1"
            md="4"
          >
            <v-select
              v-model="state.voiceStyleSet.haftarahMelody"
              :clearable="false"
              :disabled="isRecording || !state.voiceStyleSet.voice || inReadMode"
              item-title="description"
              :items="haftarahMelodyChoices"
              label="Melody"
              return-object
              variant="underlined"
            />
          </v-col>

          <!-- sentenceGroup melodic style -->
          <v-col
            v-else-if="
              !_.isEmpty(state.sentenceGroup) &&
              !isMelodicStyleDisabled &&
              sentenceGroupMelodyChoices &&
              sentenceGroupMelodyChoices.length > 1
            "
            md="4"
          >
            <v-select
              v-model="state.voiceStyleSet.sentenceGroupMelody"
              :clearable="false"
              :disabled="isRecording || !state.voiceStyleSet.voice || inReadMode"
              item-title="description"
              :items="sentenceGroupMelodyChoices"
              label="Melody"
              return-object
              variant="underlined"
            />
          </v-col>

          <v-col
            v-if="nonHiddenUserTags && nonHiddenUserTags.length"
            md="2"
            :style="!state.voiceStyleSet.tags || !state.voiceStyleSet.tags.length ? 'border: 2px solid red' : ''"
          >
            <!-- tags -->
            <v-select
              v-model="state.voiceStyleSet.tags"
              chips
              :disabled="isRecording"
              item-title="name"
              :items="nonHiddenUserTags"
              label="Style"
              multiple
              return-object
              variant="underlined"
              @update:model-value="
                () => {
                  if (
                    state.existingSentenceRecordingMetadataWithPitchedAudios &&
                    state.existingSentenceRecordingMetadataWithPitchedAudios.sentenceRecording
                  ) {
                    state.existingSentenceRecordingMetadataWithPitchedAudios.sentenceRecording.tags = _.cloneDeep(
                      state.voiceStyleSet.tags,
                    ) as Array<TagType>
                    updateSentenceRecordingMetadataWithPitchedAudios(state.existingSentenceRecordingMetadataWithPitchedAudios)
                  }
                }
              "
            />
          </v-col>
        </v-row>

        <!-- create new recording -->
        <!-- form to upload a file -->
        <v-form @submit.prevent="uploadFile">
          <v-row class="mt-2 pt-2" justify="center">
            <v-col md="2">
              <div v-if="false">
                playing() = {{ playing() }}<br />
                torahMelody = {{ state.voiceStyleSet.torahMelody }}<br />
                haftarahMelody = {{ state.voiceStyleSet.haftarahMelody }}<br />
                sentenceGroupMelody = {{ state.voiceStyleSet.sentenceGroupMelody }}<br />
                !isReadyForRecording = {{ !isReadyForRecording }}<br />
                !!state.pendingUploadRecording = {{ !!state.pendingUploadRecording }}<br />
                state.segmentation = {{ state.segmentation }}<br />
                isRecording = {{ isRecording }}<br />
              </div>
              <v-btn
                block
                :color="isRecording && !state.backgroundSampling ? 'warning' : 'primary'"
                :disabled="
                  playing() || // can't record while playing audio
                  !isReadyForRecording || // selections aren't made
                  state.uploadWaiting || // we're in the middle of an upload
                  !!state.pendingUploadRecording || // in the middle of an upload
                  (isRecording && state.disableAutomaticVoiceStartStop && currentSegmentIndex === state.annotatedSegments[0].length - 1) || // ???
                  (state.phrasing === PHRASES && state.segmentation.length === 1) // claimed manual phrasing, but didn't create a phrasing
                "
                @click="
                  () => {
                    state.replacementRecordingSegmentIndex = null
                    toggleRecord()
                  }
                "
              >
                <template
                  v-if="
                    !(
                      !isReadyForRecording ||
                      state.uploadWaiting ||
                      !!state.pendingUploadRecording ||
                      (isRecording && state.disableAutomaticVoiceStartStop && currentSegmentIndex === state.annotatedSegments[0].length - 1)
                    )
                  "
                >
                </template>
                <span class="mr-2"><font-awesome-icon icon="microphone" /></span>
                <template v-if="isRecording && !state.backgroundSampling"> Stop recording </template>
                <template v-else>
                  {{ state.existingSentenceRecordingMetadataWithPitchedAudios ? 'Update' : 'Create' }}
                  recording
                </template>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col align="center" class="ml-10" md="4">
              <v-icon v-show="isRecording && !state.backgroundSampling" color="red" label="Recording..." size="70px"
                >mdi-microphone
              </v-icon>
              <v-progress-circular v-show="!isRecording && state.uploadWaiting" indeterminate medium />
              <template v-if="!isRecording && !state.uploadWaiting">
                <div align="left">
                  <v-tooltip
                    bottom
                    open-delay="500"
                    text="Disables automatic voice phrase recognition. You need to press 'p' at the end of each phrase that you record."
                  >
                    <template #activator="{ props }">
                      <span v-bind="props">
                        <v-switch
                          v-model="state.disableAutomaticVoiceStartStop"
                          class="mt-1"
                          color="primary"
                          label="Disable automatic audio start/stop recognition"
                          @change="warnManualSegmentation"
                        />
                      </span>
                    </template>
                  </v-tooltip>
                  <v-switch
                    v-if="isDevelopment()"
                    v-model="state.displayGraph"
                    class="mt-1"
                    color="primary"
                    :disabled="state.disableAutomaticVoiceStartStop"
                    label="Graph"
                  />
                </div>
              </template>
            </v-col>
          </v-row>

          <v-row v-if="!isRecording" justify="center">
            <v-col md="3">
              <v-file-input
                v-model="state.pendingUploadRecording as File"
                accept="audio/*"
                :disabled="isRecording || !isReadyForRecording"
                drop-placeholder="Drop file here"
                label="...or upload audio file"
                variant="underlined"
              />
            </v-col>
          </v-row>

          <v-row v-if="state.pendingUploadRecording" justify="center">
            <v-col md="3">
              <v-btn block color="primary" :disabled="!isReadyForRecording || state.uploadWaiting" type="submit">Upload </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <div v-show="!state.focusSentence">
      <student-to-sentences-select
        v-model:aliyah="state.aliyah"
        v-model:bar-mitzvah="state.barMitzvah"
        v-model:haftarah="state.haftarah"
        v-model:parashah="state.parashah"
        v-model:sentence-group="state.sentenceGroup"
        v-model:sentences="state.sentences"
        :aliyah-disabled="false"
        :aliyah-reset-on-options-change="false"
        :bar-mitzvah-clearable="true"
        :bar-mitzvah-searchable="true"
        :disable-haftarah-when-student-has-no-haftarah="true"
        :disable-parashah-when-student-has-no-parashah="true"
        :disable-sentence-group-when-student-has-no-sentence-group="true"
        :force-student-select="false"
        :haftarah-disabled="false"
        :haftarah-disabled-on-no-bar-mitzvah="false"
        :haftarah-reset-on-options-change="true"
        :hide-aliyah-when-disabled="true"
        :hide-haftarah-when-disabled="true"
        :hide-parashah-when-disabled="true"
        :hide-sentence-group-when-disabled="true"
        :hide-student-when-disabled="true"
        :no-sentence-select="true"
        :no-student-select="false"
        :parashah-disabled="false"
        :parashah-disabled-on-no-bar-mitzvah="false"
        :parashah-reset-on-options-change="true"
        :prevent-auto-single-aliyah-select="false"
        :prevent-auto-single-bar-mitzvah-select="true"
        :prevent-auto-single-haftarah-select="true"
        :prevent-auto-single-parashah-select="true"
        :prevent-auto-single-sentence-group-select="true"
        :sentence-group-disabled-on-no-bar-mitzvah="false"
        :student-disabled-when-other-selected="true"
        student-dropdown-text="Choosing a student will filter choices down to those assigned to the student; clearing the student selection makes all text available for recording."
      />

      <!-- all sentences, some of which may or may not have a existingSentenceRecordingMetadataWithPitchedAudios -->
      <!-- use v-show, not v-if, because we need this component to compute
          annotatedSentences in response to changes to annotatedSentences -->
      <v-row v-if="state.sentences.length > 0" justify="end">
        <v-col v-if="!state.openedPanels.length" md="2">
          <v-btn block @click="unfoldExpansionPanels">
            <v-icon>mdi-unfold-more-horizontal</v-icon>
            expand
          </v-btn>
        </v-col>
        <v-col v-else align="right" md="2">
          <v-btn block @click="foldExansionPanels">
            <v-icon>mdi-unfold-less-horizontal</v-icon>
            collapse
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-show="state.sentences.length > 0" class="mt-2 pt-2">
        <v-col>
          <!-- not visible; used only to translate sentences -> annotatedSentences -->
          <annotated-text
            v-if="store.getters.user.userIsLoggedIn"
            v-model:annotated-sentences="state.annotatedSentences as Array<AnnotatedSentence>"
            :bar-mitzvah="state.barMitzvah"
            :clickable-sentence="() => false"
            :empty-on-missing-style="true"
            name="allText"
            :sentences="state.sentences"
            :v-show="false"
          />
          <v-expansion-panels v-model="state.openedPanels" accordion multiple>
            <v-expansion-panel v-for="(annotatedSentenceX, sentenceIndex) in state.annotatedSentences" :key="sentenceIndex">
              <v-expansion-panel-title :id="'panel-title-' + annotatedSentenceX.sentence().id" color="grey-lighten-4">
                <template #actions="{ expanded }">
                  <span class="ml-3 text-h6">
                    <template v-if="expanded">
                      <v-icon>mdi-menu-up</v-icon>
                    </template>
                    <template v-else>
                      <v-icon>mdi-menu-down</v-icon>
                    </template>
                    <span
                      v-if="
                        !_.isEmpty(sentenceRecordingMetadatas) && !_.isEmpty(sentenceRecordingMetadatas[annotatedSentenceX.sentence().id])
                      "
                    >
                      [{{ sentenceRecordingMetadatas[annotatedSentenceX.sentence().id].length }}]
                    </span>
                    <span v-else> [0] </span>
                  </span>

                  <v-btn
                    class="ml-4"
                    icon
                    size="x-small"
                    @click="
                      () => {
                        handleSentenceClick(annotatedSentenceX as AnnotatedSentence, sentenceIndex, true)
                      }
                    "
                    ><span><font-awesome-icon icon="microphone" /></span>
                  </v-btn>
                </template>

                <v-row justify="end" no-gutters>
                  <v-col align="right" md="12">
                    <!-- for strange Vue reasons, this annotated-text causes heavy reactive computation while the sampling-overlay is up; there is no direct
                          reactive reason why that might be the case, but to avoid the problem altogether, we'll v-if this annonated-text against backgroundSampling to
                          avoid the problem altogether -->
                    <annotated-text
                      v-if="!state.backgroundSampling && store.getters.user.user?.properties?.font"
                      :bar-mitzvah="state.barMitzvah"
                      :default-font="store.getters.user.user.properties.font"
                      :empty-on-missing-style="true"
                      :font-size="140"
                      name="oneLine"
                      :sentences="[annotatedSentenceX.sentence()]"
                      :show-end-of-sentence-character="true"
                      :show-responsive-headers="
                        () => {
                          return !sentencesHaveSameResponsiveness
                        }
                      "
                      :show-sentence-numbers="true"
                      :start-index="sentenceIndex"
                      :transformation="state.transformation"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-data-table
                  v-if="
                    !_.isEmpty(sentenceRecordingMetadatas) &&
                    !_.isEmpty(sentenceRecordingMetadatas[annotatedSentenceX.sentence().id]) &&
                    sentenceRecordingMetadatas[annotatedSentenceX.sentence().id].length > 0
                  "
                  disable-pagination
                  :headers="recordingFields"
                  hide-default-footer
                  :items="sentenceRecordingMetadatas[annotatedSentenceX.sentence().id]"
                  @click:row="
                    (__: Event, row: any) => {
                      const srmd = row.item as SentenceRecordingMetadataWithPitchedAudios
                      handleEditRecording(srmd, annotatedSentenceX as AnnotatedSentence, sentenceIndex)
                    }
                  "
                >
                  <template #item.action="{ item }">
                    <v-progress-circular v-if="isProcessing(item)" class="mr-1" indeterminate small />
                    <template
                      v-else-if="
                        !!item.sentenceRecording &&
                        !!item.sentenceRecording.uploadedAt &&
                        !!item.sentenceRecording.processingEndedAt &&
                        !item.sentenceRecording.processingSuccess
                      "
                    >
                      ❌ {{ item.sentenceRecording?.processingMessage }}
                    </template>
                    <template v-else>
                      <v-btn
                        class="mr-1"
                        icon
                        size="x-small"
                        @click.capture.stop="handleEditRecording(item, annotatedSentenceX as AnnotatedSentence, sentenceIndex)"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="
                          item.downloading && !!item.sentenceRecording && state.downloadingSentenceRecordingId === item.sentenceRecording.id
                        "
                        class="mr-1"
                        icon
                        size="x-small"
                      >
                        <v-progress-circular v-if="item.downloading" indeterminate />
                      </v-btn>
                      <v-btn v-else class="mr-1" icon size="x-small" @click.capture.stop="fetchRecordingForDownload(item)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="
                          item.downloading && !!item.sentenceRecording && state.playingSentenceRecordingId === item.sentenceRecording.id
                        "
                        class="mr-1"
                        icon
                        size="x-small"
                      >
                        <v-progress-circular v-if="item.downloading" indeterminate />
                      </v-btn>
                      <v-btn
                        v-else-if="playing() && !!item.sentenceRecording && state.playingSentenceRecordingId === item.sentenceRecording.id"
                        class="mr-1"
                        icon
                        size="x-small"
                        @click.capture.stop="pauseWrapper()"
                      >
                        <v-icon>mdi-pause</v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        class="mr-1"
                        icon
                        size="x-small"
                        @click.capture.stop="
                          () => {
                            // either way, if there was audio playing already, stop it
                            // (this will happen if the user clicks to play another sentence than the one currently playing
                            if (playing()) {
                              pauseWrapper()
                              // if we were pausing this sentenceRecording, then restart it where we left off
                            }

                            if (paused() && !!item.sentenceRecording && state.playingSentenceRecordingId === item.sentenceRecording.id) {
                              logger.debug('unpause current track')
                              if (item.sentenceRecording === null) {
                                logger.error('unexpected item.sentenceRecording.id')
                                return
                              }
                              state.playingSentenceRecordingId = item.sentenceRecording.id
                              play()
                              // start playing whatever track was clicked on
                            } else {
                              logger.debug('starting new track')
                              if (!item.sentenceRecording) {
                                logger.error('unexpected null item.sentenceRecording in @click.capture of mdi-play')
                                return
                              }

                              playSentenceOrSequencedSegments(item)
                            }
                          }
                        "
                      >
                        <v-icon>mdi-play</v-icon>
                      </v-btn>
                      <v-btn
                        class="mr-1"
                        :disabled="paused() || (!!item.sentenceRecording && state.playingSentenceRecordingId !== item.sentenceRecording.id)"
                        icon
                        size="x-small"
                        @click.capture.stop="
                          () => {
                            if (playing()) {
                              pauseWrapper()
                            }

                            if (!item.sentenceRecording) {
                              logger.error('unexpected null item.sentenceRecording in @click.capture of mdi-play')
                              return
                            }

                            playSentenceOrSequencedSegments(item)
                          }
                        "
                      >
                        <v-icon> mdi-skip-backward </v-icon>
                      </v-btn>
                    </template>
                    <v-btn icon size="x-small" @click.capture.stop="confirmDeleteRecording(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>

                  <!-- time stamp -->
                  <template #item.date="{ item }"
                    ><span v-if="item.sentenceRecording">{{
                      moment(item.sentenceRecording.modified).format('YYYY-MM-DD HH:mm')
                    }}</span></template
                  >

                  <!-- ID -->
                  <template #item.id="{ item }"
                    ><span v-if="item.sentenceRecording">{{ item.sentenceRecording.id }}</span></template
                  >

                  <!-- split_dbfs_plus -->
                  <template #item.dbfs="{ item }"
                    ><span v-if="item.sentenceRecording">{{ item.sentenceRecording.splitDbfsPlus }}</span></template
                  >

                  <!-- pronunciation -->
                  <template #item.pronunciation="{ item }">
                    <v-select
                      v-if="item.sentenceRecording && pronunciationChoices.length > 1"
                      v-model="item.sentenceRecording.pronunciation"
                      :clearable="false"
                      item-title="description"
                      :items="pronunciationChoices"
                      return-object
                      variant="underlined"
                      @click.capture.stop
                      @update:model-value="updateSentenceRecordingMetadataWithPitchedAudios(item)"
                    />
                  </template>

                  <template #item.warning="{ item }">
                    <template v-if="false">
                      hiddenTagName: {{ hiddenTagName(item) }}<br />
                      hiddenTagNameIncludes(SENTENCE): {{ hiddenTagNameIncludes(item, SENTENCE) }}<br />
                      hiddenTagNameIncludes(PHRASES): {{ hiddenTagNameIncludes(item, PHRASES) }}<br />
                      hiddenTagNameIncludes(WORDS): {{ hiddenTagNameIncludes(item, WORDS) }}<br />
                      isSegmentedRecordingMetadata: {{ isSegmentedRecordingMetadata(item) }}<br />
                      nSentenceSegments: {{ nSentenceSegments(item) }}<br />
                      nAudioSegments: {{ nAudioSegments(item) }}<br />
                      annotatedSentenceX.nWords(): {{ annotatedSentenceX.nWords() }}<br />
                      isRead(item): {{ isRead(item) }}<br />
                      hasEqualRecording(item.sentenceRecording) {{ hasEqualRecording(item.sentenceRecording as SentenceRecordingType)
                      }}<br />
                    </template>
                    <template v-if="!isProcessing(item)">
                      <!-- 1 segment means there are no audio segments, only the full uncut sentence audio -->
                      <span
                        v-if="
                          hiddenTagNameIncludes(item, SENTENCE) ||
                          (isSegmentedRecordingMetadata(item) && nSentenceSegments(item) === 1 && nAudioSegments(item) === 1)
                        "
                        class="text-success"
                      >
                        ✅<span class="mr-2" /> 1 sentence
                      </span>
                      <span
                        v-else-if="
                          hiddenTagNameIncludes(item, PHRASES) ||
                          (isSegmentedRecordingMetadata(item) &&
                            (nSentenceSegments(item) === nAudioSegments(item) ||
                              (nSentenceSegments(item) === 1 && nAudioSegments(item) === 0)))
                        "
                        class="text-success"
                      >
                        ✅<span class="mr-2" /> {{ nSentenceSegments(item) }} phrase{{ nSentenceSegments(item) > 1 ? 's' : '' }}
                      </span>
                      <span v-else-if="isSegmentedRecordingMetadata(item) && !nAudioSegments(item)" class="text-danger">
                        ❌<span class="mr-2" /> no phrases detected
                      </span>
                      <span
                        v-else-if="isSegmentedRecordingMetadata(item) && nSentenceSegments(item) !== nAudioSegments(item)"
                        class="text-danger"
                      >
                        ❌<span class="mr-2" /> {{ nAudioSegments(item) }} / {{ nSentenceSegments(item) }} phrases
                      </span>
                      <span
                        v-else-if="
                          isRead(item) && !isSegmentedRecordingMetadata(item) && nAudioSegments(item) !== annotatedSentenceX.nWords()
                        "
                        class="text-danger"
                      >
                        ❌<span class="mr-2" /> {{ nAudioSegments(item) }} / {{ annotatedSentenceX.nWords() }} words
                      </span>
                      <span
                        v-else-if="
                          hiddenTagNameIncludes(item, WORDS) ||
                          (!isSegmentedRecordingMetadata(item) && nAudioSegments(item) === annotatedSentenceX.nWords())
                        "
                        class="text-success"
                      >
                        ✅<span class="mr-2" /> {{ annotatedSentenceX.nWords() }} words
                      </span>
                      <span v-else-if="!isSegmentedRecordingMetadata(item) && isSung(item)" class="text-success">
                        ✅<span class="mr-2" /> 1 sentence
                      </span>
                      <div v-if="item.sentenceRecording && hasEqualRecording(item.sentenceRecording)" class="error">
                        ⚠️ Duplicate style/voice
                      </div>
                    </template>
                  </template>

                  <template #item.melody="{ item }">
                    <!-- torah melodic style -->
                    <span v-if="isSung(item) && !_.isEmpty(state.aliyah)" md="4">
                      <v-select
                        v-if="item.sentenceRecording"
                        v-model="item.sentenceRecording.torahMelody"
                        :clearable="false"
                        item-title="description"
                        :items="torahMelodyChoices"
                        return-object
                        variant="underlined"
                        @update:model-value="updateSentenceRecordingMetadataWithPitchedAudios(item)"
                      />
                    </span>

                    <!-- haftarah melodic style -->
                    <span v-else-if="isSung(item) && !_.isEmpty(state.haftarah)" md="4">
                      <v-select
                        v-if="item.sentenceRecording"
                        v-model="item.sentenceRecording.haftarahMelody"
                        :clearable="false"
                        item-title="description"
                        :items="haftarahMelodyChoices"
                        return-object
                        variant="underlined"
                        @click.capture.stop
                        @update:model-value="updateSentenceRecordingMetadataWithPitchedAudios(item)"
                      />
                    </span>

                    <!-- sentenceGroup melodic style -->
                    <span v-else-if="isSung(item) && !_.isEmpty(state.sentenceGroup)" md="4">
                      <v-select
                        v-if="item.sentenceRecording"
                        v-model="item.sentenceRecording.sentenceGroupMelody"
                        :clearable="false"
                        item-title="description"
                        :items="sentenceGroupMelodyChoices"
                        return-object
                        variant="underlined"
                        @click.capture.stop
                        @update:model-value="updateSentenceRecordingMetadataWithPitchedAudios(item)"
                      />
                    </span>

                    <span v-else> [n/a] </span>
                  </template>

                  <!-- voice style -->
                  <template #item.voice="{ item }">
                    <v-select
                      v-if="item.sentenceRecording"
                      v-model="voices[item.sentenceRecording.id]"
                      :clearable="false"
                      :items="RecordingVoiceStyles"
                      return-object
                      variant="underlined"
                      @click.capture.stop
                      @update:model-value="setVoice(item)"
                    />
                  </template>

                  <!-- tags -->
                  <template #item.tags="{ item }">
                    <v-select
                      v-if="item.sentenceRecording"
                      v-model="item.sentenceRecording.tags"
                      chips
                      :clearable="false"
                      deletable-chips
                      item-title="name"
                      :items="nonHiddenUserTags"
                      multiple
                      return-object
                      variant="underlined"
                      @click.capture.stop
                      @update:model-value="updateSentenceRecordingMetadataWithPitchedAudios(item)"
                    >
                    </v-select>
                  </template>

                  <!-- <template v-slot:item.absorb="{ item }"> </template> -->
                </v-data-table>
                <v-row class="mt-3 mr-4" justify="end">
                  <v-col md="2">
                    <v-btn
                      block
                      color="primary"
                      @click="
                        () => {
                          handleSentenceClick(annotatedSentenceX as AnnotatedSentence, sentenceIndex, true)
                        }
                      "
                      ><span class="mr-2"><font-awesome-icon icon="microphone" /></span>
                      Add recording
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </v-container>
  <!-- }}} -->
</template>

<style scoped>
/* {{{ styles
 */
a:hover {
  text-decoration: none;
}

a.disabled {
  pointer-events: none;
  color: grey;
}
/* }}} */
</style>
