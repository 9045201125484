<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import _ from 'lodash'
import { useForm } from 'vee-validate'
import { onMounted } from 'vue'
import * as yup from 'yup'

import store from '@/store'
import { CreateTeacherParameters } from '@/util/Teacher'

const { defineField, errors, handleSubmit, meta, resetForm } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      email: yup.string().email(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      username: yup.string().required(),
    }),
  ),
})

const [email, emailAttr] = defineField('email')
const [username, usernameAttr] = defineField('username')
const [firstName, firstNameAttr] = defineField('firstName')
const [lastName, lastNameAttr] = defineField('lastName')

onMounted(() => {
  resetNewUser()
})

function resetNewUser() {
  resetForm()
}

const onSubmit = handleSubmit(() => {
  store.dispatch.admin
    .createTeacher({
      email: email.value || '',
      firstName: firstName.value || '',
      lastName: lastName.value || '',
      username: username.value || '',
    } as CreateTeacherParameters)
    .then((ok: boolean) => {
      if (ok) {
        store.dispatch.dialog.show({ message: 'Please use impersonation and Profile to finalize setup', title: 'User created' })
      }
    })
})
</script>

<template>
  <v-container fluid>
    <v-form @reset.prevent="resetNewUser" @submit="onSubmit">
      <v-row>
        <v-col md="3">
          <v-text-field v-model="username" v-bind="usernameAttr" :error-messages="errors.username" label="Username" variant="underlined" />
        </v-col>
        <v-col md="3">
          <v-text-field v-model="email" v-bind="emailAttr" :error-messages="errors.email" label="Email" variant="underlined" />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="firstName"
            v-bind="firstNameAttr"
            :error-messages="errors.firstName"
            label="First name"
            variant="underlined"
          />
        </v-col>
        <v-col md="3">
          <v-text-field v-model="lastName" v-bind="lastNameAttr" :error-messages="errors.lastName" label="Last name" variant="underlined" />
        </v-col>
      </v-row>
      <v-row v-if="store.getters.admin.error">
        <v-col md="4">
          <h3 class="red--text">
            {{ store.getters.admin.error }}
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-btn block color="primary" :invalid="!meta.valid" type="submit"> Create </v-btn>
        </v-col>
        <v-col md="2">
          <v-btn block type="reset" @click="resetForm"> Reset </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
