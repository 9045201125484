<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRouter } from 'vue-router'

import AppBarAccountMenu from '@/components/AppBarAccountMenu.vue'
import GenderedLogo from '@/components/GenderedLogo.vue'
import { useUtil } from '@/composables/util'

const logger = useLogger()
const { isStaff, loginCheck } = useUtil(logger)

const state = reactive({
  tab: null as string | null,
  tabs: [
    { admin: false, label: 'Recordings', path: '/recordings' },
    { admin: false, label: 'Colors', path: '/colors' },
    { admin: false, label: 'Customize', path: '/customize' },
    { admin: false, label: 'Students', path: '/students' },
    { admin: false, label: 'Homework', path: '/practice' },
    { admin: true, label: 'Admin', path: '/admin' },
  ],
})

onMounted(() => {
  const router = useRouter()
  loginCheck(router)
})

// NB: this is a hack to make vue-router and v-tabs work
// without the @click property on v-tab, the focus does not follow the tabs
function tabClick(clickedTab: string) {
  state.tab = clickedTab
}
</script>

<template>
  <v-app-bar app color="grey-lighten-3">
    <gendered-logo />
    <v-divider class="ma-0 mx-4 pa-0" vertical></v-divider>
    <v-tabs v-model="state.tab" align-with-title>
      <template v-for="tab of state.tabs">
        <v-tab
          v-if="!tab.admin || isStaff()"
          :key="tab.path"
          exact
          exact-active-class="active"
          :to="{ path: tab.path }"
          @click="tabClick(tab.path)"
          >{{ tab.label }}</v-tab
        >
      </template>
    </v-tabs>
    <v-spacer />
    <app-bar-account-menu />
  </v-app-bar>
</template>
