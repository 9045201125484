// Translated by ChatGPT from https://github.com/Experience-Monks/analyser-frequency-average
import frequencyToIndex from '@/util/audio-frequency-to-index'

function analyserFrequencyAverage(
  div: number,
  analyser: AnalyserNode,
  frequencies: Float32Array | Uint8Array,
  minHz: number,
  maxHz: number,
): number {
  const { sampleRate } = analyser.context
  const binCount = analyser.frequencyBinCount
  let start = frequencyToIndex(minHz, sampleRate, binCount)
  const end = frequencyToIndex(maxHz, sampleRate, binCount)
  const count = end - start
  let sum = 0
  for (; start < end; start++) {
    sum += frequencies[start] / div
  }
  return count === 0 ? 0 : sum / count
}

export const floatData = analyserFrequencyAverage.bind(null, 1)
export default analyserFrequencyAverage.bind(null, 255)
