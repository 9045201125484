import { HaftarahMelodyType, PronunciationType, SentenceGroupMelodyType, TagType, TorahMelodyType } from 'types/types'

const VoiceStyles: Array<string> = ['Reading 📖', 'Singing 🎵', 'Text']
const RecordingVoiceStyles: Array<string> = ['Reading 📖', 'Singing 🎵']
const READ = 0
const SING = 1
const TEXT = 2

type VoiceStyleSet = {
  voice: string
  pronunciation: PronunciationType | null
  torahMelody: TorahMelodyType | null
  haftarahMelody: HaftarahMelodyType | null
  sentenceGroupMelody: SentenceGroupMelodyType | null
  tags: Array<TagType> | null
}

function inTextMode(voiceStyleSet: VoiceStyleSet): boolean {
  return voiceStyleSet.voice === VoiceStyles[TEXT]
}

function inReadMode(voiceStyleSet: VoiceStyleSet): boolean {
  return voiceStyleSet.voice === VoiceStyles[READ]
}

function inSingMode(voiceStyleSet: VoiceStyleSet): boolean {
  return voiceStyleSet.voice === VoiceStyles[SING]
}

const defaultVoiceStyleSet = (): VoiceStyleSet => {
  return {
    haftarahMelody: null,
    pronunciation: null,
    sentenceGroupMelody: null,
    tags: null,
    torahMelody: null,
    voice: VoiceStyles[SING],
  }
}

export { defaultVoiceStyleSet, inReadMode, inSingMode, inTextMode, READ, RecordingVoiceStyles, SING, TEXT, VoiceStyles, VoiceStyleSet }
