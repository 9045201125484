import _ from 'lodash'

import { AnnotatedSentence } from './AnnotatedSentence'

type TransformationType = {
  title: string
  subtitle: string
  name: string
  props: {
    class: string | null
  }
  transform(annotatedSentence: AnnotatedSentence): void
}

const VOWELS = 'v'
const VOWELS_CANTILLATIONS = 'vc'
const CONSONANTS = 'c'
const TORAH = 't'

function allTransformations(includeTorah: boolean): Array<TransformationType> {
  const transformations: Array<TransformationType> = [
    {
      name: VOWELS,
      props: {
        class: null,
      },
      subtitle: 'Vowels, no trope',
      title: 'לָ',
      transform: (focusAnnotatedSentence: AnnotatedSentence): void => {
        focusAnnotatedSentence.set({ cantillations: false, vowels: true })
      },
    },
    {
      name: VOWELS_CANTILLATIONS,
      props: {
        class: null,
      },
      subtitle: 'Vowels and trope',
      title: 'לָ֡',
      transform: (focusAnnotatedSentence: AnnotatedSentence): void => {
        focusAnnotatedSentence.set({ cantillations: true, vowels: true })
      },
    },
    {
      name: CONSONANTS,
      props: {
        class: null,
      },
      subtitle: 'No vowels, no trope',
      title: 'ל',
      transform: (focusAnnotatedSentence: AnnotatedSentence): void => {
        focusAnnotatedSentence.set({ cantillations: false, vowels: false })
      },
    },
  ]

  // only show Torah font for not-Haftarah
  if (includeTorah) {
    transformations.push({
      // the extra space is to ensure it's not interpreted as duplicate
      name: TORAH,

      props: {
        class: 'torah',
      },
      subtitle: 'Torah style',
      title: ' ל',
      transform: (focusAnnotatedSentence: AnnotatedSentence): void => {
        focusAnnotatedSentence.set({ cantillations: false, vowels: false })
      },
    })
  }
  return transformations
}

function transformationByName(name: string): TransformationType {
  const tIndex = _.findIndex(allTransformations(true), (transformation: TransformationType) => {
    return transformation.name === name
  })

  return allTransformations(true)[tIndex]
}

function defaultTransformation(): TransformationType {
  return transformationByName(VOWELS_CANTILLATIONS)
}

export {
  allTransformations,
  CONSONANTS,
  defaultTransformation,
  TORAH,
  transformationByName,
  TransformationType,
  VOWELS,
  VOWELS_CANTILLATIONS,
}
