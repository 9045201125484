<script setup lang="ts">
import '../assets/style/fonts.css'

import { Icon } from '@iconify/vue'
import _ from 'lodash'
import { onMounted, reactive, ref } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRouter } from 'vue-router'
import { useTheme } from 'vuetify'

import GenderedLogo from '@/components/GenderedLogo.vue'
import { useUtil } from '@/composables/util'

const logger = useLogger()
const { loginPublicUser } = useUtil(logger)

interface IndexState {
  username: string
  password: string
  loginState: boolean
  invalidFeedback: string
}

const router = useRouter()

interface Props {
  loginName?: string | null
}

const props = withDefaults(defineProps<Props>(), {
  loginName: null,
})

const state = reactive<IndexState>({
  invalidFeedback: '',
  loginState: true,
  password: '',
  username: '',
})

onMounted(() => {
  const vueTheme = useTheme()
  if (props.loginName) {
    state.username = props.loginName
  }
  // state.setToast()
  vueTheme.global.name.value = 'light' // XXX why?
})

import indexPhoto2 from '../assets/images/shutterstock_536408482.jpg'
import indexPhoto1 from '../assets/images/shutterstock_1716030475.jpg'

const indexPhoto1Url = ref(indexPhoto1)
const indexPhoto2Url = ref(indexPhoto2)
</script>

<template>
  <div>
    <v-sheet class="pt-4 pb-10" color="#f2f3f4" elevation="0">
      <v-row class="pt-4 pr-6" justify="end">
        <v-col v-if="true" md="3">
          <v-btn
            align="right"
            block
            class="white--text"
            color="#343AA3"
            @click="
              () => {
                loginPublicUser(router)
              }
            "
          >
            <span class="mr-3"><v-icon>mdi-voice</v-icon></span> Try Student Demo
          </v-btn>
        </v-col>
        <v-col md="2">
          <v-btn align="right" block class="white--text" color="#343AA3" href="/login">
            <span class="mr-3"><v-icon>mdi-account</v-icon></span> Login
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="pt-0" justify="center">
        <v-col align="center">
          <v-carousel cycle height="120px" hide-delimiters :show-arrows="false">
            <v-carousel-item>
              <gendered-logo gender="bar" height="120px" />
            </v-carousel-item>
            <v-carousel-item>
              <gendered-logo gender="bat" height="120px" />
            </v-carousel-item>
            <v-carousel-item>
              <gendered-logo gender="b" height="120px" />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row class="pt-16 blue--text" no-gutters>
        <v-col align="center" justify="center" md="12">
          <div class="bmt" style="font-weight: bold">BETA TESTER PROGRAM</div>
        </v-col>
      </v-row>
      <v-row class="pt-4 black--text" no-gutters>
        <v-col align="center" justify="center" md="12">
          <div class="made" style="font-size: 2.5em">Easy and fun laining for students and teachers</div>
        </v-col>
      </v-row>
      <v-row class="pt-6 black--text" justify="center" no-gutters>
        <v-col align="center" md="9">
          Bar/Bat Mitzvah Trainer is a browser-based study tool for students to prepare for their Bar/Bat Mitzvah with the help of a human
          teacher.
        </v-col>
      </v-row>
      <v-row class="pt-12 black--text" justify="center" no-gutters>
        <v-col align="center" md="6">
          <v-btn
            class="white--text"
            color="#343AA3"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfWqOqW4fcQ02RdjpYKqTuj7VibH8jTSGY7Kuy-0suIZ-oU0g/viewform?usp=sf_link"
            ><v-icon class="mr-2">mdi-human-greeting</v-icon>Sign up as beta tester</v-btn
          >
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet color="#e5e7e9" elevation="0">
      <v-row justify="center" no-gutters>
        <v-col md="6">
          <v-parallax dark :src="indexPhoto1Url" />
        </v-col>
        <v-col>
          <v-row align="center" class="pt-6 pb-4" justify="center" no-gutters>
            <v-row class="py-5" justify="center" no-gutters>
              <v-col align="center" md="8">
                <Icon icon="download-off" width="2em" />
                <h4>No installation</h4>
                Bar/Bat Mitzvah Trainer runs 100% in a browser and requires no add-ons or plugins.
              </v-col>
            </v-row>
            <v-col align="center" md="8">
              <Icon icon="account-voice" width="2em" />
              <h4>Adaptive voice recognition</h4>
              Bar/Bat Mitzvah Trainer can hear your voice and and follows along in the text with you.
            </v-col>
          </v-row>
          <v-row class="py-4" justify="center" no-gutters>
            <v-col align="center" md="8">
              <Icon icon="bar-graph" width="2em" />
              <h4>Advances with you</h4>
              Starts one word at a time, advances to longer phrases, and has you reading or singing whole sentences before long.
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet color="#f2f3f4" elevation="0">
      <v-row justify="center" no-gutters>
        <v-col>
          <v-row align="center" class="pt-8 pb-4" justify="center" no-gutters>
            <v-col align="center" md="8">
              <Icon icon="musical-notes" width="2em" />
              <h4>Natural pitch shifting</h4>
              Sophisticated pitch shifting allows students to naturally match their vocal range with their teacher's.
            </v-col>
          </v-row>
          <v-row class="py-5" justify="center" no-gutters>
            <v-col align="center" md="8">
              <Icon icon="people-fill" width="2em" />
              <h4>Practice call and response</h4>
              Bar/Bat Mitzvah Trainer supports studying for texts that consist of call-and-response prayers, such as שיר הכבוד.
            </v-col>
          </v-row>
          <v-row class="py-5" justify="center" no-gutters>
            <v-col align="center" md="8">
              <Icon icon="calendar-date" width="2em" />
              <h4>Flexible text and calendar</h4>
              Comes built-in with entiry Tenakh text support. Ashkenazi, Sephardic, and Triennial calendar support. Ability to manually add
              texts and perform calendar overrides.
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6">
          <v-parallax dark :src="indexPhoto2Url"></v-parallax>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet class="pb-12" color="#d6dbdf" elevation="0">
      <v-row class="pt-6 black--text" justify="center" no-gutters>
        <v-col align="center" justify="center" md="12">
          <div class="bmt" style="font-weight: bold">FROM WORDS TO TORAH</div>
        </v-col>
      </v-row>
      <v-row class="pt-6 black--text" justify="center" no-gutters>
        <v-col align="center" md="9">
          <h4>Read the words</h4>
          Students use their teacher's recordings and the accompanying text to practice reading word by word, phrase by phrase, and
          eventually full sentences.
        </v-col>
      </v-row>
      <v-row class="pt-6 black--text" justify="center" no-gutters>
        <v-col align="center" md="9">
          <h4>Sing the text</h4>
          Once students are familiar with the text, they practice singing, starting with shorter phrases, then progressing to full
          sentences.
        </v-col>
      </v-row>
      <v-row class="pt-6 black--text" justify="center" no-gutters>
        <v-col align="center" md="9">
          <h4>Torah practice</h4>
          Students gradually adapt the visual display of text to mimic the Torah, starting with nekkudot and trope until they are
          comfortable in tikkun style, without any diacritics and using a Torah font.
        </v-col>
      </v-row>
      <v-row class="pt-6 black--text" justify="center" no-gutters>
        <v-col align="center" md="9">
          <h4>...and much more</h4>
          Bar/Bat Mitzvah Trainer lets students practice b'rachot, songs, and other texts.
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet class="pb-6" color="#f2f3f4" elevation="0">
      <v-row class="pt-6 black--text" justify="center" no-gutters>
        <v-col align="center" justify="center" md="12">
          <div class="bmt" style="font-weight: bold">BETA TESTER PROGRAM</div>
        </v-col>
      </v-row>
      <v-row class="pt-4 white--black" justify="center" no-gutters>
        <v-col align="center">
          <div class="made" style="font-size: 2.5em">Start today with Bar/Bat Mitzvah Trainer</div>
        </v-col>
      </v-row>
      <v-row class="pt-12 black--text" justify="center" no-gutters>
        <v-col align="center" md="6">
          <v-btn
            class="white--text"
            color="#343AA3"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfWqOqW4fcQ02RdjpYKqTuj7VibH8jTSGY7Kuy-0suIZ-oU0g/viewform?usp=sf_link"
            ><v-icon class="mr-2">mdi-human-greeting</v-icon>Sign up as beta tester</v-btn
          >
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>
