<script setup lang="ts">
import { reactive } from 'vue'

import CustomAliyah from './CustomAliyah.vue'
import CustomHaftarah from './CustomHaftarah.vue'
import CustomResponsiveness from './CustomResponsiveness.vue'
import CustomText from './CustomText.vue'

const state = reactive({
  tab: 0,
})
</script>

<template>
  <v-tabs v-model="state.tab">
    <v-tab>Aliyot</v-tab>
    <v-tab>Haftarot</v-tab>
    <v-tab>B'rachot, Songs, Texts</v-tab>
    <v-tab>Call &amp; response</v-tab>
  </v-tabs>
  <v-window v-model="state.tab">
    <v-window-item key="0" value="1">
      <custom-aliyah />
    </v-window-item>
    <v-window-item key="1" value="2">
      <custom-haftarah />
    </v-window-item>
    <v-window-item key="2" value="0">
      <custom-text />
    </v-window-item>
    <v-window-item key="3" value="3">
      <custom-responsiveness />
    </v-window-item>
  </v-window>
</template>
