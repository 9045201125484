<script setup lang="ts">
import { onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useTheme } from 'vuetify'

import b_dark from '@/assets/images/logo-b-dark.svg'
import b_light from '@/assets/images/logo-b-light.svg'
import bar_dark from '@/assets/images/logo-bar-dark.svg'
import bar_light from '@/assets/images/logo-bar-light.svg'
import bat_dark from '@/assets/images/logo-bat-dark.svg'
import bat_light from '@/assets/images/logo-bat-light.svg'
import { useUtil } from '@/composables/util'

const logger = useLogger()
const { userGender } = useUtil(logger)

type LogoKeys = 'b' | 'bar' | 'bat'
type ThemeKeys = 'dark' | 'light'

const logos: Record<LogoKeys, Record<ThemeKeys, string>> = {
  b: {
    dark: b_dark,
    light: b_light,
  },
  bar: {
    dark: bar_dark,
    light: bar_light,
  },
  bat: {
    dark: bat_dark,
    light: bat_light,
  },
}

interface Props {
  gender?: string
  height?: string
}

const props = withDefaults(defineProps<Props>(), {
  gender: '',
  height: '50px',
})

const state = reactive({
  actualHeight: props.height,
  logoUrl: '',
})

onMounted(() => {
  const vueTheme = useTheme()

  watch(
    () => [props.gender, vueTheme.global.name.value],
    (newVal, oldVal) => {
      if (newVal === oldVal) {
        return
      }
      const gen = (props.gender || userGender()) as LogoKeys
      const theme = vueTheme.global.name.value as ThemeKeys
      logger.debug(`GenderedLogo, gen =`)
      logger.debug(gen)

      if (logos[gen] && logos[gen][theme]) {
        state.logoUrl = logos[gen][theme]
      }
    },
    { deep: true, immediate: true },
  )
})
</script>

<template>
  <img class="ml-4" :src="state.logoUrl" :style="{ cursor: 'pointer', height: state.actualHeight }" @click="$router.push('/')" />
</template>
