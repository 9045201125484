import validator from 'validator'
import { z as zod } from 'zod'

const useZodValidation = () => {
  const nonEmptyString = zod
    .string()
    .trim()
    .refine((value: string) => validator.isEmpty(value) === false, {
      message: 'This field is required',
    })

  return {
    nonEmptyString,
  }
}

export { useZodValidation }
