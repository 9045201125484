<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import _ from 'lodash'
import { AliyahType, ParashahType } from 'types/types'
import { computed, onMounted, reactive, watch, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import { formattedAliyahForTeacher, formattedAliyahForTeacherWithSection } from '@/composables/format'
import AliyotQuery from '@/queries/AliyotQuery.graphql'

interface Props {
  clearable?: boolean
  disabled?: boolean
  only?: Array<AliyahType> | null
  parashah: ParashahType
  preventAutoSingleSelect?: boolean
  remove?: Array<AliyahType>
  resetSelectionOnMultiple?: boolean
}
const xprops = withDefaults(defineProps<Props>(), {
  clearable: true,
  disabled: false,
  only: null,
  preventAutoSingleSelect: false,
  remove: (): Array<AliyahType> => [],
  resetSelectionOnMultiple: false,
})

const state = reactive({
  allAliyot: [] as Array<AliyahType>,
  selectedAliyah: null as AliyahType | null,
})

const logger = useLogger()
const emit = defineEmits(['update:aliyah'])

onMounted(() => {
  logger.debug('AliyahSelect, onMounted, xprops.parashah.reading.id = ')
  logger.debug(xprops.parashah?.reading.id)
  const { result: queryResult } = useQuery(AliyotQuery, () => ({
    mine: false,
    parashah: xprops.parashah?.reading.id ?? -1,
  }))

  watch(
    queryResult,
    (newResult) => {
      logger.debug('AliyahSelect, watch queryResult, newResult =')
      logger.debug(newResult)
      state.allAliyot = newResult?.aliyot
    },
    { immediate: true },
  )

  watch(
    () => state.selectedAliyah,
    (newVal) => {
      logger.debug('AliyahSelect; state.selectedAliyah is now newVal =')
      logger.debug(newVal)
    },
  )

  watch(
    () => state.selectedAliyah,
    (newVal) => {
      logger.debug('AliyahSelect; state.selectedAliyah is now newVal =')
      logger.debug(newVal)
    },
    { immediate: true },
  )

  watchEffect(() => {
    logger.debug('AliyahSelect, watchEffect called, filteredAliyot.value = ')
    logger.debug(filteredAliyot.value)
    if (filteredAliyot.value.length === 1) {
      if (!xprops.preventAutoSingleSelect) {
        state.selectedAliyah = filteredAliyot.value[0]
      }
    } else if (xprops.resetSelectionOnMultiple) {
      state.selectedAliyah = null
    }
  })

  watchEffect(() => {
    emit('update:aliyah', state.selectedAliyah)
  })
})

const filteredAliyot = computed<Array<AliyahType>>(() => {
  logger.debug('AliyahSelect, filteredAliyot called')
  // use a shadow copy of this.aliyot
  const aliyot = xprops.only ?? state.allAliyot

  // remove all elements from aliyot that are also in this.remove
  return _.sortBy(
    _.filter(aliyot, (a: AliyahType) => {
      return !_.find(xprops.remove, (b: AliyahType) => {
        return a.rank === b.rank
      })
    }),
    ['rank', 'year', 'variant'],
  )
})
</script>

<template>
  <v-select
    v-model="state.selectedAliyah"
    :clearable="xprops.clearable"
    :disabled="xprops.disabled"
    :items="filteredAliyot"
    label="Aliyah"
    return-object
    variant="underlined"
  >
    <template #selection="{ item }">
      <v-list-item :title="formattedAliyahForTeacher(item.raw)" />
    </template>

    <template #item="{ item, props }">
      <v-list-item v-bind="props" :title="formattedAliyahForTeacherWithSection(item.raw)" />
    </template>
  </v-select>
</template>
