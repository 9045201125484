<script setup lang="ts">
import { onMounted, reactive } from 'vue'

import { defaultRecorderServiceOptions, RecorderService } from '@/util/RecorderService'
import { RecorderServiceOptions } from '@/util/Recording'

interface Props {
  show?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  show: false,
})

interface RecorderWindowState {
  show: boolean
  recorderServiceOptions: RecorderServiceOptions | null
  recorderService: RecorderService | null
}

const state = reactive<RecorderWindowState>({
  recorderService: null,
  recorderServiceOptions: null,
  show: props.show,
})

onMounted(() => {
  state.recorderServiceOptions = {
    ...defaultRecorderServiceOptions,
  }

  state.recorderService = new RecorderService({
    ...state.recorderServiceOptions,
  })
})
</script>

<template>
  <div v-if="state.show" class="float-end">
    <v-card class="rounded-lg">
      <v-card-title class="text-center">Recorder</v-card-title>
      <v-card-text class="text-center">
        <v-icon v-if="state.recorderService && state.recorderService.isRecording()" color="red" size="xxx-large">mdi-microphone </v-icon>
        <v-icon v-else color="red" size="xxx-large" @click="state.recorderService!.startRecording()">mdi-record </v-icon>
        <v-icon color="black" size="xxx-large" @click="state.recorderService!.stopRecording()">mdi-stop </v-icon>
      </v-card-text>
    </v-card>
  </div>
</template>
