<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import _ from 'lodash'
import { AliyahType, ParashahType } from 'types/types'
import { useForm } from 'vee-validate'
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import { watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import * as yup from 'yup'

import ParashahSelect from '@/components/ParashahSelect.vue'
import VerseSelect from '@/components/VerseSelect.vue'
import { formattedParashah, formattedRank } from '@/composables/format'
import { useUtil } from '@/composables/util'
import store from '@/store'
import { ALIYAH_RANK_MAP, CreateAliyahParameters } from '@/util/Aliyah'
import { formatParashah } from '@/util/Parashah'

const logger = useLogger()
const { isStaff, selectableUsers } = useUtil(logger)

interface CustomAliyahData {
  aliyahId: string | null
  includeRootAliyot: boolean
  parashah: ParashahType | null
  tableHeaders: Array<object>
  formVisible: boolean
  ALIYAH_RANK_MAP: typeof ALIYAH_RANK_MAP
}

const state = reactive<CustomAliyahData>({
  ALIYAH_RANK_MAP,
  aliyahId: null,
  formVisible: false,
  includeRootAliyot: false,
  parashah: null,
  tableHeaders: [],
})

const { defineField, errors, handleSubmit, meta, resetForm } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      comment: yup.string().notRequired(),
      owner: yup.string().required(),
      rank: yup.string().required(),
      section: yup.string().required(),
      variant: yup.string().required(),
      year: yup
        .number()
        .required()
        .default(() => new Date().getFullYear()),
    }),
  ),
})

const [owner, ownerAttrs] = defineField('owner')
const [year, yearAttrs] = defineField('year')
const [rank, rankAttrs] = defineField('rank')
const [variant, variantAttrs] = defineField('variant')
const [section, sectionAttrs] = defineField('section')
const [comment, commentAttrs] = defineField('comment')

const aliyahVerseSelect = ref<InstanceType<typeof VerseSelect> | null>(null)

onMounted(() => {
  store.dispatch.user.fetchUser()
  state.tableHeaders = [
    { key: 'year', title: 'Year' },
    { formatter: formatParashah, key: 'parashah', title: 'Parashah' },
  ]
  if (isStaff()) {
    state.tableHeaders.push({ key: 'user.username', title: 'User' })
  }
  state.tableHeaders.push(
    { key: 'rank', title: 'Rank' },
    { key: 'section', sortable: false, title: 'Section' },
    { key: 'variant', sortable: false, title: 'Variant (full/triennial)' },
    { key: 'comment', sortable: false, title: 'Comment' },
    { key: 'action', sortable: false, title: ' ' },
  )

  watchEffect(() => {
    store.dispatch.teacher.fetchAliyot({ mine: !state.includeRootAliyot && isStaff(), parashah: null })
  })
})

const sortedAliyot = computed<Array<AliyahType>>((): Array<AliyahType> => {
  logger.debug('sortedAliyot, store.getters.teacher.aliyot =')
  logger.debug(store.getters.teacher.aliyot)
  const rootAliyot = !state.includeRootAliyot
    ? []
    : _.sortBy(
        _.filter(store.getters.teacher.aliyot, (aliyah: AliyahType) => {
          return aliyah.user.username === 'root'
        }),
        ['parashah.order', 'rank', 'year'],
      )
  logger.debug('sortedAliyot, rootAliyot =')
  logger.debug(rootAliyot)

  const userAliyot = _.sortBy(
    _.filter(store.getters.teacher.aliyot, (aliyah: AliyahType) => {
      return aliyah.user.username === store.getters.user.user!.username
    }),
    ['parashah.order', 'rank', 'year'],
  )
  logger.debug('sortedAliyot, userAliyot =')
  logger.debug(userAliyot)

  return userAliyot.concat(rootAliyot)
})

function addAliyah() {
  resetNewAliyah()
  nextTick(() => {
    if (aliyahVerseSelect.value) {
      aliyahVerseSelect.value.clear()
    }
  })
  state.aliyahId = null
  state.parashah = null
  state.formVisible = true
}

function resetNewAliyah() {
  state.aliyahId = null
  resetForm()

  if (!isStaff()) {
    if (!store.getters.user.user) {
      logger.debug('unexpected null store.getters.users.user')
    } else {
      owner.value = store.getters.user.user.username
    }
  }
}

const handleCreateAliyah = handleSubmit(() => {
  if (!state.parashah) {
    logger.debug('unexpected null parashah in handleCreateAliyah')
    return
  }

  store.dispatch.teacher.createAliyah({
    comment: comment.value ?? '',
    id: parseInt(state.aliyahId ?? ''),
    parashah: state.parashah,
    rank: rank.value ? parseInt(rank.value) : 0,
    section: section.value ?? '',
    username: owner.value ?? '',
    variant: variant.value ?? '',
    year: year.value ?? new Date().getFullYear(),
  } as CreateAliyahParameters)

  if (_.isEmpty(store.getters.teacher.error)) {
    state.formVisible = false
    resetNewAliyah()
  }
})

async function confirmDeleteAliyah(aliyah: AliyahType) {
  store.dispatch.dialog.show({
    message: 'Are you sure you want to delete this aliyah?',
    onAccept: () => store.dispatch.teacher.deleteAliyah({ aliyah }),
    title: 'Confirm',
  })
}

function editAliyah(arg1: any, arg2?: any) {
  let aliyah = null as AliyahType | null
  logger.debug(arg1)
  logger.debug(arg2)

  if (arg1?.rank) {
    aliyah = arg1
  }
  if (arg1?.item && arg1?.item.rank) {
    aliyah = arg1.item
  }

  if (arg2?.rank) {
    aliyah = arg2
  }
  if (arg2?.item && arg2.item.rank) {
    aliyah = arg2.item
  }

  if (!aliyah) {
    logger.debug('unexpected null aliyah in editHaftarah')
    return
  }

  state.formVisible = true

  nextTick(() => {
    if (aliyahVerseSelect.value && aliyah!.section) {
      aliyahVerseSelect.value.update(aliyah!.section)
    }
    state.parashah = aliyah!.parashah
    state.aliyahId = aliyah!.id
    owner.value = aliyah!.user.username
    year.value = aliyah!.year
    rank.value = aliyah!.rank.toString()
    variant.value = aliyah!.variant
    section.value = aliyah!.section
    comment.value = aliyah!.comment ?? null
  })
}
</script>

<template>
  <v-container fluid>
    <!-- Add Aliyah -->
    <v-btn color="primary" @click="addAliyah"> Add Aliyah </v-btn>

    <v-switch v-if="isStaff()" v-model="state.includeRootAliyot" color="primary" label="Include system aliyot (slow!)" />
    <v-data-table
      :headers="state.tableHeaders"
      :items="sortedAliyot"
      no-data-text="[zero aliyot match your search; create a custom aliyah?]"
      :sort-by="[{ key: 'reading.description', order: 'asc' }]"
      @click:row="editAliyah"
    >
      <template #item.parashah="{ item }">
        <span>{{ formattedParashah(item.parashah) }}</span>
      </template>
      <template #item.action="{ item }">
        <v-btn icon variant="plain" @click.capture.stop="() => editAliyah(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon variant="plain" @click.capture.stop="confirmDeleteAliyah(item)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- edit Aliyah -->
    <v-dialog id="newAliyah" v-model="state.formVisible" class="mb-4 pb-4" no-click-animation @keydown.esc="state.formVisible = false">
      <v-form @submit.prevent="handleCreateAliyah">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col> {{ state.aliyahId ? 'Change' : 'Add' }} Aliyah </v-col>
              <v-col class="text-right">
                <v-btn icon size="x-small" @click="state.formVisible = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row v-if="selectableUsers.length > 1" class="mt-3 pt-3">
              <v-col md="2">
                <v-autocomplete
                  v-model="owner"
                  v-bind="ownerAttrs"
                  :error-messages="errors.owner"
                  :items="selectableUsers"
                  label="Owner"
                  placeholder="Owner"
                  variant="underlined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="1">
                <v-text-field
                  v-model="year"
                  v-bind="yearAttrs"
                  :error-messages="errors.year"
                  label="Year"
                  placeholder="Year"
                  style="min-width: 5.5em"
                  type="number"
                  variant="underlined"
                />
              </v-col>
              <v-col md="3">
                <parashah-select
                  v-model:parashah="state.parashah"
                  :clearable="false"
                  :external-set="true"
                  :reset-selection-on-multiple="false"
                />
              </v-col>
              <v-col md="2">
                <v-select
                  v-model="rank"
                  v-bind="rankAttrs"
                  :error-messages="errors.rank"
                  :item-title="(rank) => formattedRank(parseInt(rank))"
                  :items="Object.keys(ALIYAH_RANK_MAP)"
                  label="Rank"
                  variant="underlined"
                />
              </v-col>

              <v-col md="2">
                <v-select
                  v-model="variant"
                  v-bind="variantAttrs"
                  :error-messages="errors.variant"
                  :items="['full', 'triennial']"
                  label="Variant"
                  return-object
                  variant="underlined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <verse-select ref="aliyahVerseSelect" v-model:section="section" :range="true" :selected-section="section" />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <v-text-field v-model="comment" v-bind="commentAttrs" label="Comment" placeholder="" type="text" variant="underlined" />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="1">
                <v-btn block color="primary" :disabled="!meta.valid" type="submit" width="140"
                  >{{ state.aliyahId ? 'Update' : 'Create' }}
                </v-btn>
              </v-col>
              <v-col md="1">
                <v-btn block @click="state.formVisible = false">Cancel</v-btn>
              </v-col>
              <v-col md="1">
                <v-btn block color="red-lighten-3" width="140" @click="resetNewAliyah">Reset</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.trashable:hover {
  cursor: pointer;
}

.editable:hover {
  cursor: pointer;
}

.error {
  color: red;
}
</style>
