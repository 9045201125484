// Translated by ChatGPT from https://github.com/Experience-Monks/audio-frequency-to-index
import clamp from 'clamp'

function frequencyToIndex(frequency: number, sampleRate: number, frequencyBinCount: number): number {
  const nyquist = sampleRate / 2
  const index = Math.round((frequency / nyquist) * frequencyBinCount)
  return clamp(index, 0, frequencyBinCount)
}

export default frequencyToIndex
