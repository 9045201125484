<script setup lang="ts">
import '@/assets/style/fonts.css'

import { toTypedSchema } from '@vee-validate/yup'
import { useQuery } from '@vue/apollo-composable'
import _ from 'lodash'
import {
  HaftarahMelodyType,
  PronunciationType,
  SentenceGroupMelodyType,
  SentenceRecordingType,
  SentenceType,
  TagType,
  TorahMelodyType,
  UserGrapheneType,
  UserHaftarahMelodyType,
  UserPronunciationType,
  UserSentenceGroupMelodyType,
  UserTorahMelodyType,
} from 'types/types'
import { useForm } from 'vee-validate'
import { computed, onMounted, reactive, watch, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import * as yup from 'yup'

import AnnotatedText from '@/components/AnnotatedText.vue'
import { useFont } from '@/composables/font'
import { UpdateUserParameters, useUtil } from '@/composables/util'
import store from '@/store'

const logger = useLogger()
const { isReadOnly, isTeacher } = useUtil(logger)

import AliyahSentencesQuery from '../queries/AliyahSentencesQuery.graphql'
import MySentenceRecordingsQuery from '../queries/MySentenceRecordingsQuery.graphql'

const BERESHIT_ALIYAH = 2249
const SAMPLE_SENTENCES = 5
const { MAX_FONT_SIZE, MAX_LINE_HEIGHT, MIN_FONT_SIZE, MIN_LINE_HEIGHT } = useFont()

interface ProfileData {
  mySentenceRecordings: Array<SentenceRecordingType> | null
  sampleSentences: Array<SentenceType> | null
  userCopy: UserGrapheneType | null
}

const state = reactive<ProfileData>({
  mySentenceRecordings: null, // managed by apollo
  sampleSentences: null,
  userCopy: null,
})

const { result } = useQuery(AliyahSentencesQuery, () => ({
  aliyah: BERESHIT_ALIYAH,
}))

watch(
  result,
  (newResult) => {
    logger.debug('AliyahSentencesQuery, result =')
    logger.debug(result)
    state.sampleSentences = newResult?.aliyahSentences.slice(0, SAMPLE_SENTENCES)
  },
  { immediate: true },
)

const { result: result2 } = useQuery(MySentenceRecordingsQuery, () => ({
  sentences: [],
}))

watch(
  result2,
  (newResult) => {
    state.mySentenceRecordings = newResult?.mySentenceRecordings
  },
  { immediate: true },
)

const { defineField, errors, handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      email: yup.string().email().required(),
      firstName: yup.string().trim().required(),
      font: yup.string().trim().required(),
      fontSize: yup.number().required(),
      gender: yup.string().trim().required(),
      lastName: yup.string().trim().required(),
      lineHeight: yup.number().required(),
    }),
  ),
})
const [email, emailAttr] = defineField('email')
const [firstName, firstNameAttr] = defineField('firstName')
const [font, fontAttr] = defineField('font')
const [fontSize, fontSizeAttr] = defineField('fontSize')
const [lineHeight, lineHeightAttr] = defineField('lineHeight')
const [lastName, lastNameAttr] = defineField('lastName')
const [gender, genderAttr] = defineField('gender')

const fontOptions = computed(() => {
  return [
    { title: 'default', value: '' },
    { title: 'Taamey Ashkenaz', value: 'ashkenaz' },
    { title: 'Taamey David', value: 'david' },
    { title: 'Taamey Frank', value: 'frank' },
    { title: 'Cardo', value: 'cardo' },
    { title: 'Noto Serif', value: 'noto' },
    { title: 'Shlomo', value: 'shlomo' },
  ]
})

const genderOptions = computed(() => {
  return [
    { title: 'Bar Mitzvah', value: 'bar' },
    { title: 'Bat Mitzvah', value: 'bat' },
    { title: 'B-Mitzvah', value: 'b' },
  ]
})

const userTorahMelodies = computed<Array<TorahMelodyType>>({
  get() {
    return _.map(state.userCopy!.torahMelodies, (userTorahMelody: UserTorahMelodyType) => {
      return userTorahMelody.melody
    })
  },

  set(melodies: Array<TorahMelodyType>) {
    state.userCopy!.torahMelodies = _.map(melodies, (torahMelody: TorahMelodyType) => {
      return {
        melody: torahMelody,
        user: state.userCopy,
      } as UserTorahMelodyType
    })
  },
})

const userHaftarahMelodies = computed<Array<HaftarahMelodyType>>({
  get() {
    return _.map(state.userCopy!.haftarahMelodies, (userHaftarahMelody: UserHaftarahMelodyType) => {
      return userHaftarahMelody.melody
    })
  },

  set(melodies: Array<HaftarahMelodyType>) {
    state.userCopy!.haftarahMelodies = _.map(melodies, (haftarahMelody: HaftarahMelodyType) => {
      return {
        melody: haftarahMelody,
        user: state.userCopy,
      } as UserHaftarahMelodyType
    })
  },
})

const userSentenceGroupMelodies = computed<Array<SentenceGroupMelodyType>>({
  get() {
    return _.map(state.userCopy!.sentenceGroupMelodies, (userSentenceGroupMelody: UserSentenceGroupMelodyType) => {
      return userSentenceGroupMelody.melody
    })
  },

  set(melodies: Array<SentenceGroupMelodyType>) {
    state.userCopy!.sentenceGroupMelodies = _.map(melodies, (sentenceGroupMelody: SentenceGroupMelodyType) => {
      return {
        melody: sentenceGroupMelody,
        user: state.userCopy,
      } as UserSentenceGroupMelodyType
    })
  },
})

const userPronunciations = computed<Array<PronunciationType>>({
  get() {
    return _.map(state.userCopy!.pronunciations, (userPronunciation: UserPronunciationType) => {
      return userPronunciation.pronunciation
    })
  },

  set(pronunciations: Array<PronunciationType>) {
    state.userCopy!.pronunciations = _.map(pronunciations, (pronunciation: PronunciationType) => {
      return {
        pronunciation,
        user: state.userCopy,
      } as UserPronunciationType
    })
  },
})

const userTags = computed<Array<string>>({
  get() {
    return _.map(
      _.filter(state.userCopy!.tags, (tag: TagType) => {
        return !tag.hidden as boolean
      }),
      (userTag: TagType) => {
        return userTag.name
      },
    )
  },

  set(tags: Array<string>) {
    state.userCopy!.tags = _.map(tags, (tag: string) => {
      return {
        name: tag,
        user: state.userCopy,
      } as TagType
    })
  },
})

onMounted(async () => {
  await store.dispatch.user.fetchUser()
  await store.dispatch.style.fetchStyles()

  watch(
    () => store.getters.user.error,
    () => {
      if (store.getters.user.error) {
        store.dispatch.snackbar.add({ message: store.getters.user.error, state: 'error', visible: true })
      }
    },
  )

  watchEffect(() => {
    state.userCopy = _.cloneDeep(store.getters.user.user)
    email.value = state.userCopy!.email
    firstName.value = state.userCopy!.firstName
    lastName.value = state.userCopy!.lastName
    font.value = state.userCopy!.properties?.font
    fontSize.value = state.userCopy!.properties?.fontSize
    lineHeight.value = state.userCopy!.properties?.lineHeight
    gender.value = state.userCopy?.properties?.gender
  })
})

const onSubmit = handleSubmit(() => {
  store.dispatch.user.updateUser({
    haftarahMelodyIDs: _.map(userHaftarahMelodies.value, (userHaftarahMelody) => parseInt(userHaftarahMelody.id)),
    pronunciationIDs: _.map(userPronunciations.value, (userPronunciation) => parseInt(userPronunciation.id)),
    sentenceGroupMelodyIDs: _.map(userSentenceGroupMelodies.value, (userSentenceGroupMelody) => parseInt(userSentenceGroupMelody.id)),
    tags: userTags.value,
    torahMelodyIDs: _.map(userTorahMelodies.value, (userTorahMelody) => parseInt(userTorahMelody.id)),
    user: { ...state.userCopy },
  } as UpdateUserParameters)
})
</script>

<template>
  <div v-if="store.getters.user.user && state.userCopy">
    <v-form @submit="onSubmit">
      <v-img v-if="false" class="align-start" height="400px" :src="require('../assets/images/profile-card-header.jpg')"></v-img>
      <v-row>
        <v-col md="3">
          <v-text-field
            v-model="firstName"
            v-bind="firstNameAttr"
            :error-messages="errors.firstName"
            label="First name"
            variant="underlined"
          />
        </v-col>
        <v-col md="3">
          <v-text-field v-model="lastName" v-bind="lastNameAttr" :error-messages="errors.lastName" label="Last name" variant="underlined" />
        </v-col>
      </v-row>
      <v-row align-h="start">
        <v-col md="3">
          <v-text-field v-model="email" v-bind="emailAttr" :error-messages="errors.email" label="Email" variant="underlined" />
        </v-col>
        <v-col md="3">
          <v-btn
            class="mt-3"
            color="primary"
            @click="
              () => {
                $router.push({ name: 'change-password' })
              }
            "
            >Change password
          </v-btn>
        </v-col>
      </v-row>

      <template v-if="isTeacher()">
        <v-row>
          <v-col md="4">
            <v-select
              v-model="userTorahMelodies"
              chips
              deletable-chips
              item-title="description"
              :items="store.getters.style.torahMelodies"
              label="Melodic style (Torah)"
              multiple
              return-object
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-select
              v-model="userHaftarahMelodies"
              chips
              deletable-chips
              item-title="description"
              :items="store.getters.style.haftarahMelodies"
              label="Melodic styles (Haftarah)"
              multiple
              return-object
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-select
              v-model="userSentenceGroupMelodies"
              chips
              deletable-chips
              item-title="description"
              :items="store.getters.style.sentenceGroupMelodies"
              label="Other text melodic styles"
              multiple
              return-object
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-select
              v-model="userPronunciations"
              chips
              :clearable="false"
              deletable-chips
              item-title="description"
              :items="store.getters.style.pronunciations"
              label="Pronunciation"
              multiple
              return-object
              variant="underlined"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-combobox
              v-model="userTags"
              chips
              deletable-chips
              :items="userTags"
              label="Recording styles"
              multiple
              persistent-hint
              variant="underlined"
            >
              <template #no-data>
                <v-list-item>
                  <v-list-item-title>Enter a style and press <kbd>enter</kbd> to add</v-list-item-title>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </template>

      <v-row v-if="!store.getters.user.impersonator" align-h="start">
        <v-col md="3">
          <v-select
            v-if="state.userCopy"
            v-model="gender"
            v-bind="genderAttr"
            :error-messages="errors.gender"
            :items="genderOptions"
            label="Mitzvah flavor"
            variant="underlined"
          />
        </v-col>
      </v-row>

      <v-row v-if="state.userCopy" align-h="start">
        <v-col md="6">
          <v-select v-model="font" v-bind="fontAttr" :error-messages="errors.font" :items="fontOptions" label="Font" variant="underlined">
            <template #selection="{ item }">
              <v-container>
                <v-row dense>
                  <v-col justify="end">
                    {{ item.title }}
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <span :class="item.value" style="font-weight: bold; font-size: 150%">
                      בְּרֵאשִׁ֖ית בָּרָ֣א אֱלֹהִ֑ים אֵ֥ת הַשָּׁמַ֖יִם וְאֵ֥ת הָאָֽרֶץ׃
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template #item="{ item, props }">
              <v-list-item v-bind="props">
                <v-container>
                  <v-row dense>
                    <v-col justify="end">
                      {{ item.title }}
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <span :class="item.value" style="font-weight: bold; font-size: 150%">
                        בְּרֵאשִׁ֖ית בָּרָ֣א אֱלֹהִ֑ים אֵ֥ת הַשָּׁמַ֖יִם וְאֵ֥ת הָאָֽרֶץ׃
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="3">
          <v-slider
            v-model="fontSize"
            v-bind="fontSizeAttr"
            color="primary"
            :error-messages="errors.fontSize"
            label="Font size"
            :max="MAX_FONT_SIZE"
            :min="MIN_FONT_SIZE"
            :step="10"
            thumb-label
          />
        </v-col>
        <v-col md="3">
          <v-slider
            v-model="lineHeight"
            v-bind="lineHeightAttr"
            color="primary"
            :error-messages="errors.lineHeight"
            label="Line height"
            :max="MAX_LINE_HEIGHT"
            :min="MIN_LINE_HEIGHT"
            :step="0.05"
            thumb-label
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" text-align="center">
          <annotated-text
            v-if="state.userCopy && state.sampleSentences"
            :default-font="font"
            :font-size="fontSize"
            :line-height="lineHeight"
            name="sampleSentences"
            :sentences="state.sampleSentences as Array<SentenceType>"
            :show-sentence-numbers="true"
          />
        </v-col>
      </v-row>
      <v-btn class="px-8" color="primary" :disabled="!meta.valid || isReadOnly()" :loading="store.getters.user.updating" type="submit">{{
        isReadOnly() ? 'Update disabled: read-only account' : 'Update'
      }}</v-btn>
    </v-form>
  </div>
</template>
