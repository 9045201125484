import _ from 'lodash'
import { AnnotationType } from 'types/types'
import xregexp from 'xregexp'

import { AnnotatedSentence } from './AnnotatedSentence'
import { AnnotatedWord } from './AnnotatedWord'
import { WordSplitCharRegEx } from './CharacterClasses'

class AnnotatedSegment {
  private xsentence: AnnotatedSentence

  private text: string

  private annotations: Array<AnnotationType | null>

  private xindex: number

  private xoffsetInSentence: number

  private words: Array<AnnotatedWord>

  private wordBoundaries: Array<string | null>

  constructor(
    sentence: AnnotatedSentence,
    index: number,
    offsetInSentence: number,
    text: string,
    annotations: Array<AnnotationType | null>,
  ) {
    this.xsentence = sentence
    this.xindex = index
    this.xoffsetInSentence = offsetInSentence
    this.text = text
    this.annotations = [...annotations]

    this.words = new Array<AnnotatedWord>()
    this.wordBoundaries = new Array<string | null>()
    this.generate()
  }

  sentence(): AnnotatedSentence {
    return this.xsentence
  }

  offsetInSentence(): number {
    return this.xoffsetInSentence
  }

  segment(): string {
    return this.text
  }

  nWords(): number {
    return this.words.length
  }

  nChars(): number {
    let n = 0
    _.forEach(this.words, (annotatedWord: AnnotatedWord) => {
      n += annotatedWord.nChars()
    })
    return n
  }

  index(): number {
    return this.xindex
  }

  generate(): void {
    this.words.splice(0, this.words.length)
    this.wordBoundaries.splice(0, this.wordBoundaries.length)
    // this.$log.info(`AnnotatedSentence.generate, text, length = ${text.length}:`);
    // this.$log.info(text);
    // this.$log.info(`AnnotatedSentence.generate, annotations (${annotations.length}):`);
    // this.$log.info(annotations);

    let chars = ''
    let boundaryChars = ''
    let wordStartIndex = this.xoffsetInSentence
    const characterAnnotations = new Array<AnnotationType | null>()
    for (let i = 0; i < this.text.length; i += 1) {
      if (xregexp.match(this.text[i], WordSplitCharRegEx)) {
        boundaryChars += this.text[i]
      } else {
        if (boundaryChars) {
          const annotationsForThisWord = [...characterAnnotations]
          const annotatedWord = new AnnotatedWord(this, this.words.length, wordStartIndex, chars, annotationsForThisWord)
          // this.$log.info(`AnnotatedSentence.generate, created word ${chars} with annotations:`);
          // this.$log.info(annotationsForThisWord);
          this.words.push(annotatedWord)
          this.wordBoundaries.push(boundaryChars)
          chars = ''
          boundaryChars = ''
          wordStartIndex = i
          characterAnnotations.splice(0, characterAnnotations.length)
        }
        chars += this.text[i]
        characterAnnotations.push(this.annotations[i])
      }
    }

    // last word
    this.words.push(new AnnotatedWord(this, this.words.length, wordStartIndex, chars, characterAnnotations))
    this.wordBoundaries.push(null)
  }

  forEach(callback: (word: AnnotatedWord, wordBoundary: string | null, index?: number) => void): void {
    _.forEach(this.words, (annotatedWord, wordIndex) => {
      callback(annotatedWord, this.wordBoundaries[wordIndex], wordIndex)
    })
  }
}

export { AnnotatedSegment }
