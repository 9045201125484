const absentStyle = 'absentStyle'
const waitingStyle = 'waitingStyle'
// after onVoiceStop has triggered
const userSpeakingStyle = 'userSpeakingStyle'
const playBackStyle = 'playBackStyle'
const regularStyle = '' // use `regularStyle = 'regularStyle' to debug
const badWordsStyle = 'badWordsStyle'
const wordBoundaryStyle = 'wordBoundaryStyle'
const segmentBoundaryStyle = 'segmentBoundaryStyle'
const SEGMENT_STYLES = ['segmentStyle0', 'segmentStyle1']

export {
  absentStyle,
  badWordsStyle,
  playBackStyle,
  regularStyle,
  SEGMENT_STYLES,
  segmentBoundaryStyle,
  userSpeakingStyle,
  waitingStyle,
  wordBoundaryStyle,
}
