import xregexp from 'xregexp'

const WordSplitCharRegEx = xregexp('^[\\s\u05be\u05bf,]$')

function isVowel(c: string): boolean {
  return !!c.match(/[\u05B0-\u05BD]/) || !!c.match(/[\u05C0-\u05C5]/) || !!c.match(/[\u05C7]/)
}

function isCantillation(c: string): boolean {
  return !!c.match(/[\u0591-\u05AF]/) || !!c.match(/\u05C0/)
}

function isCharacter(c: string): boolean {
  return !isVowel(c) && !isCantillation(c)
}

function isSeparator(c: string): boolean {
  return !!xregexp.match(c, WordSplitCharRegEx)
}

export { isCantillation, isCharacter, isSeparator, isVowel, WordSplitCharRegEx }
