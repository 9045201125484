import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import _ from 'lodash'
import { AliyahType, BarMitzvahType, HaftarahType, SentenceGroupType, SentenceType, TropeLineType } from 'types/types'

import AliyahSentencesQuery from '@/queries/AliyahSentencesQuery.graphql'
import HaftarahSentencesQuery from '@/queries/HaftarahSentencesQuery.graphql'
import MyBarMitzvahQuery from '@/queries/MyBarMitzvahQuery.graphql'
import SentenceGroupSentencesQuery from '@/queries/SentenceGroupSentencesQuery.graphql'
import TropesQuery from '@/queries/TropesQuery.graphql'
import { useApollo } from '@/util/apolloClient'
import { PracticeSettings } from '@/util/PracticeSettings'

const { apolloClient } = useApollo()

export interface StudentState {
  barMitzvah: BarMitzvahType | null
  aliyahSentences: Record<number, Array<SentenceType>>
  haftarahSentences: Record<number, Array<SentenceType>>
  sentenceGroupSentences: Record<number, Array<SentenceType>>
  tropes: Record<string, Array<TropeLineType>>
  practiceSettings: PracticeSettings | null
  error: string | null
  loading: boolean
}

const resetState = (): StudentState => {
  return {
    aliyahSentences: {} as Record<number, Array<SentenceType>>,
    barMitzvah: null,
    error: null,
    haftarahSentences: {} as Record<number, Array<SentenceType>>,
    loading: false,
    practiceSettings: null,
    sentenceGroupSentences: {} as Record<number, Array<SentenceType>>,
    tropes: {} as Record<string, Array<TropeLineType>>,
  }
}

const student = defineModule({
  actions: {
    clearBarMitzvah(context) {
      const { commit } = studentActionContext(context)
      commit.clearBarMitzvah()
    },

    clearPracticeSettings(context) {
      const { commit } = studentActionContext(context)
      commit.clearPracticeSettings()
    },

    async fetchAliyahSentences(context, payload: { aliyah: AliyahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = studentActionContext(context)

      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: AliyahSentencesQuery,
        variables: {
          aliyah: payload.aliyah.id,
        },
      })
      commit.setAliyahSentences({
        aliyah: payload.aliyah,
        sentences: response.data.aliyahSentences,
      })
      commit.setLoading(false)
    },

    async fetchBarMitzvah(context): Promise<void> {
      // eslint-disable-next-line no-use-before-define
      const { commit } = studentActionContext(context)

      commit.setLoading(true)

      return new Promise((resolve) => {
        const p = apolloClient.query({
          fetchPolicy: 'network-only',
          query: MyBarMitzvahQuery,
        })

        p.then((response) => {
          commit.setBarMitzvah(response.data.myBarMitzvah)
          resolve()
        }).finally(() => {
          commit.setLoading(false)
        })
      })
    },

    async fetchHaftarahSentences(context, payload: { haftarah: HaftarahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = studentActionContext(context)

      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: HaftarahSentencesQuery,
        variables: {
          haftarah: payload.haftarah.reading.id,
        },
      })
      commit.setHaftarahSentences({
        haftarah: payload.haftarah,
        sentences: response.data.haftarahSentences,
      })
      commit.setLoading(false)
    },

    fetchSentenceGroupSentences(context, payload: { sentenceGroup: SentenceGroupType }): Promise<void> {
      // eslint-disable-next-line no-use-before-define
      const { commit } = studentActionContext(context)

      commit.setLoading(true)
      return new Promise((resolve) => {
        const p = apolloClient.query({
          fetchPolicy: 'network-only',
          query: SentenceGroupSentencesQuery,
          variables: {
            sentenceGroup: payload.sentenceGroup.reading.id,
          },
        })
        p.then((response) => {
          commit.setSentenceGroupSentences({
            sentenceGroup: payload.sentenceGroup,
            sentences: response.data.sentenceGroupSentences,
          })
          resolve()
        }).finally(() => {
          commit.setLoading(false)
        })
      })
    },

    fetchTropes(context): Promise<void> {
      // eslint-disable-next-line no-use-before-define
      const { commit } = studentActionContext(context)

      commit.setLoading(true)
      return new Promise((resolve) => {
        const p = apolloClient.query({
          fetchPolicy: 'network-only',
          query: TropesQuery,
        })
        p.then((response) => {
          _.forEach(response.data.tropes, (tropeSet) => {
            commit.setTropes({
              tropeGroup: tropeSet.name,
              tropeLines: tropeSet.tropeLines,
            })
          })
          resolve()
        }).finally(() => {
          commit.setLoading(false)
        })
      })
    },

    reset(context) {
      const { commit } = studentActionContext(context)
      commit.reset()
    },

    resetError(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = studentActionContext(context)
      commit.setError(null)
    },

    setBarMitzvah(context, barMitzvah: BarMitzvahType) {
      const { commit } = studentActionContext(context)
      commit.setBarMitzvah(barMitzvah)
    },

    setPracticeSettings(context, practiceSettings: PracticeSettings) {
      const { commit } = studentActionContext(context)
      commit.setPracticeSettings(practiceSettings)
    },

    updateLoading(context, loading: boolean) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = studentActionContext(context)
      commit.setLoading(loading)
    },
  },

  getters: {
    aliyahSentences: (...args): Record<number, Array<SentenceType>> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.aliyahSentences
    },

    barMitzvah: (...args): BarMitzvahType | null => {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.barMitzvah
    },

    error(...args): string | null {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.error
    },

    haftarahSentences: (...args): Record<number, Array<SentenceType>> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.haftarahSentences
    },

    loading(...args): boolean {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.loading
    },

    practiceSettings: (...args): PracticeSettings | null => {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.practiceSettings
    },

    sentenceGroupSentences: (...args): Record<number, Array<SentenceType>> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.sentenceGroupSentences
    },

    tropes: (...args): Record<string, Array<TropeLineType>> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = studentGetterContext(args)
      return state.tropes
    },
  },

  mutations: {
    clearBarMitzvah(state: StudentState) {
      state.barMitzvah = null
    },

    clearPracticeSettings(state: StudentState) {
      state.practiceSettings = null
    },

    reset(state: StudentState) {
      Object.assign(state, { ...resetState() })
    },

    setAliyahSentences(state: StudentState, payload: { aliyah: AliyahType; sentences: Array<SentenceType> }) {
      state.aliyahSentences[parseInt(payload.aliyah.id)] = _.cloneDeep(payload.sentences)
    },

    setBarMitzvah(state: StudentState, barMitzvah: BarMitzvahType) {
      state.barMitzvah = _.cloneDeep(barMitzvah)
      state.barMitzvah.aliyot = _.sortBy(state.barMitzvah.aliyot, ['rank'])
    },

    setError(state: StudentState, error: string | null) {
      state.error = error
    },

    setHaftarahSentences(state: StudentState, payload: { haftarah: HaftarahType; sentences: Array<SentenceType> }) {
      state.haftarahSentences[parseInt(payload.haftarah.reading.id)] = _.cloneDeep(payload.sentences)
    },

    setLoading(state: StudentState, loading: boolean) {
      state.loading = loading
    },

    setPracticeSettings(state: StudentState, practiceSettings: PracticeSettings) {
      state.practiceSettings = _.cloneDeep(practiceSettings)
    },

    setSentenceGroupSentences(
      state: StudentState,
      payload: {
        sentenceGroup: SentenceGroupType
        sentences: Array<SentenceType>
      },
    ) {
      const sgId = payload.sentenceGroup.reading.id
      state.sentenceGroupSentences[parseInt(sgId)] = _.cloneDeep(payload.sentences)
    },

    setTropes(state: StudentState, payload: { tropeGroup: string; tropeLines: Array<TropeLineType> }) {
      const { tropeGroup } = payload
      state.tropes[tropeGroup] = _.cloneDeep(payload.tropeLines)
    },
  },

  namespaced: true as const,

  state: resetState(),
})

export default student
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const studentGetterContext = (args: [any, any, any, any]) => localGetterContext(args, student)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const studentActionContext = (context: any) => localActionContext(context, student)
