import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'

export interface DialogOptions {
  title: string
  message: string

  width?: number | string

  canReject?: boolean
  acceptText?: string
  acceptColor?: string
  onAccept?: () => void
  isRejectable?: boolean
  rejectText?: string
  rejectColor?: string
  onReject?: () => void
}

const defaultOptions: DialogOptions = {
  acceptColor: 'primary',
  acceptText: 'Confirm',
  canReject: true,
  isRejectable: true,
  message: '',
  onAccept: () => {},
  onReject: () => {},
  rejectColor: 'error',
  rejectText: 'Cancel',
  title: '',
  width: 550,
}

export interface DialogState {
  visible: boolean
  options: DialogOptions
}

const resetState = (): DialogState => {
  return {
    visible: false,
    options: { ...defaultOptions },
  }
}

const dialog = defineModule({
  namespaced: true as const,

  state: resetState(),

  getters: {
    visible(...args): boolean {
      const { state } = dialogGetterContext(args)
      return state.visible
    },

    options(...args): DialogOptions {
      const { state } = dialogGetterContext(args)
      return state.options
    },
  },

  mutations: {
    show(state: DialogState, options: DialogOptions) {
      state.options = { ...defaultOptions, ...options }
      console.log('store dialog mutations show, state.options =')
      console.log(state.options)
      state.visible = true
    },

    hide(state: DialogState) {
      // DO NOT DO THIS
      // (because we're still going to call options.onAccept() after hide()-ing the window)
      // by setting it to {...defaultOptions} we'll blow away onAccept()
      // state.options = { ...defaultOptions }
      state.visible = false
    },
  },

  actions: {
    show(context, options: DialogOptions) {
      const { commit } = dialogActionContext(context)
      console.log('dialog actions show, payload =')
      console.log(options)
      commit.show(options)
    },

    hide(context) {
      const { commit } = dialogActionContext(context)
      commit.hide()
    },
  },
})

export default dialog
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dialogGetterContext = (args: [any, any, any, any]) => localGetterContext(args, dialog)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dialogActionContext = (context: any) => localActionContext(context, dialog)
