import { computed } from 'vue'
import store from '@/store'

import {
  DEFAULT_FONT_SIZE,
  DEFAULT_LINE_HEIGHT,
  MAX_FONT_SIZE,
  MAX_LINE_HEIGHT,
  MIN_FONT_SIZE,
  MIN_LINE_HEIGHT,
} from '@/util/FontUtil'

const lineHeight = computed<number>(() => {
  return store.getters.user.user?.properties?.lineHeight || DEFAULT_LINE_HEIGHT
})

const fontSize = computed<number>(() => {
  return store.getters.user.user?.properties?.fontSize || DEFAULT_FONT_SIZE
})

const useFont = () => {
  return {
    MAX_FONT_SIZE,
    MAX_LINE_HEIGHT,
    MIN_FONT_SIZE,
    MIN_LINE_HEIGHT,
    fontSize,
    lineHeight,
  }
}

export { useFont }
