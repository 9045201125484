import _ from 'lodash'
import { SentenceType, TagType } from 'types/types'
import { computed, Ref } from 'vue'
import { VueLogger } from 'vue-logger-plugin'

import store from '@/store'
import { SentenceRecordingMetadataWithPitchedAudios } from '@/util/AudioUtil'
import { TAG_SORT_ORDER } from '@/util/Tags'
import { inReadMode, inTextMode, VoiceStyleSet } from '@/util/VoiceStyles'

const computeChoosableTags = (payload: { sentences: Array<SentenceType>; voiceStyleSet: VoiceStyleSet; logger?: VueLogger }) => {
  // no tags for Text mode
  if (inTextMode(payload.voiceStyleSet)) {
    return new Array<TagType>()
  }

  const sentenceIds = _.map(payload.sentences, (sentence: SentenceType) => {
    return sentence.id
  })

  // compile a list of available tags for recordings associated with the
  // current sentence and style selection
  payload.logger && payload.logger.debug('choosableTags, recordings =')
  payload.logger && payload.logger.debug(store.getters.audio.recordings)
  const tags = new Map<string, TagType>()

  _.forEach(_.toPairs(store.getters.audio.recordings), ([sentenceId, mappedBySentenceRecordingId]) => {
    if (_.includes(sentenceIds, sentenceId)) {
      _.forEach(Object.values(mappedBySentenceRecordingId), (srmd: SentenceRecordingMetadataWithPitchedAudios) => {
        const { sentenceRecording } = srmd
        if (!sentenceRecording) {
          return
        }
        const isRead = !sentenceRecording.haftarahMelody && !sentenceRecording.torahMelody && !sentenceRecording.sentenceGroupMelody
        if (isRead === inReadMode(payload.voiceStyleSet)) {
          _.forEach(sentenceRecording.tags, (tag: TagType) => {
            tags.set(tag.name, tag)
          })
        }
      })
    }
  })

  const uniqueTags = Array.from(tags.values())

  const r = _.sortBy(Array.from(uniqueTags.values()), (tag: TagType) => {
    return _.indexOf(TAG_SORT_ORDER, tag.name)
  })

  payload.logger && payload.logger.debug('choosableTags.value =')
  payload.logger && payload.logger.debug(r)
  return r
}

const useChoosableTags = (sentences: Ref<Array<SentenceType>>, voiceStyleSet: Ref<VoiceStyleSet>, logger: VueLogger) => {
  const choosableTags = computed<Array<TagType>>(() => {
    return computeChoosableTags({ logger, sentences: sentences.value, voiceStyleSet: voiceStyleSet.value })
  })

  const choosableHiddenTags = computed<Array<TagType>>(() => {
    return choosableTags.value.filter((tag: TagType) => {
      return tag.hidden
    })
  })

  const choosableNonHiddenTags = computed<Array<TagType>>(() => {
    return choosableTags.value.filter((tag: TagType) => {
      return !tag.hidden
    })
  })

  return { choosableHiddenTags, choosableNonHiddenTags, choosableTags }
}

export { computeChoosableTags, useChoosableTags }
