<script setup lang="ts">
import '../assets/style/fonts.css'

import { useQuery } from '@vue/apollo-composable'
import isIos from 'is-ios'
import _ from 'lodash'
import { AnnotationSetType, BarMitzvahType, SentenceType } from 'types/types'
import { computed, getCurrentInstance, onMounted, reactive, StyleValue, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import BarMitzvahAnnotationsQuery from '@/queries/BarMitzvahAnnotationsQuery.graphql'
import store from '@/store'
import { AnnotatedCharacter } from '@/util/AnnotatedCharacter'
import { AnnotatedSegment } from '@/util/AnnotatedSegment'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { AnnotatedWord } from '@/util/AnnotatedWord'
import { isCharacter } from '@/util/CharacterClasses'
import { DEFAULT_FONT_SIZE, DEFAULT_LINE_HEIGHT } from '@/util/FontUtil'
import { SegmentationType } from '@/util/Segmentation'
import { regularStyle } from '@/util/TextStyles'
import { defaultTransformation, TransformationType } from '@/util/Transformations'
import { defaultVoiceStyleSet, SING, VoiceStyles, VoiceStyleSet } from '@/util/VoiceStyles'

interface Props {
  // parent asks us to render these sentences
  barMitzvah?: Readonly<BarMitzvahType> | null
  sentences: Array<SentenceType>
  segmentations?: Array<SegmentationType>
  voiceStyleSet?: VoiceStyleSet
  transformation?: TransformationType
  name: string
  defaultFont?: string
  fontSize?: number
  lineHeight?: number

  // parent utilities
  startIndex?: number | null
  emptyOnMissingStyle?: boolean
  breakBeforeLine?: () => boolean
  showResponsiveHeaders?: () => boolean
  showSentenceNumbers?: boolean
  suppressColors?: () => boolean
  showEndOfSentenceCharacter?: boolean
  clickableLineNumber?: ((annotatedSentence: AnnotatedSentence, sentenceIndex: number) => boolean) | null
  clickableSentence?: ((annotatedSentence: AnnotatedSentence, sentenceIndex: number) => boolean) | null
  clickableSegment?:
    | ((annotatedSentence: AnnotatedSentence, annotatedSegment: AnnotatedSegment, sentenceIndex: number, segmentIndex: number) => boolean)
    | null
  clickableSegmentBoundary?:
    | ((annotatedSentence: AnnotatedSentence, annotatedSegment: AnnotatedSegment, sentenceIndex: number, segmentIndex: number) => boolean)
    | null
  clickableResponsiveHeader?: ((annotatedSentence: AnnotatedSentence, sentenceIndex: number) => boolean) | null
  clickableWord?:
    | ((
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        annotatedWord: AnnotatedWord,
        sentenceIndex: number,
        segmentIndex: number,
        wordIndex: number,
      ) => boolean)
    | null
  clickableWordBoundary?:
    | ((
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        annotatedWord: AnnotatedWord,
        sentenceIndex: number,
        segmentIndex: number,
        wordIndex: number,
      ) => boolean)
    | null
  clickableChar?:
    | ((
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        annotatedWord: AnnotatedWord,
        annotatedChar: AnnotatedCharacter,
        sentenceIndex: number,
        segmentIndex: number,
        wordIndex: number,
        charIndex: number,
      ) => boolean)
    | null
  sentenceStyle?: ((sentenceStyle: string, annotatedSentence: AnnotatedSentence, sentenceIndex: number) => string) | null
  segmentStyle?:
    | ((
        style: string,
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        sentenceIndex: number,
        segmentIndex: number,
      ) => string)
    | null
  segmentBoundaryStyle?:
    | ((
        style: string,
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        sentenceIndex: number,
        segmentIndex: number,
      ) => string)
    | null
  wordStyle?:
    | ((
        wordStyle: string,
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        annotatedWord: AnnotatedWord,
        sentenceIndex: number,
        segmentIndex: number,
        wordIndex: number,
      ) => string)
    | null
  wordBoundaryStyle?:
    | ((
        wordStyle: string,
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        annotatedWord: AnnotatedWord,
        sentenceIndex: number,
        segmentIndex: number,
        wordIndex: number,
      ) => string)
    | null
  charStyle?:
    | ((
        styleClass: string,
        annotatedSentence: AnnotatedSentence,
        annotatedSegment: AnnotatedSegment,
        annotatedWord: AnnotatedWord,
        annotatedChar: AnnotatedCharacter,
        sentenceIndex: number,
        segmentIndex: number,
        wordIndex: number,
        charIndex: number,
      ) => string)
    | null
  refresh?: number // bogus; to trigger an apollo refetch

  // for parent to track
  annotatedSentences?: Array<AnnotatedSentence>
  annotatedSegments?: Array<Array<AnnotatedSegment>>
  annotatedWords?: Array<Array<AnnotatedWord>>
  vShow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  annotatedSegments: () => [],
  annotatedSentences: () => [],
  annotatedWords: () => [],
  barMitzvah: null,
  breakBeforeLine: () => false,
  charStyle: null,
  clickableChar: null,
  clickableLineNumber: null,
  clickableResponsiveHeader: null,
  clickableSegment: null,
  clickableSegmentBoundary: null,
  clickableSentence: null,
  clickableWord: null,
  clickableWordBoundary: null,
  defaultFont: store.getters.user.user?.properties?.font,
  emptyOnMissingStyle: true,
  fontSize: DEFAULT_FONT_SIZE,
  lineHeight: DEFAULT_LINE_HEIGHT,
  refresh: 0,
  segmentBoundaryStyle: null,
  segmentStyle: null,
  segmentations: () => [],
  sentenceStyle: null,
  showEndOfSentenceCharacter: false,
  showResponsiveHeaders: () => false,
  showSentenceNumbers: false,
  startIndex: 0,
  suppressColors: () => false,
  transformation: () => defaultTransformation(),
  vShow: true,
  voiceStyleSet: () => defaultVoiceStyleSet(),
  wordBoundaryStyle: null,
  wordStyle: null,
})

const state = reactive({
  annotations: [] as Array<AnnotationSetType>,
  isCharacter,
  isIos,
})

const emit = defineEmits([
  'clickChar',
  'clickLineNumber',
  'clickResponsiveHeader',
  'clickSegment',
  'clickSegmentBoundary',
  'clickSentence',
  'clickWord',
  'clickWordBoundary',
  'update:annotatedSegments',
  'update:annotatedSentences',
  'update:annotatedWords',
])

const logger = useLogger()
const instance = getCurrentInstance()
const hasBarmitzvah = computed<boolean>(() => {
  return !_.isEmpty(props.barMitzvah)
})

const { onError, refetch, result } = useQuery(
  BarMitzvahAnnotationsQuery,
  () => ({
    username: props.barMitzvah!.student.username,
  }),
  {
    // see https://github.com/vuejs/apollo/issues/1422
    enabled: hasBarmitzvah, // note the type needs to be Ref<boolean>, so it's not hasBarmitzvah.value
  },
)

watch(
  result,
  (newResult) => {
    state.annotations = newResult?.barMitzvahAnnotations
  },
  { immediate: true },
)

// Handle errors if needed
onError((error) => {
  console.error('Failed to fetch annotations:', error)
})

const displayStyle = computed<StyleValue>(() => {
  return { display: props.vShow ? '' : 'none' } as StyleValue
})

const mightHaveAnnotatedSentences = computed<boolean>(() => {
  if (_.isEmpty(props.sentences)) {
    return false
  }

  // if there is no voice style, then the rest of the sentences are irrelevant
  if (_.isEmpty(props.voiceStyleSet)) {
    return true
  }

  if ((props.emptyOnMissingStyle && _.isEmpty(props.voiceStyleSet.pronunciation)) || _.isEmpty(props.voiceStyleSet.voice)) {
    return false
  }

  // when sung, a melody must be picked
  if (
    props.emptyOnMissingStyle &&
    props.voiceStyleSet.voice === VoiceStyles[SING] &&
    !props.voiceStyleSet.torahMelody &&
    !props.voiceStyleSet.haftarahMelody &&
    !props.voiceStyleSet.sentenceGroupMelody
  ) {
    return false
  }

  return true
})

const myAnnotatedSegments = computed<Array<Array<AnnotatedSegment>>>(() => {
  const segments = [] as Array<Array<AnnotatedSegment>>
  if (_.isEmpty(myAnnotatedSentences.value)) {
    return segments
  }

  _.forEach(myAnnotatedSentences.value, (annotatedSentence: AnnotatedSentence, sentenceIndex: number) => {
    segments.push(new Array<AnnotatedSegment>())
    annotatedSentence.forEach((annotatedSegment: AnnotatedSegment) => {
      segments[sentenceIndex].push(annotatedSegment)
    })
  })

  return segments
})

const annotatedChars = computed<Array<Array<Array<Array<AnnotatedCharacter | null>>>>>(() => {
  const characters = [] as Array<Array<Array<Array<AnnotatedCharacter | null>>>>
  if (_.isEmpty(myAnnotatedSentences.value)) {
    return characters
  }

  _.forEach(myAnnotatedSentences.value, (annotatedSentence: AnnotatedSentence, sentenceIndex: number) => {
    characters.push(new Array<Array<Array<AnnotatedCharacter>>>())
    annotatedSentence.forEach((annotatedSegment: AnnotatedSegment, __: string, segmentIndex = 0) => {
      characters[sentenceIndex].push(new Array<Array<AnnotatedCharacter>>())
      annotatedSegment.forEach((annotatedWord: AnnotatedWord, __: string | null, wordIndex = 0) => {
        characters[sentenceIndex][segmentIndex].push(new Array<AnnotatedCharacter>())
        annotatedWord.forEach((annotatedCharacter: AnnotatedCharacter | null) => {
          characters[sentenceIndex][segmentIndex][wordIndex].push(annotatedCharacter)
        })
      })
    })
  })

  return characters
})

const myAnnotatedSentences = computed<Array<AnnotatedSentence>>(() => {
  const annotatedSentences = new Array<AnnotatedSentence>()
  // if (!mightHaveAnnotatedSentences.value) {
  //   logger.debug('myAnnotatedSentences, !mightHaveAnnotatedSentences')
  //   return annotatedSentences
  // }

  // make computed value about the refresh factor so that Vue reactivity
  // cause a redraw of sentences when refresh changes
  if (props.refresh) {
    /* foo */
  }

  // first zip together this.selectedSentences and this.annotations into this.myAnnotatedSentences
  _.forEach(_.sortBy(props.sentences, [(sentence: SentenceType) => parseInt(sentence.id, 10)]), (sentence: SentenceType, idx: number) => {
    const annotationSet = _.find(state.annotations, (bma: AnnotationSetType) => {
      return bma.sentence.id === sentence.id
    })

    let segmentation = [] as Array<number>
    if (!_.isEmpty(props.segmentations) && idx < props.segmentations.length) {
      segmentation = props.segmentations[idx]
    }
    const annotatedSentence = new AnnotatedSentence(sentence, annotationSet, segmentation, props.showEndOfSentenceCharacter)
    // apply the transformationn
    if (props.transformation && props.transformation?.transform) {
      props.transformation.transform(annotatedSentence)
    }

    annotatedSentences.push(annotatedSentence)
  })

  return annotatedSentences
})

const myAnnotatedWords = computed<Array<Array<Array<AnnotatedWord>>>>(() => {
  const words = [] as Array<Array<Array<AnnotatedWord>>>
  if (_.isEmpty(myAnnotatedSentences.value)) {
    return words
  }

  _.forEach(myAnnotatedSentences.value, (annotatedSentence: AnnotatedSentence, sentenceIndex: number) => {
    words.push(new Array<Array<AnnotatedWord>>())
    annotatedSentence.forEach((annotatedSegment: AnnotatedSegment, __, segmentIndex = 0) => {
      words[sentenceIndex].push(new Array<AnnotatedWord>())
      annotatedSegment.forEach((annotatedWord: AnnotatedWord) => {
        words[sentenceIndex][segmentIndex].push(annotatedWord)
      })
    })
  })

  return words
})

const segmentBoundaries = computed<Array<Array<string>>>(() => {
  const segmentBoundaries = [] as Array<Array<string>>
  if (_.isEmpty(myAnnotatedSentences.value)) {
    return segmentBoundaries
  }

  _.forEach(myAnnotatedSentences.value, (annotatedSentence: AnnotatedSentence, sentenceIndex: number) => {
    segmentBoundaries.push(new Array<string>())
    annotatedSentence.forEach((__: AnnotatedSegment, segmentBoundary) => {
      segmentBoundaries[sentenceIndex].push(segmentBoundary)
    })
  })
  return segmentBoundaries
})

const segmentBoundaryStyles = computed<Array<Array<string>>>(() => {
  const segmentBoundaryStyles = [] as Array<Array<string>>
  if (_.isEmpty(myAnnotatedSegments.value)) {
    return segmentBoundaryStyles
  }

  _.forEach(myAnnotatedSegments.value, (annotatedSegment: Array<AnnotatedSegment>, segmentIndex: number) => {
    segmentBoundaryStyles.push(new Array<string>())
    annotatedSegment.forEach(() => {
      segmentBoundaryStyles[segmentIndex].push(regularStyle)
    })
  })
  return segmentBoundaryStyles
})

const segmentStyles = computed<Array<Array<string>>>(() => {
  const segmentStyles = [] as Array<Array<string>>
  if (_.isEmpty(myAnnotatedSegments.value)) {
    return segmentStyles
  }

  _.forEach(myAnnotatedSegments.value, (annotatedSegment: Array<AnnotatedSegment>, segmentIndex: number) => {
    segmentStyles.push(new Array<string>())
    annotatedSegment.forEach(() => {
      segmentStyles[segmentIndex].push(regularStyle)
    })
  })
  return segmentStyles
})

const sentenceStyles = computed<Array<string>>(() => {
  const sentenceStyles = [] as Array<string>
  if (_.isEmpty(myAnnotatedSentences.value)) {
    return sentenceStyles
  }

  _.forEach(myAnnotatedSentences.value, () => {
    sentenceStyles.push(regularStyle)
  })
  return sentenceStyles
})

const transformationClass = computed<string>(() => {
  return props.transformation?.props.class ?? ''
})

const wordBoundaries = computed<Array<Array<Array<string | null>>>>(() => {
  const wordBoundaries = [] as Array<Array<Array<string | null>>>
  if (_.isEmpty(myAnnotatedSentences.value)) {
    return wordBoundaries
  }

  _.forEach(myAnnotatedSentences.value, (annotatedSentence: AnnotatedSentence, sentenceIndex: number) => {
    wordBoundaries.push(new Array<Array<string>>())
    annotatedSentence.forEach((annotatedSegment: AnnotatedSegment, __, segmentIndex = 0) => {
      wordBoundaries[sentenceIndex].push(new Array<string>())
      annotatedSegment.forEach((__: AnnotatedWord, wordBoundary) => {
        wordBoundaries[sentenceIndex][segmentIndex].push(wordBoundary)
      })
    })
  })
  return wordBoundaries
})

const wordBoundaryStyles = computed<Array<Array<Array<string>>>>(() => {
  const wordBoundaryStyles = [] as Array<Array<Array<string>>>
  if (_.isEmpty(myAnnotatedSentences.value)) {
    return wordBoundaryStyles
  }
  _.forEach(myAnnotatedSentences.value, (annotatedSentence: AnnotatedSentence, sentenceIndex: number) => {
    wordBoundaryStyles.push(new Array<Array<string>>())
    annotatedSentence.forEach((annotatedSegment: AnnotatedSegment, __: string, segmentIndex = 0) => {
      wordBoundaryStyles[sentenceIndex].push(new Array<string>())
      annotatedSegment.forEach((__: AnnotatedWord, ___: string | null, ____ = 0) => {
        wordBoundaryStyles[sentenceIndex][segmentIndex].push(regularStyle)
      })
    })
  })
  return wordBoundaryStyles
})

const wordStyles = computed<Array<Array<Array<string>>>>(() => {
  const wordStyles = [] as Array<Array<Array<string>>>
  if (_.isEmpty(myAnnotatedSentences)) {
    return wordStyles
  }
  _.forEach(myAnnotatedSentences.value, (annotatedSentence: AnnotatedSentence, sentenceIndex: number) => {
    wordStyles.push(new Array<Array<string>>())
    annotatedSentence.forEach((annotatedSegment: AnnotatedSegment, __: string, segmentIndex = 0) => {
      wordStyles[sentenceIndex].push(new Array<string>())
      annotatedSegment.forEach((__: AnnotatedWord, ___, ____) => {
        wordStyles[sentenceIndex][segmentIndex].push(regularStyle)
      })
    })
  })
  return wordStyles
})

function onLineNumberClick(annotatedSentence: AnnotatedSentence, sentenceIndex: number) {
  emit('clickLineNumber', annotatedSentence, sentenceIndex)
}

function onRensponsiveHeaderClick(annotatedSentence: AnnotatedSentence, sentenceIndex: number) {
  emit('clickResponsiveHeader', annotatedSentence, sentenceIndex)
}

function onSentenceClick(annotatedSentence: AnnotatedSentence, sentenceIndex: number) {
  emit('clickSentence', annotatedSentence, sentenceIndex)
}

function onSegmentClick(
  annotatedSentence: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  sentenceIndex: number,
  segmentIndex: number,
) {
  emit('clickSegment', annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)
}

function onSegmentBoundaryClick(
  annotatedSentence: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  sentenceIndex: number,
  segmentIndex: number,
) {
  emit('clickSegmentBoundary', annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)
}

function onWordClick(
  annotatedSentence: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  annotatedWord: AnnotatedWord,
  sentenceIndex: number,
  segmentIndex: number,
  wordIndex: number,
) {
  emit('clickWord', annotatedSentence, annotatedSegment, annotatedWord, sentenceIndex, segmentIndex, wordIndex)
}

function onWordBoundaryClick(
  annotatedSentence: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  annotatedWord: AnnotatedWord,
  sentenceIndex: number,
  segmentIndex: number,
  wordIndex: number,
) {
  emit('clickWordBoundary', annotatedSentence, annotatedSegment, annotatedWord, sentenceIndex, segmentIndex, wordIndex)
}

function onCharClick(
  annotatedSentence: AnnotatedSentence,
  annotatedSegment: AnnotatedSegment,
  annotatedWord: AnnotatedWord,
  annotatedChar: AnnotatedCharacter,
  sentenceIndex: number,
  segmentIndex: number,
  wordIndex: number,
  charIndex: number,
) {
  emit('clickChar', annotatedSentence, annotatedSegment, annotatedWord, annotatedChar, sentenceIndex, segmentIndex, wordIndex, charIndex)
}

onMounted(() => {
  state.isIos = isIos

  watch(
    myAnnotatedSentences,
    () => {
      emit('update:annotatedSentences', myAnnotatedSentences.value)
    },
    { immediate: true },
  )

  watch(
    myAnnotatedSegments,
    () => {
      emit('update:annotatedSegments', myAnnotatedSegments.value)
    },
    {
      immediate: true,
    },
  )

  watch(
    myAnnotatedWords,
    () => {
      emit('update:annotatedWords', myAnnotatedWords.value)
    },
    {
      immediate: true,
    },
  )

  watch(
    () => props.refresh,
    () => {
      logger.debug('props.refresh, forceUpdate')
      if (!instance) {
        logger.error('unexpected null instance')
        return
      }
      if (!instance.proxy) {
        logger.error('unexpected null instance.proxy')
        return
      }
      instance.proxy.$forceUpdate()
      refetch()
    },
    { immediate: true },
  )
})
</script>

<template>
  <div :style="displayStyle">
    <div v-if="props.sentences.length && mightHaveAnnotatedSentences && !myAnnotatedSentences.length">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <div v-else dir="rtl" :style="myAnnotatedSentences.length > 1 ? { 'text-align': 'justify', 'text-justify': 'inter-word' } : {}">
      <template
        v-for="(annotatedSentence, sentenceIndex) in myAnnotatedSentences"
        :key="`${props.name}:${sentenceIndex}-${props.refresh}-div:key`"
        ><span
          :class="transformationClass || props.defaultFont"
          :style="{
            'font-weight': 'bold',
            'font-size': props.fontSize + '%',
            'line-height': props.lineHeight,
          }"
          ><span v-show="props.breakBeforeLine() && sentenceIndex > 0"><br /></span
          ><template v-if="props.showResponsiveHeaders()">
            <span
              :class="
                props.clickableResponsiveHeader && props.clickableResponsiveHeader(annotatedSentence, sentenceIndex)
                  ? 'clickableResponsiveHeader'
                  : ''
              "
              style="display: inline-block; width: 1.2em"
              @click="onRensponsiveHeaderClick(annotatedSentence, sentenceIndex)"
            >
              <span v-if="annotatedSentence.isUpdating()">
                <v-progress-circular indeterminate />
              </span>
              <span v-else>
                <v-icon>
                  {{ annotatedSentence.sentence().isResponsive ? 'mdi-account-multiple' : 'mdi-account' }}
                </v-icon>
              </span>
            </span></template
          ><span
            v-show="props.showSentenceNumbers"
            :id="name + ':' + sentenceIndex + ':number'"
            :class="props.clickableLineNumber && props.clickableLineNumber(annotatedSentence, sentenceIndex) ? 'clickableLineNumber' : ''"
            @click="onLineNumberClick(annotatedSentence, sentenceIndex)"
          >
            ({{ (props.startIndex !== null ? props.startIndex : 0) + sentenceIndex + 1 }})
          </span>
          <span
            :id="name + ':' + sentenceIndex + ':sentence'"
            :key="sentenceIndex + ':key'"
            :class="[
              sentenceStyles[sentenceIndex],
              props.sentenceStyle ? props.sentenceStyle(sentenceStyles[sentenceIndex], annotatedSentence, sentenceIndex) : '',
              props.clickableSentence && props.clickableSentence(annotatedSentence, sentenceIndex) ? 'clickableSentence' : '',
            ]"
            @click="onSentenceClick(annotatedSentence, sentenceIndex)"
            ><template
              v-for="(annotatedSegment, segmentIndex) in myAnnotatedSegments[sentenceIndex]"
              :key="name + ':' + sentenceIndex + ':' + segmentIndex + ':key'"
            >
              <span
                :id="name + ':' + sentenceIndex + ':' + segmentIndex + ':segment'"
                :class="[
                  segmentStyles[sentenceIndex][segmentIndex],
                  props.segmentStyle
                    ? props.segmentStyle(
                        segmentStyles[sentenceIndex][segmentIndex],
                        annotatedSentence,
                        annotatedSegment,
                        sentenceIndex,
                        segmentIndex,
                      )
                    : '',
                  props.clickableSegment && props.clickableSegment(annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)
                    ? 'clickableSegment'
                    : '',
                ]"
                @click="onSegmentClick(annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)"
                ><template
                  v-for="(annotatedWord, wordIndex) in myAnnotatedWords[sentenceIndex][segmentIndex]"
                  :key="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':key'"
                  ><span
                    :id="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':word'"
                    :class="[
                      wordStyles[sentenceIndex][segmentIndex][wordIndex],
                      props.wordStyle
                        ? props.wordStyle(
                            wordStyles[sentenceIndex][segmentIndex][wordIndex],
                            annotatedSentence,
                            annotatedSegment,
                            annotatedWord,
                            sentenceIndex,
                            segmentIndex,
                            wordIndex,
                          )
                        : '',
                      props.clickableWord &&
                      props.clickableWord(annotatedSentence, annotatedSegment, annotatedWord, sentenceIndex, segmentIndex, wordIndex)
                        ? 'clickableWord'
                        : '',
                    ]"
                    @click="onWordClick(annotatedSentence, annotatedSegment, annotatedWord, sentenceIndex, segmentIndex, wordIndex)"
                    ><!-- iOS cannot handle one character at a time --><template v-if="state.isIos">{{ annotatedWord.word() }}</template
                    ><template v-for="(annotatedChar, charIndex) in annotatedChars[sentenceIndex][segmentIndex][wordIndex]" v-else
                      ><span
                        v-if="annotatedChar && !isCharacter(annotatedChar.character())"
                        :id="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':' + charIndex + ':not-char-id'"
                        :key="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':' + charIndex + ':not-char-key'"
                        style="z-index: 1; position: relative"
                        >{{ annotatedChar.character() }}</span
                      ><span
                        v-else-if="annotatedChar"
                        :id="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':' + charIndex + ':char-id'"
                        :key="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':' + charIndex + ':char-key'"
                        :class="[
                          annotatedChar.styleClass,
                          props.charStyle
                            ? props.charStyle(
                                annotatedChar.styleClass,
                                annotatedSentence,
                                annotatedSegment,
                                annotatedWord,
                                annotatedChar,
                                sentenceIndex,
                                segmentIndex,
                                wordIndex,
                                charIndex,
                              )
                            : '',
                          props.clickableChar &&
                          props.clickableChar(
                            annotatedSentence,
                            annotatedSegment,
                            annotatedWord,
                            annotatedChar,
                            sentenceIndex,
                            segmentIndex,
                            wordIndex,
                            charIndex,
                          )
                            ? 'clickableChar'
                            : '',
                        ]"
                        :style="[props.suppressColors && props.suppressColors() ? {} : (annotatedChar.overrideStyle as StyleValue)]"
                        @click="
                          onCharClick(
                            annotatedSentence,
                            annotatedSegment,
                            annotatedWord,
                            annotatedChar,
                            sentenceIndex,
                            segmentIndex,
                            wordIndex,
                            charIndex,
                          )
                        "
                        >{{ annotatedChar.character() }}</span
                      ></template
                    ></span
                  ><span
                    v-if="wordBoundaries[sentenceIndex][segmentIndex][wordIndex]"
                    :id="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':boundary'"
                    :key="name + ':' + sentenceIndex + ':' + segmentIndex + ':' + wordIndex + ':boundary-key'"
                    :class="[
                      wordBoundaryStyles[sentenceIndex][segmentIndex][wordIndex],
                      props.wordBoundaryStyle
                        ? props.wordBoundaryStyle(
                            wordStyles[sentenceIndex][segmentIndex][wordIndex],
                            annotatedSentence,
                            annotatedSegment,
                            annotatedWord,
                            sentenceIndex,
                            segmentIndex,
                            wordIndex,
                          )
                        : '',
                      props.clickableWordBoundary &&
                      props.clickableWordBoundary(
                        annotatedSentence,
                        annotatedSegment,
                        annotatedWord,
                        sentenceIndex,
                        segmentIndex,
                        wordIndex,
                      )
                        ? 'clickableWordBoundary'
                        : '',
                    ]"
                    @click="onWordBoundaryClick(annotatedSentence, annotatedSegment, annotatedWord, sentenceIndex, segmentIndex, wordIndex)"
                    >{{ wordBoundaries[sentenceIndex][segmentIndex][wordIndex] }}</span
                  >
                </template></span
              ><span
                v-if="segmentBoundaries[sentenceIndex][segmentIndex]"
                :id="name + ':' + sentenceIndex + ':' + segmentIndex + ':boundary'"
                :key="name + ':' + sentenceIndex + ':' + segmentIndex + ':boundary-key'"
                :class="[
                  segmentBoundaryStyles[sentenceIndex][segmentIndex],
                  props.segmentBoundaryStyle
                    ? props.segmentBoundaryStyle(
                        segmentStyles[sentenceIndex][segmentIndex],
                        annotatedSentence,
                        annotatedSegment,
                        sentenceIndex,
                        segmentIndex,
                      )
                    : {},
                  props.clickableSegmentBoundary &&
                  props.clickableSegmentBoundary(annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)
                    ? 'clickableSegmentBoundary'
                    : '',
                ]"
                @click="onSegmentBoundaryClick(annotatedSentence, annotatedSegment, sentenceIndex, segmentIndex)"
                >{{ segmentBoundaries[sentenceIndex][segmentIndex] }}</span
              ></template
            ><span>&nbsp;</span></span
          ></span
        ><slot :annotated-sentence="annotatedSentence" name="sentence" :sentence-index="sentenceIndex" />
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
/* theme independent */
.annotated-label {
  position: relative;
  top: 10px;
  left: 10px;
  font-size: 12px;
  background-color: rgb(30, 30, 30);
  padding: 0 4px;
}

/* dark theme
.theme--dark {
  //.wordBoundaryStyle
  // no props

.regularStyle {
  // background-color: ''
  font-weight: normal;
  border-style: none;
  font-size: 100%;
}

.absentStyle {
  color: dimgrey;
}

// https://www.htmlcsscolor.com/hex/9ACD32
.waitingStyle {
  background-color: #9b7a7b;
}

// yellow green
.userSpeakingStyle {
  background-color: #634e43;
}

// dark orange
.playBackStyle {
  background-color: rgba(255, 140, 0, 0.9);
}

.segmentBoundaryStyle {
  border: 1px dotted white;
}

.wordBoundaryStyle {
  // background-color: rgba(255, 255, 0, 1)
  border: 1px dashed white;
}

.badWordsStyle {
  color: red;
}

.segmentStyle0 {
  background-color: #272727;
}

.segmentStyle1 {
  background-color: #808080;
}

.annotated-label {
  background-color: rgb(30, 30, 30);
}
*/

/* light theme
.theme--light {
  //.wordBoundaryStyle
  // no props
  */

.regularStyle {
  // background-color: ''
  font-weight: normal;
  border-style: none;
  font-size: 100%;
}

.absentStyle {
  color: lightgrey;
}

// https://www.htmlcsscolor.com/hex/9ACD32
.waitingStyle {
  background-color: #bedf7c;
}

// yellow green
.userSpeakingStyle {
  background-color: #7ba428;
}

// yellow
.playBackStyle {
  background-color: rgba(255, 255, 0, 1);
}

.segmentBoundaryStyle {
  background-color: orange;
}

.wordBoundaryStyle {
  border: 2px dashed orange;
}

.badWordsStyle {
  color: red;
}

.segmentStyle0 {
  background-color: #e0e0e0;
}

.segmentStyle1 {
  background-color: #99aabb;
}

.annotated-label {
  background-color: #fff;
}
/*
}
*/

.clickableLineNumber:hover {
  cursor: pointer;
  color: #007bff; /* Vue bootstrap primary */
}

.clickableSentence:hover {
  cursor: pointer;
  color: #007bff; /* Vue bootstrap primary */
}

.clickableSegment:hover {
  cursor: pointer;
  color: #007bff; /* Vue bootstrap primary */
}

.clickableSegmentBoundary:hover {
  cursor: pointer;
}

.clickableResponsiveHeader:hover {
  cursor: pointer;
}
.clickableWord:hover {
  cursor: pointer;
  color: #007bff; /* Vue bootstrap primary */
}

.clickableWordBoundary:hover {
  cursor: pointer;
}

.clickableChar:hover {
  cursor: pointer;
  color: #007bff; /* Vue bootstrap primary */
}
</style>
