import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import _ from 'lodash'
import { ConfirmationTokenType, UserGrapheneType } from 'types/types'

import { UpdateUserParameters } from '@/composables/util'
import Contact from '@/mutations/Contact.graphql'
import ForgotUserPassword from '@/mutations/ForgotUserPassword.graphql'
import Impersonate from '@/mutations/Impersonate.graphql'
import Login from '@/mutations/Login.graphql'
import Logout from '@/mutations/Logout.graphql'
import UpdateTheme from '@/mutations/UpdateTheme.graphql'
import UpdateUser from '@/mutations/UpdateUser.graphql'
import UpdateUserPassword from '@/mutations/UpdateUserPassword.graphql'
import UpdateUserPasswordFromSecretToken from '@/mutations/UpdateUserPasswordFromSecretToken.graphql'
import ConfirmationTokenQuery from '@/queries/ConfirmationTokenQuery.graphql'
import UserQuery from '@/queries/UserQuery.graphql'
import { useApollo } from '@/util/apolloClient'
const { apolloClient } = useApollo()

export interface UserState {
  user: UserGrapheneType | null
  impersonator: UserGrapheneType | null
  impersonatorUrl: string | null
  authenticating: boolean
  confirmationToken: ConfirmationTokenType | null
  updating: boolean
  error: string
  // keep an eye on sanitize() when adding fields
}

const resetState = (): UserState => {
  return {
    authenticating: false,
    confirmationToken: null,
    error: '',
    impersonator: null,
    impersonatorUrl: null,
    updating: false,
    user: null,
    // keep an eye on sanitize() when adding fields
  }
}

const user = defineModule({
  actions: {
    clearImpersonator(context) {
      const { commit } = userActionContext(context)
      commit.clearImpersonator()
    },

    contact(context, payload: { email: string; message: string }): Promise<boolean> {
      const { commit } = userActionContext(context)
      return new Promise<boolean>((resolve, reject) => {
        try {
          const r = apolloClient.mutate({
            mutation: Contact,
            variables: {
              email: payload.email,
              message: payload.message,
            },
          })
          r.then((result) => {
            commit.setUpdating(false)
            const { errors, ok } = result.data.contact
            if (ok) {
              resolve(ok)
              return
            }
            commit.setError(errors)
            reject(errors)
          }).catch((error) => {
            commit.setError(error)
            reject(error)
          })
        } catch (error) {
          commit.setError(error as string)
          reject(error)
        }
      })
    },

    async fetchConfirmationToken(context, payload: { token: string }) {
      const { commit } = userActionContext(context)
      const response = await apolloClient.query({
        query: ConfirmationTokenQuery,
        variables: {
          token: payload.token,
        },
      })
      commit.setConfirmationToken(response.data.confirmationToken)
    },

    fetchUser(context) {
      const { commit } = userActionContext(context)
      return new Promise<void>((resolve) => {
        const r = apolloClient.query({
          fetchPolicy: 'network-only',
          query: UserQuery,
        })
        r.then((response) => {
          commit.setUser(response.data.user)
        })
        resolve()
      })
    },

    forgotUserPassword(
      context,
      payload: {
        usernameOrEmail: string
      },
    ) {
      const { commit } = userActionContext(context)
      return new Promise<void>((resolve, reject) => {
        commit.setUpdating(true)
        try {
          const r = apolloClient.mutate({
            mutation: ForgotUserPassword,
            variables: {
              usernameOrEmail: payload.usernameOrEmail.toLowerCase(),
            },
          })

          r.then((result) => {
            commit.setUpdating(false)
            const { errors, ok } = result.data.forgotUserPassword
            if (ok) {
              commit.setUpdating(false)
              resolve()
              return
            }
            commit.setError(errors)
            reject(errors)
          }).catch((error) => {
            commit.setError(error)
            reject(error)
          })
        } catch (error) {
          commit.setError(error as string)
          reject(error)
        }
      })
    },

    login(context, payload: { username: string; password: string }) {
      const { commit } = userActionContext(context)
      return new Promise<boolean>((resolve, reject) => {
        commit.setAuthenticating(true)
        try {
          const r = apolloClient.mutate({
            mutation: Login,
            variables: {
              password: payload.password,
              username: payload.username.toLowerCase(),
            },
          })
          r.then((response) => {
            commit.setAuthenticating(false)
            const { errors, ok } = response.data.login
            if (ok) {
              console.log('actions login ok')
              commit.clearImpersonator()
              commit.setUser(response.data.login.user)
              resolve(ok)
              return
            }
            commit.setError(errors)
            reject(ok)
          }).catch((error) => {
            commit.setError(error)
            reject(error)
          })
        } catch (error) {
          commit.setError(error as string)
          reject(error)
        }
      })
    },

    // alias for reset
    logout(context) {
      const { commit } = userActionContext(context)
      return new Promise<boolean>((resolve, reject) => {
        try {
          const r = apolloClient.mutate({
            mutation: Logout,
            variables: {
              username: 'dummy',
            },
          })
          r.then((response) => {
            const { errors, ok } = response.data.logout
            console.log('response.data.logout, error =')
            console.log(errors)
            if (ok) {
              commit.reset()
              resolve(ok)
              return
            }
            commit.setError(errors)
            reject(ok)
          }).catch((error) => {
            console.log('store.user.catch 1, error =')
            console.log(error)
            commit.setError(error)
            reject(error)
          })
        } catch (error) {
          console.log('store.user.catch 2, error =')
          console.log(error)
          commit.setError(error as string)
          reject(error)
        }
      })
    },

    reset(context) {
      const { commit } = userActionContext(context)
      commit.reset()
    },

    sanitize(context) {
      const { commit } = userActionContext(context)
      commit.sanitize()
    },

    setImpersonator(context, user: UserGrapheneType) {
      const { commit } = userActionContext(context)
      commit.setImpersonator(user)
    },

    setImpersonatorUrl(context, url: string) {
      const { commit } = userActionContext(context)
      commit.setImpersonatorUrl(url)
    },

    setUser(context, user: UserGrapheneType) {
      const { commit } = userActionContext(context)
      commit.setUser(user)
    },

    setUserPassword(
      context,
      payload: {
        secretToken: string
        newPassword: string
      },
    ) {
      const { commit } = userActionContext(context)
      return new Promise<void>((resolve, reject) => {
        commit.setUpdating(true)

        try {
          const mutation = payload.secretToken ? UpdateUserPasswordFromSecretToken : UpdateUserPassword
          const variables: { newPassword: string; secretToken?: string } = {
            newPassword: payload.newPassword,
          }
          if (payload.secretToken) {
            variables.secretToken = payload.secretToken
          }
          const r = apolloClient.mutate({
            mutation,
            variables,
          })
          r.then((result) => {
            commit.setUpdating(false)
            const { errors, ok } =
              mutation === UpdateUserPasswordFromSecretToken
                ? result.data.updateUserPasswordFromSecretToken
                : result.data.updateUserPassword
            if (ok) {
              commit.setUpdating(false)
              resolve()
              return
            }
            commit.setError(errors)
            reject(errors)
          }).catch((error) => {
            commit.setError(error)
            reject(error)
          })
        } catch (error) {
          commit.setError(error as string)
          reject(error)
        }
      })
    },

    startImpersonate(context, payload: { username: string }) {
      const { commit } = userActionContext(context)
      return new Promise<boolean>((resolve, reject) => {
        try {
          const r = apolloClient.mutate({
            mutation: Impersonate,
            variables: {
              username: payload.username.toLowerCase(),
            },
          })
          r.then((response) => {
            const { errors, ok } = response.data.impersonate
            if (ok) {
              const { user } = response.data.impersonate
              commit.setImpersonation(response.data.impersonate.user)
              r.then(() => {
                resolve(user.id)
              })
              return
            }
            commit.setError(errors)
            reject(ok)
          }).catch((error) => {
            commit.setError(error)
            reject(error)
          })
        } catch (error) {
          commit.setError(error as string)
          reject(error)
        }
      })
    },

    unsetImpersonate(context) {
      const { commit } = userActionContext(context)
      commit.unsetImpersonation()
    },

    async updateTheme(
      context,
      payload: {
        theme: string
      },
    ) {
      const { commit, dispatch } = userActionContext(context)
      commit.setUpdating(true)
      const response = await apolloClient.mutate({
        mutation: UpdateTheme,
        variables: {
          theme: payload.theme,
        },
      })
      const { errors, ok } = response.data.updateTheme
      commit.setError(ok ? null : errors)
      commit.setUpdating(false)
      dispatch.fetchUser()
    },

    async updateUser(context, payload: UpdateUserParameters) {
      const { commit, dispatch } = userActionContext(context)
      commit.setUpdating(true)
      const response = await apolloClient.mutate({
        mutation: UpdateUser,
        variables: {
          font: payload.user.properties?.font,
          fontSize: payload.user.properties?.fontSize,
          gender: payload.user.properties?.gender,
          haftarahMelodies: payload.haftarahMelodyIDs,
          lineHeight: payload.user.properties?.lineHeight,
          pronunciations: payload.pronunciationIDs,
          sentenceGroupMelodies: payload.sentenceGroupMelodyIDs,
          tags: payload.tags,
          torahMelodies: payload.torahMelodyIDs,
          userData: {
            email: payload.user.email.toLowerCase(),
            firstName: payload.user.firstName,
            lastName: payload.user.lastName,
            username: payload.user.username.toLowerCase(),
          },
        },
      })

      const { errors, ok } = response.data.updateUser
      commit.setError(ok ? null : errors)
      commit.setUpdating(false)
      dispatch.fetchUser()
    },
  },
  getters: {
    authenticating(...args): boolean {
      const { state } = userGetterContext(args)
      return state.authenticating
    },

    confirmationToken(...args): ConfirmationTokenType | null {
      const { state } = userGetterContext(args)
      return state.confirmationToken
    },

    error(...args): string {
      const { state } = userGetterContext(args)
      return state.error
    },

    impersonator(...args): UserGrapheneType | null {
      const { state } = userGetterContext(args)
      let impersonator = state.impersonator
      if (!impersonator) {
        impersonator = localStorage.impersonator ? JSON.parse(localStorage.impersonator) : null
      }
      return impersonator
    },

    impersonatorUrl(...args): string {
      const { state } = userGetterContext(args)
      return state.impersonatorUrl ?? ''
    },

    updating(...args): boolean {
      const { state } = userGetterContext(args)
      return state.updating
    },

    user(...args): UserGrapheneType | null {
      const { state } = userGetterContext(args)
      let user = state.user
      if (!user) {
        user = localStorage.user ? JSON.parse(localStorage.user) : null
      }
      return user
    },

    userIsLoggedIn(...args): boolean {
      // access bump for caching purposes

      const { state } = userGetterContext(args)

      let user = state.user
      if (!user) {
        user = localStorage.user ? JSON.parse(localStorage.user) : null
      }
      return !!user
    },
  },

  mutations: {
    clearImpersonator(state: UserState) {
      state.impersonator = null
      localStorage.removeItem('impersonator')
    },

    reset(state: UserState) {
      console.log('store.user reset state')
      localStorage.removeItem('user')
      localStorage.removeItem('impersonator')
      Object.keys(resetState()).forEach((key) => {
        ;(state as any)[key] = _.cloneDeep((resetState() as any)[key])
      })
    },

    // clean non-user/impersonator fields
    sanitize(state: UserState) {
      state.authenticating = false
      state.confirmationToken = null
      state.error = ''
      state.updating = false
    },

    setAuthenticating(state: UserState, authenticating: boolean) {
      state.authenticating = authenticating
    },

    setConfirmationToken(state: UserState, confirmationTokenX: ConfirmationTokenType) {
      state.confirmationToken = confirmationTokenX
    },

    setError(state: UserState, error: string) {
      state.error = error
    },

    setImpersonation(state: UserState, userx: UserGrapheneType) {
      state.user = userx
      localStorage.user = JSON.stringify(userx)
      state.impersonator = _.cloneDeep(state.user)
      localStorage.impersonator = JSON.stringify(state.user)
    },

    setImpersonator(state: UserState, userx: UserGrapheneType) {
      state.impersonator = _.cloneDeep(userx)
      localStorage.impersonator = JSON.stringify(userx)
    },

    setImpersonatorUrl(state: UserState, url: string) {
      state.impersonatorUrl = url
    },

    setUpdating(state: UserState, updating: boolean) {
      state.updating = updating
    },

    setUser(state: UserState, userx: UserGrapheneType) {
      state.user = _.cloneDeep(userx)
      console.log('state.user is now')
      console.log(state.user)
      localStorage.user = JSON.stringify(userx)
      localStorage.theme = userx.properties?.theme
    },

    unsetImpersonation(state: UserState) {
      state.user = _.cloneDeep(state.impersonator)
      localStorage.user = JSON.stringify(state.user)
      state.impersonator = null
      localStorage.removeItem('impersonator')
    },
  },

  namespaced: true as const,

  state: resetState(),
})

export default user
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userGetterContext = (args: [any, any, any, any]) => localGetterContext(args, user)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userActionContext = (context: any) => localActionContext(context, user)
