<script setup lang="ts">
import _ from 'lodash'
import { computed, nextTick, onMounted, reactive, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import { StyledRecordings } from '@/composables/styledRecording'
import store from '@/store'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { SentenceRecordingMetadataWithPitchedAudios } from '@/util/AudioUtil'
import { VoiceStyles, VoiceStyleSet } from '@/util/VoiceStyles'
import { READ, SING, TEXT } from '@/util/VoiceStyles'

interface Props {
  input?: () => void
  label?: string
  recordings?: StyledRecordings | null
  annotatedSentences?: Array<AnnotatedSentence> | null
  voiceStyleSet: VoiceStyleSet | null
  voice?: string
}

const logger = useLogger()

const props = withDefaults(defineProps<Props>(), {
  annotatedSentences: null,
  input: () => {},
  label: 'Practice',
  recordings: null,
  voice: '',
})
const emit = defineEmits(['update:voice'])

const state = reactive({
  VoiceStyles,
  selectedVoice: props.voice,
})

// computes the number of read recordings vs the number of sung recordings
const voiceStyleStats = computed<Record<string, number>>((): Record<string, number> => {
  if (_.isEmpty(props.annotatedSentences)) {
    return {}
  }

  const map: Record<string, number> = {}

  // count the number of read recordings and sung recordings for this sentence
  _.forEach(props.annotatedSentences, (annotatedSentence: AnnotatedSentence) => {
    const sentence = annotatedSentence.sentence()
    if (store.getters.audio.recordings[sentence.id] === undefined) {
      return
    }

    const recs: Record<string, SentenceRecordingMetadataWithPitchedAudios> = store.getters.audio.recordings[sentence.id]
    _.forEach(Object.values(recs), (srmdwpa: SentenceRecordingMetadataWithPitchedAudios) => {
      if (!srmdwpa.sentenceRecording) {
        logger.error('unexpected null srmdwpa.sentenceRecording')
        return
      }

      const { haftarahMelody, sentenceGroupMelody, torahMelody } = srmdwpa.sentenceRecording
      if (torahMelody || haftarahMelody || sentenceGroupMelody) {
        if (map[VoiceStyles[SING]] === undefined) {
          map[VoiceStyles[SING]] = 0
        }
        ;(map[VoiceStyles[SING]] as number) += 1
      } else {
        if (map[VoiceStyles[READ]] === undefined) {
          map[VoiceStyles[READ]] = 0
        }
        ;(map[VoiceStyles[READ]] as number) += 1
      }
    })
  })

  // Text should always be there
  map[VoiceStyles[TEXT]] = 0
  return map
})

onMounted(() => {
  watchEffect(() => {
    if (Object.keys(voiceStyleStats.value).length === 1) {
      logger.debug('voiceStyleStats 1')
      nextTick(() => {
        state.selectedVoice = Object.keys(voiceStyleStats.value)[0]
      })
    }
  })

  watchEffect(() => {
    state.selectedVoice = props.voice
  })

  watchEffect(() => {
    emit('update:voice', state.selectedVoice)
  })
})
</script>

<template>
  <v-radio-group v-if="!_.isEmpty(voiceStyleStats)" v-model="state.selectedVoice" @update:model-value="input">
    <template #label>
      <span class="text-caption"> Voice </span>
    </template>
    <v-radio
      v-for="voiceStyle in Object.keys(voiceStyleStats)"
      :key="voiceStyle"
      :label="`${voiceStyle} (${voiceStyleStats[voiceStyle] === 0 ? 'no' : voiceStyleStats[voiceStyle]} recording${voiceStyleStats[voiceStyle] === 1 ? '' : 's'})`"
      :value="voiceStyle"
    />
  </v-radio-group>
</template>
