<script setup lang="ts">
import _ from 'lodash'
import { SentenceGroupType } from 'types/types'
import { computed, onMounted, reactive, ref, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import store from '@/store'

const innerSentenceGroupSelect = ref(null)
const logger = useLogger()

interface Props {
  clearable?: boolean
  disabled?: boolean
  externalSet?: boolean
  only?: Array<SentenceGroupType> | null
  preventAutoSingleSelect?: boolean
  remove?: Array<SentenceGroupType>
  resetSelectionOnMultiple?: boolean
  sentenceGroup: SentenceGroupType | null
}

const props = withDefaults(defineProps<Props>(), {
  clearable: true,
  disabled: false,
  externalSet: false,
  only: null,
  preventAutoSingleSelect: true,
  remove: (): Array<SentenceGroupType> => [],
  resetSelectionOnMultiple: false,
})
const emit = defineEmits(['update:sentenceGroup'])

type StateType = {
  selectedSentenceGroup: SentenceGroupType | null
}

const state = reactive<StateType>({
  selectedSentenceGroup: null,
})

onMounted(async () => {
  store.dispatch.teacher.fetchSentenceGroups({ mine: false })

  watchEffect(() => {
    if (props.externalSet) {
      state.selectedSentenceGroup = props.sentenceGroup
    }
  })

  watchEffect(() => {
    if (filteredSentenceGroups.value.length === 1) {
      if (!props.preventAutoSingleSelect) {
        state.selectedSentenceGroup = filteredSentenceGroups.value[0]
      }
    } else if (props.resetSelectionOnMultiple) {
      state.selectedSentenceGroup = null
    }
  })

  watchEffect(() => {
    if (state.selectedSentenceGroup) {
      store.dispatch.student.fetchSentenceGroupSentences({
        sentenceGroup: state.selectedSentenceGroup,
      })
    }
    emit('update:sentenceGroup', state.selectedSentenceGroup)
  })
})

const filteredSentenceGroups = computed<Array<SentenceGroupType>>(() => {
  // use a shadow copy of this.sentenceGroups
  const sentenceGroups = props.only ?? store.getters.teacher.sentenceGroups
  logger.debug('filteredSentenceGroups, props.only =')
  logger.debug(props.only)
  logger.debug('filteredSentenceGroups, sentenceGroups =')
  logger.debug(sentenceGroups)

  // remove all elements from sentenceGroups that are also in this.remove
  return _.sortBy(
    _.filter(sentenceGroups, (s: SentenceGroupType) => {
      return !_.find(props.remove, (r: SentenceGroupType) => {
        return s.reading.id === r.reading.id
      })
    }),
    ['reading.description'],
  )
})

function focus() {
  innerSentenceGroupSelect.value.focus()
}

function clear() {
  state.selectedSentenceGroup = null
}

defineExpose({ clear, focus })
</script>

<template>
  <v-autocomplete
    ref="innerSentenceGroupSelect"
    v-model="state.selectedSentenceGroup"
    :clearable="props.clearable"
    :disabled="props.disabled"
    :item-title="
      (sentenceGroup: SentenceGroupType) => {
        return sentenceGroup.reading.description
      }
    "
    :items="filteredSentenceGroups"
    :label="state.selectedSentenceGroup ? 'B\'racha / Song' : 'Choose a B\'rachah / Song'"
    :loading="!filteredSentenceGroups || !filteredSentenceGroups.length"
    return-object
    variant="underlined"
  >
  </v-autocomplete>
</template>
