<script setup lang="ts">
import _ from 'lodash'
import { AliyahType, BarMitzvahType, HaftarahType, ParashahType, SentenceGroupType, SentenceType } from 'types/types'
import { computed, onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import { formattedAliyahForStudent, formattedHaftarahForStudent } from '@/composables/format'
import store from '@/store'

const logger = useLogger()
const ALIYOT_LABEL = 'Aliyot'
const HAFTAROT_LABEL = 'Haftarot'
const SENTENCE_GROUPS_LABEL = "B'rachot / Songs"

export interface PracticeListItem {
  title: string | null
  props: object
  aliyah: AliyahType | null
  haftarah: HaftarahType | null
  sentenceGroup: SentenceGroupType | null
}

function createBlankPracticeListItem(): PracticeListItem {
  return {
    aliyah: null,
    haftarah: null,
    props: {},
    sentenceGroup: null,
    title: null,
  }
}

interface Props {
  aliyah?: AliyahType | null
  barMitzvah: BarMitzvahType
  haftarah?: HaftarahType | null
  sentenceGroup?: SentenceGroupType | null
  sentences?: Array<SentenceType> | null
}

const props = withDefaults(defineProps<Props>(), {
  aliyah: null,
  haftarah: null,
  sentenceGroup: null,
  sentences: null,
})

interface PracticeSelect {
  parshiyot: Array<ParashahType> | null // null will show all parshiyot in the dropdown; [] will show none
  aliyot: Array<AliyahType> | null // null will show all aliyot in the dropdown; [] will show none
  haftarot: Array<HaftarahType> | null // null will show all haftarot in the dropdown; [] will show none
  sentenceGroups: Array<SentenceGroupType> | null // null will show all sentence groups in the dropdown; [] will show none

  // these are all internally watched and cause an update to the associated
  // props so that parent is notified of choices made.
  selectedBarMitzvah: BarMitzvahType | null
  selectedAliyah: AliyahType | null
  selectedHaftarah: HaftarahType | null
  selectedSentenceGroup: SentenceGroupType | null

  selectedPracticeListItem: PracticeListItem | null
  mySentences: Array<SentenceType> | null // managed by watchers for aliyahSentences, haftarahSentences, sentenceGroupSentences
}

const state = reactive<PracticeSelect>({
  aliyot: null,
  haftarot: null,
  mySentences: null,
  parshiyot: null,

  selectedAliyah: props.aliyah,
  selectedBarMitzvah: props.barMitzvah,
  selectedHaftarah: props.haftarah,
  selectedPracticeListItem: null,

  selectedSentenceGroup: props.sentenceGroup,

  sentenceGroups: null,
})

const aliyahSentences = computed<Array<SentenceType>>(() => {
  if (!state.selectedAliyah) {
    return []
  }
  return _.sortBy(store.getters.student.aliyahSentences[parseInt(state.selectedAliyah.id, 10)], [
    (sentence: SentenceType) => parseInt(sentence.id, 10),
  ])
})

const formattedAliyot = computed<Array<PracticeListItem>>((): Array<PracticeListItem> => {
  // use a shadow copy of this.aliyot
  const { aliyot } = props.barMitzvah

  if (_.isEmpty(aliyot)) {
    return []
  }

  // create a .label for each aliyah
  const labeledAliyot = _.map(aliyot, (aliyah: AliyahType) => {
    return _.extend({}, createBlankPracticeListItem(), {
      aliyah,
      title: formattedAliyahForStudent(aliyah),
    }) as PracticeListItem
  })

  return _.sortBy(labeledAliyot, ['parashah.transliteratedTitle', 'aliyah.rank'])
})

const formattedHaftarot = computed<Array<PracticeListItem>>((): Array<PracticeListItem> => {
  // use a shadow copy of this.aliyot
  const { haftarah } = props.barMitzvah
  const haftarot = haftarah ? [haftarah] : []

  if (_.isEmpty(haftarot)) {
    return []
  }

  // create a .label for each haftarah
  const labeledHaftarot = _.map(haftarot, (xHaftarah: HaftarahType) => {
    return _.extend({}, createBlankPracticeListItem(), {
      haftarah: xHaftarah,
      title: formattedHaftarahForStudent(xHaftarah),
    }) as PracticeListItem
  })

  return _.sortBy(labeledHaftarot, ['haftarah.rank'])
})

const formattedSentenceGroups = computed<Array<PracticeListItem>>((): Array<PracticeListItem> => {
  // use a shadow copy of this.sentenceGroups
  const { sentenceGroups } = props.barMitzvah

  if (_.isEmpty(sentenceGroups)) {
    return []
  }

  // create a .label for each sentenceGroup
  const labeledSentenceGroups = _.map(sentenceGroups, (sentenceGroup: SentenceGroupType) => {
    return _.extend({}, createBlankPracticeListItem(), {
      sentenceGroup,
      title: sentenceGroup.reading.description,
    })
  })

  return _.sortBy(labeledSentenceGroups, ['title'])
})

// concatenate aliyot, haftarah and sentence groups into a single array for the dropdown
const combinedListItems = computed<Array<PracticeListItem>>((): Array<PracticeListItem> => {
  const list = [] as Array<PracticeListItem>

  if (formattedAliyot.value.length) {
    list.push({ aliyah: null, haftarah: null, props: { disabled: true }, sentenceGroup: null, title: ALIYOT_LABEL })
    list.push(...formattedAliyot.value)
  }
  if (formattedHaftarot.value.length) {
    list.push({ aliyah: null, haftarah: null, props: { disabled: true }, sentenceGroup: null, title: HAFTAROT_LABEL })
    list.push(...formattedHaftarot.value)
  }
  if (formattedSentenceGroups.value.length) {
    list.push({ aliyah: null, haftarah: null, props: { disabled: true }, sentenceGroup: null, title: SENTENCE_GROUPS_LABEL })
    list.push(...formattedSentenceGroups.value)
  }

  return list
})

const haftarahSentences = computed(() => {
  if (!state.selectedHaftarah) {
    return []
  }
  return _.sortBy(store.getters.student.haftarahSentences[parseInt(state.selectedHaftarah.reading.id, 10)], [
    (sentence: SentenceType) => parseInt(sentence.id, 10),
  ])
})

const sentenceGroupSentences = computed(() => {
  if (!state.selectedSentenceGroup) {
    return []
  }
  return _.sortBy(store.getters.student.sentenceGroupSentences[parseInt(state.selectedSentenceGroup.reading.id, 10)], [
    (sentence: SentenceType) => parseInt(sentence.id, 10),
  ])
})

const emit = defineEmits(['update:sentences', 'update:aliyah', 'update:haftarah', 'update:sentenceGroup'])

onMounted(() => {
  state.selectedBarMitzvah = props.barMitzvah

  watch(
    () => props.aliyah,
    () => {
      logger.debug('PracticeSelect, watch props.aliyah =')
      logger.debug(props.aliyah)
      if (props.aliyah) {
        state.selectedPracticeListItem =
          _.find(combinedListItems.value, (combinedListItem: PracticeListItem): boolean => {
            return !!combinedListItem.aliyah && !!props.aliyah && combinedListItem.aliyah.id === props.aliyah.id
          }) || null
      }
    },
  )

  watch(
    () => props.haftarah,
    () => {
      logger.debug('PracticeSelect, watch props.haftarah =')
      logger.debug(props.haftarah)
      if (props.haftarah) {
        state.selectedPracticeListItem =
          _.find(combinedListItems.value, (combinedListItem: PracticeListItem) => {
            return !!combinedListItem.haftarah && !!props.haftarah && combinedListItem.haftarah.reading.id === props.haftarah?.reading.id
          }) || null
      }
    },
  )

  watch(
    () => props.sentenceGroup,
    () => {
      logger.debug('PracticeSelect, watch props.sentenceGroup =')
      logger.debug(props.sentenceGroup)
      if (props.sentenceGroup) {
        state.selectedPracticeListItem =
          _.find(combinedListItems.value, (combinedListItem: PracticeListItem) => {
            return (
              !!combinedListItem.sentenceGroup &&
              !!props.sentenceGroup &&
              combinedListItem.sentenceGroup.reading.id === props.sentenceGroup?.reading.id
            )
          }) || null
      }
    },
  )

  watch(
    () => state.selectedAliyah,
    () => {
      logger.debug('PracticeSelect, watch state.selectedAliyah =')
      logger.debug(state.selectedAliyah)
      if (state.selectedAliyah) {
        store.dispatch.student.fetchAliyahSentences({
          aliyah: state.selectedAliyah,
        })
      }
      emit('update:aliyah', state.selectedAliyah)
    },
  )

  watch(
    () => state.selectedPracticeListItem,
    () => {
      logger.debug('state.selectedPracticeListItem =')
      logger.debug(state.selectedPracticeListItem)
      if (state.selectedPracticeListItem) {
        state.selectedAliyah = state.selectedPracticeListItem.aliyah
        state.selectedHaftarah = state.selectedPracticeListItem.haftarah
        state.selectedSentenceGroup = state.selectedPracticeListItem.sentenceGroup
      } else {
        state.selectedAliyah = null
        state.selectedHaftarah = null
        state.selectedSentenceGroup = null
        state.mySentences = null
      }
    },
    { immediate: true },
  )

  watch(aliyahSentences, () => {
    if (!_.isEmpty(aliyahSentences)) {
      state.mySentences = aliyahSentences.value
    }
  })

  watch(
    () => state.selectedHaftarah,
    () => {
      logger.debug('PracticeSelect, watch state.selectedHaftarah =')
      logger.debug(state.selectedHaftarah)
      if (state.selectedHaftarah) {
        store.dispatch.student.fetchHaftarahSentences({
          haftarah: state.selectedHaftarah,
        })
      }
      emit('update:haftarah', state.selectedHaftarah)
    },
  )

  watch(haftarahSentences, () => {
    if (!_.isEmpty(haftarahSentences.value)) {
      state.mySentences = haftarahSentences.value
    }
  })

  watch(
    () => state.selectedSentenceGroup,
    () => {
      logger.debug('PracticeSelect, watch state.selectedSentenceGroup =')
      logger.debug(state.selectedSentenceGroup)
      if (state.selectedSentenceGroup) {
        store.dispatch.student.fetchSentenceGroupSentences({
          sentenceGroup: state.selectedSentenceGroup,
        })
      }
      emit('update:sentenceGroup', state.selectedSentenceGroup)
    },
  )

  watch(
    () => state.mySentences,
    () => {
      if (!state.mySentences || !state.selectedPracticeListItem) {
        emit('update:sentences', [])
        return
      }

      emit('update:sentences', state.mySentences)
    },
  )

  watch(sentenceGroupSentences, () => {
    if (!_.isEmpty(sentenceGroupSentences.value)) {
      state.mySentences = sentenceGroupSentences.value
    }
  })
})
</script>

<template>
  <v-select
    v-model="state.selectedPracticeListItem"
    clearable
    :items="combinedListItems"
    :label="state.selectedPracticeListItem ? 'Text' : 'Choose a text'"
    :loading="!combinedListItems"
    open-on-clear
    return-object
    variant="underlined"
  />
</template>
