import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import _ from 'lodash'
import { VueLogger } from 'vue-logger-plugin'

import { CreateAliyahParameters } from '@/util/Aliyah'
import { CreateHaftarahParameters } from '@/util/Haftarah'

import { AliyahType, BarMitzvahType, HaftarahType, ParashahType, SectionType, SentenceGroupType, SentenceType } from '../../types/types'
import AddAliyahToBarMitzvah from '../mutations/AddAliyahToBarMitzvah.graphql'
import AddOrUpdateBarMitzvah from '../mutations/AddOrUpdateBarMitzvah.graphql'
import AddSentenceGroupToBarMitzvah from '../mutations/AddSentenceGroupToBarMitzvah.graphql'
import CreateOrUpdateAliyah from '../mutations/CreateOrUpdateAliyah.graphql'
import CreateOrUpdateHaftarah from '../mutations/CreateOrUpdateHaftarah.graphql'
import CreateSentenceGroup from '../mutations/CreateSentenceGroup.graphql'
import DeleteAliyah from '../mutations/DeleteAliyah.graphql'
import DeleteBarMitzvah from '../mutations/DeleteBarMitzvah.graphql'
import DeleteHaftarah from '../mutations/DeleteHaftarah.graphql'
import DeleteSentenceGroup from '../mutations/DeleteSentenceGroup.graphql'
import RemoveAliyahFromBarMitzvah from '../mutations/RemoveAliyahFromBarMitzvah.graphql'
import RemoveSentenceGroupFromBarMitzvah from '../mutations/RemoveSentenceGroupFromBarMitzvah.graphql'
import SetHaftarahToBarMitzvah from '../mutations/SetHaftarahToBarMitzvah.graphql'
import UnsetHaftarahFromBarMitzvah from '../mutations/UnsetHaftarahFromBarMitzvah.graphql'
import AliyotDetailedQuery from '../queries/AliyotDetailedQuery.graphql'
import BarMitzvahQuery from '../queries/BarMitzvahQuery.graphql'
import HaftarotQuery from '../queries/HaftarotQuery.graphql'
import MyBarMitzvotQuery from '../queries/MyBarMitzvotQuery.graphql'
import MyLightBarMitzvotQuery from '../queries/MyLightBarMitzvotQuery.graphql'
import SectionsQuery from '../queries/SectionsQuery.graphql'
import SentenceGroupSentencesQuery from '../queries/SentenceGroupSentencesQuery.graphql'
import SentenceGroupsQuery from '../queries/SentenceGroupsQuery.graphql'
import { useApollo } from '../util/apolloClient'
const { apolloClient } = useApollo()

export interface TeacherState {
  barMitzvot: Array<BarMitzvahType>
  lightBarMitzvot: Array<BarMitzvahType>
  sentenceGroups: Array<SentenceGroupType>
  aliyot: Array<AliyahType>
  haftarot: Array<HaftarahType>
  sections: Array<SectionType>
  error: string
  updating: boolean
  loading: boolean
  logger: VueLogger | null
}

const resetState = (): TeacherState => {
  return {
    aliyot: [],
    barMitzvot: [],
    error: '',
    haftarot: [],
    lightBarMitzvot: [],
    loading: false,
    logger: null,
    sections: [],
    sentenceGroups: [],
    updating: false,
  }
}

const teacher = defineModule({
  actions: {
    async addAliyahToBarMitzvah(context, payload: { barMitzvah: BarMitzvahType; aliyah: AliyahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: AddAliyahToBarMitzvah,
        variables: {
          aliyah: payload.aliyah.id,
          barMitzvah: payload.barMitzvah.student.username,
        },
      })

      p.then((response) => {
        const { aliyah, errors, ok } = response.data.addAliyahToBarMitzvah
        if (ok) {
          commit.addAliyahToBarMitzvah({
            aliyah,
            barMitzvah: payload.barMitzvah,
          })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async addOrUpdateBarMitzvah(
      context,
      payload: {
        user: {
          email: string
          firstName: string
          lastName: string
          username: string
        }
        date: string
        hidden: boolean
        torahMelodyID: number
        haftarahMelodyID: number
        sentenceGroupMelodyID: number
        pronunciationID: number
        update: boolean
      },
    ): Promise<boolean> {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      return new Promise<boolean>((resolve) => {
        const p = apolloClient.mutate({
          mutation: AddOrUpdateBarMitzvah,
          variables: {
            date: payload.date,
            haftarahMelody: payload.haftarahMelodyID,
            hidden: payload.hidden,
            pronunciation: payload.pronunciationID,
            sentenceGroupMelody: payload.sentenceGroupMelodyID,
            torahMelody: payload.torahMelodyID,
            update: payload.update,
            userData: {
              email: payload.user.email.toLowerCase(),
              firstName: payload.user.firstName,
              lastName: payload.user.lastName,
              username: payload.user.username.toLowerCase(),
            },
          },
        })

        p.then((response) => {
          const { barMitzvah, errors, ok } = response.data.addOrUpdateBarMitzvah
          if (ok) {
            commit.addOrUpdateBarMitzvah(barMitzvah)
          }
          console.log(errors)
          commit.setError(errors)
          resolve(ok)
        })
          .catch((error) => {
            commit.setError(error.message)
            resolve(false)
          })
          .finally(() => {
            commit.setUpdating(false)
          })
      })
    },

    async addSentenceGroupToBarMitzvah(context, payload: { barMitzvah: BarMitzvahType; sentenceGroup: SentenceGroupType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: AddSentenceGroupToBarMitzvah,
        variables: {
          barMitzvah: payload.barMitzvah.student.username,
          sentenceGroup: payload.sentenceGroup.reading.id,
        },
      })

      p.then((response) => {
        const { errors, ok, sentenceGroup } = response.data.addSentenceGroupToBarMitzvah
        if (ok) {
          commit.addSentenceGroupToBarMitzvah({
            barMitzvah: payload.barMitzvah,
            sentenceGroup,
          })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async createAliyah(context, payload: CreateAliyahParameters) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: CreateOrUpdateAliyah,
        variables: {
          ...payload,
          parashah: payload.parashah.reading.id,
          username: payload.username,
        },
      })

      p.then((response) => {
        const { aliyah, errors, ok } = response.data.createAliyah
        if (ok) {
          commit.addAliyah({ aliyah })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async createHaftarah(context, payload: CreateHaftarahParameters) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const pload = { ...payload }
      pload.variant = pload.variant || ''
      const p = apolloClient.mutate({
        mutation: CreateOrUpdateHaftarah,
        variables: { ...pload, parashah: pload.parashah.reading.id },
      })

      p.then((response) => {
        const { errors, haftarah, ok } = response.data.createHaftarah
        if (ok) {
          commit.addHaftarah({ haftarah })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async createSentenceGroup(
      context,
      payload: {
        id: number
        description: string
        username: string
        text: Array<string>
      },
    ) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: CreateSentenceGroup,
        variables: {
          description: payload.description,
          id: payload.id || '-1',
          text: payload.text,
          username: payload.username,
        },
      })

      p.then((response) => {
        const { errors, ok, sentenceGroup } = response.data.createSentenceGroup
        if (ok) {
          commit.addSentenceGroup({ sentenceGroup })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async deleteAliyah(context, payload: { aliyah: AliyahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: DeleteAliyah,
        variables: {
          id: payload.aliyah.id,
        },
      })

      p.then((response) => {
        const { errors, ok } = response.data.deleteAliyah
        if (ok) {
          commit.deleteAliyah({ aliyah: payload.aliyah })
        }
        commit.setError(errors || null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async deleteAliyahFromBarMitzvah(context, payload: { barMitzvah: BarMitzvahType; aliyah: AliyahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: RemoveAliyahFromBarMitzvah,
        variables: {
          aliyah: payload.aliyah.id,
          barMitzvah: payload.barMitzvah.student.username,
        },
      })

      p.then((response) => {
        const { errors, ok } = response.data.removeAliyahFromBarMitzvah
        if (ok) {
          commit.deleteAliyahFromBarMitzvah({
            aliyah: payload.aliyah,
            barMitzvah: payload.barMitzvah,
          })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async deleteBarMitzvah(context, payload: { barMitzvah: BarMitzvahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const response = await apolloClient.mutate({
        mutation: DeleteBarMitzvah,
        variables: {
          username: payload.barMitzvah.student.username,
        },
      })
      const { errors, ok } = response.data.deleteBarMitzvah
      if (ok) {
        commit.deleteBarMitzvah(payload.barMitzvah)
      }
      commit.setError(ok ? null : errors)
      commit.setUpdating(false)
    },

    async deleteHaftarah(context, payload: { haftarah: HaftarahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: DeleteHaftarah,
        variables: {
          id: payload.haftarah.reading.id,
        },
      })

      p.then((response) => {
        const { errors, ok } = response.data.deleteHaftarah
        if (ok) {
          commit.deleteHaftarah({ haftarah: payload.haftarah })
        }
        commit.setError(errors || null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async deleteSentenceGroup(context, payload: { sentenceGroup: SentenceGroupType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: DeleteSentenceGroup,
        variables: {
          id: payload.sentenceGroup.reading.id,
        },
      })

      p.then((response) => {
        const { errors, ok } = response.data.deleteSentenceGroup
        if (ok) {
          commit.deleteSentenceGroup({ sentenceGroup: payload.sentenceGroup })
        }
        commit.setError(errors || null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async deleteSentenceGroupFromBarMitzvah(context, payload: { barMitzvah: BarMitzvahType; sentenceGroup: SentenceGroupType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: RemoveSentenceGroupFromBarMitzvah,
        variables: {
          barMitzvah: payload.barMitzvah.student.username,
          sentenceGroup: payload.sentenceGroup.reading.id,
        },
      })

      p.then((response) => {
        const { errors, ok } = response.data.removeSentenceGroupFromBarMitzvah
        if (ok) {
          commit.deleteSentenceGroupFromBarMitzvah({
            barMitzvah: payload.barMitzvah,
            sentenceGroup: payload.sentenceGroup,
          })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    async fetchAliyot(context, payload: { parashah: ParashahType | null; mine: boolean }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: AliyotDetailedQuery,
        variables: {
          mine: payload.mine,
          parashah: payload.parashah ? payload.parashah.reading.id : -1,
        },
      })
      commit.setAliyot(_.cloneDeep(response.data.aliyot))
      commit.setLoading(false)
    },

    async fetchBarMitzvah(context, payload: { id: number }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: BarMitzvahQuery,
        variables: {
          id: payload.id,
        },
      })
      commit.mergeBarMitzvah(response.data.barMitzvah)
      commit.setLoading(false)
    },

    async fetchBarMitzvot(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: MyBarMitzvotQuery,
      })
      commit.setBarMitzvot(response.data.myBarMitzvot)
      commit.setLoading(false)
    },

    async fetchHaftarot(context, payload: { parashah: ParashahType | null; mine: boolean }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: HaftarotQuery,
        variables: {
          mine: payload.mine,
          parashah: payload.parashah ? payload.parashah.reading.id : -1,
        },
      })
      commit.setHaftarot(response.data.haftarot)
      commit.setLoading(false)
    },

    async fetchLightBarMitzvot(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: MyLightBarMitzvotQuery,
      })
      commit.setLightBarMitzvot(response.data.myBarMitzvot)
      commit.setLoading(false)
    },

    async fetchMySentenceGroupSentences(context, payload: { sentenceGroup: SentenceGroupType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        query: SentenceGroupSentencesQuery,
        variables: {
          sentenceGroup: payload.sentenceGroup.reading.id,
        },
      })
      commit.setSentenceGroupSentences({
        sentenceGroup: payload.sentenceGroup,
        sentences: response.data.sentenceGroupSentences.sentences,
      })
      commit.setLoading(false)
    },

    async fetchSections(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        query: SectionsQuery,
      })
      commit.setSections(response.data.sections)
      commit.setLoading(false)
    },

    async fetchSentenceGroups(context, payload = { mine: false }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setLoading(true)
      const response = await apolloClient.query({
        query: SentenceGroupsQuery,
        variables: {
          mine: payload.mine,
        },
      })
      commit.setSentenceGroups(response.data.sentenceGroups)
      commit.setLoading(false)
    },

    reset(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.reset()
    },

    resetError(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)
      commit.setError('')
    },

    async setHaftarahToBarMitzvah(context, payload: { barMitzvah: BarMitzvahType; haftarah: HaftarahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: SetHaftarahToBarMitzvah,
        variables: {
          barMitzvah: payload.barMitzvah.student.username,
          haftarah: payload.haftarah.reading.id,
        },
      })

      p.then((response) => {
        const { errors, haftarah, ok } = response.data.setHaftarahToBarMitzvah
        if (ok) {
          commit.setHaftarahToBarMitzvah({
            barMitzvah: payload.barMitzvah,
            haftarah,
          })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },

    setLogger(context, logger: VueLogger) {
      const { commit } = teacherActionContext(context)
      commit.setLogger(logger)
    },

    async unsetHaftarahFromBarMitzvah(context, payload: { barMitzvah: BarMitzvahType }) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = teacherActionContext(context)

      commit.setUpdating(true)
      const p = apolloClient.mutate({
        mutation: UnsetHaftarahFromBarMitzvah,
        variables: {
          barMitzvah: payload.barMitzvah.student.username,
        },
      })

      p.then((response) => {
        const { errors, ok } = response.data.unsetHaftarahFromBarMitzvah
        if (ok) {
          commit.unsetHaftarahFromBarMitzvah({
            barMitzvah: payload.barMitzvah,
          })
        }
        commit.setError(errors ? errors[0].message : null)
      })
        .catch((error) => {
          console.log(error)
          commit.setError(error.message)
        })
        .finally(() => {
          commit.setUpdating(false)
        })
    },
  },

  getters: {
    aliyot: (...args): Array<AliyahType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return state.aliyot
    },

    barMitzvot: (...args): Array<BarMitzvahType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return _.sortBy(state.barMitzvot, ['student.firstName'])
    },

    error(...args): string {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return state.error
    },

    haftarot: (...args): Array<HaftarahType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return state.haftarot
    },

    lightBarMitzvot: (...args): Array<BarMitzvahType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return state.lightBarMitzvot
    },

    loading(...args): boolean {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return state.loading
    },

    sections: (...args): Array<SectionType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return state.sections
    },

    sentenceGroups: (...args): Array<SentenceGroupType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return _.sortBy(state.sentenceGroups, ['reading.description'])
    },

    updating(...args): boolean {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      return state.updating
    },

    visibleBarMitzvot: (...args): Array<BarMitzvahType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      if (!state.barMitzvot) {
        return []
      }
      return _.sortBy(
        state.barMitzvot.filter((b: BarMitzvahType) => !b.hidden),
        ['student.lastName'],
      )
    },

    visibleLightBarMitzvot: (...args): Array<BarMitzvahType> => {
      // eslint-disable-next-line no-use-before-define
      const { state } = teacherGetterContext(args)
      if (!state.lightBarMitzvot) {
        return []
      }
      return _.sortBy(
        state.lightBarMitzvot.filter((b: BarMitzvahType) => !b.hidden),
        ['student.lastName'],
      )
    },
  },

  mutations: {
    addAliyah(state: TeacherState, payload: { aliyah: AliyahType }) {
      const aIndex = _.findIndex(state.aliyot, (a: AliyahType) => a.id === payload.aliyah.id)
      if (aIndex >= 0) {
        state.aliyot[aIndex] = _.cloneDeep(payload.aliyah)
      } else {
        state.aliyot.push(_.cloneDeep(payload.aliyah))
      }
    },

    addAliyahToBarMitzvah(state: TeacherState, payload: { barMitzvah: BarMitzvahType; aliyah: AliyahType }) {
      const bm = _.find(state.barMitzvot, (b: BarMitzvahType) => b.student.username === payload.barMitzvah.student.username)
      if (!bm) {
        console.log('unexpected !bm in addAliyahToBarMitzvah')
        return
      }
      bm.aliyot.push(_.cloneDeep(payload.aliyah))
    },

    addHaftarah(state: TeacherState, payload: { haftarah: HaftarahType }) {
      const hIndex = _.findIndex(state.haftarot, (h: HaftarahType) => h.reading.id === payload.haftarah.reading.id)
      if (hIndex >= 0) {
        state.haftarot[hIndex] = _.cloneDeep(payload.haftarah)
      } else {
        state.haftarot.push(_.cloneDeep(payload.haftarah))
      }
    },

    addOrUpdateBarMitzvah(state: TeacherState, barMitzvah: BarMitzvahType) {
      const i = _.findIndex(state.barMitzvot, (bm: BarMitzvahType) => {
        return bm.student.username === barMitzvah.student.username
      })

      if (i < 0) {
        state.barMitzvot.push(_.cloneDeep(barMitzvah))
      } else {
        state.barMitzvot[i] = _.cloneDeep(barMitzvah)
      }

      const j = _.findIndex(state.lightBarMitzvot, (bm: BarMitzvahType) => {
        return bm.student.username === barMitzvah.student.username
      })
      if (j < 0) {
        state.lightBarMitzvot.push(_.cloneDeep(barMitzvah))
      } else {
        state.lightBarMitzvot[j] = _.cloneDeep(barMitzvah)
      }
    },

    addSentenceGroup(state: TeacherState, payload: { sentenceGroup: SentenceGroupType }) {
      const sgIndex = _.findIndex(state.sentenceGroups, (s: SentenceGroupType) => s.reading.id === payload.sentenceGroup.reading.id)
      console.log(`addSentenceGroup, sgIndex = ${sgIndex}, payload.sentenceGroup =`)
      console.log(payload.sentenceGroup)
      console.log(`addSentenceGroup, sgIndex = ${sgIndex}, state.sentenceGroup =`)
      console.log(state.sentenceGroups)
      if (sgIndex >= 0) {
        state.sentenceGroups[sgIndex] = _.cloneDeep(payload.sentenceGroup)
      } else {
        state.sentenceGroups.push(_.cloneDeep(payload.sentenceGroup))
      }
      console.log(`addSentenceGroup done`)
    },

    addSentenceGroupToBarMitzvah(state: TeacherState, payload: { barMitzvah: BarMitzvahType; sentenceGroup: SentenceGroupType }) {
      const bm = _.find(state.barMitzvot, (b: BarMitzvahType) => b.student.username === payload.barMitzvah.student.username)
      if (bm) {
        bm.sentenceGroups.push(_.cloneDeep(payload.sentenceGroup))
      } else {
        console.log('unexpected !bm in addSentenceGroupToBarMitzvah')
      }
    },

    deleteAliyah(state: TeacherState, payload: { aliyah: AliyahType }) {
      const i = _.findIndex(state.aliyot, (a: AliyahType) => a.id === payload.aliyah.id)
      state.aliyot.splice(i, 1)
    },

    deleteAliyahFromBarMitzvah(state: TeacherState, payload: { barMitzvah: BarMitzvahType; aliyah: AliyahType }) {
      const bm = _.find(state.barMitzvot, (b: BarMitzvahType) => b.student.username === payload.barMitzvah.student.username)

      if (!bm) {
        console.log('unexpected !bm in deleteAliyahFromBarMitzvah')
        return
      }

      const aIndex = _.findIndex(bm.aliyot, (a: AliyahType) => a.id === payload.aliyah.id)
      bm.aliyot.splice(aIndex, 1)
    },

    deleteBarMitzvah(state: TeacherState, barMitzvah: BarMitzvahType) {
      const i = _.findIndex(state.barMitzvot, (b: BarMitzvahType) => b.student.username === barMitzvah.student.username)
      state.barMitzvot.splice(i, 1)

      const j = _.findIndex(state.lightBarMitzvot, (b: BarMitzvahType) => b.student.username === barMitzvah.student.username)
      state.lightBarMitzvot.splice(j, 1)
    },

    deleteHaftarah(state: TeacherState, payload: { haftarah: HaftarahType }) {
      const i = _.findIndex(state.haftarot, (h: HaftarahType) => h.reading.id === payload.haftarah.reading.id)
      state.haftarot.splice(i, 1)
    },

    deleteSentenceGroup(state: TeacherState, payload: { sentenceGroup: SentenceGroupType }) {
      const i = _.findIndex(state.sentenceGroups, (s: SentenceGroupType) => s.reading.id === payload.sentenceGroup.reading.id)
      state.sentenceGroups.splice(i, 1)
    },

    deleteSentenceGroupFromBarMitzvah(state: TeacherState, payload: { barMitzvah: BarMitzvahType; sentenceGroup: SentenceGroupType }) {
      const bm = _.find(state.barMitzvot, (b: BarMitzvahType) => b.student.username === payload.barMitzvah.student.username)

      if (!bm) {
        console.log('unexpected !bm in deleteSentenceGroupFromBarMitzvah')
        return
      }

      const sgIndex = _.findIndex(bm.sentenceGroups, (sg: SentenceGroupType) => sg.reading.id === payload.sentenceGroup.reading.id)
      bm.sentenceGroups.splice(sgIndex, 1)
    },

    mergeBarMitzvah(state: TeacherState, barMitzvah: BarMitzvahType) {
      const bmIndex = _.findIndex(state.barMitzvot, (bm: BarMitzvahType) => bm.student.username === barMitzvah.student.username)
      if (bmIndex !== -1) {
        state.barMitzvot.splice(bmIndex, 1)
      }
      state.barMitzvot.push(_.cloneDeep(barMitzvah))

      const lbmIndex = _.findIndex(state.lightBarMitzvot, (bm: BarMitzvahType) => bm.student.username === barMitzvah.student.username)
      if (lbmIndex !== -1) {
        state.lightBarMitzvot.splice(lbmIndex, 1)
      }
      state.lightBarMitzvot.push(_.cloneDeep(barMitzvah))
    },

    reset(state: TeacherState) {
      Object.assign(state, { ...resetState() })
    },

    setAliyot(state: TeacherState, aliyot: Array<AliyahType>) {
      state.aliyot = _.cloneDeep(aliyot)
    },

    setBarMitzvot(state: TeacherState, barMitzvot: Array<BarMitzvahType>) {
      state.barMitzvot = _.cloneDeep(barMitzvot)
    },

    setError(state: TeacherState, error: string) {
      state.error = error
    },

    setHaftarahToBarMitzvah(state: TeacherState, payload: { barMitzvah: BarMitzvahType; haftarah: HaftarahType }) {
      const bm = _.find(state.barMitzvot, (b: BarMitzvahType) => b.student.username === payload.barMitzvah.student.username)
      if (!bm) {
        console.log('unexpected !bm in setHaftarahToBarMitzvah')
        return
      }

      bm.haftarah = _.cloneDeep(payload.haftarah)
    },

    setHaftarot(state: TeacherState, haftarot: Array<HaftarahType>) {
      state.haftarot = _.cloneDeep(haftarot)
    },

    setLightBarMitzvot(state: TeacherState, barMitzvot: Array<BarMitzvahType>) {
      state.lightBarMitzvot = _.cloneDeep(barMitzvot)
    },

    setLoading(state: TeacherState, loading: boolean) {
      state.logger?.debug && state.logger.debug(`store.teacher setting state.loading to ${loading}`)
      state.loading = loading
    },

    setLogger(state: TeacherState, logger: VueLogger) {
      state.logger = logger
    },

    setSections(state: TeacherState, sections: Array<SectionType>) {
      state.sections = _.cloneDeep(sections)
    },

    setSentenceGroupSentences(
      state: TeacherState,
      payload: {
        sentenceGroup: SentenceGroupType
        sentences: Array<SentenceType>
      },
    ) {
      const sg = _.find(state.sentenceGroups, (s: SentenceGroupType) => s.reading.id === payload.sentenceGroup.reading.id)

      if (!sg) {
        console.log('unexpected !sg in setSentenceGroupSentences')
        return
      }
      sg.sentences = _.cloneDeep(payload.sentences)
    },

    setSentenceGroups(state: TeacherState, sentenceGroups: Array<SentenceGroupType>) {
      state.sentenceGroups = _.cloneDeep(sentenceGroups)
    },

    setUpdating(state: TeacherState, updating: boolean) {
      state.logger?.debug && state.logger.debug(`store.teacher setting state.update to ${updating}`)
      state.updating = updating
    },

    unsetHaftarahFromBarMitzvah(state: TeacherState, payload: { barMitzvah: BarMitzvahType }) {
      const bm = _.find(state.barMitzvot, (b: BarMitzvahType) => b.student.username === payload.barMitzvah.student.username)
      if (!bm) {
        console.log('unexpected !bm in unsetHaftarahToBarMitzvah')
        return
      }
      bm.haftarah = null
    },
  },

  namespaced: true as const,
  state: resetState(),
})

export default teacher
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const teacherGetterContext = (args: [any, any, any, any]) => localGetterContext(args, teacher)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const teacherActionContext = (context: any) => localActionContext(context, teacher)
