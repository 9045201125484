<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRouter } from 'vue-router'

import AppBarAccountMenu from '@/components/AppBarAccountMenu.vue'
import GenderedLogo from '@/components/GenderedLogo.vue'
import { useUtil } from '@/composables/util'

const state = reactive({
  tab: null as string | null,
  tabs: [{ admin: false, label: 'Practice', path: '/practice' }],
})
const logger = useLogger()
const { loginCheck } = useUtil(logger)

onMounted(() => {
  const router = useRouter()
  loginCheck(router)
})

// NB: this is a hack to make vue-router and v-tabs work
// without the @click property on v-tab, the focus does not follow the tabs
function tabClick(clickedTab: string) {
  state.tab = clickedTab
}
</script>

<template>
  <v-app-bar app color="grey-lighten-3">
    <gendered-logo />
    <v-divider class="ma-0 mx-4 pa-0" vertical></v-divider>
    <v-tabs v-model="state.tab" align-with-title>
      <template v-for="tab of state.tabs" :key="tab.path">
        <v-tab exact exact-active-class="active" :to="{ path: tab.path }" @click="tabClick(tab.path)">{{ tab.label }}</v-tab>
      </template>
    </v-tabs>
    <v-spacer />
    <app-bar-account-menu />
  </v-app-bar>
</template>
