import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { VueLogger } from 'vue-logger-plugin'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

// similar to apollo-http-link's createHttpLink, but can handle file uploads
const useApollo = (logger: VueLogger | null = null) => {
  const uploadLink = createUploadLink({
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': cookies.get('csrftoken'),
    },
    uri: '/backend/graphql/',
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(
        ({ locations, message, path }) =>
          logger && logger.debug(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      )

    if (networkError) {
      logger && logger.debug(`[Network error]: ${networkError}`)
    }
  })

  const chainedLink = ApolloLink.from([errorLink, uploadLink])

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    connectToDevTools: true,
    link: chainedLink,
    // defaultOptions,
  })

  return {
    apolloClient,
    chainedLink,
    errorLink,
    uploadLink,
  }
}

export { useApollo }
