// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { DarkTheme as dark, LightTheme as light } from '@/plugins/theme'

import { createVuetify } from 'vuetify'

const vuetify = createVuetify({
  components: {
  },
  theme: {
    themes: {
      dark,
      light,
    },
  },
})

export { vuetify }
