import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import _ from 'lodash'
import { UserGrapheneType } from 'types/types'

import { useApollo } from '@/util/apolloClient'
import { BasicUserInfo } from '@/util/User'

import UsersQuery from '../queries/UsersQuery.graphql'
const { apolloClient } = useApollo()

export interface StaffState {
  usernames: Array<BasicUserInfo>
}

const resetState = (): StaffState => {
  return {
    usernames: new Array<BasicUserInfo>(),
  }
}

const staff = defineModule({
  actions: {
    fetchBasicUserInfo(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = staffActionContext(context)
      return new Promise<void>((resolve) => {
        const r = apolloClient.query({
          fetchPolicy: 'network-only',
          query: UsersQuery,
        })
        r.then((response) => {
          commit.setBasicUserInfo(response.data.users)
        })
        resolve()
      })
    },

    reset(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = staffActionContext(context)
      commit.reset()
    },
  },
  getters: {
    usernames(...args): Array<BasicUserInfo> {
      // eslint-disable-next-line no-use-before-define
      const { state } = staffGetterContext(args)
      return state.usernames
    },
  },

  mutations: {
    reset(state: StaffState) {
      Object.assign(state, { ...resetState() })
    },

    setBasicUserInfo(state: StaffState, usernames: Array<UserGrapheneType>) {
      state.usernames = usernames.map(({ email, firstName, lastName, username }) => ({ email, firstName, lastName, username }))
    },
  },

  namespaced: true as const,

  state: resetState(),
})

export default staff
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const staffGetterContext = (args: [any, any, any, any]) => localGetterContext(args, staff)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const staffActionContext = (context: any) => localActionContext(context, staff)
