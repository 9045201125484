<script setup lang="ts">
import { onMounted } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRouter } from 'vue-router'

import { useUtil } from '@/composables/util'
const logger = useLogger()

const { loginPublicUser } = useUtil(logger)

onMounted(() => {
  const router = useRouter()
  loginPublicUser(router)
})
</script>

<template>
  <div></div>
</template>
