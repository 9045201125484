<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import _ from 'lodash'
import {
  AliyahType,
  BarMitzvahType,
  HaftarahMelodyType,
  HaftarahType,
  ParashahType,
  PronunciationType,
  SentenceGroupMelodyType,
  SentenceGroupType,
  TorahMelodyType,
} from 'types/types'
import { useField, useForm } from 'vee-validate'
import { computed, onMounted, reactive, Ref, ref, watch, watchEffect } from 'vue'
import { nextTick } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { z as zod } from 'zod'

import DatePicker from '@/components/DatePicker.vue'
import HaftarahSelect from '@/components/HaftarahSelect.vue'
import ParashahSelect from '@/components/ParashahSelect.vue'
import StudentSelect from '@/components/StudentSelect.vue'
import { formattedAliyahForTeacher, formattedAliyahSimple } from '@/composables/format'
import { useZodValidation } from '@/composables/zodValidation'
import store from '@/store'

interface StudentsData {
  barMitzvah: BarMitzvahType | null
  editExistingUser: boolean
  formVisible: boolean
  selectedAliyot: Array<AliyahType>
  selectedHaftarah: HaftarahType | null
  selectedParashah: ParashahType | null
  selectedSentenceGroups: Array<SentenceGroupType>
  showHiddenBarMitzvot: boolean
  showSpinner: boolean

  // these exist to circumvent a build error that disallows setting vee-validate handled fields directly
  // these combine with 4x watchEffect below
  bogusPronunciation: PronunciationType | null
  bogusTorahMelody: TorahMelodyType | null
  bogusHaftarahMelody: HaftarahMelodyType | null
  bogusSentenceGroupMelody: SentenceGroupMelodyType | null
}

const state = reactive<StudentsData>({
  barMitzvah: null,
  bogusHaftarahMelody: null,
  bogusPronunciation: null,
  bogusSentenceGroupMelody: null,
  bogusTorahMelody: null,
  editExistingUser: false,
  formVisible: false,
  selectedAliyot: new Array<AliyahType>(),
  selectedHaftarah: null,
  selectedParashah: null,
  selectedSentenceGroups: new Array<SentenceGroupType>(),
  showHiddenBarMitzvot: false,
  showSpinner: false,
})

const { nonEmptyString } = useZodValidation()
const logger = useLogger()
const { errors, handleSubmit, meta, resetForm } = useForm({
  validationSchema: toTypedSchema(
    zod.object({
      date: zod.string().refine(
        (value) => {
          const result = /^\d{4}-\d{2}-\d{2}$/.test(value)

          if (!result) {
            return false
          }

          const [year, month, day] = value.split('-').map(Number)
          if (year <= 1900) {
            return false
          }
          if (month < 1 || month > 12) {
            return false
          }

          const date = new Date(year, month - 1, day)
          if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
            return false // Date validation against invalid days of the month
          }

          return true
        },
        {
          message: 'Date must be in YYYY-MM-DD format, year > 2000, MM between 01-12, and DD valid for given month and year',
        },
      ),
      email: zod.string().email(),
      firstName: nonEmptyString,
      haftarahMelody: zod.object({ id: zod.string() }),
      hidden: zod.boolean().default(false),
      lastName: nonEmptyString,
      pronunciation: zod.object({ id: zod.string() }),
      sentenceGroupMelody: zod.object({ id: zod.string() }),
      torahMelody: zod.object({ id: zod.string() }),
      username: nonEmptyString,
    }),
  ),
})
const { value: date } = useField('date')
const { value: email } = useField('email')
const { value: firstName } = useField('firstName')
const { value: haftarahMelody } = useField('haftarahMelody')
const { value: hidden } = useField('hidden')
const { value: lastName } = useField('lastName')
const { value: pronunciation } = useField('pronunciation')
const { value: sentenceGroupMelody } = useField('sentenceGroupMelody')
const { value: torahMelody } = useField('torahMelody')
const { value: username } = useField('username')

onMounted(() => {
  store.dispatch.teacher.setLogger(logger)
  store.dispatch.user.fetchUser()
  store.dispatch.teacher.fetchLightBarMitzvot()
  store.dispatch.teacher.fetchSentenceGroups({ mine: false })

  // we can't be showing the current bar mitzvah if we're asked to only show non-hidden barmitzvot
  watch(
    () => state.showHiddenBarMitzvot,
    () => {
      if (!state.showHiddenBarMitzvot && state.barMitzvah?.hidden && barMitzvahSelect.value) {
        barMitzvahSelect.value.clear()
      }
    },
  )

  watch(
    () => state.selectedParashah,
    () => {
      store.dispatch.teacher.fetchAliyot({
        mine: false,
        parashah: state.selectedParashah as ParashahType,
      })
    },
  )

  watch(
    () => state.selectedHaftarah,
    () => {
      if (!state.barMitzvah) {
        return
      }

      let updated = false
      if (state.selectedHaftarah) {
        if (state.barMitzvah.haftarah !== state.selectedHaftarah) {
          store.dispatch.teacher.setHaftarahToBarMitzvah({ barMitzvah: state.barMitzvah, haftarah: state.selectedHaftarah })
          updated = true
        }
      } else if (state.barMitzvah.haftarah) {
        store.dispatch.teacher.unsetHaftarahFromBarMitzvah({ barMitzvah: state.barMitzvah })
        updated = true
      }

      if (updated) {
        store.dispatch.snackbar.add({ message: 'Haftarah updated.', state: 'primary' })
      }
    },
  )

  watch(
    () => state.barMitzvah,
    (newValue, oldValue) => {
      if (!state.barMitzvah) {
        state.selectedParashah = null
        state.selectedAliyot = new Array<AliyahType>()
        state.selectedSentenceGroups = new Array<SentenceGroupType>()
        state.selectedHaftarah = null
        return
      }

      state.showSpinner = true
      nextTick(() => {
        if (!state.barMitzvah) {
          logger.error('unexpected null state.barMitzvah in watch state.barMitzvah, nextTick')
          state.showSpinner = false
          return
        }

        if (!_.isEmpty(state.barMitzvah.aliyot)) {
          state.selectedParashah = state.barMitzvah.aliyot[0].parashah
          state.selectedAliyot = [...sortedAliyot([...state.barMitzvah.aliyot])]
        } else {
          // do not unset selectedParashah when the last aliyah is removed; we
          // don't want to parashah selection do get unset; but when we're changing students, then do wipe the old parashah
          if (newValue !== oldValue) {
            state.selectedParashah = null
          }
          state.selectedAliyot.splice(0, state.selectedAliyot.length)
        }
        state.selectedHaftarah = state.barMitzvah.haftarah as HaftarahType
        state.selectedSentenceGroups = _.sortBy([...state.barMitzvah.sentenceGroups], ['reading.description'])
        state.showSpinner = false
      })
    },
    { deep: true },
  )

  watch(
    () => date,
    (newValue) => {
      logger.info('StudentsView watch.date, newValue =')
      logger.info(newValue)
      date.value = newValue.value
    },
  )

  watch(
    () => state.selectedAliyot,
    (newVal) => {
      if (!state.barMitzvah) {
        logger.debug('unexpected !state.barMitzvah in changeAliyot')
        return
      }
      logger.info('changeAliyot')
      const addedAliyot = newVal.filter((aliyah: AliyahType) => state.barMitzvah!.aliyot.indexOf(aliyah) < 0)
      _.forEach(addedAliyot, (aliyah: AliyahType) => {
        store.dispatch.teacher.addAliyahToBarMitzvah({
          aliyah,
          barMitzvah: state.barMitzvah as BarMitzvahType,
        })
      })

      const removedAliyot = state.barMitzvah.aliyot.filter((aliyah: AliyahType) => newVal.indexOf(aliyah) < 0)
      _.forEach(removedAliyot, (aliyah: AliyahType) => {
        store.dispatch.teacher.deleteAliyahFromBarMitzvah({ aliyah, barMitzvah: state.barMitzvah as BarMitzvahType })
      })

      if (!_.isEmpty(addedAliyot) || !_.isEmpty(removedAliyot)) {
        store.dispatch.snackbar.add({ message: 'Aliyah updated.', state: 'primary' })
      }
    },
  )

  watch(
    () => state.selectedSentenceGroups,
    (newVal) => {
      if (!state.barMitzvah) {
        logger.debug('unexpected !state.barMitzvah in addSentenceGroup')
        return
      }

      const addedSentenceGroups = newVal.filter(
        (sentenceGroup: SentenceGroupType) => state.barMitzvah!.sentenceGroups.indexOf(sentenceGroup) < 0,
      )
      _.forEach(addedSentenceGroups, (sentenceGroup: SentenceGroupType) => {
        store.dispatch.teacher.addSentenceGroupToBarMitzvah({
          barMitzvah: state.barMitzvah as BarMitzvahType,
          sentenceGroup,
        })
      })

      const removedSentenceGroups = state.barMitzvah.sentenceGroups.filter(
        (sentenceGroup: SentenceGroupType) => newVal.indexOf(sentenceGroup) < 0,
      )
      _.forEach(removedSentenceGroups, (sentenceGroup: SentenceGroupType) => {
        store.dispatch.teacher.deleteSentenceGroupFromBarMitzvah({
          barMitzvah: state.barMitzvah as BarMitzvahType,
          sentenceGroup,
        })
      })

      if (!_.isEmpty(addedSentenceGroups) || !_.isEmpty(removedSentenceGroups)) {
        store.dispatch.snackbar.add({ message: "B'rachot / Songs updated.", state: 'primary' })
      }
    },
  )

  watchEffect(() => {
    pronunciation.value = state.bogusPronunciation
  })

  watchEffect(() => {
    torahMelody.value = state.bogusTorahMelody
  })

  watchEffect(() => {
    haftarahMelody.value = state.bogusHaftarahMelody
  })

  watchEffect(() => {
    sentenceGroupMelody.value = state.bogusSentenceGroupMelody
  })
})

const selectableAliyot = computed<Readonly<Array<AliyahType>>>((): Readonly<Array<AliyahType>> => {
  if (!state.selectedParashah) {
    return []
  }

  const r = _.filter(store.getters.teacher.aliyot, (aliyah: AliyahType) => {
    return state.selectedParashah && aliyah.parashah.reading.id === state.selectedParashah.reading.id
  })

  return r as Readonly<Array<AliyahType>>
})

const parashah = computed<ParashahType | null>((): ParashahType | null => {
  return state.barMitzvah?.aliyot[0] ? state.barMitzvah?.aliyot[0].parashah : null
})

const torahMelodies = computed<Array<TorahMelodyType>>(() => {
  if (!store.getters.user.user) {
    return []
  }
  return _.map(store.getters.user.user.torahMelodies, (m) => m.melody)
})

const haftarahMelodies = computed<Array<HaftarahMelodyType>>(() => {
  if (!store.getters.user.user) {
    return []
  }
  return _.map(store.getters.user.user.haftarahMelodies, (h) => h.melody)
})

const sentenceGroupMelodies = computed<Array<SentenceGroupMelodyType>>(() => {
  if (!store.getters.user.user) {
    return []
  }
  return _.map(store.getters.user.user.sentenceGroupMelodies, (h) => h.melody)
})

const pronunciations = computed<Array<PronunciationType>>(() => {
  if (!store.getters.user.user) {
    return []
  }
  return _.map(store.getters.user.user.pronunciations, (p) => p.pronunciation)
})

const barMitzvahSelect: Ref<typeof StudentSelect | null> = ref(null)

function sortedAliyot(aliyot: Readonly<Array<AliyahType>>): Readonly<Array<AliyahType>> {
  return _.sortBy(aliyot, ['rank'])
}

function editBarMitzvah() {
  if (!state.barMitzvah) {
    logger.error('unexpected null state.barMitzvah in editBarMitzvah')
    return
  }

  state.editExistingUser = true

  date.value = state.barMitzvah.date
  email.value = state.barMitzvah.student.email
  firstName.value = state.barMitzvah.student.firstName
  hidden.value = state.barMitzvah.hidden
  lastName.value = state.barMitzvah.student.lastName
  username.value = state.barMitzvah.student.username

  // see documentation at declaration
  state.bogusHaftarahMelody = state.barMitzvah.haftarahMelody ?? ({} as HaftarahMelodyType)
  state.bogusPronunciation = state.barMitzvah.pronunciation ?? ({} as PronunciationType)
  state.bogusSentenceGroupMelody = state.barMitzvah.sentenceGroupMelody ?? ({} as SentenceGroupMelodyType)
  state.bogusTorahMelody = state.barMitzvah.torahMelody ?? ({} as TorahMelodyType)

  state.formVisible = true
}

function confirmAndDeleteBarMitzvah() {
  store.dispatch.dialog.show({
    isRejectable: true,
    message: 'Are you sure you want to delete state student?',
    onAccept: async () => {
      await store.dispatch.teacher.deleteBarMitzvah({
        barMitzvah: state.barMitzvah as BarMitzvahType,
      })
      state.barMitzvah = null
      store.dispatch.snackbar.add({ message: 'Deleted.', state: 'primary' })
    },
    title: 'Confirm',
  })
}

function addStudent() {
  customResetForm()
  state.editExistingUser = false
  state.formVisible = true
}

const customResetForm = () => {
  resetForm()
  setDefaultTorahMelody()
  setDefaultHaftarahMelody()
  setDefaultSentenceGroupMelody()
  setDefaultPronunciation()
  date.value = new Date().toISOString().substring(0, 10)
}

const addOrUpdateBarMitzvahWrapper = handleSubmit((values) => {
  store.dispatch.teacher
    .addOrUpdateBarMitzvah({
      date: values.date ?? '',
      haftarahMelodyID: values.haftarahMelody ? parseInt((values.haftarahMelody as HaftarahMelodyType).id) : -1,
      hidden: values.hidden === undefined ? false : values.hidden,
      pronunciationID: pronunciation.value ? parseInt((pronunciation.value as PronunciationType).id) : -1,
      sentenceGroupMelodyID: sentenceGroupMelody.value ? parseInt((sentenceGroupMelody.value as SentenceGroupMelodyType).id) : -1,
      torahMelodyID: torahMelody.value ? parseInt((torahMelody.value as TorahMelodyType).id) : -1,
      update: state.editExistingUser,
      user: {
        email: values.email ?? '',
        firstName: values.firstName ?? '',
        lastName: values.lastName ?? '',
        username: values.username ?? '',
      },
    })
    .then((status: boolean) => {
      if (status) {
        store.dispatch.snackbar.add({ message: state.editExistingUser ? 'Student updated.' : 'Student added.', state: 'primary' })
        state.formVisible = false
        store.dispatch.teacher
          .fetchBarMitzvot()
          .then(() => {
            const bm = _.find(store.getters.teacher.barMitzvot, (barMitzvah: BarMitzvahType) => {
              return barMitzvah.student.username === values.username
            })
            state.barMitzvah = bm ?? null
          })
          .then(() => {
            if (!state.barMitzvah) {
              logger.error('unexpected null state.barMitzvah in addOrUpdateBarMitzvah (2)')
              return
            }
            if (!state.showHiddenBarMitzvot && state.barMitzvah.hidden && barMitzvahSelect.value) {
              barMitzvahSelect.value.clear()
            }
          })
      } else {
        store.dispatch.snackbar.add({ message: store.getters.teacher.error, state: 'error' })
      }
    })
})

function setDefaultTorahMelody() {
  if (torahMelodies.value.length === 1) {
    state.bogusTorahMelody = torahMelodies.value[0] as TorahMelodyType
  }
}

function setDefaultHaftarahMelody() {
  if (haftarahMelodies.value.length === 1) {
    state.bogusHaftarahMelody = haftarahMelodies.value[0] as HaftarahMelodyType
  }
}

function setDefaultSentenceGroupMelody() {
  if (sentenceGroupMelodies.value.length === 1) {
    state.bogusSentenceGroupMelody = sentenceGroupMelodies.value[0] as SentenceGroupMelodyType
  }
}

function setDefaultPronunciation() {
  if (pronunciations.value.length === 1) {
    state.bogusPronunciation = pronunciations.value[0] as PronunciationType
  }
}
</script>

<template>
  <!-- edit a custom text -->
  <!-- @shown="focusDescription" -->
  <v-dialog
    id="newStudent"
    ref="newStudent"
    v-model="state.formVisible"
    class="mb-4 pb-4"
    content-class="bmt-popup-dialog"
    no-click-animation
    overlay-color="secondary"
    overlay-opacity="0.4"
    @keydown.esc="state.formVisible = false"
  >
    <v-form @reset.prevent="customResetForm" @submit="addOrUpdateBarMitzvahWrapper">
      <v-card>
        <v-card-title> {{ state.editExistingUser ? 'Update' : 'Add' }} user </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="3">
              <v-text-field
                v-model="username"
                :disabled="!!state.editExistingUser"
                :error-messages="errors.username"
                label="Username"
                variant="underlined"
              />
            </v-col>
            <v-col md="3">
              <v-text-field v-model="email" :error-messages="errors.email" label="Email" variant="underlined" />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="3">
              <v-text-field v-model="firstName" :error-messages="errors.firstName" label="First name" variant="underlined" />
            </v-col>
            <v-col md="3">
              <v-text-field v-model="lastName" :error-messages="errors.lastName" label="Last name" variant="underlined" />
            </v-col>
          </v-row>
          <v-row align-h="start">
            <v-col md="2">
              <date-picker :model-value="date as string" />
            </v-col>

            <v-col md="1" />
            <v-col md="2">
              <v-checkbox v-model="hidden" dense label="Hide student" />
            </v-col>
          </v-row>
          <v-row v-if="pronunciations">
            <v-col md="3">
              <v-select
                v-model="state.bogusPronunciation as PronunciationType"
                item-title="description"
                :items="pronunciations"
                label="Pronunciation"
                return-object
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="torahMelodies" md="3">
              <v-select
                v-model="state.bogusTorahMelody as TorahMelodyType"
                item-title="description"
                :items="torahMelodies"
                label="Melodic style (Torah)"
                return-object
                variant="underlined"
              />
            </v-col>
            <v-col v-if="haftarahMelodies" md="3">
              <v-select
                v-model="state.bogusHaftarahMelody as HaftarahMelodyType"
                item-title="description"
                :items="haftarahMelodies"
                label="Melodic style (haftarah)"
                return-object
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row v-if="sentenceGroupMelodies">
            <v-col md="3">
              <v-select
                v-model="state.bogusSentenceGroupMelody as SentenceGroupMelodyType"
                item-title="description"
                :items="sentenceGroupMelodies"
                label="Melodic style (other texts)"
                return-object
                variant="underlined"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2">
              <v-btn block color="primary" :disabled="!meta.valid || store.getters.teacher.updating" type="submit">
                {{ state.editExistingUser ? 'Update' : 'Add' }}
                <v-progress-circular v-if="store.getters.teacher.updating" class="ml-4" indeterminate />
              </v-btn>
            </v-col>
            <v-col md="2">
              <v-btn block @click="state.formVisible = false"> Cancel </v-btn>
            </v-col>
            <v-col v-if="!state.editExistingUser" md="2">
              <v-btn block color="warning" :disabled="store.getters.teacher.updating" type="reset" @click="customResetForm"> Reset </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>

  <!-- Table of students -->
  <v-row>
    <v-col md="4">
      <student-select
        ref="barMitzvahSelect"
        v-model:barMitzvah="state.barMitzvah"
        :bar-mitzvah-clearable="true"
        :external-set="true"
        :include-hidden="state.showHiddenBarMitzvot"
      />
    </v-col>

    <v-col v-if="state.barMitzvah" class="mt-4" md="2">
      <v-btn class="mr-4" color="primary" icon size="small" @click="editBarMitzvah">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn class="mr-4" color="error" icon size="small" @click="confirmAndDeleteBarMitzvah">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-progress-circular v-if="store.getters.teacher.updating" indeterminate />
    </v-col>

    <v-col v-if="!state.barMitzvah" md="2">
      <v-switch v-model="state.showHiddenBarMitzvot" color="primary" label="include hidden" />
    </v-col>
  </v-row>

  <v-row v-if="!state.barMitzvah">
    <v-col>
      <v-btn class="px-6" color="primary" @click="addStudent"> Add student </v-btn>
    </v-col>
  </v-row>

  <v-row v-if="state.barMitzvah">
    <v-col>
      <v-btn @click="() => (state.barMitzvah = null)">Clear</v-btn>
    </v-col>
  </v-row>

  <v-row v-if="state.showSpinner">
    <v-col>
      <v-progress-circular indeterminate />
    </v-col>
  </v-row>

  <v-row v-if="state.barMitzvah">
    <v-col md="4">
      <parashah-select
        v-model:parashah="state.selectedParashah"
        :clearable="_.isEmpty(state.selectedAliyot)"
        :disabled="!_.isEmpty(state.selectedAliyot)"
        :external-set="true"
        :only="parashah ? [parashah] : null"
      />
    </v-col>

    <v-col v-if="state.selectedParashah" md="4">
      <v-autocomplete
        v-model="state.selectedAliyot"
        chips
        :error="!state.selectedAliyot.length"
        :error-messages="state.selectedAliyot.length ? [] : ['You must choose at least one aliyah']"
        :item-title="
          (aliyah) => {
            return formattedAliyahForTeacher(aliyah)
          }
        "
        :items="sortedAliyot(selectableAliyot)"
        :label="state.barMitzvah.aliyot.length <= 1 ? 'Aliyah' : 'Aliyot'"
        :loading="!selectableAliyot || !selectableAliyot.length"
        multiple
        return-object
        variant="underlined"
      >
        <template #chip="{ props, item }">
          <v-chip v-bind="props" closable size="small" :text="formattedAliyahSimple(item.raw)" />
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>

  <!-- Haftarah dropdown -->
  <v-row v-if="state.barMitzvah && (state.barMitzvah.haftarah || state.selectedParashah)">
    <v-col md="4">
      <haftarah-select
        v-model:haftarah="state.selectedHaftarah"
        :clearable="true"
        :external-set="true"
        :only="!state.selectedParashah ? ([state.barMitzvah.haftarah] as Array<HaftarahType>) : null"
        :parashah="state.selectedParashah"
      />
    </v-col>
  </v-row>

  <!-- SentenceGroup dropdown -->
  <v-row v-if="state.barMitzvah">
    <v-col md="8">
      <v-autocomplete
        v-model="state.selectedSentenceGroups"
        chips
        :item-title="
          (sentenceGroup) => {
            return sentenceGroup.reading ? sentenceGroup.reading.description : 'N/A'
          }
        "
        :items="store.getters.teacher.sentenceGroups"
        label="B'rachot / Songs"
        multiple
        return-object
        variant="underlined"
      >
        <template #chip="{ props, item }">
          <v-chip v-bind="props" closable size="small" :text="item.raw.reading.description as string" />
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
