import * as base64js from 'base64-js'
import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import _ from 'lodash'
import { SentenceRecordingType, SentenceType, TagType, WordRecordingType } from 'types/types'
import { ActionContext } from 'vuex'

import { useApollo } from '@/util/apolloClient'
const { apolloClient } = useApollo()
import { ApolloQueryResult } from '@apollo/client/core'
import { VueLogger } from 'vue-logger-plugin'

import {
  createBlobUrlFromBytes,
  DEFAULT_PITCH_SHIFT,
  HTMLAudioWrapper,
  PitchedSentenceOrSegmentAudio,
  PitchShiftType,
  SentenceRecordingMetadataWithPitchedAudios,
} from '@/util/AudioUtil'

import DeleteSentenceRecording from '../mutations/DeleteSentenceRecording.graphql'
import ModifySentenceRecording from '../mutations/ModifySentenceRecording.graphql'
import MySentenceRecordingsQuery from '../queries/MySentenceRecordingsQuery.graphql'
import SegmentRecordingContents from '../queries/SegmentRecordingContents.graphql'
import SentenceRecordingContents from '../queries/SentenceRecordingContents.graphql'
import SentenceRecordingQuery from '../queries/SentenceRecordingQuery.graphql'

// [sentenceId]: {
//   [sentenceRecordingId]: {
//     sentenceRecording: SentenceRecordingType;
//     updating: boolean;
//     audio: {
//       [pitchShift]: PitchedSentenceOrSegmentAudio
//     }
//   } as SentenceRecordingMetadataWithPitchedAudios
// }

type SentenceRecordingsMap = {
  [sentenceId: string]: {
    [sentenceRecordingId: string]: SentenceRecordingMetadataWithPitchedAudios
  }
}

interface RootState {}

interface RecordingsState {
  updating: boolean
  loading: boolean
  logger: VueLogger | null
  recordings: SentenceRecordingsMap
}

const nullPitchedSentenceOrSegmentAudio: PitchedSentenceOrSegmentAudio = {
  downloading: false,
  pitchShift: DEFAULT_PITCH_SHIFT,
  segmentHTMLAudioWrappers: new Array<HTMLAudioWrapper>(),
  sentenceHTMLAudioWrapper: {
    audio: null,
    blobUrl: null,
    playStatus: null,
  } as HTMLAudioWrapper,
} as PitchedSentenceOrSegmentAudio

const resetState = (): RecordingsState => {
  return {
    loading: false,
    logger: null,
    recordings: {} as SentenceRecordingsMap,
    updating: false,
  }
}

function releaseMemory(audio: HTMLAudioWrapper) {
  if (audio?.audio) {
    window.URL.revokeObjectURL(audio.audio.src)
  }
}

const audio = defineModule({
  // [sentenceId]: {
  //   [sentenceRecordingId]: {
  //     sentenceRecording: SentenceRecordingType;
  //     updating: boolean;
  //     audio: {
  //       [pitchShift]: PitchedSentenceOrSegmentAudio
  //     }
  //   } as SentenceRecordingMetadataWithPitchedAudios
  // }
  actions: {
    async deleteRecording(context, payload: { sentenceRecording: SentenceRecordingType }) {
      const { commit, state } = audioActionContext(context)

      commit.setUpdating(true)
      const sentenceId = payload.sentenceRecording.sentence.id
      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: store.audio, deleteRecording with unknown sentenceId')
        return
      }

      if (!(payload.sentenceRecording.id in state.recordings[sentenceId])) {
        console.error('WARNING: store.audio, deleteRecording with unknown sentenceRecordingId')
        return
      }

      const sentenceRecordingMetadataWithPitchedAudios = state.recordings[sentenceId][payload.sentenceRecording.id]
      if (sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.id !== payload.sentenceRecording.id) {
        console.error('WARNING: store.audio, deleteRecording weird inconsistent state')
        return
      }

      const response = await apolloClient.mutate({
        mutation: DeleteSentenceRecording,
        variables: {
          sentenceRecording: payload.sentenceRecording.id,
        },
      })
      const { ok } = response.data.deleteSentenceRecording
      if (ok) {
        commit.deleteRecording({
          sentenceRecording: payload.sentenceRecording,
        })
      }
      commit.setUpdating(false)
    },

    fetchAudio(
      context,
      payload: {
        sentenceRecording: SentenceRecordingType
        pitchShift: PitchShiftType
        cache: boolean
      },
    ): Promise<void> {
      const { commit, state } = audioActionContext(context)

      commit.setFetchingAudio({
        b: true,
        sentenceRecording: payload.sentenceRecording,
      })
      const pitchShift = payload.pitchShift === null || payload.pitchShift === undefined ? DEFAULT_PITCH_SHIFT : payload.pitchShift

      return new Promise<void>((resolve, reject) => {
        if (!payload.sentenceRecording) {
          console.error('WARNING: fetchAudio, no payload.sentenceRecording')
          commit.setFetchingAudio({
            b: false,
            sentenceRecording: payload.sentenceRecording,
          })
          reject()
          return
        }

        // there is a recording for this sentence; fetch its contents
        const sentenceId = payload.sentenceRecording.sentence.id
        if (!(sentenceId in state.recordings)) {
          console.error('WARNING: store.audio, fetchAudio with unknown sentenceId')
          commit.setFetchingAudio({
            b: false,
            sentenceRecording: payload.sentenceRecording,
          })
          resolve()
          return
        }

        const sentenceRecordingId = payload.sentenceRecording.id
        if (!(sentenceRecordingId in state.recordings[sentenceId])) {
          console.error('WARNING: store.audio, fetchAudio with unknown sentenceRecordingId')
          commit.setFetchingAudio({
            b: false,
            sentenceRecording: payload.sentenceRecording,
          })
          resolve()
          return
        }

        const sentenceRecordingMetadataWithPitchedAudios = state.recordings[sentenceId][sentenceRecordingId]
        const { sentenceRecording } = sentenceRecordingMetadataWithPitchedAudios
        if (!payload.cache || !(pitchShift in state.recordings[sentenceId][sentenceRecordingId].audio)) {
          state.logger?.debug &&
            state.logger.debug(`pitchShift ${pitchShift} not in state.recordings[sentenceId][sentenceRecordingId].audio`)
          state.recordings[sentenceId][sentenceRecordingId].audio[pitchShift] = _.cloneDeep(nullPitchedSentenceOrSegmentAudio)
        } else {
          state.logger?.debug && state.logger.debug(`!payload.cache or pitchShifted already in state.recordings`)
        }

        const pitchedSentenceOrSegmentAudio: PitchedSentenceOrSegmentAudio =
          state.recordings[sentenceId][sentenceRecordingId].audio[pitchShift]

        const promises = new Array<Promise<ApolloQueryResult<void>>>()
        if (
          !payload.cache ||
          !pitchedSentenceOrSegmentAudio.sentenceHTMLAudioWrapper.audio ||
          !pitchedSentenceOrSegmentAudio.sentenceHTMLAudioWrapper.audio.src
        ) {
          state.logger?.debug && state.logger.debug(`!audio or !blobUrl for ${sentenceRecordingId}, pitchShift ${pitchShift}`)
          const p = apolloClient.query({
            fetchPolicy: 'network-only',
            query: SentenceRecordingContents,
            variables: {
              id: sentenceRecordingId,
              pitchShift: parseFloat(String(pitchShift)),
            },
          })
          promises.push(p)

          p.then((sentenceResponse) => {
            state.logger?.debug && state.logger.debug('promise sentenceResponse =')
            state.logger?.debug && state.logger.debug(sentenceResponse)
            const { blob } = sentenceResponse.data.sentenceRecordingContents
            const compressedWavFile = base64js.toByteArray(blob)
            state.logger?.debug && state.logger.debug('promise compressedWavFile =')
            state.logger?.debug && state.logger.debug(compressedWavFile)
            const blobUrl = createBlobUrlFromBytes(compressedWavFile) // can't use decomposition; will conflict with name 'audio'
            state.logger?.debug && state.logger.debug('blobUrl =')
            state.logger?.debug && state.logger.debug(blobUrl)
            commit.addAudio({
              blobUrl,
              pitchShift: pitchShift.toString(),
              sentenceRecordingMetadataWithPitchedAudios,
              wordIndex: -1,
            })

            state.logger?.debug && state.logger.debug('sanity check post-addAudio()')
            if (!payload.cache || !(pitchShift in state.recordings[sentenceId][sentenceRecordingId].audio)) {
              state.logger?.debug &&
                state.logger.debug(`pitchShift ${pitchShift} STILL not in state.recordings[sentenceId][sentenceRecordingId].audio`)
            }
          })
        } else {
          state.logger?.debug && state.logger.debug(`!payload.cache or .audio or .audio.src already exists`)
          state.logger?.debug && state.logger.debug('payload.cache =')
          state.logger?.debug && state.logger.debug(payload.cache)
        }

        // sets this.segmentHTMLAudioWrappers for all the segments in this.sentence.id
        if (!payload.cache || _.isEmpty(pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers)) {
          // state.logger?.debug && state.logger.debug(`sentencRecordingId ${sentenceRecordingId} has empty segmentHTMLAudioWrappers`);
          // resize slots so that we can asynchrounly put entries into it
          pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers.splice(0)
          _.forEach(sentenceRecording!.segments, () => {
            pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers.push({} as HTMLAudioWrapper)
          })
        }

        _.forEach(sentenceRecording!.segments, async (segmentRecording: WordRecordingType, wordIndex: number) => {
          if (!payload.cache || !pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers[wordIndex].audio?.src) {
            // state.logger?.debug && state.logger.debug(`fetching segment recording ${wordIndex} for sentencRecordingId ${sentenceRecordingId}`);
            // state.logger?.debug && state.logger.debug(`going to fetch audio for wordIndex ${wordIndex}`);
            const p = apolloClient.query({
              fetchPolicy: 'network-only',
              query: SegmentRecordingContents,
              variables: {
                id: segmentRecording.id,
                pitchShift: parseFloat(String(pitchShift)),
              },
            })
            promises.push(p)
            p.then((response) => {
              const { blob } = response.data.segmentRecordingContents
              const compressedWavFile = base64js.toByteArray(blob)
              const blobUrl = createBlobUrlFromBytes(compressedWavFile)
              commit.addAudio({
                blobUrl,
                pitchShift: pitchShift.toString(),
                sentenceRecordingMetadataWithPitchedAudios,
                wordIndex,
              })
            })
          }
        })

        if (!sentenceRecording) {
          state.logger?.error && state.logger.error('unexpected !sentenceRecording before allSettled in fetchAudio')
          commit.setLoading(false) // can't call setFetchingAudio, because sentenceResponse === null, but still need to turn off the loading flag which was turned on earlier
          reject()
          return
        }

        Promise.allSettled(promises).then(() => {
          commit.setFetchingAudio({ b: false, sentenceRecording })
          resolve()
        })
      })
    },

    fetchRecording(context: ActionContext<RecordingState, RootState>, payload: { id: number }): Promise<void> {
      const { commit, dispatch } = audioActionContext(context)

      commit.setLoading(true)
      return new Promise((resolve) => {
        const p = apolloClient.query({
          fetchPolicy: 'network-only',
          query: SentenceRecordingQuery,
          variables: {
            id: payload.id,
          },
        })
        p.then((response) => {
          // const sentenceId = response.data.sentenceRecording.sentence.id;
          // const sentenceRecordingId = response.data.sentenceRecording.id;
          // if (sentenceId in getters.recordings && sentenceRecordingId in getters.recordings[sentenceId]) {
          //   state.logger?.debug && state.logger.debug('fetchRecording calls deleteRecording');
          //   commit.deleteRecording({ sentenceRecording: response.data.sentenceRecording });
          // }
          const sra = dispatch.storeSentenceRecording({ sentenceRecording: response.data.sentenceRecording })
          commit.setLoading(false)
          resolve(sra)
        })
      })
    },

    async fetchRecordings(context, payload: { sentences: Array<SentenceType> }) {
      const { commit, dispatch, getters, state } = audioActionContext(context)

      state.logger?.debug && state.logger.debug('fetchRecordings, payload.sentences =')
      state.logger?.debug && state.logger.debug(payload.sentences)
      commit.setLoading(true)
      const response = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: MySentenceRecordingsQuery,
        variables: {
          sentences: _.map(payload.sentences, (sentence: SentenceType) => {
            return sentence.id
          }),
        },
      })

      _.forEach(response.data.mySentenceRecordings, (sentenceRecording: SentenceRecordingType) => {
        state.logger?.debug && state.logger.debug('iterating over mySentenceRecordings, sentenceRecording =')
        state.logger?.debug && state.logger.debug(sentenceRecording)
        const sentenceId = sentenceRecording.sentence.id
        const sentenceRecordingId = sentenceRecording.id
        if (sentenceId in getters.recordings && sentenceRecordingId in getters.recordings[sentenceId]) {
          commit.deleteRecording({ sentenceRecording })
        }
        dispatch.storeSentenceRecording({ sentenceRecording })
      })
      commit.setLoading(false)
    },

    async modifySentenceRecording(
      context,
      payload: {
        sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios
      },
    ) {
      const { commit } = audioActionContext(context)
      if (!payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
        return
      }

      const { haftarahMelody, sentenceGroupMelody, torahMelody } = payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording
      commit.setUpdatingState({
        b: true,
        sentenceRecordingMetadataWithPitchedAudios: payload.sentenceRecordingMetadataWithPitchedAudios,
      })
      const response = await apolloClient.mutate({
        mutation: ModifySentenceRecording,
        variables: {
          haftarahMelodyStyle: haftarahMelody ? haftarahMelody.id : null,
          pronunciation: payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.pronunciation!.id,
          sentenceGroupMelodyStyle: sentenceGroupMelody ? sentenceGroupMelody.id : null,
          sentenceRecording: payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.id,
          tags: _.map(payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.tags, (tag: TagType) => tag.id),
          torahMelodyStyle: torahMelody ? torahMelody.id : null,
        },
      })
      const { ok } = response.data.modifySentenceRecording
      if (ok) {
        commit.modifySentenceRecording(payload.sentenceRecordingMetadataWithPitchedAudios)
      }
      commit.setUpdatingState({
        b: false,
        sentenceRecordingMetadataWithPitchedAudios: payload.sentenceRecordingMetadataWithPitchedAudios,
      })
    },

    releaseRecording(context, payload: { sentenceRecording: SentenceRecordingType }) {
      const { commit, state } = audioActionContext(context)

      if (_.isEmpty(payload.sentenceRecording)) {
        console.error('WARNING: sentenceRecording is empty')
        return
      }

      const sentenceId = payload.sentenceRecording.sentence.id
      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: store.audio, releaseRecording with unknown sentenceId')
        return
      }

      const sentenceRecordingId = payload.sentenceRecording.id
      if (!(sentenceRecordingId in state.recordings[sentenceId])) {
        console.error('WARNING: store.audio, releaseRecording with unknown sentenceRecordingId')
        return
      }

      const sentenceRecordingMetadataWithPitchedAudios = state.recordings[sentenceId][sentenceRecordingId]
      if (sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.id !== sentenceRecordingId) {
        console.error('WARNING: store.audio, releaseRecording weird inconsistent state')
        return
      }

      commit.deleteRecording({ sentenceRecording: payload.sentenceRecording })
    },

    releaseSentence(context, payload: { sentence: SentenceType }) {
      const { commit, state } = audioActionContext(context)

      const sentenceId = payload.sentence.id
      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: store.audio, releaseSentence with unknown sentenceId')
        return
      }

      // release all recordings for this sentence
      for (const sentenceRecordingId in state.recordings[sentenceId]) {
        const sentenceRecordingMetadataWithPitchedAudios = state.recordings[sentenceId][sentenceRecordingId]
        if (sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.id !== sentenceRecordingId) {
          return
        }
        context.dispatch('releaseRecording', {
          sentenceRecording: sentenceRecordingMetadataWithPitchedAudios.sentenceRecording,
        })
      }

      // release the sentence
      commit.deleteSentence({ sentence: payload.sentence })
    },

    reset(context) {
      const { commit, getters } = audioActionContext(context)

      Object.keys(getters.recordings).forEach((sentenceId: string) => {
        Object.keys(getters.recordings[sentenceId]).forEach((sentenceRecordingId: string) => {
          const { sentenceRecording } = getters.recordings[sentenceId][sentenceRecordingId]
          if (sentenceRecording) {
            commit.deleteRecording({ sentenceRecording })
          }
        })
      })

      commit.reset()
    },

    setLogger(context, logger: VueLogger) {
      const { commit } = audioActionContext(context)
      commit.setLogger(logger)
    },

    // utility function for fetchRecording and fetchRecordings
    storeSentenceRecording(context, payload: { sentenceRecording: SentenceRecordingType }) {
      const { commit, state } = audioActionContext(context)

      state.logger?.debug && state.logger.debug(`storeSentenceRecording, sentenceRecording (${payload.sentenceRecording.id}) =`)
      state.logger?.debug && state.logger.debug(payload.sentenceRecording)

      // already stored
      if (payload.sentenceRecording.id in state.recordings) {
        return
      }

      commit.addRecording(payload.sentenceRecording)
    },
  },

  getters: {
    loading(...args): boolean {
      const { state } = audioGetterContext(args)
      return state.loading
    },

    recordings(...args): SentenceRecordingsMap {
      const { state } = audioGetterContext(args)
      return state.recordings
    },

    updating(...args): boolean {
      const { state } = audioGetterContext(args)
      return state.updating
    },
  },

  mutations: {
    addAudio(
      state,
      payload: {
        sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios
        wordIndex: number // -1 means sentenceHTMLAudioWrapper; otherwise segmentHTMLAudioWrappers
        blobUrl: string
        pitchShift: PitchShiftType
      },
    ) {
      const sentenceId = payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.sentence.id
      if (!sentenceId) {
        return
      }

      state.logger?.debug && state.logger.debug(`addAudio 1 for sentenceId = ${sentenceId}`)
      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: store.audio, addAudio with unknown sentenceId')
        return
      }

      const sentenceRecordingId = payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.id

      if (!sentenceRecordingId) {
        return
      }

      if (!(sentenceRecordingId in state.recordings[sentenceId])) {
        console.error('WARNING: store.audio, addAudio with unknown sentenceRecordingId')
        return
      }
      state.logger?.debug && state.logger.debug(`addAudio 2 for sentenceRecordingId = ${sentenceRecordingId}`)

      const { pitchShift } = payload
      if (!(pitchShift in state.recordings[sentenceId][sentenceRecordingId].audio)) {
        console.error('WARNING: store.audio, addAudio with unknown pitchShift')
        return
      }

      const pitchedSentenceOrSegmentAudio: PitchedSentenceOrSegmentAudio =
        state.recordings[sentenceId][sentenceRecordingId].audio[pitchShift]
      state.logger?.debug && state.logger.debug(`addAudio 3 for pitchedSentenceOrSegmentAudio = `)
      state.logger?.debug && state.logger.debug(pitchedSentenceOrSegmentAudio)

      state.logger?.debug && state.logger.debug(`addAudio 4 payload = `)
      state.logger?.debug && state.logger.debug(payload)

      if (payload.wordIndex < 0) {
        //
        // sentence
        //
        if (pitchedSentenceOrSegmentAudio.sentenceHTMLAudioWrapper.audio) {
          state.logger?.debug && state.logger.debug('revoking old sentence objectUrl')
          window.URL.revokeObjectURL(pitchedSentenceOrSegmentAudio.sentenceHTMLAudioWrapper.audio.src)
        }
        state.logger?.debug && state.logger.debug('new Audio for sentence')
        pitchedSentenceOrSegmentAudio.sentenceHTMLAudioWrapper.audio = new Audio(payload.blobUrl)
        state.logger?.debug && state.logger.debug('pitchedSentenceOrSegmentAudio.sentenceHTMLAudioWrapper.audio =')
        state.logger?.debug && state.logger.debug(pitchedSentenceOrSegmentAudio.sentenceHTMLAudioWrapper.audio)

        //
        // segment
        //
      } else {
        if (_.isEmpty(pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers)) {
          console.error('WARNING: store.audio, addAudio with empty segmentHTMLAudioWrappers')
          return
        }
        if (pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers[payload.wordIndex].audio) {
          state.logger?.debug && state.logger.debug('revoking old segment objectUrl')
          window.URL.revokeObjectURL(pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers[payload.wordIndex].audio!.src)
        }
        const audio = new Audio(payload.blobUrl)
        pitchedSentenceOrSegmentAudio.segmentHTMLAudioWrappers[payload.wordIndex].audio = audio
        state.logger?.debug && state.logger.debug('addAudio 4, segment audio=')
        state.logger?.debug && state.logger.debug(audio)
      }
    },

    addRecording(state: RecordingsState, sentenceRecording: SentenceRecordingType) {
      state.logger?.debug && state.logger.debug('addRecording, sentenceRecording = ')
      state.logger?.debug && state.logger.debug(sentenceRecording)
      const sentenceId = sentenceRecording.sentence.id
      if (!(sentenceId in state.recordings)) {
        state.recordings[sentenceId] = {}
      }

      if (!(sentenceRecording.id in state.recordings[sentenceId])) {
        state.recordings[sentenceId][sentenceRecording.id] = {
          // can't use DEFAULT_PITCH_SHIFT, because that will end up being the literal key
          audio: {
            [DEFAULT_PITCH_SHIFT]: _.cloneDeep(nullPitchedSentenceOrSegmentAudio), // no pitch shift
          },
          downloading: false,
          sentenceRecording: null,
          updating: false,
        } as SentenceRecordingMetadataWithPitchedAudios
        state.logger?.info && state.logger.info(`added sentenceRecording.id ${sentenceRecording.id} to state.recordings[sentenceId]`)
      } else {
        state.logger?.info && state.logger.info('sentenceRecording.id already in state.recordings[sentenceId]')
      }

      // store sentenceRecording
      state.recordings[sentenceId][sentenceRecording.id].sentenceRecording = _.cloneDeep(sentenceRecording)

      // sort stored tags
      state.recordings[sentenceId][sentenceRecording.id].sentenceRecording!.tags.sort((a: TagType, b: TagType) => {
        return a.name.localeCompare(b.name)
      })
    },

    deleteRecording(state: RecordingsState, { sentenceRecording }: { sentenceRecording: SentenceRecordingType }) {
      const { id: sentenceId } = sentenceRecording.sentence
      const { id: sentenceRecordingId } = sentenceRecording
      const sentenceRecordings = state.recordings[sentenceId]

      if (!sentenceRecordings) {
        console.error('WARNING: Unknown sentenceId')
        return
      }

      const recording = sentenceRecordings[sentenceRecordingId]

      if (!recording) {
        console.error('WARNING: Unknown sentenceRecordingId')
        return
      }

      for (const sentenceRecordingAudio of Object.values(recording.audio)) {
        releaseMemory(sentenceRecordingAudio.sentenceHTMLAudioWrapper)

        for (const wordIndex of Object.keys(sentenceRecordingAudio.segmentHTMLAudioWrappers).map(Number)) {
          releaseMemory(sentenceRecordingAudio.segmentHTMLAudioWrappers[wordIndex])
        }
      }

      state.logger?.debug && state.logger.debug(`deleteRecording, deleting state.recordings[${sentenceId}][${sentenceRecordingId}]`)
      delete state.recordings[sentenceId][sentenceRecordingId]
    },

    deleteSentence(state: RecordingsState, payload: { sentence: SentenceType }) {
      const sentenceId = payload.sentence.id
      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: deleteSentence with unknown sentenceId')
        return
      }

      if (!_.isEmpty(state.recordings[sentenceId])) {
        console.error('WARNING: deleteSentence with non-empty store')
        return
      }
      delete state.recordings[sentenceId]
    },

    modifySentenceRecording(
      state: RecordingsState,
      sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios,
    ) {
      const sentenceId = sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.sentence.id
      if (!sentenceId) {
        return
      }

      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: store.audio, modifyRecording with unknown sentenceId')
        return
      }

      const sentenceRecordingId = sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.id
      if (!sentenceRecordingId) {
        return
      }
      state.recordings[sentenceId][sentenceRecordingId] = sentenceRecordingMetadataWithPitchedAudios
    },

    reset(state: RecordingsState) {
      Object.assign(state, { ...resetState() })
    },

    setFetchingAudio(state, payload: { sentenceRecording: SentenceRecordingType; b: boolean }) {
      const sentenceId = payload.sentenceRecording.sentence.id
      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: store.audio, setFetchingAudio with unknown sentenceId')
        return
      }

      const sentenceRecordingId = payload.sentenceRecording.id
      if (!(sentenceRecordingId in state.recordings[sentenceId])) {
        console.error('WARNING: store.audio, setFetchingAudio with unknown sentenceRecordingId')
        return
      }

      state.loading = payload.b
      state.recordings[sentenceId][sentenceRecordingId].downloading = payload.b
    },

    setLoading(state: RecordingsState, loading: boolean) {
      state.loading = loading
    },

    setLogger(state: RecordingsState, logger: VueLogger) {
      state.logger = logger
    },

    setUpdating(state: RecordingsState, updating: boolean) {
      state.updating = updating
    },

    setUpdatingState(
      state: RecordingsState,
      payload: {
        sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios
        b: boolean
      },
    ) {
      const sentenceId = payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.sentence.id
      if (!sentenceId) {
        return
      }

      if (!(sentenceId in state.recordings)) {
        console.error('WARNING: store.audio, modifyRecording with unknown sentenceId')
        return
      }

      const sentenceRecordingId = payload.sentenceRecordingMetadataWithPitchedAudios.sentenceRecording?.id

      if (!sentenceRecordingId) {
        return
      }

      if (!(sentenceRecordingId in state.recordings[sentenceId])) {
        console.error('WARNING: store.audio, modifyRecording with unknown sentenceRecordingId')
      }

      state.updating = payload.b
      state.recordings[sentenceId][sentenceRecordingId].updating = payload.b
    },
  },

  namespaced: true as const,

  state: () => resetState(),
})

export default audio
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const audioGetterContext = (args: [any, any, any, any]) => localGetterContext(args, audio)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const audioActionContext = (context: any) => localActionContext(context, audio)
