<script setup lang="ts">
import { createPopper, Placement } from '@popperjs/core'
import { onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'

interface Props {
  attachId: string | null
  id?: string
  placement?: Placement
  visible: boolean
}

const props = withDefaults(defineProps<Props>(), {
  attachId: null,
  id: 'tooltip',
  placement: 'top' as Placement,
  visible: false,
})

const logger = useLogger()
const state = reactive({
  popoverId: null as HTMLElement | null,
})

onMounted(() => {
  watch(
    () => props.visible,
    () => {
      if (props.visible) {
        state.popoverId = document.getElementById('tooltip')
        const attachElt = document.getElementById(props.attachId ?? '')
        if (!attachElt) {
          logger.debug('unexpected null attachElt in PopOver watch')
          return
        }
        if (!state.popoverId) {
          logger.debug('unexpected null state.popoverId in PopOver watch')
          return
        }
        createPopper(attachElt, state.popoverId, {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
          placement: props.placement,
        })
      }
    },
  )
})
</script>

<template>
  <div v-show="props.visible" :id="id" role="tooltip">
    <slot name="default"></slot>
    <div id="arrow" data-popper-arrow></div>
  </div>
</template>

<style scoped>
/* https://codesandbox.io/s/github/popperjs/website/tree/master/examples/placement?file=/src/styles.css */
#tooltip {
  display: inline-block;
  background: #ffffff;
  /* color: #643045; */
  /* font-weight: bold; */
  /* padding: 5px 10px; */
  /* font-size: 13px; */
  border-radius: 4px;
  z-index: 2;
  border: 1px solid;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  border: 1px solid;
  z-index: -1;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
  z-index: -1;
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>
