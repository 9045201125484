<script setup lang="ts">
import _ from 'lodash'
import { BookChapterType, BookType, SectionType } from 'types/types'
import { computed, nextTick, onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import xregexp from 'xregexp'

import store from '@/store'

interface Props {
  range?: boolean
}

const logger = useLogger()
const props = withDefaults(defineProps<Props>(), {
  range: false,
})

const state = reactive({
  book: null as BookType | null,
  chapter: null as number | null,
  endChapter: null as number | null,
  endVerse: null as number | null,
  setSection: null as string | null,

  verse: null as number | null,
})

const emit = defineEmits(['update:section'])

const books = computed<Array<{ title: string; value: BookType }>>(() => {
  const books = [] as Array<{ title: string; value: BookType }>
  _.forEach(
    _.sortBy(store.getters.teacher.sections, (s: SectionType) => s.order),
    (s: SectionType) => {
      _.forEach(
        _.sortBy(s.books, (b: BookType) => b.order),
        (b: BookType) => {
          books.push({
            title: `${s.transliteratedName} / ${b.transliteratedName} / ${b.heName}`,
            value: b,
          })
        },
      )
    },
  )
  return books
})

const chapters = computed<Array<BookChapterType>>(() => {
  return _.isEmpty(state.book) ? [] : state.book.chapters
})

const endChapters = computed<Array<BookChapterType>>(() => {
  if (state.chapter === null) {
    return []
  }
  return state.book && state.chapter && state.verse ? _.slice(state.book.chapters, state.chapter - 1) : []
})

const endVerses = computed<Array<number>>(() => {
  if (!state.book || !state.chapter || !state.verse || !state.endChapter) {
    return []
  }

  if (state.chapter === state.endChapter) {
    return _.slice(verses.value, state.verse)
  }

  const max = state.endChapter ? state.book.chapters[state.endChapter - 1].verses : 0
  return _.map(_.range(max), (i: number) => i + 1)
})

const section = computed<string | null>(() => {
  if (!state.book || !state.chapter || !state.verse) {
    return null
  }

  if (props.range && (!state.endChapter || !state.endVerse)) {
    return null
  }

  const transliteratedName = state.book.transliteratedName.replace(/ /g, '_')
  let s = `${transliteratedName}.${state.chapter}:${state.verse}`
  if (props.range) {
    s += `-${state.endChapter}:${state.endVerse}`
  }
  return s
})

const verses = computed<Array<number>>(() => {
  if (_.isEmpty(state.book)) {
    return []
  }
  const max = state.chapter ? state.book.chapters[state.chapter - 1].verses : 0
  return _.map(_.range(max), (i: number) => i + 1)
})

onMounted(() => {
  store.dispatch.teacher.fetchSections()

  watch(books, () => {
    parseAndSetSection()
  })

  watch(section, (newVal) => {
    emit('update:section', newVal)
  })
})

function clear(): void {
  state.book = null
  state.chapter = null
  state.verse = null
  state.endChapter = null
  state.endVerse = null
}

function parseAndSetSection() {
  logger.debug('parseAndSetSection, state.setSection =')
  logger.debug(state.setSection)
  if (_.isEmpty(books.value) || _.isEmpty(state.setSection)) {
    return
  }

  const xSection = state.setSection
  state.setSection = null

  if (!xSection) {
    logger.debug('unexpected null xSection in parseAndSetSection')
    return
  }

  const m = xregexp.exec(xSection, /^\s*(\S+)\s*\.\s*(\d+)\s*:\s*(\d+)/)
  if (!m) {
    logger.debug('unexpected null m in parseAndSetSection')
    return
  }

  // state.$log.info('m = ');
  // state.$log.info(m);
  const bookName = m[1].replace(/_/g, ' ')
  // state.$log.info(`bookName = ${bookName}`);
  const b = _.find(books.value, (b: { title: string; value: BookType }) => {
    return b.value.transliteratedName === bookName
  })
  if (!b) {
    logger.debug('unexpected null b in parseAndSetSection')
    return
  }

  state.book = b.value

  // state.$log.info(`book = `);
  // state.$log.info(state.book);
  nextTick(() => {
    const cx = _.find(chapters.value, (c: BookChapterType) => {
      return c.chapter === +m[2]
    })

    if (!cx) {
      logger.debug('unexpected null cx in parseAndSetSection')
      return
    }

    state.chapter = cx.chapter

    nextTick(() => {
      state.verse = parseInt(m[3])

      if (props.range) {
        nextTick(() => {
          if (!xSection) {
            logger.debug('unexpected null xSection in parseAndSetSection')
            return
          }
          const mx = xregexp.exec(xSection, /-\s*(\d+)\s*:\s*(\d+)\s*$/)
          if (!mx) {
            logger.debug('unexpected null mx in parseAndSetSection')
            return
          }

          const cxx = _.find(endChapters.value, (c) => {
            return c.chapter === parseInt(mx[1])
          })
          if (!cxx) {
            logger.debug('unexpected cxx in parseAndSetSection')
            return
          }
          state.endChapter = cxx.chapter
          nextTick(() => {
            state.endVerse = parseInt(mx[2])
          })
        })
      }
    })
  })
}

function update(section: string): void {
  logger.debug('VerseSelect, update()')
  state.setSection = section // gets updated when books() is set
  parseAndSetSection()
}

defineExpose({ clear, update })
</script>

<template>
  <div fluid>
    <template v-if="!store.getters.teacher.sections || !store.getters.teacher.sections.length">
      <v-row class="my-3">
        <v-col>
          <v-skeleton-loader type="card-avatar" />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row>
        <v-col md="3">
          <v-autocomplete v-if="books && books.length" v-model="state.book" :items="books" label="Book" variant="underlined" />
        </v-col>
      </v-row>
      <v-row class="my-3">
        <v-col md="2">
          <v-autocomplete
            v-model="state.chapter"
            :disabled="!state.book"
            item-title="chapter"
            :items="chapters"
            :label="range ? 'Start chapter' : 'Chapter'"
            variant="underlined"
          />
        </v-col>
        <v-col md="2">
          <v-select
            v-model="state.verse"
            :disabled="!state.chapter"
            :items="verses"
            :label="range ? 'Start verse' : 'Verse'"
            variant="underlined"
          />
        </v-col>
        <v-col v-if="range" md="2">
          <v-autocomplete
            v-model="state.endChapter"
            :disabled="!state.verse"
            item-title="chapter"
            :items="endChapters"
            label="End chapter"
            variant="underlined"
          />
        </v-col>
        <v-col v-if="range" md="2">
          <v-autocomplete
            v-model="state.endVerse"
            :disabled="!state.endChapter"
            :items="endVerses"
            label="End verse"
            variant="underlined"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>
