<script setup lang="ts">
import _ from 'lodash'
import { computed, onMounted, reactive, watch } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRoute, useRouter } from 'vue-router'
import { useTheme } from 'vuetify'

import AppBarPlain from '@/components/AppBarPlain.vue'
import AppBarStudent from '@/components/AppBarStudent.vue'
import AppBarTeacher from '@/components/AppBarTeacher.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import RecorderWindow from '@/components/RecorderWindow.vue'
import Snackbar from '@/components/SnackBar.vue'
import { useUtil } from '@/composables/util'
import store from '@/store'

const logger = useLogger()
const route = useRoute()
const router = useRouter()
const PAGES_WITHOUT_NAVBAR = ['index'] as Array<string>
const PAGES_WITHOUT_FOOTER = [] as Array<string>

const { forceLogout, isStudent, isTeacher } = useUtil(logger)

import faviconDark from './assets/images/favicon-dark.png'
import faviconLight from './assets/images/favicon-light.png'

const state = reactive({
  favicons: {
    dark: faviconDark,
    light: faviconLight,
  },
})

const pageWithAppBar = computed<boolean>(() => {
  return !PAGES_WITHOUT_NAVBAR.includes(route.name as string)
})

const pageWithFooter = computed<boolean>(() => {
  return !PAGES_WITHOUT_FOOTER.includes(route.name as string)
})

onMounted(() => {
  const vueTheme = useTheme()
  vueTheme.global.name.value = localStorage.theme
  const headElt = document.querySelector('head')

  //
  // set favicon
  //

  // https://stackoverflow.com/questions/56393880/how-do-i-detect-dark-mode-using-javascript
  const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

  // https://spemer.com/articles/set-favicon-with-javascript.html
  const faviconElt = document.createElement('link')
  faviconElt.setAttribute('rel', 'shortcut icon')
  faviconElt.setAttribute('href', state.favicons[theme])
  faviconElt.setAttribute('media', `(prefers-color-scheme: ${theme})`)
  headElt!.appendChild(faviconElt)

  watch(
    () => store.getters.user.userIsLoggedIn,
    (newValue, oldValue) => {
      // user logged out
      if (oldValue && !newValue) {
        logger.debug('forceLogout from watch store.getters.user.userIsLoggedIn')
        forceLogout(router)
        return
      }
    },
  )
})
</script>

<template>
  <v-app>
    <template v-if="pageWithAppBar">
      <template v-if="!store.getters.user.userIsLoggedIn">
        <app-bar-plain />
      </template>
      <template v-else>
        <app-bar-student v-if="isStudent()" />
        <app-bar-teacher v-else-if="isTeacher()" />
      </template>
    </template>
    <v-main>
      <v-container fluid>
        <recorder-window />
        <router-view />
        <v-footer v-if="pageWithFooter" app color="grey-lighten-3">
          <v-row align="center" justify="center" no-gutters>
            <v-btn class="mx-4 my-2" size="x-small" to="/about" variant="text"> About </v-btn>
            <v-btn class="mx-4 my-2" size="x-small" to="/contact" variant="text"> Contact </v-btn>
          </v-row>
        </v-footer>
      </v-container>
      <snackbar />
      <confirm-dialog />
    </v-main>
  </v-app>
</template>

<style>
/* Vuetify uses Google’s Roboto font and Material Design Icons. The simplest way to install these are to include their CDN’s in your main index.html file. */
/* <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900" rel="stylesheet"> */
@import './assets/vuetify/roboto-font.css';
/* <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet"> */
@import './assets/vuetify/materialdesignicons.min.css';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
.bmt {
  color: #343aa3;
}
.v-subheader {
  height: inherit;
}

// beautify scrollbars
// --scrollbarBG: #1e1e1e;

html {
  --scrollbarBG: #01579b; // light-blue-darken-4
  --thumbBG: #29b6f6; // light-blue-darken-2
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
}

th,
td {
  vertical-align: inherit !important;
}

// Change Autocomplete styles in Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff !important;
}
*/
</style>
