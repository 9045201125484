<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import store from '@/store'
const { t } = useI18n()
</script>

<template>
  <div>
    <v-snackbar v-for="(item, index) in store.getters.snackbar.messages" :key="index" v-model="item.visible" :timeout="3000">
      {{ item.message }}
      <template #actions>
        <v-btn :color="item.state" data-cy="snackbar-close" variant="text" @click="store.dispatch.snackbar.delete(index)">
          {{ t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
