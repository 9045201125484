<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import _ from 'lodash'
import { HaftarahType, ParashahType } from 'types/types'
import { useField, useForm } from 'vee-validate'
import { computed, nextTick, onMounted, reactive, ref, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import * as yup from 'yup'

import ParashahSelect from '@/components/ParashahSelect.vue'
import VerseSelect from '@/components/VerseSelect.vue'
import { formattedParashah } from '@/composables/format'
import { useUtil } from '@/composables/util'
import store from '@/store'
import { CreateHaftarahParameters } from '@/util/Haftarah'

const logger = useLogger()
const { isStaff, selectableUsers } = useUtil(logger)

interface CustomHaftarahData {
  haftarahId: string | null
  includeRootHaftarah: boolean
  tableHeaders: Array<object>
  formVisible: boolean
  parashah: ParashahType | null
  sections: Array<string>
}

const state = reactive<CustomHaftarahData>({
  formVisible: false,
  haftarahId: null,
  includeRootHaftarah: false,
  parashah: null,
  sections: new Array<string>(''),
  tableHeaders: [],
})

const { defineField, errors, handleSubmit, meta, resetForm } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      comment: yup.string(),
      owner: yup.string().required(),
      parashah: yup.object().required(),
      section: yup.string().trim().required(),
      variant: yup.string().trim(),
      year: yup
        .number()
        .required()
        .default(() => new Date().getFullYear()),
    }),
  ),
})

const [comment, commentAttrs] = defineField('comment')
const [owner, ownerAttrs] = defineField('owner')
const [variant, variantAttrs] = defineField('variant')
const [year, yearAttrs] = defineField('year')
const { value: section } = useField('section')
const { value: parashah } = useField('parashah')

const haftarahVerseSelect = ref<InstanceType<typeof VerseSelect> | null>(null)

onMounted(() => {
  resetNewHaftarah()
  store.dispatch.user.fetchUser()
  store.dispatch.teacher.fetchHaftarot({ mine: !isStaff(), parashah: null })
  state.tableHeaders = [
    { key: 'year', title: 'Year' },
    { key: 'parashah', title: 'Parashah' },
  ]
  if (isStaff()) {
    state.tableHeaders.push({ key: 'reading.user.username', title: 'User' })
  }
  state.tableHeaders.push(
    { key: 'section', sortable: false, title: 'Section' },
    {
      key: 'variant',
      sortable: false,
      title: 'Variant (Ashkenazi/Sephardic)',
    },
    { key: 'comment', sortable: false, title: 'Comment' },
    { key: 'action', sortable: false, title: ' ' },
  )

  watchEffect(() => {
    parashah.value = _.cloneDeep(state.parashah)
  })

  watchEffect(() => {
    section.value = state.sections ? state.sections.join(';') : ''
  })
})

// custom haftarot at the top
const sortedHaftarot = computed<Array<HaftarahType>>(() => {
  const rootHaftarot = !state.includeRootHaftarah
    ? []
    : _.sortBy(
        _.filter(store.getters.teacher.haftarot, (haftarah: HaftarahType) => {
          return haftarah.reading.user.username === 'root'
        }),
        ['parashah.order', 'year'],
      )

  const userHaftarot = _.sortBy(
    _.filter(store.getters.teacher.haftarot, (haftarah: HaftarahType) => {
      return haftarah.reading.user.username === store.getters.user.user!.username
    }),
    ['parashah.order', 'year'],
  )
  return userHaftarot.concat(rootHaftarot)
})

function addHaftarah() {
  resetNewHaftarah()
  state.sections = new Array<string>('') // default to one empty section
  state.parashah = null
  state.formVisible = true
  nextTick(() => {
    clearSection(0)
  })
}

const editHaftarah = (arg1: any, arg2?: any) => {
  let haftarah = null
  logger.debug(arg1)
  logger.debug(arg2)

  if (arg1?.reading) {
    haftarah = arg1
  }
  if (arg1?.item && arg1?.item?.reading) {
    haftarah = arg1.item
  }
  if (arg2?.reading) {
    haftarah = arg2
  }
  if (arg2?.item && arg2?.item?.reading) {
    haftarah = arg2.item
  }

  if (!haftarah) {
    logger.debug('unexpected null haftarah in editHaftarah')
    return
  }

  state.haftarahId = haftarah.reading.id
  state.formVisible = true
  state.parashah = haftarah.parashah
  owner.value = haftarah.reading.user.username
  variant.value = haftarah.variant ?? ''

  // first let the UI build up the sections
  state.sections = new Array<string>()
  const xSections = haftarah.section.split(/\s*;\s*/)
  _.forEach(xSections, (section: string) => {
    state.sections.push(section)
  })
  repopulateSections(xSections)
}

function repopulateSections(sections: Array<string>): void {
  _.forEach(sections, (section: string, index: number) => {
    populateSection(index, section)
  })
}

// waits for state.$refs.haftarahVerseSelect[index] to become available
function clearSection(index: number) {
  if (haftarahVerseSelect.value && haftarahVerseSelect.value[index]) {
    haftarahVerseSelect.value[index].clear()
  } else {
    setTimeout(() => {
      clearSection(index)
    }, 50)
  }
}

// waits for state.$refs.haftarahVerseSelect[index] to become available
function populateSection(index: number, section: string) {
  if (haftarahVerseSelect.value && haftarahVerseSelect.value[index]) {
    haftarahVerseSelect.value[index].update(section)
  } else {
    setTimeout(() => {
      populateSection(index, section)
    }, 50)
  }
}

function resetNewHaftarah() {
  state.haftarahId = null
  state.sections = []
  resetForm()

  if (!isStaff()) {
    if (!store.getters.user.user) {
      logger.debug('unexpected null store.getters.users.user')
    } else {
      owner.value = store.getters.user.user.username
    }
  }
}

const handleCreateHaftarah = handleSubmit(() => {
  store.dispatch.teacher.createHaftarah({
    comment: comment.value ?? '',
    id: parseInt(state.haftarahId ?? ''),
    parashah: state.parashah,
    section: section.value,
    username: owner.value ?? '',
    variant: variant.value ?? '',
    year: year.value ?? new Date().getFullYear(),
  } as CreateHaftarahParameters)
  if (!store.getters.teacher.error) {
    state.formVisible = false
    resetNewHaftarah()
  }
})

async function confirmDeleteHaftarah(haftarah: HaftarahType) {
  store.dispatch.dialog.show({
    isRejectable: true,
    message: 'Are you sure you want to delete state haftarah?',
    onAccept: () => store.dispatch.teacher.deleteHaftarah({ haftarah }),
    title: 'Confirm',
  })
}
</script>

<template>
  <v-container fluid>
    <!-- Add Haftarah -->
    <v-btn color="primary" @click="addHaftarah"> Add Haftarah </v-btn>

    <template v-if="store.getters.teacher.haftarot && store.getters.teacher.haftarot.length">
      <v-switch v-if="isStaff()" v-model="state.includeRootHaftarah" color="primary" label="Include system haftarot" />
      <v-data-table
        :headers="state.tableHeaders"
        :items="sortedHaftarot"
        no-data-text="[no haftarot in this selection]"
        :sort-by="[{ key: 'reading.description', order: 'asc' }]"
        @click:row="editHaftarah"
      >
        <template #item.parashah="{ item }">
          <span>{{ formattedParashah(item.parashah) }}</span>
        </template>
        <template #item.action="{ item }">
          <v-btn icon variant="plain" @click.capture.stop="() => editHaftarah(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            variant="plain"
            @click.capture.stop="
              () => {
                confirmDeleteHaftarah(item)
              }
            "
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </template>

    <!-- edit Haftarah -->
    <v-dialog id="newHaftarah" v-model="state.formVisible" class="mb-4 pb-4" no-click-animation @keydown.esc="state.formVisible = false">
      <v-form @submit="handleCreateHaftarah">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col> {{ state.haftarahId ? 'Change' : 'Add' }} Haftarah </v-col>
              <v-col class="text-right">
                <v-btn icon size="x-small" @click="state.formVisible = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row v-if="selectableUsers.length > 1" class="mt-3 pt-3">
              <v-col md="2">
                <v-autocomplete
                  v-bind="ownerAttrs"
                  v-model="owner"
                  :error-messages="errors.owner"
                  :items="selectableUsers"
                  label="Owner"
                  placeholder="Owner"
                  variant="underlined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="1">
                <v-text-field
                  v-model="year"
                  v-bind="yearAttrs"
                  :error-messages="errors.year"
                  label="Year"
                  placeholder="Year"
                  style="min-width: 5.5em"
                  type="number"
                  variant="underlined"
                />
              </v-col>
              <v-col md="3">
                <parashah-select
                  v-model:parashah="state.parashah"
                  :clearable="false"
                  :external-set="true"
                  :reset-selection-on-multiple="false"
                />
              </v-col>
              <v-col md="2">
                <!-- variant not required -->
                <v-autocomplete
                  v-model="variant"
                  v-bind="variantAttrs"
                  :clearable="false"
                  :error-messages="errors.variant"
                  :items="[
                    { title: 'Ashkenazi', value: 'A' },
                    { title: 'Sephardic', value: 'S' },
                    { title: 'Both', value: '' },
                  ]"
                  label="Ashkenazi / Sephardic"
                  variant="underlined"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="3">
                <v-text-field
                  v-model="comment"
                  v-bind="commentAttrs"
                  :error-messages="errors.comment"
                  label="Comment"
                  placeholder=""
                  type="text"
                  variant="underlined"
                />
              </v-col>
            </v-row>
            <v-row v-for="(__, index) in state.sections" :key="'haftarahSection-' + index">
              <v-col>
                <v-card outlined width="100%">
                  <v-card-subtitle>Haftarah Section {{ index + 1 }}</v-card-subtitle>
                  <v-card-text>
                    <verse-select
                      ref="haftarahVerseSelect"
                      v-model:section="state.sections[index]"
                      :range="true"
                      :selected-section="state.sections[index]"
                    />
                    <v-btn
                      @click="
                        () => {
                          state.sections.splice(index, 1)
                          repopulateSections(state.sections)
                        }
                      "
                      >Delete section</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                <v-btn
                  block
                  @click="
                    () => {
                      state.sections.push('')
                    }
                  "
                  >Add section</v-btn
                >
              </v-col>
            </v-row>

            <!-- buttons -->
            <v-row>
              <v-col md="1">
                <v-btn block color="primary" :disabled="!meta.valid || !state.sections || !state.sections.length" type="submit"
                  >{{ state.haftarahId ? 'Update' : 'Add' }}
                </v-btn>
              </v-col>
              <v-col md="1">
                <v-btn block @click="state.formVisible = false">Cancel</v-btn>
              </v-col>
              <v-col md="1">
                <v-btn block color="red-lighten-3" @click="resetNewHaftarah">Reset</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.error {
  color: red;
}
</style>
