import lodash from 'lodash'
import { useLogger } from 'vue-logger-plugin'
import { createRouter, createWebHistory } from 'vue-router'

import { useUtil } from '@/composables/util'
import store from '@/store'
import AboutView from '@/views/AboutView.vue'
import AdminView from '@/views/AdminView.vue'
import ColorsView from '@/views/ColorsView.vue'
import ContactView from '@/views/ContactView.vue'
import CustomizeView from '@/views/CustomizeView.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ImpersonateView from '@/views/ImpersonateView.vue'
import IndexView from '@/views/IndexView.vue'
import LoginView from '@/views/LoginView.vue'
import ParseView from '@/views/ParseView.vue'
import PracticeView from '@/views/PracticeView.vue'
import ProfileView from '@/views/ProfileView.vue'
import RecordingsView from '@/views/RecordingsView.vue'
import SetPassword from '@/views/SetPassword.vue'
import StudentDemo from '@/views/StudentDemo.vue'
import StudentsView from '@/views/StudentsView.vue'

const logger = useLogger()
const { GENDER_MAP, isProduction, isStaff, isStudent, isTeacher } = useUtil(logger)

const routes = [
  {
    component: IndexView,
    meta: {
      public: true, // Allow access to even if not logged in
    },
    name: 'index',
    path: '/',
  },
  {
    component: StudentDemo,
    meta: {
      // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      public: true,
    },
    name: 'student-demo',
    path: '/student-demo',
  },
  {
    component: LoginView,
    meta: {
      // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      public: true,
    },
    name: 'login',
    path: '/login',
    props: true,
  },
  {
    component: ParseView,
    meta: {
      public: true, // Allow access to even if not logged in
    },
    name: 'parse',
    path: '/parse',
  },
  {
    component: AboutView,
    meta: {
      public: true, // Allow access to even if not logged in
    },
    name: 'about',
    path: '/about',
  },
  {
    component: ContactView,
    meta: {
      public: true, // Allow access to even if not logged in
    },
    name: 'contact',
    path: '/contact',
  },
  {
    component: ForgotPassword,
    meta: {
      // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      public: true,
    },
    name: 'forgot-password',
    path: '/forgot-password',
    props: true,
  },
  {
    component: SetPassword,
    meta: {
      // Allow access to even if not logged in
      onlyWhenLoggedOut: true,
      public: true,
    },
    name: 'set-password',
    path: '/set-password/:secretToken',
    props: true,
  },

  // teacher pages
  {
    component: StudentsView,
    meta: { role: ['teacher'] },
    name: 'students',
    path: '/students',
  },
  {
    component: RecordingsView,
    meta: { role: ['teacher'] },
    name: 'recordings',
    path: '/recordings',
  },
  {
    component: CustomizeView,
    meta: { role: ['teacher'] },
    name: 'customize',
    path: '/customize',
  },
  {
    component: ColorsView,
    meta: { role: ['teacher'] },
    name: 'colors',
    path: '/colors',
  },

  // common pages
  {
    component: PracticeView,
    meta: { role: ['student', 'teacher'] },
    name: 'practice',
    path: '/practice',
  },

  {
    component: ProfileView,
    meta: { role: ['teacher', 'student'] },
    name: 'profile',
    path: '/profile',
    props: true,
  },
  {
    component: SetPassword,
    meta: { role: ['teacher', 'student'] },
    name: 'change-password',
    path: '/change-password',
  },
  {
    component: ImpersonateView,
    meta: { role: ['teacher', 'student'] },
    name: 'impersonate',
    path: '/impersonate',
  },

  // admin {
  {
    component: AdminView,
    meta: { role: ['admin'] },
    name: 'admin',
    path: '/admin',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // debugger
  // console.log('beforeEach, to =')
  // console.log(to)
  // console.log('beforeEach, from =')
  // console.log(from)
  // console.log('beforeEach, next =')
  // console.log(next)
  const isPublic = to.matched.some((record) => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut)

  // only track history when not impersonating
  if (store && !store.getters.user.impersonator) {
    store.dispatch.user.setImpersonatorUrl(from.path)
    // console.log(`setImpersonatorUrl = ${store.getters.user.impersonatorUrl}`)
  }

  const studentOnlyPage = to.matched.some((record) => record.meta && record.meta.role && lodash.isEqual(record.meta.role, ['student']))
  const teacherOnlyPage = to.matched.some((record) => record.meta && record.meta.role && lodash.isEqual(record.meta.role, ['teacher']))
  const staffOnlyPage = to.matched.some((record) => record.meta && record.meta.role && lodash.isEqual(record.meta.role, ['admin']))

  const userIsLoggedIn = store.getters.user.userIsLoggedIn

  // this isn't a public page and the user isn't logged in
  if (!isPublic && !userIsLoggedIn) {
    // console.log('router.index 00')
    return next({
      path: '/login',
      query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
    })
  }

  // deal with gender redirects
  const realUser = store.getters.user.impersonator || store.getters.user.user
  if (realUser && realUser.properties && realUser.properties.gender) {
    const { gender } = realUser.properties
    const { hostname } = window.location
    // note that we need to use the impersonator's gender
    const genderHostname = GENDER_MAP[gender].host
    const newLocation = `${window.location.protocol}//${genderHostname}${
      window.location.port ? `:${window.location.port}` : ''
    }${window.location.pathname}?gx=x&redirect=${to.fullPath}` // indicate gender change

    if (hostname !== genderHostname) {
      // console.log(`router.index, hostname ${hostname} !== genderHostname ${genderHostname}`)
      const urlParams = new URLSearchParams(window.location.search)
      const gx = urlParams.get('gx')
      if (gx === 'x') {
        // we got here through a gender change redirect; don't redirect back
        // console.log('router.index detected gix param; avoiding gx flap')
        router.push(urlParams.get('redirect') as string)
      } else if (isProduction()) {
        if (store.getters.user.impersonator) {
          // console.log('router.index prevents impersonation across a gender change')
          return
        }
        // console.log(`router.index replace URL with ${newLocation}`)
        window.location.replace(newLocation)
      } else {
        // console.log(`production would route to ${newLocation}`)
      }
    }
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (userIsLoggedIn) {
    // can only access this page when logged out
    if (onlyWhenLoggedOut) {
      // console.log('router.index A')
      return next('/')
    }

    if (isTeacher() && studentOnlyPage) {
      // console.log('router.index B')
      return next({ name: 'recordings' })
    }

    if (isStudent() && teacherOnlyPage) {
      // console.log('router.index C')
      return next({ name: 'practice' })
    }

    if (!isStaff() && staffOnlyPage) {
      // console.log('router.index D')
      return next({ name: '/' })
    }

    if (!!store.getters.user.impersonator && to.path === '/impersonate') {
      // console.log('router.index E')
      return next({ name: '/' })
    }
  }

  // console.log(`to.path = ${to.path}`)
  // console.log(`to.fullPath = ${to.fullPath}`)
  // console.log(`next = ${next}`)

  if (to.fullPath === '/') {
    if (userIsLoggedIn) {
      if (isTeacher()) {
        // console.log('router.index F')
        return next('/recordings')
      }
      if (isStudent()) {
        // console.log('router.index G')
        return next('/practice')
      }
    }
  }

  // console.log('router.index H')
  return next()
})

export default router
