<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import _ from 'lodash'
import { useField, useForm } from 'vee-validate'
import { onMounted, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { z as zod } from 'zod'

import { useZodValidation } from '@/composables/zodValidation'
import store from '@/store'

interface ForgotPasswordState {
  usernameOrEmail: string
}

const state = reactive<ForgotPasswordState>({
  usernameOrEmail: '',
})

const router = useRouter()

const { nonEmptyString } = useZodValidation()

const { errors, handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(
    zod.object({
      usernameOrEmail: nonEmptyString,
    }),
  ),
})

const { value: usernameOrEmail } = useField('usernameOrEmail')

onMounted(() => {
  watch(
    () => state.usernameOrEmail,
    () => {
      usernameOrEmail.value = state.usernameOrEmail
    },
  )
})

const submit = handleSubmit((values) => {
  if (_.isEmpty(state.usernameOrEmail)) {
    return
  }
  const r = store.dispatch.user.forgotUserPassword({
    usernameOrEmail: values.usernameOrEmail,
  })
  r.then(() => {
    // Password saved
    router.push({
      name: 'login',
      params: {
        msgBody: `Password reset email sent (if that username or email indeed exists)`,
        msgTitle: `Password reset`,
      },
    })
  })
})
</script>

<template>
  <v-form id="form" @submit="submit">
    <v-row>
      <v-col lg="3" md="5" sm="7" xl="2" xs="11">
        <v-text-field
          id="usernameOrEmail"
          v-model="state.usernameOrEmail"
          autofocus
          :error-messages="errors.usernameOrEmail"
          placeholder="Username or email"
          variant="underlined"
        />

        <v-row align-h="between">
          <v-col md="4">
            <v-btn block class="mr-2 pr-2" color="primary" :disabled="!meta.valid || store.getters.user.updating" type="submit">
              Submit
              <v-progress-circular v-if="store.getters.user.updating" class="mx-4" indeterminate size="20" />
            </v-btn>
          </v-col>
          <v-col md="4">
            <v-btn block color="warning" @click="() => $router.push({ name: 'login' })"> Cancel </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>
