import _ from 'lodash'
import { SentenceType } from 'types/types'
import { computed, Ref } from 'vue'
import { VueLogger } from 'vue-logger-plugin'

import { AnnotatedSentence } from '@/util/AnnotatedSentence'

const useSegmentation = (segmentation: Ref<Array<number>>, preSentences: Ref<Array<SentenceType>>, logger: VueLogger) => {
  //
  // sets a sentence segmentation either to whole sentence or individual words
  const RESET_TO_SENTENCE = 0
  const RESET_TO_WORDS = 1

  const segmentations = computed<Array<Array<number>>>((): Array<Array<number>> => {
    logger.debug('computed segmentations; segmentation = ')
    logger.debug(segmentation.value)
    let ss = _.compact([segmentation.value])

    // this is to ensure that if there are surrounding sentences, the
    // segmentation information is shifted forward in the array. In other
    // words, if surround == 2, then the segmented sentences we care about
    // needs to be 3rd in the arary; we prepend bogus [] entries in the array
    // before the ss entry
    if (preSentences.value?.length) {
      ss = new Array(preSentences.value.length).fill([]).concat(ss)
    }

    return ss
  })

  function resetSegments(how = RESET_TO_WORDS, annotatedSentence: Ref<AnnotatedSentence | null>): void {
    if (!annotatedSentence.value) {
      return
    }

    segmentation.value = new Array<number>()
    const nWords = annotatedSentence.value.nWords()
    if (how === RESET_TO_SENTENCE) {
      segmentation.value.push(nWords)
    } else if (how === RESET_TO_WORDS) {
      for (let i = 0; i < annotatedSentence.value.nSegments(); i++) {
        segmentation.value.push(1)
      }
    }
  }

  return {
    RESET_TO_SENTENCE,
    RESET_TO_WORDS,
    resetSegments,
    segmentations,
  }
}

export { useSegmentation }
