import { defineModule, localActionContext, localGetterContext } from 'direct-vuex'
import _ from 'lodash'

import { HaftarahMelodyType, PronunciationType, SentenceGroupMelodyType, TorahMelodyType } from '../../types/types'
import HaftarahMelodiesQuery from '../queries/HaftarahMelodiesQuery.graphql'
import PronunciationsQuery from '../queries/PronunciationsQuery.graphql'
import SentenceGroupMelodiesQuery from '../queries/SentenceGroupMelodiesQuery.graphql'
import TorahMelodiesQuery from '../queries/TorahMelodiesQuery.graphql'
import { useApollo } from '../util/apolloClient'
const { apolloClient } = useApollo()

export interface StyleState {
  torahMelodies: Array<TorahMelodyType>
  haftarahMelodies: Array<HaftarahMelodyType>
  sentenceGroupMelodies: Array<SentenceGroupMelodyType>
  pronunciations: Array<PronunciationType>
}

const resetState = (): StyleState => {
  return {
    haftarahMelodies: [],
    pronunciations: [],
    sentenceGroupMelodies: [],
    torahMelodies: [],
  }
}

const style = defineModule({
  actions: {
    fetchStyles(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = styleActionContext(context)
      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: TorahMelodiesQuery,
        })
        .then((response) => {
          commit.setTorahMelodies(response.data.torahMelodies)
        })

      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: HaftarahMelodiesQuery,
        })
        .then((response) => {
          commit.setHaftarahMelodies(response.data.haftarahMelodies)
        })

      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: SentenceGroupMelodiesQuery,
        })
        .then((response) => {
          commit.setSentenceGroupMelodies(response.data.sentenceGroupMelodies)
        })

      apolloClient
        .query({
          fetchPolicy: 'network-only',
          query: PronunciationsQuery,
        })
        .then((response) => {
          commit.setPronunciations(response.data.pronunciations)
        })
    },

    reset(context) {
      // eslint-disable-next-line no-use-before-define
      const { commit } = styleActionContext(context)
      commit.reset()
    },
  },
  getters: {
    haftarahMelodies(...args): Array<HaftarahMelodyType> {
      // eslint-disable-next-line no-use-before-define
      const { state } = styleGetterContext(args)
      return state.haftarahMelodies
    },

    pronunciations(...args): Array<PronunciationType> {
      // eslint-disable-next-line no-use-before-define
      const { state } = styleGetterContext(args)
      return state.pronunciations
    },

    sentenceGroupMelodies(...args): Array<SentenceGroupMelodyType> {
      // eslint-disable-next-line no-use-before-define
      const { state } = styleGetterContext(args)
      return state.sentenceGroupMelodies
    },

    torahMelodies(...args): Array<TorahMelodyType> {
      // eslint-disable-next-line no-use-before-define
      const { state } = styleGetterContext(args)
      return state.torahMelodies
    },
  },

  mutations: {
    reset(state: StyleState) {
      console.log('store style, reset mutation, _.clone start')
      Object.keys(resetState()).forEach((key) => {
        ;(state as any)[key] = _.clone((resetState() as any)[key])
      })
      console.log('store style, reset mutation, _.clone end')
    },

    setHaftarahMelodies(state: StyleState, haftarahMelodies: Array<HaftarahMelodyType>) {
      state.haftarahMelodies = _.cloneDeep(haftarahMelodies)
    },

    setPronunciations(state: StyleState, pronunciations: Array<PronunciationType>) {
      state.pronunciations = _.cloneDeep(pronunciations)
    },

    setSentenceGroupMelodies(state: StyleState, sentenceGroupMelodies: Array<SentenceGroupMelodyType>) {
      state.sentenceGroupMelodies = _.cloneDeep(sentenceGroupMelodies)
    },

    setTorahMelodies(state: StyleState, torahMelodies: Array<TorahMelodyType>) {
      state.torahMelodies = _.cloneDeep(torahMelodies)
    },
  },

  namespaced: true as const,

  state: resetState(),
})

export default style
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styleGetterContext = (args: [any, any, any, any]) => localGetterContext(args, style)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styleActionContext = (context: any) => localActionContext(context, style)
