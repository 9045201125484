import _ from 'lodash'
import { SentenceType } from 'types/types'
import { Ref, watch } from 'vue'
import { VueLogger } from 'vue-logger-plugin'

import store from '@/store'

const useSentences = (sentences: Ref<Array<SentenceType>>, logger: VueLogger) => {
  return {
    watchSentences() {
      // curiously, () => sentences syntax does _now_ work here
      watch(sentences, () => {
        logger.debug('watch sentences from useSentences, downloading sentences.value =')
        logger.debug(sentences.value)
        if (_.isEmpty(sentences.value)) {
          return
        }
        store.dispatch.audio.fetchRecordings({ sentences: sentences.value })
      })
    },
  }
}

export { useSentences }
