<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable'
import _ from 'lodash'
import { ParashahType } from 'types/types'
import { computed, onMounted, reactive, ref, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import { formattedParashah } from '@/composables/format'
import ParshiyotQuery from '@/queries/ParshiyotQuery.graphql'

interface Props {
  clearable?: boolean
  disabled?: boolean
  externalSet?: boolean
  only?: Array<ParashahType> | null
  parashah: ParashahType | null
  preventAutoSingleSelect?: boolean
  remove?: Array<ParashahType>
  resetSelectionOnMultiple?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  clearable: true,
  disabled: false,
  externalSet: false,
  only: null,
  preventAutoSingleSelect: false,
  remove: (): Array<ParashahType> => [],
  resetSelectionOnMultiple: false,
})

const logger = useLogger()

const emit = defineEmits(['update:parashah'])
const innerParashahSelect = ref<HTMLElement | null>(null)

const focus = (): void => {
  innerParashahSelect.value?.focus()
}

const { result } = useQuery(ParshiyotQuery)

const filteredParshiyot = computed<Array<ParashahType>>(() => {
  const parshiyot = props.only ?? result.value?.parshiyot
  if (_.isEmpty(parshiyot)) {
    return []
  }

  // remove all elements from haftarot that are also in this.remove
  const filteredParshiyot = _.filter(parshiyot, (a): boolean => {
    return !_.find(props.remove, (b): boolean => {
      return a.reading.id === b.reading.id
    })
  })

  return _.sortBy(filteredParshiyot, ['order'])
})

const state = reactive({
  parshiyot: [] as Array<ParashahType>,
  selectedParashah: props.parashah as ParashahType | null,
})

onMounted(() => {
  watchEffect(() => {
    if (props.externalSet) {
      state.selectedParashah = props.parashah
    }
  })

  watchEffect(() => {
    if (filteredParshiyot.value.length === 1) {
      if (!props.preventAutoSingleSelect) {
        state.selectedParashah = filteredParshiyot.value[0]
      }
    } else if (props.resetSelectionOnMultiple) {
      logger.debug('setting state.selectedParashah to null from watchEffect')
      state.selectedParashah = null
    }
  })

  watchEffect(() => {
    logger.debug('ParashahSelect watch state.selectedParashah, state.selectedParashah =')
    logger.debug(state.selectedParashah)
    emit('update:parashah', state.selectedParashah)
  })
})
defineExpose({ focus })
</script>

<template>
  <v-autocomplete
    ref="innerParashahSelect"
    v-model="state.selectedParashah"
    :clearable="props.clearable"
    :disabled="props.disabled"
    :item-title="
      (parashah: ParashahType) => {
        return formattedParashah(parashah)
      }
    "
    :items="filteredParshiyot"
    :label="state.selectedParashah ? 'Parashah' : 'Choose a parashah'"
    :loading="!filteredParshiyot || !filteredParshiyot.length"
    return-object
    variant="underlined"
  />
</template>
