import { AnnotationType } from 'types/types'

import { AnnotatedWord } from './AnnotatedWord'
import { regularStyle } from './TextStyles'

interface CharacterStyleValue {
  color?: string
  backgroundColor?: string
}

class AnnotatedCharacter {
  private xword: AnnotatedWord

  private text: string

  private offsetInSentence: number

  public styleClass: string

  public overrideStyle: CharacterStyleValue

  constructor(word: AnnotatedWord, offsetInSentence: number, text: string, annotation: AnnotationType | null) {
    this.xword = word
    this.offsetInSentence = offsetInSentence
    this.text = text

    this.styleClass = regularStyle
    if (annotation && annotation.style) {
      this.overrideStyle = JSON.parse(annotation.style)
    } else {
      this.overrideStyle = {} as CharacterStyleValue
    }
  }

  word(): AnnotatedWord {
    return this.xword
  }

  index(): number {
    return this.offsetInSentence
  }

  character(): string {
    return this.text
  }
}

export { AnnotatedCharacter, CharacterStyleValue }
