const dict = {
  en: {
    controls: {
      first: 'First',
      last: 'Last',
      next: 'Next',
      previous: 'Previous',
    },
    labels: {
      text: 'Text',
    },
    message: {
      pitchChange: 'Changes the pitch of play back',
    },
    titles: {
      practice: 'Practice',
    },
  },
}

export default dict
