<script setup lang="ts">
import _ from 'lodash'
import { HaftarahType, ParashahType } from 'types/types'
import { computed, onMounted, reactive, ref, watchEffect } from 'vue'

import { formattedHaftarahForTeacher, formattedHaftarahSimple } from '@/composables/format'
import store from '@/store'

interface Props {
  clearable?: boolean
  disabled?: boolean
  externalSet?: boolean
  haftarah: HaftarahType | null
  only?: Array<HaftarahType> | null
  parashah: ParashahType | null
  preventAutoSingleSelect?: boolean
  remove?: Array<HaftarahType>
  resetSelectionOnMultiple?: boolean
}

const xprops = withDefaults(defineProps<Props>(), {
  clearable: false,
  disabled: false,
  externalSet: false,
  only: null,
  preventAutoSingleSelect: false,
  remove: (): Array<HaftarahType> => [],
  resetSelectionOnMultiple: false,
})

const emit = defineEmits(['update:haftarah'])
const innerHaftarahSelect = ref<HTMLElement | null>(null)

const state = reactive({
  selectedHaftarah: xprops.haftarah as HaftarahType | null,
})

const filteredHaftarot = computed<Array<HaftarahType>>(() => {
  const haftarot = xprops.only ?? store.getters.teacher.haftarot
  if (_.isEmpty(haftarot)) {
    return []
  }

  // remove all elements from haftarot that are also in this.remove
  const filteredHaftarot = _.filter(haftarot, (a) => {
    return !_.find(xprops.remove, (b) => {
      return a.reading.id === b.reading.id
    })
  })

  return _.sortBy(filteredHaftarot, [
    (haftarah: HaftarahType) => parseInt(haftarah.reading.id, 10),
    (haftarah: HaftarahType) => haftarah.year,
  ])
})

function focus() {
  innerHaftarahSelect.value?.focus()
}

function clear() {
  state.selectedHaftarah = null
}

defineExpose({ clear, focus })

onMounted(() => {
  store.dispatch.teacher.fetchHaftarot({
    mine: false,
    parashah: xprops.parashah,
  })

  watchEffect(() => {
    if (xprops.externalSet) {
      state.selectedHaftarah = xprops.haftarah
    }
  })

  watchEffect(() => {
    if (filteredHaftarot.value.length === 1) {
      if (!xprops.preventAutoSingleSelect) {
        state.selectedHaftarah = filteredHaftarot.value[0]
      }
    } else if (xprops.resetSelectionOnMultiple) {
      state.selectedHaftarah = null
    }
  })

  watchEffect(() => {
    if (state.selectedHaftarah) {
      store.dispatch.student.fetchHaftarahSentences({
        haftarah: state.selectedHaftarah,
      })
    }
    emit('update:haftarah', state.selectedHaftarah)
  })
})
</script>

<template>
  <v-autocomplete
    ref="innerHaftarahSelect"
    v-model="state.selectedHaftarah"
    :clearable="clearable"
    :disabled="disabled"
    :item-title="
      (haftarah: HaftarahType) => {
        return formattedHaftarahSimple(haftarah)
      }
    "
    :items="filteredHaftarot"
    :label="state.selectedHaftarah ? 'Haftarah' : 'Choose a haftarah'"
    :loading="!filteredHaftarot || !filteredHaftarot.length"
    return-object
    variant="underlined"
  >
    <template #item="{ item, props }">
      <v-list-item v-bind="props" :title="formattedHaftarahForTeacher(item.raw)" />
    </template>

    <template #selection="{ item }">
      <v-list-item :title="formattedHaftarahSimple(item.raw)" />
    </template>
  </v-autocomplete>
</template>
