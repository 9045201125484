<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import _ from 'lodash'
import { SentenceGroupType, SentenceType } from 'types/types'
import { useField, useForm } from 'vee-validate'
import { computed, nextTick, onMounted, reactive, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { VDataTable } from 'vuetify/components'
import xregexp from 'xregexp'
import * as yup from 'yup'

import AnnotatedText from '@/components/AnnotatedText.vue'
import Popover from '@/components/PopOver.vue'
import { useFont } from '@/composables/font'
import { useUtil } from '@/composables/util'
import store from '@/store'
import { AnnotatedSegment } from '@/util/AnnotatedSegment'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'

const logger = useLogger()
const { isStaff, selectableUsers } = useUtil(logger)

const { fontSize, lineHeight } = useFont()

interface CustomTextData {
  annotatedSentences: Array<AnnotatedSentence> | null
  clickedAnnotatedSegment: AnnotatedSegment | null
  clickedSentenceIndex: number | null
  formVisible: boolean
  includeRootSentenceGroups: boolean
  popoverTarget: string | null
  sentenceGroupId: string | null
  sentenceGroupsSortBy: Array<string>
  sentenceGroupsSortDesc: boolean
  sentences: Array<SentenceType>
  tableHeaders: Array<object>
}

const state = reactive<CustomTextData>({
  annotatedSentences: null,
  clickedAnnotatedSegment: null,
  clickedSentenceIndex: null,
  formVisible: false,
  includeRootSentenceGroups: false,
  popoverTarget: null,
  sentenceGroupId: null,
  sentenceGroupsSortBy: ['reading.description'],
  sentenceGroupsSortDesc: false,
  sentences: [], // managed by AnnotatedText
  tableHeaders: [],
})

const { defineField, errors, handleSubmit, meta, resetForm } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      description: yup.string().required(),
      owner: yup.string().required(),
      sentences: yup.array().min(1),
      text: yup.string().trim().required(),
    }),
  ),
})
const [description, descriptionAttrs] = defineField('description')
const [owner, ownerAttrs] = defineField('owner')
const [text, textAttrs] = defineField('text')
const { errorMessage: sentencesError, value: sentences } = useField('sentences')

onMounted(() => {
  store.dispatch.user.fetchUser()

  state.tableHeaders = [{ key: 'reading.description', title: 'Description' }]
  if (isStaff()) {
    state.tableHeaders.push({
      key: 'reading.user.username',
      title: 'User',
    })
  }
  state.tableHeaders.push(
    { key: 'joinedText', sortable: false, title: 'Text' },
    { key: 'numberOfRecordings', title: 'Recordings' },
    { key: 'action', sortable: false, title: ' ' },
  )

  watchEffect(() => {
    sentences.value = state.sentences
  })

  watchEffect(() => {
    logger.debug('refetchSentenceGroups')
    store.dispatch.teacher.fetchSentenceGroups({ mine: !state.includeRootSentenceGroups && isStaff() })
  })
})

const sentencesFromNewAnnotatedSentences = computed<Array<SentenceType>>((): Array<SentenceType> => {
  return _.map(state.annotatedSentences as Array<AnnotatedSentence>, (annotatedSentence: AnnotatedSentence) => {
    return annotatedSentence.sentence()
  })
})

const sortedSentenceGroups = computed<Array<SentenceGroupType>>((): Array<SentenceGroupType> => {
  const rootSentenceGroups = !state.includeRootSentenceGroups
    ? []
    : _.sortBy(
        _.filter(store.getters.teacher.sentenceGroups, (sentenceGroup: SentenceGroupType) => {
          return sentenceGroup.reading.user.username === 'root'
        }),
        ['reading.description'],
      )
  logger.debug('sortedAliyot, rootSentenceGroups =')
  logger.debug(rootSentenceGroups)

  const userSentenceGroups = _.sortBy(
    _.filter(store.getters.teacher.sentenceGroups, (sentenceGroup: SentenceGroupType) => {
      return sentenceGroup.reading.user.username === store.getters.user.user!.username
    }),
    ['reading.description'],
  )
  logger.debug('sortedAliyot, userSentenceGroups =')
  logger.debug(userSentenceGroups)

  return userSentenceGroups.concat(rootSentenceGroups)
})

function addNewSentenceGroup() {
  state.sentences = []
  state.sentenceGroupId = null
  resetForm()

  if (!isStaff()) {
    if (!store.getters.user.user) {
      logger.debug('unexpected null store.getters.users.user')
    } else {
      logger.debug('setting owner.value')
      owner.value = store.getters.user.user.username
    }
  }
  state.formVisible = true
}

const editSentenceGroup = (__: Event, row: any) => {
  if (!row) {
    logger.debug('unexpected null editSentenceGroup in editSentenceGroup')
    return
  }

  const sentenceGroup = row.item as SentenceGroupType
  logger.debug('editSentenceGroup, sentenceGroup =')
  logger.debug(sentenceGroup)
  state.sentenceGroupId = sentenceGroup.reading.id
  description.value = sentenceGroup.reading.description ?? ''
  owner.value = sentenceGroup.reading.user.username
  text.value = _.map(sentenceGroup.sentences, (s: SentenceType) => s.text).join('\n')
  runSplitWizard()
  state.formVisible = true

  if (!!sentenceGroup && sentenceGroup.recordings.length) {
    nextTick(() => {
      store.dispatch.dialog.show({
        isRejectable: true,
        message:
          'Modifying a text for which recordings already exist is very risky. You may lose or invalidate your existing recordings. Changing the description is safe.',
        onReject: () => {
          state.formVisible = false
        },
        title: 'Warning',
      })
    })
  }
}

function formattedJoinedText(sentenceGroup: SentenceGroupType): string {
  const re = xregexp('^(.{60}).*(.{60})$')
  const t = _.map(sentenceGroup.sentences, (s: SentenceType) => s.text).join(' ')
  // eslint-disable-next-line no-template-curly-in-string
  return xregexp.replace(t, re, '${1} ... ${2}')
}

function numberOfRecordings(sentenceGroup: SentenceGroupType): number {
  return sentenceGroup.recordings.length
}

function runSplitWizard() {
  if (!text.value) {
    logger.debug('unexpected null text.value')
    return
  }

  const splitChar = xregexp('[\u05c3\u05c5\n]')
  const rawLines = xregexp.split(text.value, splitChar)
  state.sentences = _.filter(
    _.map(rawLines, (rawLine: string) => {
      return {
        text: rawLine.trim(),
      } as SentenceType
    }),
    (sentence: SentenceType) => {
      return !_.isEmpty(sentence.text)
    },
  )
}

const handleClickWrapper = (callback: () => void) => {
  closePopover()
  nextTick(() => {
    callback()
  })
}

const handleLineNumberClick = (__: AnnotatedSentence, sentenceIndex: number) => {
  if (sentenceIndex === 0) {
    return
  }
  handleClickWrapper(() => {
    state.clickedSentenceIndex = sentenceIndex
    state.clickedAnnotatedSegment = null
    state.popoverTarget = `newText:${sentenceIndex}:number`
  })
}

function closePopover() {
  state.popoverTarget = null
  state.clickedAnnotatedSegment = null
  state.clickedSentenceIndex = null
}

function mergeSentences() {
  const i = state.clickedSentenceIndex // copy value before closePopover nulls it

  if (i === null) {
    logger.debug('unexpected null i in mergeSentences')
    return
  }

  closePopover()
  const sentences = [...sentencesFromNewAnnotatedSentences.value]
  sentences[i - 1].text = `${sentences[i - 1].text} ${sentences[i].text}`.trim()
  sentences.splice(i, 1)
  state.sentences = [...sentences]
}

// state very much relies on segment boundaries being the same as word boundaries
function handleSegmentClick(__: AnnotatedSentence, annotatedSegment: AnnotatedSegment, sentenceIndex: number, segmentIndex: number): void {
  // can't split before first word
  if (segmentIndex === 0) {
    return
  }

  logger.debug('handleSegmentClick, sentenceIndex = ')
  logger.debug(sentenceIndex)
  logger.debug('handleSegmentClick, segmentIndex = ')
  logger.debug(segmentIndex)
  logger.debug('handleSegmentClick, annotatedSegment = ')
  logger.debug(annotatedSegment)

  handleClickWrapper(() => {
    state.clickedSentenceIndex = sentenceIndex
    state.clickedAnnotatedSegment = annotatedSegment
    state.popoverTarget = `newText:${sentenceIndex}:${segmentIndex}:segment`
  })
}

function splitSentences() {
  // copy values before closePopover nulls it
  const annotatedWord = state.clickedAnnotatedSegment
  const sentenceIndex = state.clickedSentenceIndex

  if (!annotatedWord) {
    logger.debug('unexpected null annotatedWord in splitSentences()')
    return
  }

  if (sentenceIndex === null) {
    logger.debug('unexpected null sentenceIndex in splitSentences()')
    return
  }

  closePopover()
  const annotatedSentence = annotatedWord!.sentence()
  if (!annotatedSentence) {
    logger.debug('unexpected null annotatedSentence in splitSentences()')
    return
  }

  if (!annotatedSentence.sentence().text) {
    logger.debug('unexpected null annotatedSegment.sentence().text in splitSentences()')
    return
  }

  const t1 = annotatedSentence.sentence().text!.slice(0, annotatedWord.offsetInSentence()).trim()
  logger.debug('splitSentence t1 =')
  logger.debug(t1)
  const t2 = annotatedSentence.sentence().text!.slice(annotatedWord.offsetInSentence()).trim()
  logger.debug('splitSentence t2 =')
  logger.debug(t2)
  const sentences = [...sentencesFromNewAnnotatedSentences.value]
  logger.debug('splitSentence, sentences =')
  logger.debug(sentences)
  logger.debug('splitSentence, sentenceIndex =')
  logger.debug(sentenceIndex)
  sentences[sentenceIndex].text = t1
  sentences.splice(sentenceIndex + 1, 0, reactive({ text: t2 }) as SentenceType)
  state.sentences = [...sentences].map((sentence, index) => ({
    ...sentence,
    id: index,
  })) as unknown as Array<SentenceType>
  logger.debug('splitSentence, sentences =')
  logger.debug(state.sentences)
}

const handleCreateSentenceGroup = handleSubmit(() => {
  store.dispatch.teacher.createSentenceGroup({
    description: description.value ? description.value.trim() : '',
    id: state.sentenceGroupId ? parseInt(state.sentenceGroupId) : 0,
    text: _.map(sentencesFromNewAnnotatedSentences.value as Array<SentenceType>, (s: SentenceType) => s.text as string),
    username: owner.value ?? '',
  })

  if (_.isEmpty(store.getters.teacher.error)) {
    closePopover()
    state.formVisible = false
    description.value = ''
    text.value = ''
    state.sentences = []
  }
})

async function confirmDeleteSentenceGroup(sentenceGroup: SentenceGroupType) {
  state.formVisible = false // in case it popped open
  if (sentenceGroup.recordings.length) {
    store.dispatch.dialog.show({
      isRejectable: false,
      message: 'Cannot delete text with recordings; delete those first.',
      title: 'Error',
    })
    return
  }

  store.dispatch.dialog.show({
    message: 'Are you sure you want to delete this text?',
    onAccept: () => {
      store.dispatch.teacher.deleteSentenceGroup({ sentenceGroup })
    },
    title: 'Confirm',
  })
}
</script>

<template>
  <v-container fluid>
    <v-btn class="px-6" color="primary" @click="addNewSentenceGroup"> Add text </v-btn>

    <v-switch v-if="isStaff()" v-model="state.includeRootSentenceGroups" color="primary" label="Include system b'rachot / songs / texts" />
    <v-data-table
      v-if="store.getters.teacher.sentenceGroups && store.getters.teacher.sentenceGroups.length"
      :headers="state.tableHeaders"
      :items="sortedSentenceGroups"
      no-data-text="Add a text"
      :sort-by="[{ key: 'reading.description', order: 'asc' }]"
      @click:row="editSentenceGroup"
    >
      <template #item.joinedText="{ item }">
        <span>{{ formattedJoinedText(item) }}</span>
      </template>
      <template #item.numberOfRecordings="{ item }">
        <span>{{ numberOfRecordings(item) }}</span>
      </template>
      <template #item.action="{ item }">
        <v-btn icon variant="plain" @click="editSentenceGroup">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn :disabled="!!item.recordings.length" icon variant="plain" @click.capture.stop="confirmDeleteSentenceGroup(item)">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- edit a custom text -->
    <v-dialog
      id="newText"
      ref="newText"
      v-model="state.formVisible"
      class="mb-4 pb-4"
      content-class="bmt-popup-dialog"
      no-click-animation
      overlay-color="secondary"
      overlay-opacity="0.4"
      :retain-focus="false"
      @keydown.esc="state.formVisible = false"
    >
      <v-card class="mb-12 pb-12">
        <v-card-title>
          <v-row>
            <v-col>
              {{ state.sentenceGroupId !== null ? 'Change' : 'Add' }}
              Text
            </v-col>
            <v-col class="text-right">
              <v-btn icon size="x-small" @click="state.formVisible = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-card>
            <v-card-title>Step 1: Describe new text</v-card-title>
            <v-card-text>
              <v-row class="mt-3 pt-3">
                <v-col md="4">
                  <v-text-field
                    v-bind="descriptionAttrs"
                    v-model="description"
                    :error-messages="errors.description"
                    label="Description"
                    placeholder="Description"
                    type="text"
                    variant="underlined"
                  />
                </v-col>
              </v-row>

              <v-row v-if="selectableUsers.length > 1">
                <v-col md="2">
                  <v-autocomplete
                    v-model="owner"
                    v-bind="ownerAttrs"
                    :error-messages="errors.owner"
                    :items="selectableUsers"
                    label="Owner"
                    placeholder="Owner"
                    variant="underlined"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-12">
            <v-card-title>Step 2: Paste text</v-card-title>
            <v-card-text>
              <v-row class="mt-1 pt-1 mb-0 py-0">
                <v-col md="6">
                  <v-textarea
                    v-model="text"
                    v-bind="textAttrs"
                    class="my-0 py-0"
                    :error-messages="errors.text"
                    height="400px"
                    placeholder="Paste text here"
                  />
                </v-col>
              </v-row>

              <v-row class="mt">
                <v-col md="2">
                  <v-btn class="px-6" color="primary" :disabled="!text || _.isEmpty(text.trim())" @click.capture.stop="runSplitWizard">
                    Split text
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card v-if="state.sentences && state.sentences.length" class="mt-12">
            <v-card-title>Step 3: Split or merge text into sentences</v-card-title>
            <v-card-text>
              <v-row class="mt-1 pt-1">
                <v-col align-self="end" md="12">
                  <popover :attach-id="state.popoverTarget" :visible="!!state.popoverTarget">
                    <v-card>
                      <v-card-title>
                        {{ state.clickedAnnotatedSegment ? 'Split before ' + state.clickedAnnotatedSegment.segment() : 'Merge sentences' }}
                      </v-card-title>
                      <v-card-text>
                        <v-btn v-if="state.clickedAnnotatedSegment" class="mr-2 pr-2" color="primary" @click="splitSentences">
                          <v-icon left> mdi-arrow-split-vertical </v-icon>
                          <span>Split</span>
                        </v-btn>
                        <v-btn v-else class="mr-2 pr-2" color="primary" @click="mergeSentences">
                          Merge
                          <v-icon>mdi-arrow-collapse-horizontal</v-icon>
                        </v-btn>
                        <v-btn color="primary" @click="closePopover"> Close </v-btn>
                      </v-card-text>
                    </v-card>
                  </popover>
                  <annotated-text
                    v-if="store.getters.user.user"
                    v-model:annotated-sentences="state.annotatedSentences as Array<AnnotatedSentence>"
                    :break-before-line="
                      () => {
                        return true
                      }
                    "
                    :clickable-line-number="
                      (_, sentenceIndex) => {
                        return sentenceIndex > 0
                      }
                    "
                    :clickable-segment="
                      (__, ___, ____, segmentIndex) => {
                        return segmentIndex > 0
                      }
                    "
                    :default-font="store.getters.user.user.properties!.font"
                    :font-size="fontSize"
                    :line-height="lineHeight"
                    name="newText"
                    :sentences="state.sentences"
                    :show-end-of-sentence-character="true"
                    :show-sentence-numbers="true"
                    @click-line-number="handleLineNumberClick"
                    @click-segment="handleSegmentClick"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row v-if="text && !_.isEmpty(text.trim()) && !_.isEmpty(sentencesError)" class="mt-4">
            <v-col>
              <div class="text-red">{{ sentencesError }}</div>
              <div>Did you press the "Split Text" button?</div>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col md="1">
              <v-btn class="ml-2 px-6" color="primary" :disabled="!meta.valid" @click="handleCreateSentenceGroup">
                {{ state.sentenceGroupId !== null ? 'Update' : 'Create' }}
              </v-btn>
            </v-col>
            <v-col md="1">
              <v-btn class="ml-2 px-6" @click="state.formVisible = false"> Cancel </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.annotated-scroller {
  height: 30vh;
  border-style: solid;
  border-color: darkgray;
  border-width: 1px;
  border-radius: 4px;
}
</style>
