import _ from 'lodash'
import { TagType } from 'types/types'
import { computed, Ref } from 'vue'
import { useLogger, VueLogger } from 'vue-logger-plugin'

import store from '@/store'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { SentenceRecordingMetadataWithPitchedAudios } from '@/util/AudioUtil'
import { inReadMode, VoiceStyleSet } from '@/util/VoiceStyles'

// PitchedSentenceOrSegmentAudio is  [from src/util/AudioUtil.ts]
//
// interface PitchedSentenceOrSegmentAudio {
//   sentenceHTMLAudioWrapper: HTMLAudioWrapper
//   segmentHTMLAudioWrappers: Array<HTMLAudioWrapper>
//   pitchShift: PitchShiftType
// }
//
// and SentenceRecordingMetadataWithPitchedAudios is  [from src/util/AudioUtil.ts]
//
// interface SentenceRecordingMetadataWithPitchedAudios {
//   downloading: boolean
//   sentenceRecording: SentenceRecordingType | null
//   updating: boolean
//   audio: {
//     [pitchShift: string]: PitchedSentenceOrSegmentAudio
//   }
// }
//
// and HTMLAudioWrapper is [from src/util/AudioUtil.ts]
//
// interface HTMLAudioWrapper {
//   audio: HTMLAudioElement | null
//   playStatus: string | null
//   blobUrl: string | null
// }
//

type StyledRecordings = Record<string, Array<SentenceRecordingMetadataWithPitchedAudios>>

// So StyledRecordings = {
//   [sentenceId]: [
//   {
//     downloading: boolean
//     sentenceRecording: SentenceRecordingType | null
//     updating: boolean
//     audio: {
//       [pitchShift: string]: PitchedSentenceOrSegmentAudio
//     }
//   },
//   {
//     downloading: boolean
//     sentenceRecording: SentenceRecordingType | null
//     updating: boolean
//     audio: {
//       [pitchShift: string]: PitchedSentenceOrSegmentAudio
//     }
//   },
//   ...
// }

//
// Build a map from sentenceId to all the recordings that match the current
// annotatedSentences and voiceStyleset criteria.
//

const DEBUG = true

const computeRecordingsInStyle = (payload: {
  voiceStyleSet: VoiceStyleSet
  annotatedSentences: Array<AnnotatedSentence>
  logger: VueLogger
}): StyledRecordings => {
  const tags = _.flatten([payload.voiceStyleSet.tags]) as Array<TagType>

  const recordingsInStyle = {} as StyledRecordings

  if (_.isEmpty(store.getters.audio.recordings) || _.isEmpty(payload.annotatedSentences)) {
    payload.logger && payload.logger.debug('empty audio.recordings')
    return recordingsInStyle
  }

  payload.annotatedSentences.forEach((annotatedSentence: AnnotatedSentence) => {
    const sentenceId = annotatedSentence.sentence().id
    const hasRecording = sentenceId in store.getters.audio.recordings
    if (DEBUG) {
      payload.logger && payload.logger.debug(`sentenceId ${sentenceId} hasRecording ${hasRecording}`)
    }

    // figure out whether this recording matches the filtering requirements:
    // pronunciation, melodic style
    if (!hasRecording) {
      if (DEBUG) {
        payload.logger && payload.logger.debug(`sentenceId ${sentenceId} has no recording, returning`)
      }
      return
    }

    // go through each recording for this sentence and see if the styles
    // match.
    Object.values(store.getters.audio.recordings[sentenceId]).forEach(
      (sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios) => {
        const { haftarahMelody, id, pronunciation, sentenceGroupMelody, torahMelody } =
          sentenceRecordingMetadataWithPitchedAudios.sentenceRecording!

        // pronunciation style is not correct
        if (payload.voiceStyleSet.pronunciation!.id !== pronunciation!.id) {
          if (DEBUG) {
            payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} wrong pronunciation`)
          }
          return
        }

        // we're looking for read recordings, but this one has a melodic style
        // set; skip
        if (inReadMode(payload.voiceStyleSet)) {
          if (torahMelody || haftarahMelody || sentenceGroupMelody) {
            if (DEBUG) {
              payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} in read mode, but has melody`)
            }
            return
          }

          // yes, it's sung, but torah melodic style does not match
        } else if (payload.voiceStyleSet.torahMelody && (!torahMelody || torahMelody.id !== payload.voiceStyleSet.torahMelody.id)) {
          if (DEBUG) {
            payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} bad torah melody style match`)
          }
          return

          // yes, it's sung, but haftarah melodic style does not match
        } else if (
          payload.voiceStyleSet.haftarahMelody &&
          (!haftarahMelody || haftarahMelody.id !== payload.voiceStyleSet.haftarahMelody.id)
        ) {
          if (DEBUG) {
            payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} bad haftarah melody style match`)
          }
          return

          // yes, it's sung, but sentenceGroup melodic style does not match
        } else if (
          payload.voiceStyleSet.sentenceGroupMelody &&
          (!sentenceGroupMelody || sentenceGroupMelody.id !== payload.voiceStyleSet.sentenceGroupMelody.id)
        ) {
          if (DEBUG) {
            payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} bad sentence_group melody style match`)
          }
          return
        }

        // if the current tag selection is empty, then match only those recordings
        // that have no tags.
        //
        // if the current tag selection is not empty, then match only those
        // recordings that include all selected tags; note that this may match
        // multiple recordings; for example if tag selection is ["A"], then a
        // recording with ["A"] or ["A", "B"] both match.
        if (DEBUG) {
          payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId}, tags = `)
          payload.logger && payload.logger.debug(sentenceRecordingMetadataWithPitchedAudios.sentenceRecording!.tags)
          payload.logger && payload.logger.debug(`voiceStyleSet.tags = `)
          payload.logger && payload.logger.debug(tags)
        }

        if (_.isEmpty(tags)) {
          if (!_.isEmpty(sentenceRecordingMetadataWithPitchedAudios.sentenceRecording!.tags)) {
            if (DEBUG) {
              payload.logger &&
                payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} empty tag selection, but recording has tags`)
            }
            return
          }
        } else {
          const tagsMatch = tags.every((tagX: TagType) => {
            const r = _.find(sentenceRecordingMetadataWithPitchedAudios.sentenceRecording!.tags, (tagY) => {
              return tagX.id === tagY.id
            })
            if (DEBUG) {
              payload.logger &&
                payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} matching voiceStyleSet.tags ${tagX.name}, matched =`)
              payload.logger && payload.logger.debug(r)
            }
            return !!r
          })
          payload.logger &&
            payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} matching voiceStyleSet.tags, tagsMatch = ${tagsMatch}`)

          if (!tagsMatch) {
            if (DEBUG) {
              payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} bad tag match`)
            }
            return
          }
        }

        // all criteria match! prepare the array to hold a value
        payload.logger && payload.logger.debug(`recording ${id} for sentenceId ${sentenceId} tags match!`)
        if (_.isEmpty(recordingsInStyle[sentenceId])) {
          recordingsInStyle[sentenceId] = [] as Array<SentenceRecordingMetadataWithPitchedAudios>
        }

        // This is what we were working towards -- this
        // sentenceRecordingMetadataWithPitchedAudios matches all the criteria; store it as the
        // match for this sentenceId.
        if (!recordingsInStyle[sentenceId].length) {
          recordingsInStyle[sentenceId].push(sentenceRecordingMetadataWithPitchedAudios)
        }
      },
    )
  })

  // We now have an object that matches sentenceIds to an array of
  // SentenceRecordingMetadataWithPitchedAudios. The only reason for there being more than one
  // match is if multiple recordings matched the tag selection. There is one
  // special case we want to deal with here: if there is an *exact* match
  // between the tag selection and the tags on the recording (and there's
  // only one of those exact matches), we want to return just that recording,
  // and not any other recording.
  const sortedVoiceStyleTags = [...tags].sort()
  Object.keys(recordingsInStyle).forEach((sentenceId) => {
    const exactSentenceRecordingMetadataWithPitchedAudioss = _.filter(
      recordingsInStyle[sentenceId] as Array<SentenceRecordingMetadataWithPitchedAudios>,
      (sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios) => {
        if (!sentenceRecordingMetadataWithPitchedAudios.sentenceRecording) {
          payload.logger && payload.logger.error('unexpected null sentenceRecordingMetadataWithPitchedAudios.sentenceRecording')
          return false
        }
        const sortedSentenceRecordingTags = [...sentenceRecordingMetadataWithPitchedAudios.sentenceRecording.tags].sort()
        return _.isEqual(sortedVoiceStyleTags, sortedSentenceRecordingTags)
      },
    )
    if (exactSentenceRecordingMetadataWithPitchedAudioss.length === 1) {
      recordingsInStyle[sentenceId] = [
        exactSentenceRecordingMetadataWithPitchedAudioss[0],
      ] as Array<SentenceRecordingMetadataWithPitchedAudios>
    }
  })

  payload.logger && payload.logger.debug('recordingsInStyle is done, recordingsInStyle =')
  payload.logger && payload.logger.debug(recordingsInStyle)
  return recordingsInStyle
}

const useStyledRecordings = (annotatedSentences: Ref<Array<AnnotatedSentence>>, voiceStyleSet: Ref<VoiceStyleSet>) => {
  const logger = useLogger()

  function prepareStyledRecordings(): void {
    // this is slow and should be run by whoever includes this mixin
    // this.fetchRecordings();
    store.dispatch.user.fetchUser()
    store.dispatch.style.fetchStyles()
  }

  // non-computed; the computed() version is below
  const recordingsInStyle = computed<StyledRecordings>((): StyledRecordings => {
    logger.debug('recordingsInStyle')
    return computeRecordingsInStyle({
      annotatedSentences: annotatedSentences.value as Array<AnnotatedSentence>,
      logger,
      voiceStyleSet: voiceStyleSet.value,
    })
  })

  return {
    prepareStyledRecordings,
    recordingsInStyle,
  }
}

export { computeRecordingsInStyle, StyledRecordings, useStyledRecordings }
