import { useQuery } from '@vue/apollo-composable'
import generator from 'generate-password-ts'
import _ from 'lodash'
import { GroupType, UserGrapheneType } from 'types/types'
import { computed, watch } from 'vue'
import { VueLogger } from 'vue-logger-plugin'
import { Router } from 'vue-router'
import { useBrowserDetect } from 'vue3-detect-browser'

import UserIsAuthenticatedQuery from '@/queries/UserIsAuthenticatedQuery.graphql'
import store from '@/store'
import { useApollo } from '@/util/apolloClient'

import logoBDark from '../assets/images/logo-b-dark.png'
import logoBLight from '../assets/images/logo-b-light.png'
import logoBarDark from '../assets/images/logo-bar-dark.png'
import logoBarLight from '../assets/images/logo-bar-light.png'
import logoBatDark from '../assets/images/logo-bat-dark.png'
import logoBatLight from '../assets/images/logo-bat-light.png'

interface UpdateUserParameters {
  user: UserGrapheneType
  torahMelodyIDs: Array<number>
  haftarahMelodyIDs: Array<number>
  sentenceGroupMelodyIDs: Array<number>
  pronunciationIDs: Array<number>
  tags: Array<string>
}

const { apolloClient } = useApollo()

const useUtil = (logger: VueLogger) => {
  const PUBLIC_USER = 'public'

  type GenderSiteType = {
    host: string
    mitzvah: string
    title: string
  }

  type GenderMapType = {
    [key: string]: GenderSiteType
  }

  const GENDER_MAP: GenderMapType = {
    b: {
      host: 'b-mitzvahtrainer.com',
      mitzvah: 'B-Mitzvah',
      title: 'B-Mitzvah Trainer',
    },

    bar: {
      host: 'barmitzvahtrainer.com',
      mitzvah: 'Bar Mitzvah',
      title: 'Bar Mitzvah Trainer',
    },

    bat: {
      host: 'batmitzvahtrainer.com',
      mitzvah: 'Bat Mitzvah',
      title: 'Bat Mitzvah Trainer',
    },
  }

  type LogoType = {
    light: string
    dark: string
  }

  type FallbackEntry = {
    logo: LogoType
    gender: string
    environment: string
  }

  type GenderFallbackType = {
    [ip: string]: FallbackEntry
  }

  const GENDER_FALLBACK: GenderFallbackType = {
    '172.16.126.1': {
      environment: 'development',
      gender: 'bar',
      logo: {
        dark: logoBarDark,
        light: logoBarLight,
      },
    },

    '192.168.1.5': {
      environment: 'development',
      gender: 'bar',
      logo: {
        dark: logoBarDark,
        light: logoBarLight,
      },
    },

    'b-mitzvahtrainer.com': {
      environment: 'production',
      gender: 'b',
      logo: {
        dark: logoBDark,
        light: logoBLight,
      },
    },

    'barmitzvahtrainer.com': {
      environment: 'production',
      gender: 'bar',
      logo: {
        dark: logoBarDark,
        light: logoBarLight,
      },
    },

    'batmitzvahtrainer.com': {
      environment: 'production',
      gender: 'bat',
      logo: {
        dark: logoBatDark,
        light: logoBatLight,
      },
    },

    localhost: {
      environment: 'development',
      gender: 'bar',
      logo: {
        dark: logoBarDark,
        light: logoBarLight,
      },
    },

    'staging.b-mitzvahtrainer.com': {
      environment: 'staging',
      gender: 'b',
      logo: {
        dark: logoBDark,
        light: logoBLight,
      },
    },

    'staging.barmitzvahtrainer.com': {
      environment: 'staging',
      gender: 'bar',
      logo: {
        dark: logoBarDark,
        light: logoBarLight,
      },
    },

    'staging.batmitzvahtrainer.com': {
      environment: 'staging',
      gender: 'bat',
      logo: {
        dark: logoBatDark,
        light: logoBatLight,
      },
    },
  }

  function userGroups(): Array<string> {
    const groups = store.getters.user.user
      ? _.map(store.getters.user.user.groups, (group: GroupType) => {
          return group.name
        })
      : new Array<string>()
    return groups
  }

  function environment(): string {
    return GENDER_FALLBACK[window.location.hostname].environment
  }

  function isProduction(): boolean {
    return environment() === 'production'
  }

  function isStaging(): boolean {
    return environment() === 'staging'
  }

  function isDevelopment(): boolean {
    return environment() === 'development'
  }

  function isStaff(): boolean {
    return !!store.getters.user.user?.isStaff
  }

  function isReadOnly(): boolean {
    return store.getters.user.user?.properties?.readOnly ?? false
  }

  function isTeacher(): boolean {
    return _.includes(userGroups(), 'Teachers')
  }

  function isStudent(): boolean {
    return _.includes(userGroups(), 'Students')
  }

  function userGender(): string {
    return store.getters.user.user && store.getters.user.user.properties && store.getters.user.user.properties.gender
      ? store.getters.user.user.properties.gender
      : GENDER_FALLBACK[window.location.hostname].gender
  }

  function userMitzvah(): string {
    return GENDER_MAP[userGender()].mitzvah
  }

  function userTrainer(): string {
    return GENDER_MAP[userGender()].title
  }

  function userHostname(): string {
    return GENDER_MAP[userGender()].host
  }

  function forceLogout(router: Router): void {
    console.log('forceLogout')
    doLogout()
      .then(() => {
        logger && logger.debug('router.push from forceLogout')
        router.push({
          name: 'login',
          params: {
            loginName: store.getters.user.user?.username,
            msgBody: `You logged out in another window/tab or your session timed out; please log in again.`,
            msgTitle: `Log in again`,
          },
        })
      })
      .catch((err) => {
        // Handle navigation failure, e.g., redirect to the same route not allowed
        console.error(err)
      })
  }

  function resetStoreExceptUser(): void {
    store.dispatch.admin.reset()
    store.dispatch.audio.reset()
    // store.dispatch.dialog.reset()
    // store.dispatch.snackbar.reset()
    store.dispatch.staff.reset()
    store.dispatch.student.reset()
    store.dispatch.style.reset()
    store.dispatch.teacher.reset()
  }

  function resetStore(): void {
    resetStoreExceptUser()
    store.dispatch.user.reset()
  }

  function doLogout(): Promise<void> {
    console.log('src/composable/util/doLoogut')
    return new Promise<void>((resolve) => {
      resetStore()
      const r = store.dispatch.user.logout()
      r.finally(() => {})
        .catch(() => {})
        .finally(() => {
          store.dispatch.user.reset()
          apolloClient.cache.reset()
          resolve()
        })
    })
  }

  function loginPublicUser(router: Router): void {
    const pw = generator.generate({ length: 12, numbers: true })
    const r = store.dispatch.user.login({ password: pw, username: PUBLIC_USER })
    r.then(() => {
      router.push('/practice')
    })
  }

  const browserCheck = () => {
    const { isChrome, isEdge, isFirefox } = useBrowserDetect()
    if (!isFirefox && !isEdge && !isChrome) {
      store.dispatch.dialog.show({
        message: 'This browser is not supported; please use Chrome, Edge, or Firefox.',
        title: 'Browser not supported',
      })
    }
  }

  const loginCheck = (router: Router) => {
    const { onError, result } = useQuery(UserIsAuthenticatedQuery, {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })
    onError(() => {
      logger && logger.debug('forcing logout because user is not logged in false')
      forceLogout(router)
    })

    watch(
      () => result.value,
      (newValue) => {
        console.log('AppBarAccountMenu onmounted, userIsAuthenticated result')
        console.log(newValue)
        if (newValue && !newValue.userIsAuthenticated.ok) {
          console.log('forcing logout because userIsAuthenticated.ok is false')
          forceLogout(router)
        }
      },
      { deep: true, immediate: true },
    )
  }

  async function handleLogoutClick(router: Router) {
    await doLogout()
    router.push('/login')
  }

  const selectableUsers = computed<Array<string>>(() => {
    if (!store.getters.user.user) {
      return []
    }

    const users: string[] = new Array(store.getters.user.user.username)
    if (isStaff()) {
      users.push('root')
    }
    return users
  })

  const getCsrfToken = () => {
    const cookies = document.cookie.split(';')
    const csrfTokenCookie = cookies.find((cookie) => cookie.trim().startsWith('csrftoken='))
    if (!csrfTokenCookie) {
      return null
    }
    return csrfTokenCookie.split('=')[1]
  }

  return {
    GENDER_MAP,
    browserCheck,
    doLogout,
    environment,
    forceLogout,
    getCsrfToken,
    handleLogoutClick,
    isDevelopment,
    isProduction,
    isReadOnly,
    isStaff,
    isStaging,
    isStudent,
    isTeacher,
    loginCheck,
    loginPublicUser,
    resetStore,
    resetStoreExceptUser,
    selectableUsers,
    userGender,
    userHostname,
    userMitzvah,
    userTrainer,
  }
}

export { UpdateUserParameters, useUtil }
