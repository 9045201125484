<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { reactive } from 'vue'
import * as yup from 'yup'

import store from '@/store'

const state = reactive({
  feedback: '',
  sending: false,
})

const { defineField, errors, handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      email: yup.string().email().required(),
      message: yup.string().required(),
    }),
  ),
})

const [email, emailAttr] = defineField('email')
const [message, messageAttr] = defineField('message')

const submit = handleSubmit(() => {
  state.sending = true
  // await state.$recaptchaLoaded()
  // state.$recaptcha('contact').then((token: string) => {
  store.dispatch.user.contact({ email: email.value!, message: message.value! }).then((ok: boolean) => {
    if (ok) {
      state.feedback = 'Thank you. Your message has been sent.'
    } else {
      state.feedback = 'Message failed to send.'
    }
    state.sending = false
  })
  // })
})
</script>

<template>
  <v-form id="form" @submit="submit">
    <v-row>
      <v-col lg="3" md="5" sm="7" xl="2" xs="11">
        <v-text-field
          v-model="email"
          v-bind="emailAttr"
          class="input-group--focused"
          :error-messages="errors.email"
          label="Your email"
          variant="underlined"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="4" md="6" sm="8" xl="3" xs="12">
        <v-textarea
          v-model="message"
          v-bind="messageAttr"
          class="my-0 py-0"
          :error-messages="errors.message"
          placeholder="Message"
          variant="underlined"
        />
      </v-col>
    </v-row>
    <v-row v-if="state.feedback" class="my-3">
      <v-col lg="4" md="6" sm="8" xl="3" xs="12">
        {{ state.feedback }}
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" md="5" sm="7" xl="2" xs="11">
        <v-btn block color="primary" :disabled="!meta.valid || state.sending" type="submit">
          Send
          <v-progress-circular v-if="state.sending" class="mx-4" indeterminate size="20" />
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
