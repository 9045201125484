<script setup lang="ts">
import { reactive } from 'vue'
interface Props {
  message?: string
  visible: boolean
  percent: number
  onClick: (() => void) | null
}

const props = withDefaults(defineProps<Props>(), {
  message: 'Be quiet... Measuring background noise...',
  onClick: null,
})

const state = reactive({ visible: props.visible })
</script>

<template>
  <v-overlay v-model="state.visible" class="d-flex align-center justify-center" opacity="0.92" style="min-height: 100vh">
    <v-container class="d-flex flex-column justify-center align-center" style="min-height: 100vh">
      <p class="mb-3 pb-3 text-h3 text-white">{{ message }}</p>
      <v-progress-linear class="my-4" color="secondary" :model-value="percent" />
      <v-btn
        color="error"
        @click="
          () => {
            props.onClick && props.onClick()
          }
        "
        >Cancel</v-btn
      >
    </v-container>
  </v-overlay>
</template>
