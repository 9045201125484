// translated by ChatGPT from
// https://raw.githubusercontent.com/rsocket/ewma/master/index.js
// part of https://github.com/rsocket/ewma
import assert from 'assert-plus'

interface Clock {
  now: () => number
}

class EWMA {
  private _decay: number
  private _ewma: number
  private _clock: Clock
  private _stamp: number

  constructor(halfLifeMs: number, initialValue?: number, clock?: Clock) {
    // assert.number(halfLifeMs, 'halfLifeMs')
    // assert.ok(!Number.isNaN(halfLifeMs), 'halfLifeMs cannot be NaN')
    // assert.optionalNumber(initialValue, 'initialValue')
    // assert.ok(initialValue === undefined || !Number.isNaN(initialValue), 'initialValue cannot be NaN')

    this._decay = halfLifeMs
    this._ewma = initialValue ?? 0
    this._clock = clock ?? Date
    this._stamp = typeof initialValue === 'number' ? this._clock.now() : 0

    if (clock !== undefined) {
      assert.func(clock.now, 'clock.now')
    }
  }

  insert(x: number): void {
    // assert.number(x, 'x')
    // assert.ok(!Number.isNaN(x), 'x cannot be NaN')

    const now = this._clock.now()
    const elapsed = now - this._stamp
    this._stamp = now

    const w = Math.pow(2, -elapsed / this._decay)
    this._ewma = w * this._ewma + (1.0 - w) * x
  }

  reset(x: number): void {
    // assert.number(x, 'x')
    // assert.ok(!Number.isNaN(x), 'x cannot be NaN')

    this._stamp = this._clock.now()
    this._ewma = x
  }

  value(): number {
    return this._ewma
  }
}

export default EWMA
