<script setup lang="ts">
import { reactive } from 'vue'

import CreateTeacher from './CreateTeacher.vue'

interface AdminData {
  tab: number
}

const state = reactive<AdminData>({
  tab: 1,
})
</script>

<template>
  <v-tabs v-model="state.tab">
    <v-tab>Create teacher</v-tab>
  </v-tabs>
  <v-window v-model="state.tab">
    <v-window-item key="0" value="0">
      <create-teacher />
    </v-window-item>
  </v-window>
</template>
