import { ParashahType } from '../../types/types';

function formatParashah(parashah: ParashahType): string {
  // return `${parashah.transliteratedTitle} / ${parashah.heTitle} (${parashah.section})`;
  return `${parashah.transliteratedTitle} / ${parashah.heTitle}`;
}

function formatParashahForStudent(parashah: ParashahType): string {
  return `${parashah.transliteratedTitle} [${parashah.heTitle}]`;
}

export { formatParashah, formatParashahForStudent };
