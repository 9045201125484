import _ from 'lodash'
import { Ref, ref } from 'vue'
import { VueLogger } from 'vue-logger-plugin'

import { HTMLAudioWrapper, PAUSED, PLAYING, SentenceRecordingMetadataWithPitchedAudios } from '@/util/AudioUtil'

interface AudioPlayerParams {
  segmentIndex: number
  sentenceRecordingMetadataWithPitchedAudios: SentenceRecordingMetadataWithPitchedAudios
  pitchShift: string
}

const useAudioPlayer = (logger: VueLogger) => {
  const _audioWrapper: Ref<HTMLAudioWrapper | null> = ref(null)

  const playing = (): boolean => {
    return !!_audioWrapper.value?.audio && _audioWrapper.value.playStatus === PLAYING
  }

  const paused = (): boolean => {
    return !!_audioWrapper.value?.audio && _audioWrapper.value.playStatus === PAUSED
  }

  const unloaded = (): boolean => {
    return !_audioWrapper.value || !_audioWrapper.value.audio
  }

  const loaded = (): boolean => {
    return !!_audioWrapper.value?.audio
  }

  const load = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!_audioWrapper.value) {
        logger.info('null _audioWrapper.value in load()')
        reject()
        return
      }

      if (!_audioWrapper.value.audio) {
        logger.error('unexpected null _audioWrapper.value.audio in load()')
        reject()
        return
      }

      _audioWrapper.value.audio.load()
      _audioWrapper.value.audio.oncanplaythrough = () => {
        if (!_audioWrapper.value) {
          logger.info('null _audioWrapper.value in load().oncanplaythrough')
          reject()
          return
        }
        _audioWrapper.value.playStatus = PAUSED
        resolve()
      }
    })
  }

  const play = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!_audioWrapper.value) {
        logger.info('null _audioWrapper.value in play()')
        reject()
        return
      }

      if (!_audioWrapper.value.audio) {
        logger.error('unexpected null _audioWrapper.value.audio in play()')
        reject()
        return
      }

      if (_audioWrapper.value.playStatus !== PAUSED) {
        logger.error('unexpected not-PAUSED status in play()')
        return
      }

      // both .onpause and .onended need to invoke resolve()
      _audioWrapper.value.audio.onpause = _audioWrapper.value.audio.onended = () => {
        if (!_audioWrapper.value) {
          logger.info('null _audioWrapper.value in play().onended')
          return
        }

        if (!_audioWrapper.value.audio) {
          logger.error('unexpected null _audioWrapper.value.audio in play().onended')
          return
        }

        _audioWrapper.value.playStatus = PAUSED
        resolve()
      }
      _audioWrapper.value.audio.play()
      _audioWrapper.value.playStatus = PLAYING
    })
  }

  const pause = (): void => {
    if (!_audioWrapper.value) {
      logger.info('null _audioWrapper.value in pause()')
      return
    }

    if (!_audioWrapper.value.audio) {
      logger.error('unexpected null _audioWrapper.value.audio in pause()')
      return
    }

    if (_audioWrapper.value.playStatus !== PLAYING) {
      logger.error('unexpected not-PLAYING status in pause()')
      return
    }

    _audioWrapper.value.audio.pause()
    _audioWrapper.value.playStatus = PAUSED
  }

  const pauseOrPlay = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!_audioWrapper.value) {
        logger.info('null _audioWrapper.value in pauseOrPlay()')
        reject()
        return
      }

      if (!_audioWrapper.value.audio) {
        logger.error('unexpected null _audioWrapper.value.audio in pauseOrPlay()')
        reject()
        return
      }

      if (_audioWrapper.value.playStatus === PAUSED) {
        return play()
      } else if (_audioWrapper.value.playStatus === PLAYING) {
        pause()
        resolve()
      } else {
        logger.error('neither PLAYING or PAUSED in pauseOrPlay')
      }
    })
  }

  const onCanPlay = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!_audioWrapper.value) {
        logger.info('null _audioWrapper.value in onCanPlay()')
        reject()
        return
      }

      if (!_audioWrapper.value.audio) {
        logger.error('unexpected null _audioWrapper.value.audio in onCanPlay()')
        reject()
        return
      }

      _audioWrapper.value.audio.oncanplaythrough = () => {
        resolve()
      }
    })
  }

  //
  // utility function to find the sentence or segment HTMLAudioWrapper associated with a
  // sentence and a segmentIndex
  //
  // if segmentIndex === -1, return the HTMLAudioWrapper for the sentence recording
  // if segmentIndex === 0 and there are no segments, it means the entire
  //    sentence was recorded as one segment, in which case segment 0 refers to
  //    the recording of the whole sentence.
  // ...otherwise, return the HTMLAudioWrapper for the segment recording
  //
  // if autoPlay == true, play the audio before returning the HTMLAudioWrapper;
  // setting it to false might be handy when just trying to check whether the
  // HTMLAudioWrapper exists
  //
  const getAudioWrapper = (payload: Readonly<AudioPlayerParams>): HTMLAudioWrapper | null => {
    if (!payload.sentenceRecordingMetadataWithPitchedAudios) {
      logger.error('unexpected null payload.sentenceRecordingMetadataWithPitchedAudios in getAudioWrapper()')
      return null
    }

    // it's still downloading
    if (payload.sentenceRecordingMetadataWithPitchedAudios.downloading) {
      logger.info('still downloading in getAudioWrapper()')
      return null
    }

    //
    // sentence
    //
    if (
      payload.segmentIndex < 0 ||
      (payload.segmentIndex === 0 &&
        _.isEmpty(payload.sentenceRecordingMetadataWithPitchedAudios.audio[payload.pitchShift].segmentHTMLAudioWrappers))
    ) {
      logger.debug('segmentIndex < 0 || segmentIndex === 0 and no audio[pitchShift].segmentHTMLAudioWrappers')
      return payload.sentenceRecordingMetadataWithPitchedAudios.audio[payload.pitchShift].sentenceHTMLAudioWrapper
    }

    //
    // segment
    //
    if (_.isEmpty(payload.sentenceRecordingMetadataWithPitchedAudios.audio[payload.pitchShift].segmentHTMLAudioWrappers)) {
      return null
    }
    return payload.sentenceRecordingMetadataWithPitchedAudios.audio[payload.pitchShift].segmentHTMLAudioWrappers[payload.segmentIndex]
  }

  const isPlayable = (payload: Readonly<AudioPlayerParams>): boolean => {
    return !!getAudioWrapper(payload)
  }

  const getAudio = (): Readonly<HTMLAudioWrapper> => {
    return _audioWrapper.value as Readonly<HTMLAudioWrapper>
  }

  const setAudio = (payload: Readonly<AudioPlayerParams>): boolean => {
    logger.info('setAudio, payload =')
    logger.info(payload)
    const audioWrapper = getAudioWrapper(payload)
    if (!audioWrapper) {
      logger.error('unexpected null audioWrapper in setAudio()')
      return false
    }
    _audioWrapper.value = audioWrapper
    logger.info('setAudio, _audioWrapper.value =')
    logger.info(_audioWrapper.value)
    return true
  }

  const setLoadPlay = (payload: AudioPlayerParams): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (loaded()) {
        purge()
      }

      if (!setAudio(payload)) {
        logger.error('setAudio() failed in setLoadPlay()')
        reject()
        return
      }

      load()
        .then(() => {
          play()
            .then(() => {
              resolve()
            })
            .catch(() => {
              logger.error('play() failed in setLoadPlay()')
              reject()
            })
        })
        .catch(() => {
          logger.error('load() failed in setLoadPlay()')
          reject()
        })
    })
  }

  const purge = (): void => {
    if (playing()) {
      pause()
    }

    if (!_audioWrapper.value) {
      logger.error('unexpected null _audioWrapper.value in purge()')
      return
    }

    if (!_audioWrapper.value.audio) {
      logger.error('unexpected null _audioWrapper.value.audio in purge()')
      return
    }

    if (_audioWrapper.value.playStatus !== PAUSED) {
      logger.error('unexpected !PAUSED in purge()')
      return
    }

    // do not revoke here; it destroys the audio object making it unplayable in the future
    // window.URL.revokeObjectURL(_audioWrapper.value.audio.src)
    // _audioWrapper.value.audio = null
    _audioWrapper.value = null
  }

  return {
    getAudio,
    isPlayable,
    load,
    loaded,
    onCanPlay,
    pause,
    pauseOrPlay,
    paused,
    play,
    playing,
    purge,
    setAudio,
    setLoadPlay,
    unloaded,
  }
}

export { AudioPlayerParams, useAudioPlayer }
