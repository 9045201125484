import { SentenceRecordingType } from 'types/types'

const PLAYING = 'playing'
const PAUSED = 'paused'
const DEFAULT_PITCH_SHIFT = '1.0'

type PitchShiftType = string

//
// wrapper around an HTML audio media element.
//
interface HTMLAudioWrapper {
  audio: HTMLAudioElement | null
  playStatus: string | null
  blobUrl: string | null
}

//
// Holds one sentence or segments worth of HTMLAudioWrapper for a certain pitch
// shift
//
interface PitchedSentenceOrSegmentAudio {
  sentenceHTMLAudioWrapper: HTMLAudioWrapper
  segmentHTMLAudioWrappers: Array<HTMLAudioWrapper>
  pitchShift: PitchShiftType
}

//
// Holds a sentenceRecording, all its pitch shifts and other data associated
// with it
//
interface SentenceRecordingMetadataWithPitchedAudios {
  sentenceRecording: SentenceRecordingType | null
  audio: { [key: string]: PitchedSentenceOrSegmentAudio }
  downloading: boolean
  updating: boolean
}

function createBlobUrlFromBytes(bytes: Uint8Array): string {
  const blob = new Blob([bytes], { type: 'audio/wav' })
  const blobUrl = window.URL.createObjectURL(blob)
  return blobUrl
}

export {
  createBlobUrlFromBytes,
  DEFAULT_PITCH_SHIFT,
  HTMLAudioWrapper,
  PAUSED,
  PitchedSentenceOrSegmentAudio,
  PitchShiftType,
  PLAYING,
  SentenceRecordingMetadataWithPitchedAudios,
}
