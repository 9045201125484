<script setup lang="ts">
import _ from 'lodash'
import { UserGrapheneType } from 'types/types'
import { computed, onMounted, reactive } from 'vue'
import { useLogger } from 'vue-logger-plugin'
import { useRouter } from 'vue-router'

import { useUtil } from '@/composables/util'
import store from '@/store'
import { BasicUserInfo } from '@/util/User'

interface ImpersonateData {
  impersonatedUser: string | null
}

const logger = useLogger()
const router = useRouter()
const { getCsrfToken, resetStoreExceptUser } = useUtil(logger)

const sortedUsernames = computed(() => {
  return _.sortBy(store.getters.staff.usernames, ['firstName']).map((basicUserInfo: BasicUserInfo) => ({
    label: `${basicUserInfo.firstName} ${basicUserInfo.lastName} [${basicUserInfo.username}] <${basicUserInfo.email}>`,
    value: basicUserInfo.username,
  }))
})

const state = reactive<ImpersonateData>({
  impersonatedUser: null,
})

//
// impersonation is hard.
//
// First we remember the current user (impersonator), then we call
// startImpersonate() which moves user to store.getters.user.impersonator.
// Then we remember the impersonatee (which is now user).
//
// We tell the backend to start impersonation (/backend/impersonate/userId/)
// and then we need to clear the store entirely to prevent information leakage.
//
//
async function doStartImpersonate() {
  const impersonator = _.cloneDeep(store.getters.user.user)
  await store.dispatch.user.startImpersonate({
    username: state.impersonatedUser ?? '',
  })
  const user = _.cloneDeep(store.getters.user.user)

  if (!user) {
    logger.error('unexpected null user')
    return
  }

  const response = await fetch(`/backend/impersonate/${user.id}/`, {
    credentials: 'include',
    headers: {
      'x-csrftoken': getCsrfToken(),
    },
    method: 'POST',
  })
  logger.debug('response = ')
  logger.debug(response)

  if (response.ok) {
    logger.debug('calling stopImpersonate')
    logger.debug('calling resetStore')

    // remember the impersonatorUrl (i.e., the URL we were at _before_ visiting the Impersonation page)
    const url = store.getters.user.impersonatorUrl

    // clear everything to prevent information leakage
    resetStoreExceptUser()
    store.dispatch.user.sanitize()

    // restore store.getters.user.impersonator
    store.dispatch.user.setImpersonator(impersonator as UserGrapheneType)

    // restore store.getters.user.impersonatorUrl
    store.dispatch.user.setImpersonatorUrl(url)

    // restore store.getters.user.user
    // this will trigger the store.getters.user.user watcher in App.vue
    store.dispatch.user.setUser(user as UserGrapheneType)

    // go to / to see where we end up as the new user
    // note that we cannot do window.location.href = ... because we will lose the store
    logger.debug('calling router.push /')
    router.push('/')
  }
}

onMounted(() => {
  store.dispatch.staff.fetchBasicUserInfo()
})
</script>

<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-autocomplete
          v-model="state.impersonatedUser"
          autofocus
          item-title="label"
          item-value="value"
          :items="sortedUsernames"
          variant="underlined"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-1 pt-1" md="3">
        <v-btn color="primary" @click="doStartImpersonate">Impersonate</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
