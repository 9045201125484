<script setup lang="ts">
import _ from 'lodash'
import { SentenceGroupType, SentenceType } from 'types/types'
import { computed, onMounted, reactive, watchEffect } from 'vue'

import AnnotatedText from '@/components/AnnotatedText.vue'
import SentenceGroupSelect from '@/components/SentenceGroupSelect.vue'
import SetResponsivenessOnSentence from '@/mutations/SetResponsivenessOnSentence.graphql'
import store from '@/store'
import { AnnotatedSentence } from '@/util/AnnotatedSentence'
import { useApollo } from '@/util/apolloClient'
const { apolloClient } = useApollo()

interface CustomResponsivenessData {
  selectedSentenceGroup: SentenceGroupType | null
  annotatedSentences: Array<AnnotatedSentence> | null
}

const state = reactive<CustomResponsivenessData>({
  annotatedSentences: null,
  selectedSentenceGroup: null,
})

onMounted(() => {
  store.dispatch.user.fetchUser()

  watchEffect(() => {
    if (state.selectedSentenceGroup) {
      store.dispatch.student.fetchSentenceGroupSentences({
        sentenceGroup: state.selectedSentenceGroup,
      })
    }
  })
})

const allSentenceGroupSentences = computed<Readonly<Record<number, Array<SentenceType>>>>(
  (): Readonly<Record<number, Array<SentenceType>>> => {
    return store.getters.student.sentenceGroupSentences
  },
)

const sentenceGroupSentences = computed<Array<SentenceType>>((): Array<SentenceType> => {
  if (!state.selectedSentenceGroup) {
    return []
  }
  return _.sortBy(allSentenceGroupSentences.value[parseInt(state.selectedSentenceGroup.reading.id)], [
    (sentence: SentenceType) => parseInt(sentence.id, 10),
  ])
})

function flipResponsiveness(annotatedSentence: AnnotatedSentence, __: number) {
  annotatedSentence.setIsUpdating(true)
  apolloClient
    .mutate({
      mutation: SetResponsivenessOnSentence,
      variables: {
        responsive: !annotatedSentence.sentence().isResponsive,
        sentence: annotatedSentence.sentence().id,
      },
    })
    .finally(() => {
      store.dispatch.student
        .fetchSentenceGroupSentences({
          sentenceGroup: state.selectedSentenceGroup as SentenceGroupType,
        })
        .then(() => {
          annotatedSentence.setIsUpdating(false)
        })
    })
}
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col md="4">
        <sentence-group-select v-model:sentence-group="state.selectedSentenceGroup" />
      </v-col>
    </v-row>
    <v-row v-if="sentenceGroupSentences && sentenceGroupSentences.length" class="mt-3 pt-3">
      <v-col md="12">
        <v-card>
          <v-card-text>
            <v-row justify="center">
              <v-col class="text-center" md="11">
                <annotated-text
                  v-if="state.selectedSentenceGroup"
                  v-model:annotated-sentences="state.annotatedSentences as Array<AnnotatedSentence>"
                  :break-before-line="() => true"
                  :clickable-line-number="() => true"
                  :clickable-responsive-header="() => true"
                  :clickable-sentence="() => true"
                  name="text"
                  :sentences="sentenceGroupSentences"
                  :show-responsive-headers="() => true"
                  :show-sentence-numbers="true"
                  @click-line-number="flipResponsiveness"
                  @click-responsive-header="flipResponsiveness"
                  @click-sentence="flipResponsiveness"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.annotated-scroller {
  min-height: 200px;
  height: calc(100vh - 450px);
}
</style>
