<script setup lang="ts">
import _ from 'lodash'
import { AliyahType, BarMitzvahType, HaftarahType, ParashahType, SentenceGroupType, SentenceType } from 'types/types'
import { computed, nextTick, onMounted, reactive, ref, watch, watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import AliyahSelect from '@/components/AliyahSelect.vue'
import HaftarahSelect from '@/components/HaftarahSelect.vue'
import ParashahSelect from '@/components/ParashahSelect.vue'
import SentenceGroupSelect from '@/components/SentenceGroupSelect.vue'
import StudentSelect from '@/components/StudentSelect.vue'
import { formattedAliyahSimple, formattedBarMitzvah, formattedHaftarahSimple, formattedParashah } from '@/composables/format'
import store from '@/store'

const logger = useLogger()
const emit = defineEmits([
  'update:barMitzvah',
  'update:aliyah',
  'update:parashah',
  'update:haftarah',
  'update:sentenceGroup',
  'update:sentences',
])

interface StudentToSentencesSelect {
  parshiyot: Array<ParashahType> | null // null will show all parshiyot in the dropdown; [] will show none
  aliyot: Array<AliyahType> | null // null will show all aliyot in the dropdown; [] will show none
  haftarot: Array<HaftarahType> | null // null will show all haftarot in the dropdown; [] will show none
  sentenceGroups: Array<SentenceGroupType> | null // null will show all sentence groups in the dropdown; [] will show none

  // these are all internally watched and cause an update to the associated
  // xprops so that parent is notified of choices made.
  selectedBarMitzvah: BarMitzvahType | null
  selectedParashah: ParashahType | null
  selectedAliyah: AliyahType | null
  selectedHaftarah: HaftarahType | null
  selectedSentenceGroup: SentenceGroupType | null

  changeSelection: boolean

  mySentences: Array<SentenceType> | null // managed by watchers for aliyahSentences, haftarahSentences, sentenceGroupSentences
}

interface Props {
  aliyah?: AliyahType | null
  barMitzvah?: BarMitzvahType | null
  haftarah?: HaftarahType | null
  parashah?: ParashahType | null

  aliyahDisabled: boolean

  // These disable the dropdowns altogether
  parashahDisabled: boolean

  haftarahDisabled: boolean

  sentenceGroup: SentenceGroupType | null
  hideHaftarahWhenDisabled: boolean
  sentences: Array<SentenceType>

  hideAliyahWhenDisabled?: boolean

  studentDropdownText?: string | null

  // whether or they are searchable [NOT IMPLEMENTED]
  barMitzvahSearchable?: boolean

  // Hide these checkboxes when they are disabled
  hideStudentWhenDisabled?: boolean

  haftarahSearchable?: boolean

  sentenceGroupDisabled?: boolean

  aliyahSearchable?: boolean
  hideParashahWhenDisabled: boolean
  // whether or they are clearable [NOT IMPLEMENTED]
  barMitzvahClearable?: boolean

  haftarahClearable?: boolean

  hideSentenceGroupWhenDisabled: boolean

  aliyahClearable?: boolean
  parashahSearchable?: boolean
  parashahClearable?: boolean
  haftarahDisabledOnNoBarMitzvah?: boolean

  sentenceGroupSearchable?: boolean

  // disable dropbox when a student has been selected, but the student has no
  // entries associated with that dropdown
  disableParashahWhenStudentHasNoParashah: boolean

  // These cause the associated dropdowns to be disabled if no BarMitzvah
  // selection has been made.
  parashahDisabledOnNoBarMitzvah?: boolean

  disableHaftarahWhenStudentHasNoHaftarah: boolean

  sentenceGroupClearable?: boolean

  disableSentenceGroupWhenStudentHasNoSentenceGroup: boolean
  // hide the student drop down when any other drop down is selected
  studentDisabledWhenOtherSelected?: boolean
  // These resetOnOptionsChange settings are tricky and have far-reaching
  // consequences. The problem is that v-select will set the associated
  // v-model (ie, the parashah on the parashah dropdown) to null when the
  // options change, thus causing any watcher to trigger. In other words,
  // change the set of parshiyot triggers the resetOnOptionsChange, which
  // resets the select, thus setting the parashah, thus triggering an update
  // on selectedParashah watcher.
  // parashahResetOnOptionsChange: boolean,
  // haftarahResetOnOptionsChange: boolean,
  // aliyahResetOnOptionsChange: boolean,
  // sentenceGroupResetOnOptionsChange: Boolean,
  // In some cases it's pleasant if a select automatically chooses the option
  // if there is only one option to choose from. These settings prevent that behavior.
  preventAutoSingleBarMitzvahSelect?: boolean

  preventAutoSingleAliyahSelect: boolean
  sentenceGroupDisabledOnNoBarMitzvah?: boolean
  forceStudentSelect: boolean

  // Hide the BarMitzvah dropdown
  noStudentSelect?: boolean

  preventAutoSingleHaftarahSelect: boolean

  preventAutoSingleParashahSelect: boolean

  preventAutoSingleSentenceGroupSelect: boolean

  compressAfterSelection?: boolean
}

const xprops = withDefaults(defineProps<Props>(), {
  aliyah: null,
  aliyahClearable: true,
  aliyahSearchable: true,
  barMitzvah: null,
  barMitzvahClearable: true,
  barMitzvahSearchable: true,
  compressAfterSelection: true,
  haftarah: null,
  haftarahClearable: true,
  haftarahDisabledOnNoBarMitzvah: true,
  haftarahSearchable: true,
  hideAliyahWhenDisabled: true,
  hideStudentWhenDisabled: true,
  noStudentSelect: false,
  parashah: null,
  parashahClearable: true,
  parashahDisabledOnNoBarMitzvah: true,
  parashahSearchable: true,
  preventAutoSingleBarMitzvahSelect: false,
  sentenceGroupClearable: true,
  sentenceGroupDisabled: false,
  sentenceGroupDisabledOnNoBarMitzvah: false,
  sentenceGroupSearchable: true,
  sentenceGroups: null,
  studentDisabledWhenOtherSelected: true,
  studentDropdownText: null,
})

const state = reactive<StudentToSentencesSelect>({
  aliyot: null,

  changeSelection: false,

  haftarot: null,

  mySentences: null,

  parshiyot: null,

  selectedAliyah: xprops.aliyah,

  selectedBarMitzvah: xprops.barMitzvah,

  selectedHaftarah: xprops.haftarah,
  selectedParashah: xprops.parashah,

  selectedSentenceGroup: xprops.sentenceGroup,
  sentenceGroups: null,
})

const parashahSelect = ref<HTMLElement | null>(null)
const haftarahSelect = ref<HTMLElement | null>(null)
const sentenceGroupSelect = ref<HTMLElement | null>(null)

const aliyahSentences = computed<Array<SentenceType>>(() => {
  if (!xprops.aliyah) {
    return []
  }
  return _.sortBy(store.getters.student.aliyahSentences[parseInt(xprops.aliyah.id, 10)], [
    (sentence: SentenceType) => parseInt(sentence.id, 10),
  ])
})

const haftarahSentences = computed<Array<SentenceType>>(() => {
  if (!xprops.haftarah) {
    return []
  }
  return _.sortBy(store.getters.student.haftarahSentences[parseInt(xprops.haftarah.reading.id)], [
    (sentence: SentenceType) => parseInt(sentence.id, 10),
  ])
})

const isAliyahDisabled = computed<boolean>(() => {
  return xprops.aliyahDisabled || !!state.selectedHaftarah || !!state.selectedSentenceGroup
})

const isHaftarahDisabled = computed(() => {
  return (
    xprops.haftarahDisabled ||
    (xprops.disableHaftarahWhenStudentHasNoHaftarah && state.selectedBarMitzvah && !state.selectedBarMitzvah.haftarah) ||
    (xprops.haftarahDisabledOnNoBarMitzvah && !state.selectedBarMitzvah) ||
    (xprops.parashahDisabled ? !!state.selectedAliyah : !!state.selectedParashah) ||
    !!state.selectedSentenceGroup
  )
})

const isParashahDisabled = computed<boolean>((): boolean => {
  return (
    xprops.parashahDisabled ||
    (xprops.disableParashahWhenStudentHasNoParashah && !_.isEmpty(state.selectedBarMitzvah) && !state.selectedBarMitzvah.aliyot?.length) ||
    (xprops.parashahDisabledOnNoBarMitzvah && _.isEmpty(state.selectedBarMitzvah)) ||
    !_.isEmpty(state.selectedHaftarah) ||
    !_.isEmpty(state.selectedSentenceGroup)
  )
})

const isSentenceGroupDisabled = computed<boolean>(() => {
  return (
    xprops.sentenceGroupDisabled ||
    (xprops.disableSentenceGroupWhenStudentHasNoSentenceGroup &&
      state.selectedBarMitzvah &&
      !state.selectedBarMitzvah.sentenceGroups?.length) ||
    (xprops.sentenceGroupDisabledOnNoBarMitzvah && _.isEmpty(state.selectedBarMitzvah)) ||
    !_.isEmpty(state.selectedParashah) ||
    !_.isEmpty(state.selectedHaftarah)
  )
})

const isStudentDisabled = computed<boolean>(() => {
  const r =
    xprops.studentDisabledWhenOtherSelected &&
    !state.selectedBarMitzvah &&
    (!_.isEmpty(state.selectedParashah) || !_.isEmpty(state.selectedHaftarah) || !_.isEmpty(state.selectedSentenceGroup))
  return r
})

const sentenceGroupSentences = computed<Array<SentenceType>>(() => {
  logger.debug('STSS computed sentenceGroupSentences')
  if (!state.selectedSentenceGroup) {
    logger.debug('STSS computed sentenceGroupSentences, return []')
    return []
  }
  const r = _.sortBy(store.getters.student.sentenceGroupSentences[parseInt(state.selectedSentenceGroup.reading.id, 10)], [
    (sentence: SentenceType) => parseInt(sentence.id, 10),
  ])
  logger.debug('STSS sentenceGroupSentences, r = ')
  logger.debug(r)
  return r
})

const showBarMitzvahDropdown = computed<boolean>(() => {
  const r = !(xprops.noStudentSelect || (xprops.hideStudentWhenDisabled && isStudentDisabled.value))
  return r
})

onMounted(() => {
  store.dispatch.teacher.fetchLightBarMitzvot()
})

function barMitzvahChangeHandler() {
  logger.debug('STSS, barMitzvahChangeHandler')
  if (!isParashahDisabled.value) {
    nextTick(() => {
      parashahSelect.value?.focus()
    })
    return
  }
  if (!isHaftarahDisabled.value) {
    nextTick(() => {
      haftarahSelect.value?.focus()
    })
    return
  }
  if (!isSentenceGroupDisabled.value) {
    nextTick(() => {
      sentenceGroupSelect.value?.focus()
    })
  }
}

function partialResetSelection(): void {
  // if there's only one choice for this student, then the partial reset is not
  // good enough, because the auto-select functionality will again automatically
  // choose the relevant Practice text -- so we need to unset the student also
  if (
    (state.selectedBarMitzvah?.aliyot.length ?? 0) +
      (state.selectedBarMitzvah?.haftarah ? 1 : 0) +
      (state.selectedBarMitzvah?.sentenceGroups.length ?? 0) ===
    1
  ) {
    fullResetSelection()
  } else {
    state.selectedAliyah = null
    state.selectedParashah = null
    state.selectedSentenceGroup = null
    state.selectedHaftarah = null
  }
}

function fullResetSelection(): void {
  logger.debug('STSS, fullResetSelection()')
  state.selectedAliyah = null
  state.selectedParashah = null
  state.selectedSentenceGroup = null
  state.selectedHaftarah = null
  nextTick(() => {
    state.selectedParashah = null
    nextTick(() => {
      state.selectedBarMitzvah = null
    })
  })
}

onMounted(() => {
  if (xprops.forceStudentSelect) {
    state.parshiyot = []
    state.haftarot = []
    state.sentenceGroups = []
  }

  // there was a barMitzvah override from the parent.
  // force the barMitzvah and parashah setting
  if (xprops.barMitzvah) {
    state.selectedBarMitzvah = xprops.barMitzvah
    // invokes selectedBarMitzvahHandler via watch
  }

  watchEffect(async () => {
    logger.debug('STSS, selectedBarMitzvahHandler')
    if (state.selectedBarMitzvah === null) {
      // lift the limitation on the parashah dropdown
      if (xprops.forceStudentSelect) {
        state.parshiyot = []
      } else {
        state.parshiyot = null
      }
      // lift the limitation on the aliyah and sentenceGroups dropdown
      state.aliyot = null
      state.sentenceGroups = null
      // lift the limitation on the haftarah dropdown
      if (xprops.forceStudentSelect) {
        state.haftarot = []
      } else {
        state.haftarot = null
      }

      //
      // initial pick from the drop-down when the "light" version of the data has been loaded (i.e., only name and year)
      //
    } else if (state.selectedBarMitzvah.aliyot === undefined) {
      // fetch barMitzvah details and switch to the fully populated object
      logger.debug('fetching bar mitzvah details')
      await store.dispatch.teacher.fetchBarMitzvah({
        id: parseInt(state.selectedBarMitzvah.student.id, 10),
      })
      const bmIndex = _.findIndex(store.getters.teacher.barMitzvot, (b: BarMitzvahType) => {
        logger.debug('considering b.student.username = ')
        logger.debug(b.student.username)
        logger.debug(state.selectedBarMitzvah!.student.username)
        return b.student.username === state.selectedBarMitzvah!.student.username
      })

      if (bmIndex === -1) {
        logger.debug('Unexpected !fullBM')
        return
      }
      state.selectedBarMitzvah = store.getters.teacher.barMitzvot[bmIndex]

      //
      // when the student's full barmitzvah details are loaded
      //
    } else {
      logger.debug('fetching bar mitzvah details - DONE')

      if (!_.isEmpty(state.selectedBarMitzvah.aliyot)) {
        state.parshiyot = [state.selectedBarMitzvah.aliyot[0].parashah]
        logger.debug('barMitzvah has associated aliyot')
      }

      state.haftarot = []
      if (!_.isEmpty(state.selectedBarMitzvah.haftarah)) {
        state.haftarot.push(state.selectedBarMitzvah.haftarah)
      }

      if (!_.isEmpty(state.selectedBarMitzvah.sentenceGroups)) {
        state.sentenceGroups = [...state.selectedBarMitzvah.sentenceGroups]
      } else {
        state.sentenceGroups = []
      }

      // if this barmitzvah has one associated parashah and no haftarah and no sentencegroups, automatically pick that parashah
      if (
        !_.isEmpty(state.selectedBarMitzvah.aliyot) &&
        !_.isEmpty(state.parshiyot) &&
        state.parshiyot?.length === 1 &&
        _.isEmpty(state.selectedBarMitzvah.haftarah) &&
        _.isEmpty(state.selectedBarMitzvah.sentenceGroups)
      ) {
        state.selectedParashah = state.parshiyot[0]

        // if there's only one aliyah, pick that too
        if (state.selectedBarMitzvah.aliyot.length === 1) {
          state.selectedAliyah = state.selectedBarMitzvah.aliyot[0]
        }
      }

      // if this barmitzvah has one associated haftarah and no aliyot and no sentencegroups, automatically pick that haftarah
      if (
        _.isEmpty(state.selectedBarMitzvah.aliyot) &&
        _.isEmpty(state.selectedBarMitzvah.sentenceGroups) &&
        !_.isEmpty(state.selectedBarMitzvah.haftarah) &&
        state.haftarot.length === 1
      ) {
        state.selectedHaftarah = state.haftarot[0]
      }

      // if this barmitzvah has one associated senteneGroup and no aliyot and no haftarah, automatically pick that sentenceGroup
      if (
        _.isEmpty(state.selectedBarMitzvah.aliyot) &&
        _.isEmpty(state.selectedBarMitzvah.haftarah) &&
        !_.isEmpty(state.selectedBarMitzvah.sentenceGroups) &&
        state.sentenceGroups.length === 1
      ) {
        state.selectedSentenceGroup = state.sentenceGroups[0]
      }
    }
    emit('update:barMitzvah', state.selectedBarMitzvah)
  })

  watch(
    () => xprops.parashah,
    (newVal, oldVal) => {
      logger.debug(`STSS, watch xprops.parashah xprops.parashah =`)
      logger.debug(xprops.parashah)
      if (xprops.parashah === null) {
        state.selectedAliyah = null
        if (state.selectedBarMitzvah) {
          state.aliyot = state.selectedBarMitzvah.aliyot
        }

        // display only the aliyot that have been assigned to state bar mitzvah
      } else if (state.selectedBarMitzvah) {
        state.aliyot = state.selectedBarMitzvah.aliyot
        if (oldVal !== newVal) {
          logger.debug('we switched bar/mitzvahs')
        }

        // display all aliyot for state parashah
      } else {
        state.selectedParashah = xprops.parashah
      }
    },
  )

  watch(
    () => state.selectedParashah,
    (newVal) => {
      emit('update:parashah', newVal)
    },
  )

  watch(
    () => state.selectedAliyah,
    (newVal) => {
      if (newVal) {
        store.dispatch.student.fetchAliyahSentences({
          aliyah: newVal,
        })
      }
      emit('update:aliyah', state.selectedAliyah)
    },
  )

  watch(
    () => state.selectedHaftarah,
    (newVal) => {
      logger.debug(newVal)
      emit('update:haftarah', newVal)
    },
  )

  watch(
    () => state.selectedSentenceGroup,
    (newVal) => {
      emit('update:sentenceGroup', newVal)
    },
  )

  watch(
    () => state.mySentences,
    (newVal) => {
      logger.debug('STSS, emit update:sentences')
      emit('update:sentences', newVal)
      if (!_.isEmpty(newVal)) {
        state.changeSelection = false
      }
    },
  )

  watch(store.getters.teacher.visibleLightBarMitzvot, () => {
    if (!xprops.preventAutoSingleBarMitzvahSelect) {
      if (store.getters.teacher.visibleLightBarMitzvot.length === 1) {
        state.selectedBarMitzvah = store.getters.teacher.visibleLightBarMitzvot[0]
      }
    }
  })

  watch(aliyahSentences, () => {
    state.mySentences = aliyahSentences.value
  })

  watch(haftarahSentences, () => {
    state.mySentences = haftarahSentences.value
  })

  watch(sentenceGroupSentences, () => {
    state.mySentences = sentenceGroupSentences.value
    logger.debug('STSS, watch sentenceGroupSentences, state.mySentences =')
    logger.debug(state.mySentences)
  })
})
</script>

<template>
  <v-sheet>
    <template v-if="_.isEmpty(state.mySentences) || state.changeSelection || !compressAfterSelection">
      <!-- student drop dopwn -->
      <v-row v-if="showBarMitzvahDropdown">
        <v-col md="3">
          <student-select
            v-model:barMitzvah="state.selectedBarMitzvah"
            :bar-mitzvah-clearable="xprops.barMitzvahClearable"
            @change="barMitzvahChangeHandler"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="
          (!hideParashahWhenDisabled ||
            !isParashahDisabled ||
            !hideHaftarahWhenDisabled ||
            !isHaftarahDisabled ||
            !hideSentenceGroupWhenDisabled ||
            !isSentenceGroupDisabled) &&
          !state.selectedBarMitzvah &&
          !state.selectedHaftarah &&
          !state.selectedParashah &&
          !state.selectedSentenceGroup
        "
      >
        <v-col>
          <span class="text-h5">OR</span>
        </v-col>
      </v-row>

      <!-- row with parashah / haftarah / sentence group drop down -->
      <v-row
        v-if="
          !hideParashahWhenDisabled ||
          !isParashahDisabled ||
          !hideHaftarahWhenDisabled ||
          !isHaftarahDisabled ||
          !hideSentenceGroupWhenDisabled ||
          !isSentenceGroupDisabled
        "
      >
        <!-- parashah -->
        <v-col v-if="!hideParashahWhenDisabled || !isParashahDisabled" md="3">
          <parashah-select
            ref="parashahSelect"
            v-model:parashah="state.selectedParashah"
            :clearable="xprops.parashahClearable"
            :disabled="isParashahDisabled"
            :external-set="true"
            :only="state.parshiyot"
            :prevent-auto-single-select="preventAutoSingleParashahSelect"
          />
        </v-col>
        <!-- haftarah -->
        <v-col v-if="!hideHaftarahWhenDisabled || !isHaftarahDisabled" md="3">
          <haftarah-select
            ref="haftarahSelect"
            v-model:haftarah="state.selectedHaftarah"
            :clearable="xprops.haftarahClearable"
            :disabled="isHaftarahDisabled"
            :external-set="true"
            :only="state.haftarot"
            :parashah="state.selectedParashah"
            :prevent-auto-single-select="preventAutoSingleHaftarahSelect"
          />
        </v-col>
        <!-- sentence groups -->
        <v-col v-if="!hideSentenceGroupWhenDisabled || !isSentenceGroupDisabled" md="3">
          <sentence-group-select
            ref="sentenceGroupSelect"
            v-model:sentence-group="state.selectedSentenceGroup"
            :clearable="xprops.sentenceGroupClearable"
            :disabled="isSentenceGroupDisabled"
            :only="state.sentenceGroups"
            :prevent-auto-single-select="preventAutoSingleSentenceGroupSelect"
          />
        </v-col>
      </v-row>
      <!-- aliyah drop down -->
      <v-row v-if="!!state.selectedBarMitzvah || !!state.selectedParashah || (hideAliyahWhenDisabled && !isAliyahDisabled)">
        <v-col v-if="!!state.selectedParashah" md="3">
          <aliyah-select
            v-model:aliyah="state.selectedAliyah"
            :clearable="xprops.aliyahClearable"
            :disabled="isAliyahDisabled"
            :editable="false"
            :external-set="true"
            :only="state.aliyot"
            :parashah="state.selectedParashah"
            :prevent-auto-single-select="preventAutoSingleAliyahSelect"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-btn
            block
            :disabled="
              !(
                (showBarMitzvahDropdown && !!state.selectedBarMitzvah) ||
                !!state.selectedParashah ||
                !!state.selectedHaftarah ||
                !!state.selectedAliyah ||
                !!state.selectedSentenceGroup
              )
            "
            @click="fullResetSelection"
            >Reset</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="text-h6">
        <v-col md="6">
          <span v-if="!!state.selectedBarMitzvah">
            {{ formattedBarMitzvah(state.selectedBarMitzvah) }}
            /
          </span>
          <span v-if="!!state.selectedParashah">
            {{ formattedParashah(state.selectedParashah) }}
            /
          </span>
          <span v-if="!!state.selectedHaftarah">
            {{ formattedHaftarahSimple(state.selectedHaftarah) }}
          </span>
          <span v-if="!!state.selectedAliyah">
            {{ formattedAliyahSimple(state.selectedAliyah) }}
          </span>
          <span v-if="!!state.selectedSentenceGroup">
            {{ state.selectedSentenceGroup.reading.description }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-n4">
        <v-col md="1">
          <v-btn
            block
            size="small"
            @click="
              () => {
                state.changeSelection = true
                partialResetSelection()
              }
            "
            >Change</v-btn
          >
        </v-col>
      </v-row>
    </template>
  </v-sheet>
</template>
