<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import olivierImage from '../assets/images/olivier.jpg'
import thomerImage from '../assets/images/thomer.jpg'

const router = useRouter()
const olivierUrl = computed(() => {
  return olivierImage
})

const thomerUrl = computed(() => {
  return thomerImage
})
</script>

<template>
  <v-row>
    <v-col md="6">
      <h3>Who are we?</h3>

      <p>
        Bar/Bat Mitzvah Trainer started around May, 2020 as a conversation between two cousins,
        <a href="https://www.linkedin.com/in/olivierorlow/">Olivier Orlow</a>
        and
        <a href="https://www.linkedin.com/in/thomer-gil/">Thomer Gil</a>, who had the good fortune to have their beloved grandfather, Hugo
        G&uuml;nzburger Z"L, prepare them both for their Bar Mitzvah. This site is devoted to his memory.
      </p>

      <h3 class="mt-4">Nerdy curiosity: how was this built?</h3>
      <p>
        Bar/Bat Mitzvah Trainer is under very active development. It's written in
        <a href="https://www.typescriptlang.org/">TypeScript</a> and <a href="https://www.python.org/">Python</a>. Specifically, it uses
        <a href="https://vuejs.org/">Vue.js</a>, <a href="https://vuetifyjs.com/">Vuetify</a>, <a href="https://graphql.org/">GraphQL</a> on
        the front-end, and <a href="https://www.djangoproject.com/">Django</a>, <a href="https://graphene-python.org/">Graphene-Python</a>,
        and <a href="https://www.postgresql.org/">Postgresql</a> on the back-end. Everything runs in
        <a href="https://www.docker.com/">Docker</a> containers on servers in Switzerland. Too many open-source libraries are involved to
        mention all of them, but honorary mentions go to <a href="https://parselmouth.readthedocs.io/en/stable/">Parselmouth</a>, a wrapper
        around <a href="https://www.fon.hum.uva.nl/praat/">Praat</a>, which implements natural sounding voice pitch shifting, and the
        <a href="https://github.com/jiaaro/pydub">pydub</a> project, which implements sophisticated audio manipulation.
      </p>

      <h3 class="mt-4">Credits</h3>
      <p>
        Many thanks to Shira Fischer for being generally awesome, but specifically for sharing her feedback, her insights, and for teaching
        us rules, exceptions to those rules, and the exceptions to the exceptions.
      </p>

      <h3 class="mt-4">Get in touch</h3>
      <p>
        Please
        <a
          href="#"
          @click.prevent="
            () => {
              router.push('/contact')
            }
          "
          >get in touch</a
        >.
      </p>
    </v-col>
    <v-col align="center" md="6">
      <v-row justify="center" no-gutters>
        <v-col md="6">
          <a href="https://www.linkedin.com/in/olivierorlow/"> <img class="mx-2" :src="olivierUrl" width="220px" /><br /> </a>
          Olivier Orlow
        </v-col>
        <v-col md="6">
          <a href="https://www.linkedin.com/in/thomer-gil/"> <img class="mx-2" :src="thomerUrl" width="220px" /><br /> </a>
          Thomer Gil
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped>
a img {
  border: 1px solid darkgrey;
}
</style>
