import { library } from '@fortawesome/fontawesome-svg-core'
import { faMicrophone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Compact } from '@lk77/vue3-color'
import * as Sentry from '@sentry/vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApp, h, provide } from 'vue'
import VueGtag from 'vue-gtag'
import { createI18n } from 'vue-i18n'
import { createLogger, LoggerOptions } from 'vue-logger-plugin'
import { browserDetect } from 'vue3-detect-browser'

import App from '@/App.vue'
import { useUtil } from '@/composables/util'
import { vuetify } from '@/plugins/vuetify'
import store from '@/store'
import { useApollo } from '@/util/apolloClient'
import dict from '@/util/i18n'

import router from './router'
library.add(faMicrophone)

// https://github.com/AurityLab/vue-recaptcha-v3/tree/vue-v2.x
// Vue.use(VueReCaptcha, {
//   siteKey: "6LfceMsaAAAAAIMab6AH38FHZ8VXKTlnF5_e-g_I",
//   loaderOptions: {
//     autoHideBadge: true,
//   },
// });

const loggerOptions = {
  callerInfo: true,
  consoleEnabled: true,
  enabled: true,
  level: 'error', // we re-set this a few lines below, but we have a dependency loop
} as LoggerOptions

const logger = createLogger(loggerOptions)
const { environment, isProduction } = useUtil(logger)
const { apolloClient } = useApollo(logger)

const i18n = createI18n({
  fallbackLocale: 'en-US',
  legacy: false,
  locale: 'en-US',
  messages: dict,
})

// override log level
loggerOptions.level = isProduction() ? 'error' : 'debug'
logger.apply(loggerOptions)

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})
  .component('compact-picker', Compact)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(vuetify)
  .use(browserDetect)
  .use(store.original)
  .use(logger)
  .use(i18n)

// only Google Analytics and Sentry in production
if (isProduction()) {
  app.use(VueGtag, {
    config: { id: 'G-YJ5QRNCF20' },
  })
}

if (isProduction()) {
  Sentry.init({
    app,
    dsn: 'https://aea8b1ff8d224ad1911e8fc21a120f5d@o565658.ingest.sentry.io/5707394',
    environment: environment(),
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
    },
  })
}

app.config.performance = !isProduction()
app.mount('#app')
