import { AliyahType, BarMitzvahType, HaftarahType, ParashahType } from 'types/types'

import {
  formatAliyahForStudent,
  formatAliyahForTeacher,
  formatAliyahForTeacherWithSection,
  formatAliyahSimple,
  formatRank,
  rankName,
} from '@/util/Aliyah'
import { formatBarMitzvah } from '@/util/BarMitzvah'
import { formatHaftarahForStudent, formatHaftarahForTeacher, formatHaftarahSimple } from '@/util/Haftarah'
import { formatParashah } from '@/util/Parashah'

function formattedBarMitzvah(barMitzvah: BarMitzvahType): string {
  return formatBarMitzvah(barMitzvah)
}

function formattedParashah(parashah: ParashahType): string {
  return parashah ? formatParashah(parashah) : ''
  // XXX removed for better separation as part of Vue2 -> Vue3
  // return this.parashah ? formatParashah(this.parashah) : ''
}

function formattedHaftarahForTeacher(haftarah: HaftarahType): string {
  return formatHaftarahForTeacher(haftarah)
}

function formattedHaftarahForStudent(haftarah: HaftarahType): string {
  return formatHaftarahForStudent(haftarah)
}

function formattedHaftarahSimple(haftarah: HaftarahType): string {
  return formatHaftarahSimple(haftarah)
}

function formattedAliyahSimple(aliyah: AliyahType): string {
  return formatAliyahSimple(aliyah)
}

function formattedAliyahForStudent(aliyah: AliyahType): string {
  return formatAliyahForStudent(aliyah)
}

function formattedAliyahForTeacher(aliyah: AliyahType): string {
  return formatAliyahForTeacher(aliyah)
}

function formattedAliyahForTeacherWithSection(aliyah: AliyahType): string {
  return formatAliyahForTeacherWithSection(aliyah)
}

function formattedRank(rank: number): string {
  return formatRank(rank)
}

function formattedRankName(rank: number): string {
  return rankName(rank)
}

export {
  formattedAliyahForStudent,
  formattedAliyahForTeacher,
  formattedAliyahForTeacherWithSection,
  formattedAliyahSimple,
  formattedBarMitzvah,
  formattedHaftarahForStudent,
  formattedHaftarahForTeacher,
  formattedHaftarahSimple,
  formattedParashah,
  formattedRank,
  formattedRankName,
}
