<script setup lang="ts">
// from https://stackoverflow.com/questions/76787777/how-to-create-a-datepicker-textfield-using-vuetify-3
import { computed, reactive, watch } from 'vue'

// import { useLogger } from 'vue-logger-plugin'

interface Props {
  label?: string
  color?: string
  title?: string
  header?: string
  modelValue: string
}

const xprops = withDefaults(defineProps<Props>(), {
  color: 'primary',
  header: '',
  label: 'Date',
  title: '',
})

const state = reactive({
  isMenuOpen: false,
  selectedDate: new Date(xprops.modelValue + 'T13:00Z'),
})

// const logger = useLogger()
const emits = defineEmits(['update:modelValue'])

// Format the date as a string in the 'YYYY-MM-DD' format in UTC
const formattedDate = computed<string>(() => {
  const year = state.selectedDate.getUTCFullYear()
  const month = (state.selectedDate.getUTCMonth() + 1).toString().padStart(2, '0') // getUTCMonth() is 0-indexed
  const day = state.selectedDate.getUTCDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
})

watch(
  () => state.selectedDate,
  () => {
    state.isMenuOpen = false
    emits('update:modelValue', formattedDate.value)
  },
)
</script>

<template>
  <v-menu v-model="state.isMenuOpen" :close-on-content-click="false">
    <template #activator="{ props }">
      <v-text-field
        v-bind="props"
        hide-details
        :label="label"
        :model-value="formattedDate"
        readonly
        single
        :title="xprops.title"
        variant="underlined"
      />
    </template>

    <v-date-picker v-model="state.selectedDate" :color="xprops.color" :label="xprops.label" :title="xprops.title" />
  </v-menu>
</template>
