<script setup lang="ts">
import _ from 'lodash'
import store from '@/store'

function agree() {
  store.dispatch.dialog.hide()
  if (store.getters.dialog.options.onAccept) {
    store.getters.dialog.options.onAccept()
  }
}

function cancel() {
  store.dispatch.dialog.hide()
  if (store.getters.dialog.options.onReject) {
    store.getters.dialog.options?.onReject()
  }
}
</script>

<template>
  <v-dialog
    v-model="store.getters.dialog.visible"
    persistent
    :max-width="store.getters.dialog.options.width"
    opacity="0.60"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="headline"> {{ store.getters.dialog.options.title }}</v-card-title>
      <v-card-text v-show="!!store.getters.dialog.options.message">
        <v-container fluid>
          <v-row align="start">
            <v-col>
              {{ store.getters.dialog.options.message }}
            </v-col>
          </v-row>
          <v-row align="end">
            <v-col md="3">
              <v-btn
                v-if="store.getters.dialog.options.isRejectable"
                :color="store.getters.dialog.options.rejectColor"
                @click="cancel"
                @keydown.esc="cancel"
              >
                {{ store.getters.dialog.options.rejectText }}
              </v-btn>
            </v-col>
            <v-col md="3">
              <v-btn :color="store.getters.dialog.options.acceptColor" @click.native="agree">
                {{ store.getters.dialog.options.acceptText }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
