<script setup lang="ts">
import _ from 'lodash'
import { BarMitzvahType } from 'types/types'
import { computed, onMounted, reactive, watch } from 'vue'
import { watchEffect } from 'vue'
import { useLogger } from 'vue-logger-plugin'

import { formattedBarMitzvah } from '@/composables/format'
import store from '@/store'

const logger = useLogger()

interface Props {
  barMitzvah?: BarMitzvahType | null
  barMitzvahClearable?: boolean
  disabled?: boolean
  externalSet?: boolean
  includeHidden?: boolean
  searchable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  barMitzvah: null,
  barMitzvahClearable: true,
  disabled: false,
  externalSet: false,
  includeHidden: false,
  searchable: true,
})

interface StudentSelect {
  selectedBarMitzvah: BarMitzvahType | null
}

const emit = defineEmits(['update:barMitzvah', 'change'])

const state = reactive<StudentSelect>({
  selectedBarMitzvah: props.barMitzvah,
})

onMounted(() => {
  watch(
    () => state.selectedBarMitzvah,
    async () => {
      logger.info('StudentSelect.watch state.selectedBarMitzvah')
      if (state.selectedBarMitzvah === null) {
        //  nothing
        //
        // initial pick from the drop-down when the "light" version of the data has been loaded (i.e., only name and year)
        //
      } else if (state.selectedBarMitzvah.aliyot === undefined) {
        // fetch barMitzvah details and switch to the fully populated object
        logger.debug('fetching bar mitzvah details')
        await store.dispatch.teacher.fetchBarMitzvah({
          id: parseInt(state.selectedBarMitzvah.student.id, 10),
        })
        const bmIndex = _.findIndex(store.getters.teacher.barMitzvot, (b: BarMitzvahType) => {
          logger.debug('considering b.student.username = ')
          logger.debug(b.student.username)
          logger.debug(state.selectedBarMitzvah!.student.username)
          return b.student.username === state.selectedBarMitzvah!.student.username
        })

        if (bmIndex === -1) {
          logger.debug('Unexpected !fullBM')
          return
        }
        state.selectedBarMitzvah = store.getters.teacher.barMitzvot[bmIndex]

        //
        // when the student's full barmitzvah details are loaded
        //
      } else {
        logger.debug('fetching bar mitzvah details - DONE')
      }
      emit('update:barMitzvah', state.selectedBarMitzvah)
    },
    {
      deep: true,
      immediate: true,
    },
  )

  watchEffect(() => {
    state.selectedBarMitzvah = props.barMitzvah
  })
})

const students = computed<Array<BarMitzvahType>>(() => {
  return _.sortBy(props.includeHidden ? store.getters.teacher.lightBarMitzvot : store.getters.teacher.visibleLightBarMitzvot, [
    'student.firstName',
  ])
})

const onChange = () => {
  logger.info('StudentSelect.onchange')
  emit('change', state.selectedBarMitzvah)
}

function clear() {
  state.selectedBarMitzvah = null
}

defineExpose({ clear })
</script>

<template>
  <v-autocomplete
    v-model="state.selectedBarMitzvah"
    :clearable="barMitzvahClearable"
    :disabled="props.disabled"
    :item-title="
      (barMitzvah: BarMitzvahType) => {
        return formattedBarMitzvah(barMitzvah)
      }
    "
    :items="students"
    :label="state.selectedBarMitzvah ? 'Student' : 'Choose a student'"
    no-data-text="No students! Add a Student via the Students tab."
    return-object
    :searchable="searchable"
    variant="underlined"
    @change="onChange"
  />
</template>
