/// <reference types="@types/dom-mediacapture-record" />
//
// Inspired by https://github.com/kaliatech/web-audio-recording-tests-simpler
//
import { RecorderServiceOptions, RecordingType } from '@/util/Recording'
import { VoiceActivityDetector, VoiceActivityDetectorOptions } from '@/util/VoiceActivityDetector'

const DEBUG = true
const HIGHPASS_CUTOFF = 50
const LOWPASS_CUTOFF = 3000
// const Q_VALUE = 0.5;

const CONSTRAINTS = {
  audio: {
    autoGainControl: { ideal: true },
    echoCancellation: { ideal: true },
    noiseSuppression: { ideal: true },
  },
} as MediaStreamConstraints

const defaultRecorderServiceOptions: RecorderServiceOptions = {
  audioFilters: false,
  onBaseLevel: null,
  onError: null,
  onRecordingReady: null,
  onSoundUpdate: null,
  onStart: null,
  onStartRecording: null,
  onStartSilence: null,
  onStopRecording: null,
  onTriggerLevel: null,
  onVoiceStart: null,
  onVoiceStop: null,
  voiceActivityDetection: true,
}

class RecorderService {
  private audioCtx: AudioContext | null

  private chunks: Array<Blob>

  private mimeType: string | null

  private destinationNode: MediaStreamAudioDestinationNode | null

  private inputStreamNode: MediaStreamAudioSourceNode | null

  // is recording sound, but not necessarily to a WAV file yet
  private isRecordingSound: boolean

  // will be true when isRecordSound == true, but after background audio sampling is over
  private isRecordingToWav: boolean

  private mediaRecorder: MediaRecorder | null

  private stream: MediaStream | null

  private voiceActivityDetector: VoiceActivityDetector | null

  private gainNode: GainNode | null

  // private biquadFilterNode: BiquadFilterNode | null

  private lowPassBiquadFilterNode: BiquadFilterNode | null

  private highPassBiquadFilterNode: BiquadFilterNode | null

  public options: RecorderServiceOptions | null

  private fakeSoundUpdateTimer: NodeJS.Timeout | null

  constructor(options?: RecorderServiceOptions) {
    this.audioCtx = null
    this.chunks = new Array<Blob>()
    this.destinationNode = null
    this.inputStreamNode = null
    this.fakeSoundUpdateTimer = null
    this.isRecordingSound = false
    this.isRecordingToWav = false
    this.mimeType = null
    this.mediaRecorder = null
    this.stream = null
    this.voiceActivityDetector = null
    this.gainNode = null
    // this.biquadFilterNode = null
    this.lowPassBiquadFilterNode = null
    this.highPassBiquadFilterNode = null
    options?.logger?.debug('RecorderService.constructor, options =')
    options?.logger?.debug(options)
    this.options = { ...defaultRecorderServiceOptions, ...options }

    if (DEBUG) {
      options?.logger?.debug('RecorderService.constructor, this.options = ')
      options?.logger?.debug(this.options)
    }
  }

  public pause(): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.pause()
    }
  }

  public unpause(): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.unpause()
    }
  }

  // fork the media stream for other purposes, eg, the media recorder
  public fork(): MediaStream | null {
    if (!this.stream) {
      console.error('No stream available to fork')
      return null
    }
    return this.stream.clone()
  }

  public reset(): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.reset()
    }
  }

  public setSensitivity(value: number): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.setSensitivity(value)
    }
  }

  public setSlowSilenceDetection(onoff: boolean): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.setSlowSilenceDetection(onoff)
    }
  }

  public setSoundEwmaHalfLifeMultiplier(multiplier: number): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.setSoundEwmaHalfLifeMultiplier(multiplier)
    }
  }

  public setSlowdownEwmaMultiplier(multiplier: number): void {
    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.setSlowdownEwmaMultiplier(multiplier)
    }
  }

  public isRecording(): boolean {
    return this.isRecordingSound
  }

  public startRecording(baseLevel: number = 0): void {
    if (DEBUG) {
      this.options?.logger?.debug('startingRecording, baseLevel =')
      this.options?.logger?.debug(baseLevel)
    }
    if (this.isRecordingSound) {
      if (DEBUG) {
        this.options?.logger?.debug('startingRecording; isRecordingSound, returning')
      }
      return
    }

    // This is the case on ios/chrome, when clicking links from within ios/slack (sometimes), etc.
    if (!navigator || !navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      if (DEBUG) {
        this.options?.logger?.debug('!getUserMedia')
      }
      throw new Error('No permission to access microphone!')
    }

    this.audioCtx = new window.AudioContext()

    // Create stream destination on chrome/firefox because, AFAICT, we
    // have no other way of feeding audio graph output in to
    // MediaRecorder. Safari/Edge don't have this method as of 2018-04.
    if (this.audioCtx.createMediaStreamDestination) {
      if (DEBUG) {
        this.options?.logger?.debug('startingRecording, createMediaStreamDestination')
      }
      this.destinationNode = this.audioCtx.createMediaStreamDestination()
    } else {
      if (DEBUG) {
        this.options?.logger?.debug('startingRecording, !createMediaStreamDestination')
      }
      this.destinationNode = this.audioCtx.destination as unknown as MediaStreamAudioDestinationNode
    }

    // This will prompt user for permission if needed
    if (DEBUG) {
      this.options?.logger?.debug('startingRecording, getUserMedia')
    }

    navigator.mediaDevices
      .getUserMedia(CONSTRAINTS)
      .then((stream: MediaStream) => {
        this.stream = stream
        if (this.audioCtx) {
          this.inputStreamNode = this.audioCtx.createMediaStreamSource(this.stream)
        }

        if (this.options?.onStart) {
          if (DEBUG) {
            this.options?.logger?.debug('startingRecording, onStart')
          }
          this.options.onStart()
        }
        if (DEBUG) {
          this.options?.logger?.debug('startingRecording.isRecordingToWavWithStream()')
        }

        //
        // interject the VAD detector
        //
        const vadOptions = {
          logger: this.options?.logger,
          onBaseLevel: this.options?.onBaseLevel,
          // once the base level gets set, we start the MediaRecorder; that way
          // we don't record the first few seconds of silence; after that we
          // bubble the call further up.
          onSoundUpdate: (v) => {
            if (!this.isRecordingToWav) {
              // if using the MediaRecorder, start recording now
              this.mediaRecorder?.start()

              // callback
              if (DEBUG) {
                this.options?.logger?.debug('isRecordingToWavWithStream, onSoundUpdate, isRecordingToWav = true')
                this.options?.logger?.debug('this.options.onStartRecording =')
                this.options?.logger?.debug(this.options?.onStartRecording)
              }
              if (this.options?.onStartRecording) {
                this.options.onStartRecording()
              }
              this.isRecordingToWav = true
            }

            if (this.options?.onSoundUpdate) {
              this.options.onSoundUpdate(v)
            }
          },

          onStartSilence: this.options?.onStartSilence,

          onTriggerLevel: this.options?.onTriggerLevel,

          onVoiceStart: this.options?.onVoiceStart,

          onVoiceStop: this.options?.onVoiceStop,
        } as VoiceActivityDetectorOptions

        if (DEBUG) {
          this.options?.logger?.debug('vadOptions =')
          this.options?.logger?.debug(vadOptions)
        }

        if (baseLevel) {
          if (DEBUG) {
            this.options?.logger?.debug('isRecordingToWavWithStream, baseLevel was already set')
          }
          vadOptions.noiseStartingLevel = baseLevel
          vadOptions.skipBackgroundSampling = true
        } else if (DEBUG) {
          this.options?.logger?.debug('isRecordingToWavWithStream, baseLevel was not yet set')
          vadOptions.skipBackgroundSampling = false
        }

        let inputStreamNode = this.inputStreamNode as AudioNode
        if (this.options?.audioFilters && this.audioCtx) {
          this.gainNode = this.audioCtx.createGain()
          this.gainNode.gain.setValueAtTime(1, this.audioCtx.currentTime)
          if (this.inputStreamNode) {
            this.inputStreamNode.connect(this.gainNode)
          }

          this.lowPassBiquadFilterNode = this.audioCtx.createBiquadFilter()
          this.lowPassBiquadFilterNode.type = 'lowpass'
          this.lowPassBiquadFilterNode.frequency.setValueAtTime(LOWPASS_CUTOFF, this.audioCtx.currentTime)
          this.gainNode.connect(this.lowPassBiquadFilterNode)

          this.highPassBiquadFilterNode = this.audioCtx.createBiquadFilter()
          this.highPassBiquadFilterNode.type = 'highpass'
          this.highPassBiquadFilterNode.frequency.setValueAtTime(HIGHPASS_CUTOFF, this.audioCtx.currentTime)
          this.lowPassBiquadFilterNode.connect(this.highPassBiquadFilterNode)

          // this.biquadFilterNode = this.audioCtx.createBiquadFilter();
          // this.biquadFilterNode.type = 'bandpass';
          // this.biquadFilterNode.frequency.setValueAtTime(
          //   (LOWPASS_CUTOFF + HIGHPASS_CUTOFF) / 2,
          //   this.audioCtx.currentTime,
          // );
          // this.biquadFilterNode.Q.setValueAtTime(Q_VALUE, this.audioCtx.currentTime);
          // this.gainNode.connect(this.biquadFilterNode);
          inputStreamNode = this.highPassBiquadFilterNode
        }

        // optionally pipe audio through the VAD
        if (DEBUG) {
          this.options?.logger?.debug('before voiceActivityDetection, options =')
          this.options?.logger?.debug(this.options)
          this.options?.logger?.debug('before voiceActivityDetection, audioCtx =')
          this.options?.logger?.debug(this.audioCtx)
          this.options?.logger?.debug('before voiceActivityDetection, destinationNode =')
          this.options?.logger?.debug(this.destinationNode)
        }
        if (this.options?.voiceActivityDetection && this.audioCtx && this.destinationNode) {
          this.voiceActivityDetector = new VoiceActivityDetector(this.audioCtx, inputStreamNode, this.destinationNode, vadOptions)
          this.options?.logger?.debug('this.voiceActivityDetector =')
          this.options?.logger?.debug(this.voiceActivityDetector)
        } else if (this.destinationNode) {
          inputStreamNode.connect(this.destinationNode)
        }

        if (this.destinationNode) {
          this.mediaRecorder = new MediaRecorder(this.destinationNode.stream)
        }

        if (this.mediaRecorder) {
          this.mediaRecorder.ondataavailable = (e: Event) => {
            this.ondataavailable(e)
          }
          this.mediaRecorder.onstop = () => {
            this.onstop()
          }
          this.mediaRecorder.onerror = (e: Event) => {
            this.onError(e)
          }
        }

        if (DEBUG) {
          this.options?.logger?.debug('isRecordingToWavWithStream, set isRecordingSound = true')
        }
        this.isRecordingSound = true

        if (!this.voiceActivityDetector) {
          if (DEBUG) {
            this.options?.logger?.debug('voiceActivityDetection disabled; scheduling fake onBaselevel, onSoundUpdate')
          }
          // fake soundLevel
          setTimeout(() => {
            if (vadOptions.onBaseLevel) {
              vadOptions.onBaseLevel(0.5, 100)
            } else {
              this.options?.logger?.debug('unpexpect null vadOptions.onBaseLevel')
            }
          }, 3000)

          this.fakeSoundUpdateTimer = setInterval(() => {
            if (vadOptions.onSoundUpdate) {
              vadOptions.onSoundUpdate(0.5)
            } else {
              this.options?.logger?.debug('unpexpect null vadOptions.onSoundUpdate')
            }
          }, 100)
        }
      })
      .catch((error) => {
        if (DEBUG) {
          console.error(error)
        }
        if (this.options?.onError) {
          this.options.onError(error)
        }
      })
  }

  public stopRecording(): void {
    if (DEBUG) {
      this.options?.logger?.debug('stopRecording')
      this.options?.logger?.debug(`stopRecording, isRecordingSound = ${this.isRecordingSound}, isRecordingToWav = ${this.isRecordingToWav}`)
    }
    if (!this.isRecordingSound) {
      if (DEBUG) {
        this.options?.logger?.debug('stopRecording, !isRecordingSound')
      }
      return
    }

    this.isRecordingSound = false
    this.isRecordingToWav = false
    if (this.voiceActivityDetector) {
      if (DEBUG) {
        this.options?.logger?.debug('stopRecording, voiceActivityDetector.pause()')
      }
      this.voiceActivityDetector.pause()
    }

    if (DEBUG) {
      this.options?.logger?.debug('stopRecording, this.mediaRecorder.stop()')
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.stop()
    }

    this.dismantleAudio()

    if (this.options?.onStopRecording) {
      this.options.onStopRecording()
    }
  }

  private dismantleAudio() {
    if (DEBUG) {
      this.options?.logger?.debug('dismantleAudio')
    }

    if (this.fakeSoundUpdateTimer) {
      clearInterval(this.fakeSoundUpdateTimer)
      this.fakeSoundUpdateTimer = null
    }

    if (this.chunks && this.chunks.length) {
      this.chunks.splice(0, this.chunks.length)
    }

    if (this.destinationNode) {
      this.destinationNode.disconnect()
      this.destinationNode = null
    }

    if (this.voiceActivityDetector) {
      this.voiceActivityDetector.disconnect()
      this.voiceActivityDetector.destroy()
      this.voiceActivityDetector = null
    }

    if (this.highPassBiquadFilterNode) {
      this.highPassBiquadFilterNode.disconnect()
      this.highPassBiquadFilterNode = null
    }

    if (this.lowPassBiquadFilterNode) {
      this.lowPassBiquadFilterNode.disconnect()
      this.lowPassBiquadFilterNode = null
    }

    if (this.gainNode) {
      this.gainNode.disconnect()
      this.gainNode = null
    }

    if (this.inputStreamNode) {
      this.inputStreamNode.disconnect()
      this.inputStreamNode = null
    }

    // this removes the red bar in iOS/Safari
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop())
      this.stream = null
    }

    if (this.audioCtx) {
      this.audioCtx.close()
      this.audioCtx = null
    }
  }

  private ondataavailable(evt: Event): void {
    if (DEBUG) {
      this.options?.logger?.debug(`ondataavailable, typeof = ${typeof evt}`)
    }

    if (this.isRecordingSound) {
      if (DEBUG) {
        this.options?.logger?.debug('ondataavailable; this.isRecordingSound, returning')
      }
      return
    }

    if (!this.mimeType) {
      this.mimeType = (evt as any).data.type
    }
    this.chunks.push((evt as any).data)
  }

  private onstop(): void {
    if (!this.options?.onRecordingReady) {
      this.dismantleAudio()
      return
    }

    const blob = new Blob(this.chunks, { type: this.mimeType })
    const blobUrl = URL.createObjectURL(blob)
    const recording: RecordingType = {
      blob,
      blobUrl,
      mimeType: blob.type,
      size: blob.size,
      ts: new Date().getTime(),
    }

    this.options
      .onRecordingReady(new CustomEvent('recording', { detail: { recording } }))
      .then(() => {
        URL.revokeObjectURL(blobUrl)
      })
      .finally(() => {
        this.dismantleAudio()
      })
  }

  private onError(e: Event): void {
    if (this.options?.onError) {
      this.options.onError(new Error(e.type))
    }
  }
}

export { defaultRecorderServiceOptions, RecorderService }
